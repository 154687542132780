import { Injectable } from '@angular/core';
import { View } from './view';
import { BehaviorSubject } from 'rxjs';
import { AccountService } from 'src/app/accounts/services/accounts.service';

const DEFAULT_VIEW_INFO: View = new View;

@Injectable({
  providedIn: 'root'
})
export class ViewService {
  // Observable Sources
  subjectView = new BehaviorSubject(DEFAULT_VIEW_INFO);
  view$ = this.subjectView.asObservable();
  
  constructor(
 
  ) { }

  currentView(): View {
    return this.subjectView.value;
  }

  nextView(views: View, from: string) {
    //console.log("Viewing time lallalala", views, from)
    this.subjectView.next(views);
  }

  changeView(change: string, to: boolean, data: any, from: string){
    const newView = DEFAULT_VIEW_INFO;

    newView.isLoading = false;

    switch (change){
      case 'loading': newView.isLoading = to; break;

      case 'show_window': newView.showInputWindow = to; break;

      case 'show_message': 
        newView.messageView.message = data;
        newView.showMessage = to; break;

      // MARK - Trello

      // MARK - Accounts
      case 'tools': newView.accountView.showTools = to; newView.accountView.showLogin = to ? false:true;newView.isLogged = true;break;

      case ('logout' || 'login'): 
        newView.isLogged = to; 
        if(!to){
          newView.accountView.showLogin = true;
          newView.accountView.showTools = false;
        }
        break;
      
      
    }

    this.nextView(newView, "changeView: " + change)
  }

  accountView(isLogged: boolean){
    const newView = this.currentView();

    newView.isLogged = isLogged;
    this.nextView(newView, 'accountView')

  }
}
