export class Item {
    info = -1;
    item_dimension: string = "";
    item_grade: string = "";
    item_image: string = "";
    item_name: string = "";
    item_number: number = -1;
    item_quantity: number = -1;
    item_units: string = "";
    item_volume: number = -1;
    item_weight: number = -1;
    top_price: number = -1;
    top_retailer: string = ""; 
    item_price: number = -1;
    
    item_length: number = -1;
    
    top_id: number = -1
    top_sub_id: number = -1

    sub_id: number = -1;}
