import { DefaultAppConfig } from "src/app/app.config";

export class View {
    //IS
    isMobile: boolean = false;
    isLogged: boolean = DefaultAppConfig.DEFAULT_TESTING;
    isLoading: boolean = false;

    //SHOW
    showInputWindow: boolean = false;
    showMessage: boolean = false;

    // CLASSES   
    navbarView = new NavbarView;
    accountView = new AccountView;

    messageView = new MessageView
}

export class NavbarView{
    showDropDownMenu: boolean = false;
}

export class AccountView{
    hasLocation: boolean = false;

    showLogin: boolean = !DefaultAppConfig.DEFAULT_TESTING;
    showTools: boolean = DefaultAppConfig.DEFAULT_TESTING;
    showHotbar: boolean = true;

    hotbarView = new HotbarView;
}

export class HotbarView {
    isMini: boolean = false;

    showUpdater: boolean = false;
    showAddRemove: boolean = false;
    showPickup: boolean = false;
    showRoute: boolean = true;
    showCarry: boolean = false;

  
}

export class MessageView{
    
    message: string = '';
}

/* MARK - DEFAULTS */

export const DEFAULT_HOTBAR_VIEW: HotbarView = {
    isMini: false,

    showUpdater: false,
    showAddRemove: false,
    showPickup: false,
    showRoute: true,
    showCarry: false,
}

export const DEFAULT_ACCOUNT_VIEW: AccountView = {
    hasLocation: false,

    showLogin: !DefaultAppConfig.DEFAULT_TESTING,
    showTools: DefaultAppConfig.DEFAULT_TESTING ,
    showHotbar: true,

    hotbarView: DEFAULT_HOTBAR_VIEW
}

export const DEFAULT_NAVBAR_VIEW: NavbarView = {
    showDropDownMenu: false,
}

export const DEFAULT_MESSAGE: MessageView ={
    message: 'This be the fault of DDD\'s'
}

export const DEFAULT_VIEW: View = {

    isMobile: false,
    isLogged: !DefaultAppConfig.DEFAULT_TESTING,
    isLoading: false,

    showInputWindow: false,
    showMessage: true,

    navbarView: DEFAULT_NAVBAR_VIEW,
    accountView: DEFAULT_ACCOUNT_VIEW,
    messageView: DEFAULT_MESSAGE,

}