import { Component, Input, OnInit } from '@angular/core';
import { Trello, TrelloLabel } from 'src/app/api/classes/trello';

@Component({
  selector: 'app-trello-labels',
  templateUrl: './trello-labels.component.html',
  styleUrl: './trello-labels.component.css'
})
export class TrelloLabelsComponent implements OnInit {
  @Input() labels: TrelloLabel[] = [];
  @Input() from: string = '';

  ngOnInit(): void {
    //console.log(this.labels, " what does this look like?")
  }
}

