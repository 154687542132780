import { Injectable } from '@angular/core';
import { ViewService } from '../view/view.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    private viewService: ViewService,
  ) { }

  copyText(copyText: string, copyMessage: string, from: string) {
    const checkClipboard = navigator.clipboard;


    console.log("Copying the following", copyText);
    if(copyText && checkClipboard){
      navigator.clipboard.writeText(copyText).then(() => {
        console.log('Text copied to clipboard');
        this.viewService.changeView('show_message', false, copyMessage, 'general.service:copyText')
        this.startTimer('show_message')
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
    } else {
      console.log("Failed to load the clipboard???")
      this.copyTextFallback(copyText, copyMessage, from)
    }
  
  }

  private copyTextFallback(copyText: string, copyMessage: string, from: string) {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = copyText;
    document.body.appendChild(textarea);
    textarea.select();
    
    try {
        // Execute the copy command
        document.execCommand('copy');
        this.startTimer('show_message');
        this.viewService.changeView('show_message', false, copyMessage, from); 
        console.log('Text copied using fallback method');   
    } catch (err) {
        console.error('Fallback method error:', err);
    } finally {
        // Clean up: remove the temporary textarea element
        document.body.removeChild(textarea);
    }
}

  
  // MARK: - SHOW'S
  startTimer(reason: string) {
    // Set time dependence
    let seconds = 0;
    switch (reason) {
        case 'show_message':
            seconds = 5; // test time
            break;
    }

    // Timer Function
    const timer = window.setInterval(() => {
        seconds -= 1;
        if (seconds <= 0) {
            if (reason === 'show_message') {
                this.viewService.changeView('show_message', true, 'Time to put something new in!!!', 'item.service:startTimer');
            }
            clearInterval(timer);
        }
    }, 1000);
  }
}
