
<div class="navbar-container">
    <div 
        class="navbar-btn-container"
        type="button" 
        (click)="clickDropDownMenu()" >
        <img 
            *ngIf="!view.navbarView.showDropDownMenu"
            class="navbar-btn-container-img" 
            src="assets/images/hamburger_black.svg" 
            alt="">
        <img 
            *ngIf="view.navbarView.showDropDownMenu"
            class="navbar-btn-container-img" 
            src="assets/images/x_button_black.svg" 
            alt="">
    </div>
    <div    
        class="navbar-btn-container" 
        type="button"
        [routerLink]="['/']">
        <img
            class="navbar-logo-img" 
            src="assets/images/roomerator_logo_blue.svg" 
            alt="" >  
        
    </div>
</div>