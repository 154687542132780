import { Component, ChangeDetectorRef, OnInit } from '@angular/core';

import { ViewService } from './general-services/view/view.service';
import { View } from './general-services/view/view';


import { Subscription } from 'rxjs'
import { AccountService } from './accounts/services/accounts.service';
import { StorageService } from './general-services/storage.service';
import { AdminApiService } from './api/services/admin-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'app-updater';

  view = new View;
  viewSub: Subscription;

  constructor(
    private accountSevice: AccountService,
    private viewService: ViewService,
    private detectChanges: ChangeDetectorRef,
    private storageService: StorageService,
  ){
    this.viewSub = viewService.view$.subscribe(view => {
      this.view = view;
      this.detectChanges.markForCheck();
    });
  }

  ngOnInit(): void {
    this.viewService.changeView('loading', true, null, 'app.components');
    const storageCheck = this.storageService.testStorage();
    if(storageCheck === 'Success'){
      const logged_in = this.storageService.getStorage('isLogged');
      
      if(logged_in){
        const request = this.accountSevice.login(null, 'loading');
        
        if(request){
            request.subscribe({
            next: (data: any) => {
              if(data.secure !== 'Error' && data.secure !== 0){ 
                const account_data = this.accountSevice.populateAccount(data);
                this.accountSevice.newAccountInfo(account_data);
                this.viewService.changeView('tools', true, null, 'app.components');
              }
            },
            error: (error: any) => { console.error(error)}
          })
        }
      }
    } else { console.error("Storage fail!!") }
  }
  
}


