import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from 'src/app/general-services/storage.service';
import {  Trello, TrelloCard, TrelloCheckItem, TrelloChecklist } from '../classes/trello';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { DragItem, RouteChange, RouteDragDrop } from 'src/app/accounts/account-tools/tool-routes/classes/tool-routes';


const DEFAULT_TRELLO = {
  trello_token: '',
  trello_key: '097d608a9c59562645c3fc58675e770e',
  trello_card: [],
  active_trello_card: [],
};

@Injectable({
  providedIn: 'root'
})

export class TrelloService {
  token = localStorage['trello_token'];
  key = '097d608a9c59562645c3fc58675e770e';


  subjectTrello = new BehaviorSubject<Trello>(DEFAULT_TRELLO);
  trello$ = this.subjectTrello.asObservable();

  
  params:  any = {
    'key' : this.key,
    'token' : this.token
}

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  nextTrelloInfo(newTrello: Trello, from: string) {
    this.subjectTrello.next(newTrello);
  }

  currentTrelloInfo() {
      return this.subjectTrello.value;
  }

  // MARK - SET's
  setActiveTrelloCard(card: TrelloCard|null, from: string){
    const newTrello = this.currentTrelloInfo();

    if(newTrello.active_trello_card.length > 0){newTrello.active_trello_card.pop()};
    if(card){newTrello.active_trello_card.push(card)}
    
    this.nextTrelloInfo(newTrello, "setActiveTrelloCard")
  }

  // MARK - Requests
  getRequest(request:string, id:string|null){
    const path = `https://api.trello.com/1` + this.buildGetPath(request, id);
    return this.http.get(path, { params: this.params});
  }
  
  buildGetPath(request:string, id:string|null): string{
    let path = ``;

    switch(request){      
      case 'boards': path = path + `/members/me/boards`; break;
      case 'board': path = path + `/boards/${id}`; break;
      case 'board_list': path = path + `/boards/${id}/lists`; break;
      case 'board_card': path = path + `/boards/${id}/cards`; break;
      case 'list_card': path = path + `/lists/${id}/cards?`; break;
      case 'cards': path = path + `/cards/${id}`; break;
      case 'checklists': path = path + `/checklists/${id}`; break;
      case 'labels': path = path + `labels/${id}`; break;
      case 'attachement': path = path + `/cards/${id}/attachments`; break;
    }

    return path;
  }

  putRequest(request:string, idCard:string, idCheckist: string, idCheckItem: string, data: any){
    const path = `https://api.trello.com/1` + this.buildPutPath(request, idCard, idCheckist, idCheckItem, data);
    if(request === 'archive'){ return this.http.put(path,{"closed": true}, { params: this.params})};
    
    return this.http.put(path, data, { params: this.params});
  }
  
  buildPutPath(request:string, idCard:string, idChecklist: string, idCheckItem: string, data: any): string{
    let path = ``;
    //console.log(request, idChecklist, "buildPutPath")
    switch(request){
      case 'archive': path = `/cards/${idCard}`; break;
      case 'put:checkItem': path = `/cards/${idCard}/checkItem/${idCheckItem}`;break;
      case 'put:checklist': path = `/checklists/${idChecklist}?`; break;
      case 'put:card': path = `/cards/${idCard}?`; break;
    }

    return path;
  }

  delRequest(request: string,idCard: string, idChecklist: string, idCheckItem: string ){
    const path = `https://api.trello.com/1/` + this.buildDelPath(request,idCard, idChecklist, idCheckItem);

    return this.http.delete(path, { params: this.params});
  }

  buildDelPath(requestName: string,idCard: string, idChecklist: string, idCheckItem: string){
    let path = ``;
    // Request names will come in with the Driver or Titg which needs to be cropped out
    const request = requestName.replace((/titg:|driver:/g),'')

    //console.log(request, "del request")
    switch(request){
      case 'del:checklist': path = `cards/${idCard}/checklists/${idChecklist}`; break;
      case 'del:checkItem': path = `checklists/${idChecklist}/checkItems/${idCheckItem}`; break;

    }

    return path;
  }

  getBatchRequest(urls: string[]): Observable<any>{
    const batch_url = `https://api.trello.com/1/batch?urls=${urls}`;

    return this.http.get(batch_url, { params: this.params});
  }

  postRequest(request: string, idCard: string, idChecklist: string, idCheckItem: string, data: any){
    const path = `https://api.trello.com/1` + this.buildPostPath(request, idCard, idChecklist, idCheckItem);
    // if(request === 'archive'){ return this.http.put(path,{"closed": true}, { params: this.params})};

    return this.http.post(path, data, { params: this.params});
  }

  buildPostPath(request:string, idCard: string, idChecklist: string, idCheckItem: string){
 
    let path = ``;

    switch(request){
      case('post:card'): path = `/cards`; break;
      case('post:checklist'): path = `/cards/${idCard}/checklists`; break;
      case('post:checkItem'): path = `/checklists/${idChecklist}/checkItems`; break; // Requires Data
      case('post:dragItem'): path = `/checklists/${idChecklist}/checkItems`; break; // Requires Data
      case('archive'): path = `/lists/${idCard}/archiveAllCards?`;  break;
    }

    return path;
  }

  // MARK - MORE
  assignTrelloToken(filteredCards: any[]) {
      const trelloCards: any[] = [];
      filteredCards.forEach((element) => {
          const name = element.name;
          const id = element.id;
          if (name) {
              const trelloCard = {
                  name: name,
                  id: id,
                  pictures: [],
                  contact: [],
                  emails: [],
                  telephone: [],
              };
              trelloCards.push(trelloCard);
          }
      });

      let newTrello = new Trello;
      let newToken = this.storageService['trello_token'];
      newTrello['trello_token'] = newToken;
      newTrello.trello_card = trelloCards;
      newTrello.trello_key = this.key;
      this.nextTrelloInfo(newTrello, "assignTrelloToken");
  }

  setPos(dropData: RouteDragDrop, from: string): any{
    const dragItems = dropData.dragItems;
    const currentIndex = dropData.nextIndex;

    // Get before and after positions to set new card position
    if(currentIndex === dragItems.length-1){ return 'bottom'}
    else if(currentIndex === 0){ return 0.1 }
    else{
      let beforePos = -1;
      let afterPos = -1;
      
      if(dragItems[currentIndex-1] && dragItems[currentIndex-1]&& dragItems[currentIndex - 1].pos){
        const newBeforePos = dragItems[currentIndex - 1].pos
        const checkBeforePosType = typeof newBeforePos === 'number'
        if(checkBeforePosType){
          beforePos = newBeforePos
        }
        
      } 
      else {beforePos = 99999999999999};

      if(dragItems[currentIndex-1] && dragItems[currentIndex+1]){
        const newAfterPos = dragItems[currentIndex + 1].pos
        const checkBeforePosType = typeof newAfterPos === 'number'
        if(checkBeforePosType){
          afterPos = newAfterPos
        }
      } 
      else { afterPos = 999999999999999}

      const diff = ((afterPos - beforePos)/10000000) + beforePos;

      return diff;
    }
  }

  findLinkIndex(name: string): number{
    let index = -1;

    const findIndex = name.indexOf('https');
    if(findIndex){ return findIndex}

    return index;
  }

  // MARK - CREATING  Trello Items
  createTrelloCard(trelloCard: TrelloCard): TrelloCard{
    const newCard: TrelloCard = {
      address: trelloCard.address,
      attachements:trelloCard.attachements,
      checkItemStates: trelloCard.checkItemStates,
      coordinates: trelloCard.coordinates,
      desc: trelloCard.desc,
      due: trelloCard.due,
      dueComplete: trelloCard.dueComplete,
      id: trelloCard.id,
      idAttachmentCover: trelloCard.idAttachmentCover,
      idBoard: trelloCard.idBoard,
      idCardSource: trelloCard.idCardSource,
      idChecklists: trelloCard.idChecklists,
      idLabels: trelloCard.idLabels,
      idList: trelloCard.idList,
      keepFromSource: trelloCard.keepFromSource,
      labels: trelloCard.labels,
      locationName: trelloCard.locationName,
      name: trelloCard.name,
      pos: trelloCard.pos,
      shortLink: trelloCard.shortLink,
      shortUrl: trelloCard.shortUrl,
      start: trelloCard.start,
      state: trelloCard.state,
      url: trelloCard.url,

      showAddChecklist: false,
    }

    return newCard;
  }

  createTrelloChecklist(trelloChecklist: TrelloChecklist): TrelloChecklist{
    const newChecklist: TrelloChecklist ={
      checkItems: trelloChecklist.checkItems,
      id: trelloChecklist.id,
      idBoard: trelloChecklist.idBoard,
      idCard: trelloChecklist.idCard,
      name: trelloChecklist.name,
      pos: trelloChecklist.pos,
      state: trelloChecklist.state,
    }

    return newChecklist;
  }

  createTrelloCheckItem(trelloCheckItem: TrelloCheckItem): TrelloCheckItem{
    const checkItem: TrelloCheckItem = {
      due: trelloCheckItem.due,
      dueReminder: trelloCheckItem.dueReminder,
      id: trelloCheckItem.id,
      idChecklist: trelloCheckItem.idChecklist,
      idMember: trelloCheckItem.idMember,
      name: trelloCheckItem.name,
      pos: trelloCheckItem.pos,
      state: trelloCheckItem.state,

      showAddCheckItem: false,
    }
    
    return checkItem
  }

  // MARK - Creation Roomerator to Trello
  createTrelloCardFromDragItem(dragItem: DragItem, from: string): TrelloCard{
    const newTrelloCard = new TrelloCard;

    newTrelloCard.name = dragItem.name;
    newTrelloCard.idList = dragItem.idList;
    //newTrelloCard.desc = dragItem.desc;
    //newTrelloCard.idCardSource = dragItem.idCardSource;
    newTrelloCard.idChecklists = dragItem.idChecklists;
    //newTrelloCard.keepFromSource = dragItem.keepFromSource;
    newTrelloCard.pos = dragItem.pos;
    newTrelloCard.due = dragItem.due.toDateString()

    //newTrelloCard.start = dragItem.start;
    newTrelloCard.dueComplete = dragItem.dueComplete;
    newTrelloCard.idLabels = dragItem.idLabels;
    newTrelloCard.url = dragItem.url;
    newTrelloCard.address = dragItem.location;
    newTrelloCard.locationName = dragItem.location;

    return newTrelloCard
  }

  createTrelloCheckItemFromDragItem(dragItem: DragItem, from: string): TrelloCheckItem{
    const newCheckItem = new TrelloCheckItem;

    newCheckItem.due = dragItem.due.toDateString();
    newCheckItem.id = dragItem.idCheckItem;
    newCheckItem.idChecklist = dragItem.idChecklist;
    newCheckItem.name = dragItem.name;
    newCheckItem.pos = dragItem.pos;
    if(dragItem.state){ newCheckItem.state = dragItem.state};

    return newCheckItem;
  }

  // MARK - Update
  
  handleError(error: any) {
    console.error('An error occured', error);
    return Promise.reject(error.message || error);
  }
}