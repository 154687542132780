import { Directive,Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements AfterViewInit {

  constructor(public elementRef: ElementRef) {
  }

  _autofocus: boolean = false;

  @Input() set autofocus(focus: boolean){ this._autofocus = focus;}

  ngAfterViewInit(): void {
    if(this._autofocus){
      const element = this.elementRef.nativeElement as HTMLInputElement;
      element.focus();
    }
    
  }
}