import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { AccountInfo } from '../../accounts/classes/account-settings';
import { ViewService } from 'src/app/general-services/view/view.service';
import { HotbarView, View } from 'src/app/general-services/view/view';
import { ServerService } from 'src/app/general-services/server.service';
import { DefaultAppConfig } from 'src/app/app.config';
import { AccountService } from '../../accounts/services/accounts.service';
import { Subscription } from 'rxjs';
import { HotbarService } from './hotbar.service';
import { HotbarHeader, hotbarUser } from './classes/hotbar';

@Component({
  selector: 'app-hotbar',
  templateUrl: './hotbar.component.html',
  styleUrls: ['./hotbar.component.css']
})
export class HotbarComponent implements OnDestroy {  
  minorHeaders = [true];
  
  hotbar: HotbarHeader[] = hotbarUser;
  
  accountInfo = new AccountInfo;
  view = new View;

  accountInfoSub: Subscription;
  viewSub: Subscription;

  constructor(
    private viewService: ViewService,
    private serverService: ServerService,
    private hotbarService: HotbarService,
    private accountService: AccountService,
    private detectChanges: ChangeDetectorRef
  ){
    
    this.accountInfoSub = accountService.account$.subscribe(accountInfo => {
      this.accountInfo = accountInfo;
      this.detectChanges.markForCheck();
    });

    this.viewSub = viewService.view$.subscribe(view => {
      this.view = view;
      this.detectChanges.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.accountInfoSub.unsubscribe();
    this.viewSub.unsubscribe();
  }

  setPosition(trigger: string){

    const newPostion = new HotbarView;
    const currentHotbar = hotbarUser

    // RESET VIEW
    newPostion.showAddRemove = false;
    newPostion.showCarry = false;
    newPostion.showRoute = false;
    newPostion.showUpdater = false;
    newPostion.showPickup = false;

    currentHotbar.forEach(element => { element.selected = false});

    // SET SELECTED
    let hotbarIndex = -1

    switch(trigger){
      case 'updater': newPostion.showUpdater = true;hotbarIndex = 0; break;
      case 'add': newPostion.showAddRemove = true;hotbarIndex = 1; break;
      case 'routes': newPostion.showRoute = true;hotbarIndex = 2; break;
      case 'carry': newPostion.showCarry = true;hotbarIndex = 3; break;
      case 'pickup': newPostion.showPickup = true;hotbarIndex = 4; break;
    }

    currentHotbar[hotbarIndex].selected=true;
    
    this.hotbarService.nextView(currentHotbar);

    const currentView = this.viewService.currentView();

    // Set Mini 
    newPostion.isMini = currentView.accountView.hotbarView.isMini 
  
    currentView.accountView.hotbarView = newPostion;
    console.log(currentView.accountView.hotbarView, "<------------------------")
    this.viewService.nextView(currentView, "hotbar")
  }

  minimizeToolbar(){
    const newView = this.viewService.currentView();
    
    newView.accountView.hotbarView.isMini = newView.accountView.hotbarView.isMini ? false : true;
    this.viewService.nextView(newView, 'minimizeToolbar')
  }

  logout(where: string){
    console.log("From!@#@!#", where)
    // Init Loading
    this.viewService.changeView('loading', true, null, 'hotbar.component:logout');
    if(!DefaultAppConfig.DEFAULT_TESTING){ 
      const request = this.accountService.logout();
      if(request){
        request.subscribe({
          next: (data: any) => { if(data.secure !== 'Error'){ this.viewService.changeView('logout', false, null, 'hotbar.component:logout')}},
          error: (error: any) => {
            this.viewService.changeView('logout', false, null, 'hotbar.component:logout')
            console.error(error)
          }
        })
      }
    } else { this.viewService.changeView('logout', false, null, 'hotbar.component:logout'); }
  }
}
