import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren,  } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { AdminApiService } from 'src/app/api/services/admin-api.service';
import { Driver, RouteDragDrop, DragItem, ToolRoutes, RouteBtnContextView, RouteIndexes } from './classes/tool-routes';
import { ToolRoutesService } from './tool-routes.service';
import { TrelloService } from 'src/app/api/services/trello.service';
import { TrelloCard, TrelloCheckItem } from 'src/app/api/classes/trello';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ViewService } from 'src/app/general-services/view/view.service';
import { InputWindowService } from 'src/app/modal/input-window/input-window.service';
import { InputSetting, InputViewSetting } from 'src/app/modal/inputbox/inputbox';
import { InputSettingToolRoute } from 'src/app/modal/inputbox/inputbox.setting/tool-routes';
import { ToolRouteOptionsService } from './tool-route-options/tool-route-options.service';
import { GeneralService } from 'src/app/general-services/general/general.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { InputboxService } from 'src/app/modal/inputbox/inputbox.service';

@Component({
  selector: 'app-tool-routes',
  templateUrl: './tool-routes.component.html',
  styleUrls: ['./tool-routes.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ToolRoutesComponent implements OnInit, OnDestroy {
  @ViewChild('prototypeDriverItemList',{static: false}) public prototypeDriverList!: ElementRef;
  @ViewChild('activeDriverItemList',{static: false}) public activeDriverList!: ElementRef;
  @ViewChild('routeItemContainer',{static: false}) public routeItemContainer!: ElementRef;

  @ViewChildren('activeDriveItems', { read: ElementRef }) activeDriveItems!: QueryList<ElementRef>;  
  
  trello_token = localStorage['trello_token'];
  
  routeInfo = new ToolRoutes;
  routesSub: Subscription;

  dragTest: boolean = false;

  showList: boolean[] = []
  boardName: string = '';

  setupDrivers: Driver[] = [];
  setupDriverSelected: boolean[] = [];
  
  showItemOrCard: boolean[][] = [[true,true,true,true,true,],[true,true,true,true,true,],[true,true,true,true,true,],[true,true,true,true,true,]];
  showExtraDriverDetails: boolean[][] = [[true,true,true,true,true,],[true,true,true,true,true,]];
  showTitgCardIndex: number = -1;

  // Driver && Titg Check Item Options Show
  showDriverCheckItemOptions: boolean[] = [];
  showTitgCheckItemOptions: boolean[] = [];

  activeRouteIndexes: RouteIndexes|null = null;
  activeDriver: Driver|null = null;
  activeDragItem: DragItem|null = null;
  activeCheckItem: TrelloCheckItem|null = null;

  // Show add input boxes
  showTitgAddChecklistInput: boolean[]=[];

  showAddCustomCheckItem: boolean[] = [];
  
  // Move Titg Items to Prototype or Active
  showMoveTitgItems: boolean[] = [];
  showTitgCardChecklist: boolean[][] = [];
  

  // Input Setting for App-Inputbox
  inputSettingGeneral: InputSetting = InputSettingToolRoute;

  inputValue: string = ''; // SUPER IMPORTANT: Used for inputbox mutable value
  outputValue: string = '';
 
  trackDrag: boolean = false;
  dragItemOptions: any[] = ['hello'];

  // Active Move 
  activeMove = false;

  constructor(
    private apiService: AdminApiService,
    private routeService: ToolRoutesService,
    private detectChanges: ChangeDetectorRef, 
    private trelloService: TrelloService,
    private viewService: ViewService,
    private generalService: GeneralService,
    private windowService: InputWindowService,
    private btnOptionService: ToolRouteOptionsService, 
    private sanitizer: DomSanitizer,
    private inputboxService: InputboxService,
  ){
    this.routesSub = routeService.routeView$.subscribe(newRoute => {
      this.routeInfo = newRoute;
      this.detectChanges.markForCheck();
    });
  }

  ngOnInit(): void {
    this.showDriverCheckItemOptions.push()
    if(this.trello_token){ 
      // Morning Protocol
      this.routeService.refreshAllBoards();
    
      // Move all today's delivery card to TiTg
      //this.routeService.movePendingToTitg();
      //this.getTrelloBoards();
    } 
    else { this.apiService.requestTrelloToken('routes')};
  }

  ngOnDestroy(): void {
    this.routesSub.unsubscribe();
  }

  // MARK - DRIVER AUTOMATION  
  moveAllDragItemFromTitg(type: string, driver: Driver, indexTitgCardOrDriver: number, listIndex: number, itemIndex: number){
    
    // Setup items to be moved
    const currentRouteInfo = this.routeService.currentRouteInfo();

    const dragCard = currentRouteInfo.titgCardCatalog[listIndex];
    const preDragItems: DragItem[] = dragCard.dragItems;  
    

    const dragItems: DragItem[] = [];
    preDragItems.forEach(item => {if(!item.routed){dragItems.push(item)}});

    const dropData: RouteDragDrop = {
      dragCard: [dragCard], 
      dragItems: dragItems,
      nextIndex: -1, 
      previousIndex: -1,
      indexTitgCardOrDriver: indexTitgCardOrDriver,
      driver: driver,

      postItems: dragItems
    }
    
    // Initiate and Complete the moving procedure
    this.routeService.moveDragItems(dropData, 'moveTitgToDriver');

    // Dimisss Driver Selector
    this.showMoveTitgItems[listIndex] = false;
    //console.log(dragItemsToBeMoved, "<------ helo helo")

  }

  updateDragItem(type: string, driver: Driver| null, passedData: DragItem|TrelloCard, checkItem: TrelloCheckItem|null, routeIndexes: RouteIndexes, from: string): any{
    const currentRouteInfo = this.routeService.currentRouteInfo()
    let newData: any = {};
    let idCard = '';
    let idCheckItem = '';
    let idChecklist = '';

    
    const checkDragItem = passedData instanceof DragItem;
 
    const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver
    const indexTitgChecklistOrRouteDragItem = routeIndexes.indexTitgChecklistOrRouteDragItem
    const indexDragItem = routeIndexes.indexDragItem;

    let currentDriver = new Driver;
    if(driver){ currentDriver = driver} 
    else{currentDriver = currentRouteInfo.activeDrivers[indexTitgCardOrDriver]};

    
    
    let dragItems: DragItem[] = [];    
    if(indexTitgCardOrDriver > -1){dragItems = this.routeService.currentRouteInfo().activeDrivers[indexTitgCardOrDriver].dragItems};

    // Drop Data Assignment for certain switches
    const routeDragDrop: RouteDragDrop = {
      dragCard: [], 
      dragItems: dragItems,
      previousIndex: -1,
      nextIndex: indexDragItem, 
      indexTitgCardOrDriver: indexTitgCardOrDriver,
      driver: currentDriver,

      postItems: dragItems
    }

    // Check if we have a card or DragItem
    if(checkDragItem){
      idCard = passedData.idCard;
      if(!idCard || from === 'moveDrivertoDriver'){ 
        idCard = passedData.idRouteOrTitgCard };

      // Catch for drag and drop move driver to same driver
      idChecklist = passedData.idChecklist;

      idCheckItem = passedData.idCheckItem;
      routeDragDrop.postItems = [passedData];
    }else{
      console.log(passedData, "This is where the fun starts");
      idCard = passedData.id;
    }

    console.log(type,)


    switch(type){
      // MARK - UPDATE: CheckItems
      case 'update_check_item_state': 
        console.log(type,from, "EHWHEHREHWHWE TOOOO ", driver, "opopopop", currentDriver)
     
        if(from.includes('driver') && currentDriver){
          console.log("Checking the item state", driver)
          idCard = currentDriver.routeCard.id;
          newData = {state: passedData.state === 'complete' ? 'incomplete': 'complete'};}
      
        // checkitem from TITG (Most likely)
        if(from.includes('titg') && passedData instanceof DragItem && checkItem){
         
          idCard = passedData.idCard;
          idChecklist = checkItem.idChecklist;
          idCheckItem = checkItem.id;

          newData = {state: checkItem.state === 'complete' ? 'incomplete': 'complete'};
        }
        this.routeService.requestTrelloAction(newData, routeIndexes,idCard,idChecklist,idCheckItem, 'put:checkItem',from+':state');
      break;

      case 'update_check_item_position':
        newData = {pos: this.trelloService.setPos(routeDragDrop,'updateDragItem:update_check_item_position')};
        
        let passFrom = from;
        if(from === 'moveDrivertoDriver'){ passFrom = 'driver:checkItem'};

        this.routeService.requestTrelloAction(newData, routeIndexes,idCard,idChecklist,idCheckItem, 'put:checkItem',passFrom);
      break;
      
      case 'update_checklist_position':
        newData = {pos: passedData.pos};
        this.routeService.requestTrelloAction(newData, routeIndexes,idCard,idChecklist,idCheckItem, 'put:checklist',from);
      break;
      
      // MARK - UPDATE: Card
      case 'put:card_due_complete':
        if(passedData instanceof TrelloCard || passedData instanceof DragItem){
          newData = { dueComplete: passedData.dueComplete ? false : true};
          this.routeService.requestTrelloAction(newData, routeIndexes,idCard,idChecklist,idCheckItem, 'put:card',from);
        }
        
      break;
      // MARK - checkitem Update
      case 'update_checkItem_due':
        if(passedData instanceof TrelloCard || passedData instanceof DragItem){
          newData = { dueComplete: passedData.dueComplete ? false : true};
          console.log(idCheckItem,idCard)
          this.routeService.requestTrelloAction(newData, routeIndexes,idCard,idChecklist,idCheckItem, 'put:card',from);
        }
      
      break;
      
    }

  }

  // MARK - GET Func
  getTrelloBoards(){
    this.trelloService.getRequest('boards', null).subscribe({
      next: (data: any) => {
        if(data){
          const boards = data;
          const currentTrelloDriverInfo = this.routeService.currentRouteInfo();

          currentTrelloDriverInfo.view.trelloView.showBoards = true;
          currentTrelloDriverInfo.boards = boards;
          currentTrelloDriverInfo.view.isLoading = false;
          currentTrelloDriverInfo.view.showDrivers = true;

          this.routeService.nextRouteInfo(currentTrelloDriverInfo, 'boards')
        }
      },
      error: (error: any) => { console.error(error)}
    })
  }

  getTrelloBoard(id: string){
    this.trelloService.getRequest('board', id).subscribe({
      next: (data: any) => {
        if(data){

        }
      },
      error: (error: any) => { console.error(error)}
    })
  }

  getMasterTrelloLists(board: any, index: number){
    //console.log("REQUESTING SOME BOARDS AND THINGS ______________________________>", board)
    this.trelloService.getRequest('board_list', board.id).subscribe({
      next: (data: any) => {
        if(data){
          if(data){ 
            const currentRouteInfo = this.routeInfo;

            this.boardName = board.name;
            currentRouteInfo.lists = data;
            currentRouteInfo.view.trelloView.showLists = true;

            this.showList = [];
            this.showList[index] = true;

            this.routeService.nextRouteInfo(currentRouteInfo, "TrelloLists");
          }
        }
      },
      error: (error: any) => { console.error(error)}
    })
  }

  getCards(requestId: string,  driverIndex: number, typeIndex: number, from:string){    
    let mapRequest: any
    if(typeIndex !== 0){ mapRequest= this.routeService.getRequest([requestId], from , 'list_card')}

    if(mapRequest){
      let request: any = null;

      if(mapRequest.length > 1){request = forkJoin(mapRequest)};
      if(mapRequest.length === 1){request = mapRequest[0]};

      if(request){  

        request.subscribe({
          next: (data: TrelloCard[]) => {
            const newRouteInfo = this.routeService.currentRouteInfo();
            const mapCards = this.routeService.mapActiveCardtoDrag(data, newRouteInfo.activeDrivers[driverIndex], "getCards");
            newRouteInfo.activeDrivers[driverIndex].routeCards = mapCards;
            this.routeService.nextRouteInfo(newRouteInfo, from);
          },
          error: (error: any) => { console.error(error)}
        })   
      }
    }
  }

  getPictureBoard(){

    const currentView = this.routeService.currentRouteInfo();
    const minorHeaders = currentView.view.routeViewObjects.minorHeaders;

    if(minorHeaders[5]){
      const requestId = `5f32f726dc3428568b44a7e8`;

      let mapRequest = this.routeService.getRequest([requestId], 'getPictures' , 'list_card')

      if(mapRequest){
        let request: any = null;

        if(mapRequest.length > 1){request = forkJoin(mapRequest)};
        if(mapRequest.length === 1){request = mapRequest[0]};

        if(request){  
          request.subscribe({
            next: (data: TrelloCard[]) => {
              const newRouteInfo = this.routeService.currentRouteInfo();
              newRouteInfo.utilities.pictures = data;
              newRouteInfo.view.showPictures = true;
              this.routeService.nextRouteInfo(newRouteInfo, 'getPictures');
            },
            error: (error: any) => { console.error(error)}
          })
        }
      }
    
    }
  }

  getPictures(card: TrelloCard, index: number){
    console.log(card, "<-------------------------- Pictures")

    const requestId = card.id;

    let mapRequest = this.routeService.getRequest([requestId], 'getPictures' , 'attachement')

    if(mapRequest){
      let request: any = null;

      if(mapRequest.length > 1){request = forkJoin(mapRequest)};
      if(mapRequest.length === 1){request = mapRequest[0]};

      if(request){  
        request.subscribe({
          next: (data: any) => {
            const newRouteInfo = this.routeService.currentRouteInfo();
            newRouteInfo.utilities.pictures[index].attachements = data;
            newRouteInfo.view.showBackground = true;
            this.routeService.nextRouteInfo(newRouteInfo, 'getPictures');
          },
          error: (error: any) => { console.error(error)}
        })
      }
    }
  }

  setDropLabel(dragItem: DragItem){
    console.log("Set drop laels!!!!@#", dragItem)
  }

  // MARK - Import multi POST Functions
  generateDriversRoutes(driver: Driver){ // REFACTORED 2024-03-09
    // Remove the driver that is being added
    const newRouteView = this.routeService.currentRouteInfo();
    const findAvailableDriveIndex = newRouteView.availableDrivers.findIndex(availableDriver => availableDriver === driver);
   
    if(findAvailableDriveIndex > -1){
      newRouteView.availableDrivers.splice(findAvailableDriveIndex, 1)
      this.routeService.nextRouteInfo(newRouteView, 'generateDriversRoutes');
    };

    // Prepare for diver board creation
    

    let drivers: Driver[] = [];
    drivers.push(driver)

    const postDriver = this.routeService.putDriversRouteRequest(drivers);
    
    if(postDriver){
      postDriver.subscribe({
        next: (driverCards: any) => {
          // Assign Cards     
          const cardRoute = driverCards[0];
          const cardMaintenance = driverCards[1];
        
          // Link maintenance card to route card
          const nameMaintenance = 'https://trello.com/c/' + cardMaintenance.id;

         

          const checkItemMaintenance = this.trelloService.createTrelloCheckItem(driverCards[1]);
          checkItemMaintenance.name = nameMaintenance;

          const postAddMaitenanceCheckItem = this.trelloService.postRequest('post:checkItem', '', cardRoute.idChecklists[0],'', checkItemMaintenance);


          postAddMaitenanceCheckItem.subscribe({
            next: (data:any) => {
              const newDragItemMaintenance = this.routeService.mapActiveCardtoDrag([cardMaintenance],driver,'generateDriversRoutes')

              driver.routeCards.push(newDragItemMaintenance[0]);
              driver.routeCard = cardRoute
              
              // Assign New checkITem to RouteDragDrop
              const newCheckItemMaintenance = this.routeService.mapActiveDriverDragItem(data, driver, 0, 'generateDriversRoutes');
              driver.dragItems.pop()
              driver.dragItems.push(newCheckItemMaintenance);

              // Push the new Driver
              newRouteView.activeDrivers.push(driver);

              // Open the active board is not already open
              newRouteView.view.routeViewObjects.minorHeaders[4] = true;

              // Remove cloned maintenance card
              //console.log(newRouteView)

              this.routeService.nextRouteInfo(newRouteView, 'generateDriversRoutes');

            },            
            error: (error: any) => { console.error(error)}

          })          
        },
        error: (error: any) => { console.error(error)}
      })
    }
  }

  // MARK: Refresh
  initRefresh( state: number|null, from: string){
    let currentState = state;

    if(state === null){ currentState = this.routeService.currentRouteInfo().view.showBoard};
    this.routeService.setView('show_board', currentState, null, 'initRefresh');
    this.routeService.refreshAllBoards();
  }

  initModifyCheckItem(indexCheckItem: number, domRect: DOMRect, dragItem: DragItem, checkItem: TrelloCheckItem,  routeIndexes: RouteIndexes, requestName: string, from: string){
    console.log(dragItem, "What this!!@#@#", from, requestName)
    routeIndexes.indexDragItem = indexCheckItem;

    this.inputValue = checkItem.name;
    this.activeCheckItem = checkItem;
    this.showInputContainer(domRect,null,dragItem,checkItem,routeIndexes,requestName)
  }

  initDelCheckItem(dragItem: DragItem, checkItem: TrelloCheckItem | null, routeIndexes: RouteIndexes, requestName: string, from: string){
    if(checkItem){
      const idCheckist = checkItem.idChecklist;
      const idCheckItem = checkItem.id;

      this.routeService.requestTrelloAction(null, routeIndexes, '', idCheckist, idCheckItem,requestName, from);
      
    } else {this.showInputContainer( null, null,dragItem, null,null,'dimiss')}
  }

  // MARK - Show Tracking
  showSubItems(dragItems: DragItem, from: string){
    console.log(dragItems, from)
  };

  showCardInfo(dragItem: any, from: string){
    console.log(dragItem, "Drag Request!")

    if( dragItem.idCard || dragItem.id){
      const routeIndexes = new RouteIndexes;
      this.routeService.getCard(dragItem, routeIndexes, from);
    } else { this.routeService.setView('show_dismiss', false, null,'showCardInfo')}
  };

  showIframe(url:string){
    // Wouldn't work with Trello
    this.windowService.changeModalWindowInfo('inputWindow', 'iframe', url);
    this.viewService.changeView('show_window', true, null, 'tool-routes.component:showIframe')
  };

  showChecklist(dragItem: DragItem, routeIndexes: RouteIndexes, from: string){
    const action = dragItem.action.toLowerCase();
    if(
      dragItem.pos !== 999999999999 && (
      action.includes('return')|| 
      action.includes('pickup')||
      action.includes('p&p') ||
      from.includes('titg'))){

      const newRouteView = this.routeService.currentRouteInfo();

      const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver;
      const indexTitgChecklistOrRouteDragItem = routeIndexes.indexTitgChecklistOrRouteDragItem;
      const indexDragItem = routeIndexes.indexDragItem;
    
      let idCheckist = '';

      // TITG Driver Checklist
      if(from.includes('titg')){
        const currentCard = newRouteView.titgCardCatalog[indexTitgCardOrDriver];

        if(currentCard.showChecklist[indexTitgChecklistOrRouteDragItem]){currentCard.showChecklist[indexTitgChecklistOrRouteDragItem]=false;
        } else {
          idCheckist = dragItem.idChecklists[indexTitgChecklistOrRouteDragItem];
          this.routeService.getChecklist(routeIndexes,idCheckist,from)};
      } 
      
      // Driver Checklist Items
      if(from.includes('driver')) { 
        // Request the Checklist and open it

        // This is for driver route items
        if(from.includes('checkItem')){  
          if(dragItem.checklists.length > 0){
            const lengthChecklist = newRouteView.activeDrivers[indexTitgCardOrDriver].dragItems[indexTitgChecklistOrRouteDragItem].checklists.length;
            newRouteView.activeDrivers[indexTitgCardOrDriver].dragItems[indexTitgChecklistOrRouteDragItem].checklists.splice(0,lengthChecklist);
            this.routeService.nextRouteInfo(newRouteView,'showContextMenu:' + from)
          }else{ this.routeService.getChecklists(dragItem, routeIndexes, from)}};

        // This is for driver cards
        if(from.includes('card')){

        }
      }
    }
  }

  showCheckItemOptionsBtn(from: string, index:number): boolean{
    if(from.includes('titg')){if(this.showTitgCheckItemOptions && this.showTitgCheckItemOptions[index]){return true}};
    if(from.includes('driver')){if(this.showDriverCheckItemOptions && this.showDriverCheckItemOptions[index]){return true}};
    return false;
  }

  showTitgSidebar(){this.routeService.setView('show_titg_sidebar',null,null,'showTitgSidebar')};

  showArchiveConfirm(idCard: string , from: string){
    const newRouteBtnContextView: RouteBtnContextView = this.routeService.generateRouteBtnContextViewFromDragItem(null, idCard,from);

    this.routeService.setRouteBtnContextView(newRouteBtnContextView, 'archiveCard')
    
    this.btnOptionService.showConfirmWindow('archive', null)
  };

  showContextMenu(data:DragItem| TrelloCard, routeIndexes: RouteIndexes, domRect: DOMRect|null, type: string, from: string): boolean{    
    let changeType = type;

    const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver;
    let indexTitgChecklistOrRouteDragItem = routeIndexes.indexTitgChecklistOrRouteDragItem
    let indexDragItem = routeIndexes.indexDragItem;

    if(indexTitgChecklistOrRouteDragItem < 0){indexTitgChecklistOrRouteDragItem = 0}
    if(indexDragItem > 0){ indexDragItem = 0 }
    
    // Check for move to 
    if(type === 'titg:card:move_all'){
      const checkView = this.routeInfo.view;
      changeType = 'titg:card:move_all';
      if(checkView.showBoard === 1 ){ changeType = 'put:card_to_list'};    
    }
  
    // Setup information for actions
    const btn = this.routeService.assignRouteChange(data, indexTitgCardOrDriver, indexTitgChecklistOrRouteDragItem, indexDragItem, changeType, from);
    
    // Pass the info in an object
    const passData = {showBtnOptions: null, btn: btn, domRect: domRect };
    //console.log("Passed data for show context menu", passData)

    this.routeService.setView('show_btn_options', null , passData, from);


    return false;
  };

  showInputContainer(domRect: DOMRect|null, driver:Driver|null, dragItem: DragItem|null, checkItem: TrelloCheckItem|null, routeIndexes: RouteIndexes | null, from: string){
    
    // Input Button settings
    this.inputSettingGeneral.input_container_css_setting = 'tool-route-input-padding';
    this.inputSettingGeneral.input_has_action_btn = true;

    // Clear Show Array's
    const currentRouteInfo=this.routeService.currentRouteInfo();

    currentRouteInfo.view.showInputbox = true;
    currentRouteInfo.view.showBackground = true;

    // Setup Active Variables
    if(driver){this.activeDriver = driver};
    if(dragItem){this.activeDragItem = dragItem};
    if(checkItem){this.activeCheckItem = this.activeCheckItem};
    if(routeIndexes){this.activeRouteIndexes = routeIndexes};

    if(currentRouteInfo.view.showInputbox && domRect){

      const newInputBoxView: InputViewSetting = new InputViewSetting;
    
      this.inputSettingGeneral.input_dom_rect = domRect;
      newInputBoxView.showInputBackground = true;

      this.inputSettingGeneral.input_action = from;

      // Rename placeholder
      if(from.includes('dragItem')){this.inputSettingGeneral.input_place_holder = "Add a route item"};
      if(from.includes('checklist')){this.inputSettingGeneral.input_place_holder = "Add a checklist" };
      if(from.includes('checkItem')){this.inputSettingGeneral.input_place_holder = "Add a checklist item"};
      
      this.activeDriver = driver;
      this.activeDragItem = dragItem;
      this.activeRouteIndexes = routeIndexes;
     
      this.inputboxService.nextInputView(newInputBoxView, 'showInputContainer:submit');
    }
    
    this.routeService.nextRouteInfo(currentRouteInfo,'showInputContainer:'+from)
  };

  showCheckItemsBtns(state: string, indexCheckItem: number, from: string){
    if(from.includes('titg')){
      if(state.includes('enter')){
        this.showTitgCheckItemOptions.fill(false,indexCheckItem); 
        this.showTitgCheckItemOptions[indexCheckItem]=true;
      }
      if(state.includes('leave')){this.showTitgCheckItemOptions=[]}
    }

    if(from.includes('driver')){
      if(state.includes('enter')){
        this.showDriverCheckItemOptions.fill(false,indexCheckItem); 
        this.showDriverCheckItemOptions[indexCheckItem]=true;
      }
      if(state.includes('leave')){this.showDriverCheckItemOptions=[]}
    }
  }
 
  // MARK - Drag and drop Stuff
  onDrop(dropEvent: CdkDragDrop<any>, from: string): void {
    let initDel = false;

    // Previous Container Data
    const previousIndex = dropEvent.previousIndex;
    const previousContainer = dropEvent.previousContainer.data;
    const previousDropItem: DragItem = previousContainer[previousIndex];
    
    // New Container Data
    const nextIndex = dropEvent.currentIndex;
    const nextContainer = dropEvent.container.data;
    const nextDropItem = nextContainer[nextIndex];

   
    // Find current Driver list
    let driver: Driver = new Driver;
    const activeDrivers = this.routeService.currentRouteInfo().activeDrivers;
    const indexTitgCardOrDriver  = activeDrivers.findIndex(boards => boards.dragItems === nextContainer);
    
    if(indexTitgCardOrDriver >= 0){driver = activeDrivers[indexTitgCardOrDriver]};
    const currentBoard = previousContainer[previousIndex]['driver'];
    
    // Set Route Indexes
    const routeIndexes:RouteIndexes = {
      indexTitgCardOrDriver:indexTitgCardOrDriver,
      indexTitgChecklistOrRouteDragItem: nextIndex,
      indexDragItem: nextIndex
    }

    // Return to original container
    if (dropEvent.previousContainer === dropEvent.container) {
      //Check for TITG Drag Item sorting ( Need to block sorting out the drop system )
      //console.log(previousDropItem, "<------------ start here")
      const action = previousDropItem.action.toLowerCase();
      
      const dropCheck = action.includes('drop') || action.includes('carry') || action.includes('boom') 
      const idCheckItemCheck = previousDropItem.idCheckItem === '' && previousDropItem.driver === 'TBD';
      const titgDropCheck = dropCheck && idCheckItemCheck;

      // Continue with moving of items cause it's not the drop on the TITG Drag Item board
      if(!titgDropCheck){
        moveItemInArray(
          dropEvent.container.data,
          dropEvent.previousIndex,
          dropEvent.currentIndex
        );
        if(dropEvent.previousIndex !== dropEvent.currentIndex){
          // Drop event where a driver checkitem pos is being swapped
          if(currentBoard){
            // Check if the Swap is for a Checklist or Checklist Item

            this.updateDragItem('update_check_item_position', driver, previousDropItem, null, routeIndexes, from)}
             // Drop event where a checklist is being swapped with another in the TITG list
          else{
            const posPreviousLeadingItem = nextContainer[nextIndex-1];
            //console.error(nextContainer, nextIndex, " <-----------------")
            if(previousDropItem && nextIndex !== 0){ previousDropItem.pos = posPreviousLeadingItem.pos + 1}
            else{previousDropItem.pos = 0}
           
          
            this.updateDragItem('update_checklist_position', driver, previousDropItem, null, routeIndexes, from)}
          } 
      }

     
    } else {        
      //console.log(currentBoard, " here is the board deter", previousContainer, previousIndex)
      
      if(currentBoard && currentBoard !== 'TBD'){initDel = true};

      // Assign Driver for emailer
      if(driver){previousContainer[previousIndex]['driver'] = driver.driverInfo.firstName}
      else{previousContainer[previousIndex]['driver'] = 'TBD'};

     
      // Move the card
      transferArrayItem(
        dropEvent.previousContainer.data,
        dropEvent.container.data,
        dropEvent.previousIndex,
        dropEvent.currentIndex
      );

      const dropData: RouteDragDrop = {
        dragCard: [], 
        dragItems: dropEvent.container.data, 
        previousIndex: previousIndex,
        nextIndex: nextIndex, 

        indexTitgCardOrDriver: indexTitgCardOrDriver,
        driver: driver,

        postItems:[dropEvent.container.data[nextIndex]]
      }

      //if(dropContainerName)
      if(!initDel){ this.routeService.moveDragItems(dropData, 'moveTitgToDriver')};
      if(initDel) { this.routeService.moveDragItems(dropData, 'moveDrivertoDriver')};


    }
  
  };

  onTitgPredicate(cdkDragItem: CdkDrag<DragItem>, dragChecklist:CdkDropList<DragItem[]>): boolean{return false};

  onDriverCardPredicate(cdkDragItem: CdkDrag<DragItem>, dragChecklist:CdkDropList<DragItem[]>): boolean{return false};

  onDragItemPredicate(dragItem: CdkDrag<DragItem>, dragChecklist:CdkDropList<DragItem[]>){}

  canDropActiveItem(index: number, item: CdkDrag<any>,drop: CdkDropList<any>): boolean{
    function findHighestCompleteIndex(array: any) {
      let highestIndex = -1;
      for (let i = 0; i < array.length; i++) {if (array[i].state === 'complete') {  highestIndex = i;}}
      return highestIndex;
  }
    
    // Can't put an item higher than the maintenance card
    if(index === 0 ){ return false};

    // Find the last completed card index
    const dropCheck = drop && drop.data && drop.data.length > 0;
    if(dropCheck){
      const highestCompletedIndex = findHighestCompleteIndex(drop.data);
      if(index <= highestCompletedIndex){ console.log(highestCompletedIndex, "Lets see this");return false};
    }


    return true;
  };

  // MARK - Input Actions
  keyPressedInputbox(event: any){
    const driver = this.activeDriver;
    const dragItem = this.activeDragItem;
    const routeIndexes = this.activeRouteIndexes;

    let idCard = '';
    let idChecklist = '';
    let idCheckItem = '';

    let from = this.inputSettingGeneral.input_action;

    if(event && typeof event === 'string'){

      switch (event){
        case 'esc': 
          this.dismissModalContainers();
          break;
        case 'enter': 
          //Drivers
          if(this.outputValue && routeIndexes){

          
            let data = { name: this.outputValue, pos: 'bottom'};
            let requestName = 'post:';

            if(from){
              if(from.includes('driver')){
                if(this.inputValue){requestName = 'put:'};

                console.error("Frommmmmmm", requestName, from)

                // Add driver Route Item && checkItem
                if(from.includes('dragItem') && this.activeDriver ){
                  requestName = requestName + 'dragItem';
                  idChecklist = this.activeDriver.routeCard.idChecklists[0];
                }
                if(from.includes('checkItem') && this.activeDragItem){
                  // Inbound Drag Item is a Trello CheckItem
                  requestName = requestName + 'checkItem';
                  idChecklist = this.activeDragItem.checklists[0].id;
                }
              }

              // Titg
              if(from.includes('titg')){
                if(this.inputValue){requestName = 'put:'};
                // Add driver Route Item
                if(from.includes('checklist') && !from.includes('checkItem')){
                  requestName = requestName + 'checklist';
                  idCard = this.routeInfo.titgCardCatalog[routeIndexes.indexTitgCardOrDriver].idCard;
                }
                // Add driver checkitem
                if(from.includes('checkItem')){
                  requestName = requestName + 'checkItem';
                  // Make a CheckItem
                  if(requestName.includes('post')){idChecklist = this.routeInfo.titgCardCatalog[routeIndexes.indexTitgCardOrDriver].idChecklists[routeIndexes.indexTitgChecklistOrRouteDragItem]};
                  // Modify a CheckItem
                  if(requestName.includes('put') && this.activeCheckItem){
                    idCard = this.routeInfo.titgCardCatalog[routeIndexes.indexTitgCardOrDriver].idCard
                   
                    idCheckItem= this.activeCheckItem.id;
                  };
                }
              }
              //console.log(data, routeIndexes, idCard, idChecklist, idCheckItem, requestName, from, "Data time baby");
              this.routeService.requestTrelloAction(data,routeIndexes,idCard, idChecklist, idCheckItem,requestName,from);
                      
              // Clear Input window
              this.showInputContainer(null, null, dragItem,null,null,'dimiss');
            }
          }
      }
    }
  }

  keyPressedEnter(event: string){
    switch(event){
      case 'suppress': console.log("suppressing FIReeeee!!!"); break;
    }
  }
  
  // MARK - Random use Functions
  copyUrl(url: string){ console.log('copyinhg', url);this.generalService.copyText(url, "URL Copied!", 'tool-routes.components:copyUrl' )};

  sanitizeUrl(url: string): SafeUrl{
    const safeUrl: SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    return safeUrl
  };

  setMinifyDriver(driverIndex: number){ this.routeService.setView('mini', null, driverIndex,'driverIndex')};
    
  dismissModalContainers(){
    // Clear input Values
    this.outputValue = '';
    this.inputValue = '';

    // Clear Active Values
    this.activeDriver = null;
    this.activeDragItem = null;
    this.activeRouteIndexes = null;

    this.routeService.setView('dismiss', true, null, 'dismissModalContainers'); 
  };

  toggleShowCompletedRouteItems(driver: Driver){ driver.driverView.showCompleteRouteItems = this.routeService.setupCompletedRouteItems(driver)};
  
  toggleEmailOrdered(driver: Driver|null, dragItem: DragItem|null, routeIndexes: RouteIndexes, from: string){

    if(dragItem && driver){
      let newName = '';
      let idCard = dragItem.idCard;

      // Check ordered
      const ordered = dragItem.ordered;
      if(ordered){ newName = dragItem.name.toLowerCase().replace('(o)', '')}
      else{
        const action = dragItem.action
        const location = dragItem.location.toLowerCase();

        const capitalLocation = this.routeService.capitalizeWords(location);
        const capitalAction = this.routeService.capitalizeWords(action)
        
        newName = ('**' + capitalAction + ' @ ' + capitalLocation + ' (o)** ' + dragItem.url).trim(); 
      }
      const newData = {name: newName};

      console.log(newData, dragItem)
      
      //this.routeService.requestTrelloAction(newData, routeIndexes,idCard,'','', 'put:checklist',from)
    }
  };

  // MARK - Shortcut Tracker
 
  setItemCardSelected(typeIndex: number, driverIndex: number, driver: Driver){
    const bolCardRoute = this.showItemOrCard[typeIndex][driverIndex] ? false : true ;
    this.showItemOrCard[typeIndex][driverIndex] = bolCardRoute;
  
    if(!bolCardRoute){
      const idCheckist = driver.trelloInfo.idChecklistDriverCards;

      this.getCards(idCheckist, driverIndex, typeIndex, 'select_route')
    }
  };

  setMinorHeader(index: number){ 
    const newRouteView = this.routeService.currentRouteInfo();
    newRouteView.view.routeViewObjects.minorHeaders[index] = newRouteView.view.routeViewObjects.minorHeaders[index] ? false:true;
    this.routeService.nextRouteInfo(newRouteView, "setMinorHeader");
  };
 
}
