import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, AfterViewInit, Output, Renderer2, ViewChild, HostListener } from '@angular/core';
import { DEFAULT_ROUTE_OPTIONS_BUTTON, Driver, DragItem, RouteBtnOption, ToolRoutes, RouteDragDrop, RouteChange, RouteIndexes } from '../classes/tool-routes';
import { ToolRoutesService } from '../tool-routes.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { ToolRouteOptionsService } from './tool-route-options.service';
import { TrelloCard } from 'src/app/api/classes/trello';
import { TrelloService } from 'src/app/api/services/trello.service';

@Component({
  selector: 'app-tool-route-options',
  templateUrl: './tool-route-options.component.html',
  styleUrl: './tool-route-options.component.css', 
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ToolRouteOptionsComponent implements OnInit, OnDestroy  {
  // Host listener for key pressed
  @HostListener('document:keydown.escape', ['$event'])
  handleEscapePress(event: KeyboardEvent) {
    console.log('Escape key pressed!',event);
    if(this.routeInfo.view.showBackground){ this.routeService.setView('dismiss', false, null, 'tool-route-options.component:handleEscapePress')}
  }

  @Input() domRect: DOMRect|null = null;
  
  @Output() refresh = new EventEmitter<boolean>();

  @ViewChild('containerInfo', { static: true }) containerInfo!: ElementRef;
  @ViewChild('containerImport', { static: true }) containerImport!: ElementRef;
  @ViewChild('textareaDragItem') textareaDragItem!: ElementRef;
  @ViewChild('containerButtons', { static: true }) containerButtons!: ElementRef;

  showSubSelection: boolean = false;
  
  // Show variable
  showSubBtns: boolean[] = [];
  showingSubBtns: boolean = false;

  btnMain: RouteBtnOption[] = [];

  dragItem: DragItem = new DragItem;

  changeType: string = '';
  from: string = '';

  dragTest: boolean = false;
  rowDirection: boolean = false;
  
  // Subscriptions
  routeInfo = new ToolRoutes;
  routesSub: Subscription;

  //domRect: DOMRect= new DOMRect

  constructor(
    private routeService: ToolRoutesService,
    private trelloService: TrelloService,
    private detectChanges: ChangeDetectorRef, 
    private renderer: Renderer2,
    private btnOptionService: ToolRouteOptionsService
  ){
    this.routesSub = routeService.routeView$.subscribe(newRoute => {
      this.routeInfo = newRoute;
      this.detectChanges.markForCheck();
    });
  }
  
  ngOnInit(): void {
    this.renderer.addClass(document.body, 'modal-open');
    this.setOptionContainerBounding();

    const dragItem = this.routeInfo.view.routeBtnContextView?.btnContextMenuInfo?.dragItem;
    if(dragItem){ this.dragItem = dragItem};
    
    /*if(this.containerImport){

      
      if(this.changeType !== 'titg:card:move_all'){ this.setupCheckItemButtons('ngOnInit', null) };
    }*/
  }

  ngOnDestroy(): void {
    
    this.renderer.removeClass(document.body, 'modal-open');
    this.routesSub.unsubscribe();
    this.btnMain.forEach(btn => {btn.btnSub=[]});
    this.btnMain = [];
  }

  ngAfterViewInit(): void{
    if(this.textareaDragItem){
      this.textareaDragItem.nativeElement.select()
    }
  }
  
  // MARK - DOM Setup
  setOptionContainerBounding(){    
    const domRect = this.routeInfo.view.routeBtnContextView.domRect;
    const btnContextMenuInfo  = this.routeInfo.view.routeBtnContextView.btnContextMenuInfo;

    //console.log(btnContextMenuInfo, " Context check", this.routeInfo.view.routeBtnContextView);
    let changeType = btnContextMenuInfo?.changeType;
    const dragItem = btnContextMenuInfo?.dragItem;
    const from = btnContextMenuInfo?.from;

    if(domRect && changeType && dragItem && from){
      // Assign Type
      this.changeType = changeType;
      this.from = from;

      if(this.changeType.includes('driver')){changeType = 'driver'};
     
      switch (changeType){
        case 'titg:card:move_all': 
        console.log("Move all")
        this.setupContainerTitgCard(domRect, from!, changeType); break;

        case 'put:card_to_list': this.setupContainerTitgCard(domRect, from!, changeType); break;

        case 'titg:dragItem': this.setupTitgDomContainer(domRect, from!, changeType); break;

        case 'active_driver_card': this.setupDriverOptionContainer(domRect, from!, changeType); break;

        case 'driver': this.setupDriverOptionContainer(domRect, from!, changeType); break;
      }

      this.setupCheckItemButtons(changeType, dragItem, from!);
    }
  }

  // MARK - Button Setup
  setupCheckItemButtons(changeType: string, data: any, from: string){
    function checkInfo(card: string|null): RouteBtnOption|null{
      //console.log(card, "<-0 info check")
      if(card && (card.length === 24 || card.length === 8)){
        const newBtn = DEFAULT_ROUTE_OPTIONS_BUTTON
        const infoBtn:RouteBtnOption = newBtn[1]

        infoBtn.clickAction = 'info';
        infoBtn.disabled = false;
        infoBtn.class = 'option-btn';
        
        return infoBtn;
      }
      return null
    }
    
    function checkEmail(action:string, name: string):RouteBtnOption|null{
      //const regFindPickupPlace = /^(pickup\s\@\s*)([^\n\r]*\s)([^\n\r]*)$/
      
      if(action === 'pickup' || action === 'return' || action === 'returns'){
        const pencilPickup = name.toLowerCase().includes('pencil');
        if(!pencilPickup){
          const newBtn = DEFAULT_ROUTE_OPTIONS_BUTTON;
          const orderBtn:RouteBtnOption = newBtn[0];
  
          orderBtn.clickAction = 'email_order';
          orderBtn.clickAction = 'email_order';
          orderBtn.class = 'option-btn';
  
          const orderCheck = name.includes('(o)');
          
          if(orderCheck){ orderBtn.completed = true;}else{orderBtn.completed = false};

          return orderBtn
        }
      }
      return null
       
    }

    function checkArchive(dragItem: DragItem): RouteBtnOption|null{
      console.log(dragItem)
      
      if(dragItem.action.includes('card')){
        const newBtn = DEFAULT_ROUTE_OPTIONS_BUTTON
        const archiveBtn:RouteBtnOption = newBtn.find(btn => btn.clickAction === 'archive')!;
        return archiveBtn;
      }
      return null
    
    }

    function checkMove(changeType: string, dragItem: DragItem, routeInfo: ToolRoutes, showBoard: number, from: string):RouteBtnOption|null{
    
      if((dragItem.action === '' || dragItem.action === "Van Maintenance - Daily" || dragItem.action === 'Maintenance') 
        && changeType !== 'active_driver_card_to_board' 
        && changeType !== 'active_driver_card_to_card' 
        && changeType !== 'active_driver_card_to_driver'
        && changeType !== 'driver_to_driver') { return null};

      const newBtn = DEFAULT_ROUTE_OPTIONS_BUTTON

      let btnMove: RouteBtnOption = new RouteBtnOption;
      btnMove.clickAction = 'put:card';
      btnMove.clickAction = 'put:card';
      btnMove.class = 'option-btn';

      const checkComplete = dragItem.dueComplete;

      const btnSelector = DEFAULT_ROUTE_OPTIONS_BUTTON;
      
      /** State check:
       *  if from TITG: 2 states, team cards or route cards
       *    if TEAM card: stats are move to driver || pictures || to TITG
       *    if ROUTE CARD: stats are move all to driver 
       * if from DRIVER BOARDS: 2 states driver cards or driver route: same object; DragItem
       *    if DRIVER CARD: stats are move all to another driver, move to pictures; if due complete; move to TiTG
       *  
      */

      // Show Boards
      if( changeType === 'team_put:card' || 
          changeType === 'active_driver_card_to_board' || 
          changeType === 'titg_put:card' || 
          changeType === 'put:card_to_list'){
        
        // Change Button Name
        btnMove.name = 'Board move';

        // Find move types
        const btnMoveTitg = btnSelector.find(btn => btn.type === 'move_titg');
        const btnMoveTeam = btnSelector.find(btn => btn.type === 'move_team');
        const btnMovePhoto = btnSelector.find(btn => btn.type === 'move_photo');
        const btnMoveTesting = btnSelector.find(btn => btn.type === 'move_testing');

        if(btnMoveTitg ){btnMove.btnSub.push(btnMoveTitg)};
        if(btnMoveTeam && !dragItem.team){ btnMove.btnSub.push(btnMoveTeam)}
        if(btnMovePhoto){btnMove.btnSub.push(btnMovePhoto)};
        if(btnMoveTesting && dragItem.idList !== '609a6bc7ed92fd8bec9c3c08'){btnMove.btnSub.push(btnMoveTesting)};

      }
      
      // Show Active Drivers
      if( changeType === 'titg:card:move_all' || 
          changeType === 'titg:dragItem' || 
          changeType === 'put:card_to_driver' || 
          changeType === 'driver_drag_item' ||
          changeType === 'driver' ||
          changeType === 'active_driver_card_to_driver' ||
          changeType === 'driver_to_driver'
      ){

        //console.log(changeType, ' <-------------------')
        // Change Button Name
        btnMove.name = 'Route & Move CARD';

        if((changeType === 'titg:dragItem' || changeType === 'driver_to_driver') && !dragItem.name.includes('(r)')){btnMove.name = 'Task move'; btnMove.clickAction = 'put:card'}
        else if((changeType === 'titg:dragItem') && dragItem.name.includes('(r)')){return null};
        
        if(changeType === 'put:card_to_driver' || changeType === 'active_driver_card_to_driver' || showBoard === 1){ btnMove.name = 'Card to Driver'; btnMove.clickAction = 'put:card'};
        // Team Creation (Same as move but with more Spirite!)
        if(changeType === 'driver'){ btnMove = DEFAULT_ROUTE_OPTIONS_BUTTON[7] };

        // Get active drivers
        const currentActiveDriver = routeInfo.activeDrivers;

        // Available active drivers to pass the info to
        if(currentActiveDriver.length > 0){
          let activerDriver = '';
          if(dragItem && dragItem.driver){ activerDriver = dragItem.driver.toLowerCase()}
          currentActiveDriver.forEach(driver => {
            if(!activerDriver || activerDriver !== driver.driverInfo.firstName.toLowerCase()){
              const newButton = new RouteBtnOption;

              newButton.clickAction = 'titg:card:move_all';
              if(changeType === 'put:card_to_driver' || changeType === 'active_driver_card_to_driver' || showBoard === 1){
                //console.log("Should be setting this click ation $#########@@#$@$")
                newButton.clickAction = 'put:card_to_driver'};
              if(changeType === 'titg:dragItem'){newButton.clickAction = 'move_titg_item'};

              if(changeType === 'driver_to_driver'){newButton.clickAction = 'move_driver_item'};
              if(changeType === 'driver'){newButton.clickAction = 'create_team'};

              newButton.type = driver.driverInfo.firstName;

              newButton.name = driver.driverInfo.firstName;
              btnMove.btnSub.push(newButton);
            }
          });
        }
        // No driver available 
        else {
          const btnFail = new RouteBtnOption;

          btnFail.disabled = true;
          btnFail.name = 'No Active Drivers';
        }
      
      }         
      
      if(changeType === 'show_teams'){ console.log("Active driver ITem!!@#!@#!@#", btnMove)}
     
      return btnMove
    }

    function checkDelete(dragItem: DragItem, from: string):RouteBtnOption|null{
      // Fail cause can't init del with idCard and idChecklist
      if((!from.includes('titg')) && (!dragItem.idRouteOrTitgCard || !dragItem.idChecklist)){ return null};
      if(from.includes('titg') && from.includes('card')){return null};

      const newBtn = DEFAULT_ROUTE_OPTIONS_BUTTON
      const btnDelete: RouteBtnOption = newBtn[5];

      // TITG Del Item or Driver Del item
      if((from.includes('titg'))){ btnDelete.type = 'titg:del:checklist'};
      if(from.includes( 'driver')){ btnDelete.type = 'driver:del:checkItem'};

      return btnDelete;
    }

    function checkRouted(routed: boolean| null, changeType: string): RouteBtnOption|null{
      if(!routed){ return null}

      const newBtn = DEFAULT_ROUTE_OPTIONS_BUTTON;
      const btnDelRouted: RouteBtnOption = newBtn[6];

      return btnDelRouted;
    }

    // Re init Arraqy
    this.btnMain = [];
    const btn = this.routeInfo.view.routeBtnContextView.btnContextMenuInfo;
    
    if (btn &&
        btn.dragItem){
      
      // Check data tpes
      let dragItem = btn.dragItem;
      
      let action = '';

      if(data instanceof DragItem){ 
        dragItem = data;
        action = dragItem.action;
      }

      if(from.includes('titg')){  
        const showBoard = this.routeService.currentRouteInfo().view.showBoard;

        // Task move  
        const btnTitgMoveAll = checkMove(changeType, data, this.routeInfo, showBoard, from);
        if(btnTitgMoveAll){this.btnMain.push(btnTitgMoveAll);}

        const btnEmail = checkEmail(dragItem.action, dragItem.name);
       
        if(btnEmail){ this.btnMain.push(btnEmail)};

        // Special Case - TITG && on Team board - Move card to driver
        let bntDelRouted = checkRouted(data.routed, changeType);
        if(bntDelRouted){this.btnMain.push(bntDelRouted)}; 

        // Delete a checklist
        const delChecklist = checkDelete(dragItem, from);
        if(delChecklist){ this.btnMain.push(delChecklist)};
        
        // Move 
        if(changeType === 'titg:card:move_all'){
          const bntTitgMoveCard = checkMove('titg_put:card',data, this.routeInfo, -1, from);
          if(bntTitgMoveCard){this.btnMain.push(bntTitgMoveCard);}

          const btnMoveCardToDriver = checkMove('put:card_to_driver',data, this.routeInfo, -1, from,);
          if(btnMoveCardToDriver){this.btnMain.push(btnMoveCardToDriver)};
        }
      }

      

      // MARK - Active Driver
      if(from.includes('driver')){
        
        // DRIVER: Route Item
        if(from.includes('checkItem')){
          let mapLabelNames: string[] = []

          // Team Check
          if(dragItem.labels.length > 0) {mapLabelNames = dragItem.labels.map(label => label.name)}
          else{mapLabelNames = [dragItem.name]};

          const btnEmail = checkEmail(dragItem.action, dragItem.name);

          const btnActiveDriverMoveItemToDriver = checkMove('driver_to_driver', data, this.routeInfo, -1, from);

          const btnTeam = checkMove(changeType, data, this.routeInfo, -1, from);
          const btnInfoCard = checkInfo(dragItem.idCard);

          const btnDelete = checkDelete(dragItem, changeType);

          //if(changeType === 'show_teams')


        

          if(btnActiveDriverMoveItemToDriver){this.btnMain.push(btnActiveDriverMoveItemToDriver)};
          if(btnTeam){ this.btnMain.push(btnTeam)};

          if(btnEmail){ this.btnMain.push(btnEmail)};
          if(btnInfoCard){ this.btnMain.push(btnInfoCard)}
          if(btnDelete){ this.btnMain.push(btnDelete)};
        };    

        // DRIVER: Card
        if(from.includes('card')){
          dragItem.action = 'card'
          //console.log(changeType, "Home time for the time and times", dragItem)

          // Buttons
          const btnActiveDriverMoveToBoard = checkMove('active_driver_card_to_board', data,  this.routeInfo, -1, from);

          const btnArchiveDriver = checkArchive(dragItem);
          const bntInfoDriver = checkInfo(dragItem.idCard);
          
          const btnActiveDriverMoveCardToDriver = checkMove('active_driver_card_to_driver', data,  this.routeInfo, -1, from);

          if(btnActiveDriverMoveToBoard && !dragItem.action.includes("Maintenance")){this.btnMain.push(btnActiveDriverMoveToBoard)};
          
          if(btnActiveDriverMoveCardToDriver && !dragItem.action.includes("Maintenance")){this.btnMain.push(btnActiveDriverMoveCardToDriver)};

          if(btnArchiveDriver){this.btnMain.push(btnArchiveDriver)};
          if(bntInfoDriver){this.btnMain.push(bntInfoDriver)};

          //console.log(this.btnMain, "<--------- what is going on here")
        };

    
      };

       
      
      const btnCount = this.btnMain.length;
      this.showSubBtns.fill(false, btnCount);

      // MARK - Empty Check
      if(this.btnMain.length === 0 ){
        const emptyBtn = new RouteBtnOption;
        emptyBtn.name = "No action available";
        emptyBtn.type = 'dismiss';
        emptyBtn.clickAction = 'dismiss';
        emptyBtn.class = 'option-btn option-small-icon';
        this.btnMain.push(emptyBtn);
      }
      
    }
  }

  btnClickOptions(button: any, data: any, index: number, from: string ){ 
  

    // Sub functions
    const currentRouteInfo = this.routeService.currentRouteInfo()
    const currentView = currentRouteInfo.view;
    const checkConfirmWindowOpen = currentView.confirmWindow;

    const currentOptions = currentView.routeBtnContextView.btnContextMenuInfo;
    if(currentOptions){
      const dragItem = currentOptions.dragItem;
      const trelloCard = currentOptions.trelloCard;
      

      const routeIndexes = currentOptions.routeIndexes;
      

      const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver;
      const indexTitgChecklistOrRouteDragItem = routeIndexes.indexTitgChecklistOrRouteDragItem
      const indexDragItem = routeIndexes.indexDragItem;


      let currentDriver = new Driver;
      if(indexTitgCardOrDriver >= 0){ currentDriver = currentRouteInfo.activeDrivers[indexTitgCardOrDriver]};

      let idCard = '';
      
      if((button && button !== null && dragItem)|| checkConfirmWindowOpen){
        let clickAction = '';
        let type = button.type;
        let requestName = button.type
        
        
        if(typeof button === 'string'){clickAction = button}else if(button){clickAction = button.clickAction};
        if(dragItem instanceof DragItem){ idCard = dragItem.idCard!};
        if(trelloCard){ idCard = trelloCard.id};
        //if(checkConfirmWindowOpen){clickAction = currentView.confirmWindow.confirmActionId};

        
        let idCheckist = '';
        let idCheckItem = '';


        switch(clickAction){
          // TEAM CREATION
          case 'create_team': 
            // Assign Team Driver here index 0 is current driver and index 1 is the receiving driver
            const primaryDriver = currentDriver;
            
            // Remove the current active driver associate with the button click (it's removed from the list and will cause an indexing issue)
            let activeDriverList = this.routeInfo.activeDrivers.filter(driver => driver.driverInfo.firstName !== currentDriver.driverInfo.firstName)
            
            const secondaryDriver = activeDriverList[index];
            
            const teamDrivers: Driver[] = [primaryDriver,secondaryDriver];

            if(dragItem?.idCard){idCard = dragItem.idCard};

            this.routeService.implementTeamCreation(teamDrivers, dragItem!); 
            this.routeService.setView('dismiss', false, null, 'btnClick:team'); 
          break;
          
          case 'show_teams':
            // show Teams  
            if(this.showSubBtns[index]){
              /* Clear sub buttons*/ 
              this.showSubBtns[index] = false; 
              this.showingSubBtns = false;
              this.btnMain.forEach(btn => {btn.disabled = false });
            }
            else{
              // Tracking showing sub buttons
              this.showingSubBtns = true;

              // Disable all other buttons
              for (let i = 0; i < this.btnMain.length; i++) {
                const btn = this.btnMain[i];
                if(i !== index){
                  
                  console.log("Disabling on Drag Team Card");
                  btn.disabled = true};
              }

              // Set open sub button
              const count = this.showSubBtns.length;
              this.showSubBtns = [];
              this.showSubBtns.fill(false, count);
              this.showSubBtns[index] = true;
            }   
          break;

          // MOVE ACTIONS 
          case 'titg:card:move_all': 
            if(button instanceof RouteBtnOption){
              const currentDriverName = button.name;
              const currentDriverIndex = this.routeInfo.activeDrivers.findIndex(driver => driver.driverInfo.firstName === currentDriverName);
              if(currentDriverIndex > -1){
                const currentDriver = this.routeInfo.activeDrivers[currentDriverIndex];
                // Clear Active Buttons
                this.clearBtns(currentDriverIndex);

                // Init Move
                this.moveAllDragItemFromTitg(currentDriver, currentDriverIndex);
                
              }
            }
          break;

          case 'move_team': // CREATE TEAM
            const idBoardTeam = "5d9d218d17b87227bfe9980a";
            const idListTeam = '5d9d21938f451d773ed3b1ef';

            if(dragItem?.idCard){idCard = dragItem.idCard};
      
            this.clearBtns(indexTitgCardOrDriver);
            this.btnOptionService.moveCard(idBoardTeam, idListTeam, idCard, 'btnClick:move_team');
            this.routeService.setView('dismiss', false, null, 'btnClick:move_team'); 
          break;

          case 'move_titg_item': // TASK MOVE

            const titgDragItem: DragItem = data;
                  
            // Setup observable and Route Changes
            //let postDataTitg: Observable<Object>[] = [];
            const routeChanges: RouteChange[] = [];
  

            const driverTitg: Driver = this.routeInfo.activeDrivers[index];
            
            // Index counter
            let pos: any = 'bottom';

            const dropDataTitg: RouteDragDrop = {
              dragCard: [], 
              dragItems: [titgDragItem],
              nextIndex: -1, 
              previousIndex: -1,
              indexTitgCardOrDriver: index,
              driver: driverTitg,
        
              postItems: [titgDragItem]
            }

            clickAction = 'moveTitgToDriver';
            const postMoveTitgItem = this.routeService.setupItemToItemCreation(clickAction,driverTitg,titgDragItem,dropDataTitg, pos, 'btnClickOptions:move_titg_item');
            const request = forkJoin(postMoveTitgItem);

            if(request){this.routeService.generalSubscription(request, routeChanges, clickAction) };
              
            this.routeService.setView('dismiss', false, null, 'btnClick:move_titg'); 

          break;

          case 'move_driver_item':
            // Setup observable and Route Changes
            console.log()
            let postDataDriver: Observable<Object>[] = [];
          
            // Remove the current active driver associate with the button click (it's removed from the list and will cause an indexing issue)
            let filteredDrivers = this.routeInfo.activeDrivers.filter(driver => driver.driverInfo.firstName !== dragItem.driver)
          

            const currentDriverDragItem: DragItem = data;
            const targetDriver = filteredDrivers[index];
            

            console.log(currentDriverDragItem, targetDriver, "<-------------------------");

            const idChecklistTargetDriver = targetDriver.routeCard.idChecklists[0];
            const idChecklistCurrentDriver = currentDriverDragItem.idChecklist;
        
            const driverItemName = "**" + currentDriverDragItem.action.toUpperCase() + " @ " + currentDriverDragItem.location + "** " + currentDriverDragItem.url;    
            const driverItemData = { name: driverItemName, idChecklist: idChecklistTargetDriver, pos: 'bottom' };
            

            driverItemData.name  = currentDriverDragItem.name.trim();  
    
            postDataDriver.push(this.trelloService.postRequest('post:checkItem','', idChecklistTargetDriver,'', driverItemData));
            postDataDriver.push(this.trelloService.delRequest('del:checkItem', '', idChecklistCurrentDriver, currentDriverDragItem.idCheckItem));
    
            const itemMoveRequest = forkJoin(postDataDriver);
            this.routeService.generalSubscription(itemMoveRequest, null, 'moveCard');

            // Close and reset btns
            this.routeService.setView('dismiss', true, null,'move_driver_item'); 
          break;
          
          case 'move_titg':
            const idBoardTitg = '5ea969ad22a3425649825fa5';
            const idListTitg = '652da076674df197bee13726';

            if(dragItem?.idCard){idCard = dragItem.idCard};
      
            this.btnOptionService.moveCard(idBoardTitg, idListTitg, idCard, 'btnClick:move_titg');
            this.routeService.setView('dismiss', false, null, 'btnClick:move_titg'); 
          break;
          
          case 'move_photo':            
            const idBoardPhoto= '5f1f75ac6d4a774b8d1d8a49';
            const idListPhoto = '5f32f726dc3428568b44a7e8';

            if(dragItem?.idCard){idCard = dragItem.idCard};
            
            this.btnOptionService.moveCard(idBoardPhoto, idListPhoto, idCard, 'btnClick:move_titg');
            this.routeService.setView('dismiss', false, null, 'btnClick:move_titg'); 
          
          break;

          case 'move_testing':            
            const idBoardTesting= '5ea969ad22a3425649825fa5';
            const idListTestingidBoardTesting = '609a6bc7ed92fd8bec9c3c08';

            if(dragItem?.idCard){idCard = dragItem.idCard};
            
            this.btnOptionService.moveCard(idBoardTesting, idListTestingidBoardTesting, idCard, 'btnClick:move_titg');
            this.routeService.setView('dismiss', false, null, 'btnClick:move_titg'); 
          
          break;

          // PUTS
          case 'put:card': 
            if(this.showSubBtns[index]){this.clearBtns(index) }
            else{
              // Tracking showing sub buttons
              this.showingSubBtns = true;

              // Disable all other buttons
              for (let i = 0; i < this.btnMain.length; i++) {
                const btn = this.btnMain[i];
                if(i !== index){ btn.disabled = true};
              }

              // Set open sub button
              const count = this.showSubBtns.length;
              this.showSubBtns = [];
              this.showSubBtns.fill(false, count);
              this.showSubBtns[index] = true;
            }      
          break;
          
          case 'put:card_to_driver':
            /// Working
            if(button instanceof RouteBtnOption){
              const currentDriverName = button.name;
              const currentDriverIndex = this.routeInfo.activeDrivers.findIndex(driver => driver.driverInfo.firstName === currentDriverName);

              console.log(button, currentDriverIndex, "current driver index?", this.routeInfo.activeDrivers)

              const idBoardDriver = this.routeInfo.activeDrivers[currentDriverIndex].trelloInfo.idBoardDriver;
              const idListDriver = this.routeInfo.activeDrivers[currentDriverIndex].trelloInfo.idChecklistDriverCards;

              //console.log(dragItem, "<-------- card move", idBoardDriver,"------", idListDriver, "--------------", currentDriver, " -- - - -- - - " )

              if(dragItem?.idCard){idCard = dragItem.idCard}
              this.btnOptionService.moveCard(idBoardDriver, idListDriver, idCard, 'btnClick:put:card_to_driver');
              this.routeService.setView('dismiss', false, null, 'btnClick:move_titg'); 
            }

          break;

          // Basic Functions
          case 'email_order':
            if(dragItem){
              this.btnOptionService.generateEmail(dragItem, 'btnClickOptions',dragItem.po!);
            };
          break;

          case 'info': 
            if(dragItem && dragItem.idCard){idCard = dragItem.idCard}
            if(trelloCard && trelloCard.id){idCard = trelloCard.id}
            
            routeIndexes.indexTitgCardOrDriver = -1;
            routeIndexes.indexTitgChecklistOrRouteDragItem = -1;
            routeIndexes.indexDragItem = -1;
             
            this.routeService.getCard(dragItem, routeIndexes, 'btnClickOptions'); 
          break;

          case 'archive': 
            if(data){
              if(dragItem?.idCard){idCard = dragItem.idCard} else {console.error('Missing and failed')}

              this.btnOptionService.archiveCard(idCard,'btnClickOptions'); 
              this.routeService.setView('show_confirm_window', false, null, 'archiveCard');
              break;
            }
            this.btnOptionService.showConfirmWindow(clickAction, idCard);
          break;

          case 'delete': 
            // checkitem Del Request 
            console.log(currentOptions, " Something is missing here")
            type = currentOptions.from;
            
            if(requestName && requestName.includes('del:checkItem')){
              requestName = 'del:checkItem'
              idCard = '';
              idCheckist = dragItem.idChecklist;
              idCheckItem = dragItem.idCheckItem;
            };
            // Checklist Del Request
            if(requestName && requestName.includes('del:checklist')){
              requestName = 'del:checklist';
              idCard = dragItem.idCard;
              idCheckist = dragItem.idChecklist;
              idCheckItem = '';
            };

            this.routeService.requestTrelloAction(null,routeIndexes,idCard,idCheckist,idCheckItem,requestName,type );
            this.routeService.setView('dismiss', false, null, 'btnClickOptions');
            /*

            const btnCheck = button instanceof RouteBtnOption
            if(
              button && 
              button.type && 
              button.type.includes("del:checklist")){ // Del Checklist

              // Time for some id's
              idCard = dragItem.idCard;
              idCheckist = dragItem.idChecklist;
              
              console.log(indexTitgCardOrDriver, indexTitgChecklistOrRouteDragItem, indexDragItem)

              this.routeService.requestTrelloAction(null, routeIndexes,idCard, idCheckist, '', button.type, from);
              this.routeService.setView('dismiss', false, null, 'btnClickOptions');
            } else if(
                button && 
                button.type && 
                button.type.includes("del:checkItem")) { // Del checkitem
              // Time for some id's

              const newCheckItem = this.trelloService.createTrelloCheckItemFromDragItem(dragItem, from);

              const routeIndexes: RouteIndexes = {
                indexTitgCardOrDriver: indexTitgCardOrDriver, 
                indexTitgChecklistOrRouteDragItem: indexTitgChecklistOrRouteDragItem, 
                indexDragItem: -1

              }
              
              this.routeService.del:checkItem(newCheckItem.idChecklist, newCheckItem.id, routeIndexes,currentOptions.from);
              this.routeService.setView('dismiss', false, null, 'btnClickOptions');
            }*/
          
            
          break;

          case 'reroute': 
            if(dragItem){        
                  
              // Rename 
              const locationString = dragItem.name.toLocaleLowerCase();
              const newName = locationString.replace(/\(r\)/g,'');
    
              console.log(newName, "<--- re routed")
              // Prepare and send post
              const passData = {name: newName.trim()};
              idCheckist = dragItem.idChecklist;
    
              this.routeService.requestTrelloAction(passData,routeIndexes, idCard, idCheckist, idCheckItem, 'put:checklist',  'titg:checklist' );
              
              // Close Btn view
              this.routeService.setView('dismiss', false, null, 'btnClickOptions');
            }
          break;
        
          case 'dismiss':this.routeService.setView('dismiss', false, null, 'btnClickOptions');break;
        }
        
        // Clear Disabled from buttons
        if(clickAction.includes('move')|| clickAction.includes('create')){this.clearBtns(index);}
        
      }else{ 
        this.routeService.setView('dismiss', false, null, 'btnClickOptions');
      };
    }
  }

  modifyName(name: string, dragItem: DragItem){
    this.textareaDragItem.nativeElement.blur();
    const btnContextInfo = this.routeInfo.view.routeBtnContextView?.btnContextMenuInfo;

    if(name !== dragItem.name && btnContextInfo){
      const indexDriver = btnContextInfo;

      const dragItem = btnContextInfo.dragItem;
      const routeIndexes = btnContextInfo.routeIndexes;

      // Setup modify information
      const data = { name: name};

      let idCard = dragItem.idCard;
      const idChecklist = dragItem.idChecklist;
      const idCheckItem  = dragItem.idCheckItem;


      console.log("Id card: ",idCard, "Id Checklist: ",idChecklist, "Id Checkitem: ",idCheckItem,  "Name change", name, " more to it",btnContextInfo.from, btnContextInfo.dragItem )
      
      const from = btnContextInfo.from;

      let requestName = 'put';
      if(from.includes('checkItem')){ requestName = requestName + ':checkItem'};
      if(from.includes('checklist')){ requestName = requestName + ':checklist'};
      if(from.includes('driver')){idCard = dragItem.idRouteOrTitgCard};

      if(name){this.routeService.requestTrelloAction(data, routeIndexes, idCard, idChecklist, idCheckItem, requestName, from)} 
      else {console.error("Failed in modifying the name")
      }
     
    }
  }

  clearBtns(index: number){
    /* Clear sub buttons*/ 
    this.showSubBtns[index] = false; 
    this.showingSubBtns = false;
    this.btnMain.forEach(btn => {
      btn.disabled = false 
    });
} 
  
  // MARK - Background && Confirmation Functions
  dismissBackgroundOnContextMenu(){
    this.routeService.setView('dismiss', true, null,'dismissBackgroundOnContextMenu'); 
    return true;
  }

  // MARK - HTML Assignments
  // TITG
  setupContainerTitgCard(domRect: DOMRect, from: string, changeType: string){
    console.log("Setting up container for the TITG", from, changeType)
    if(this.containerInfo && this.containerImport){
     
      const widthInnerWindow = window.innerWidth;

      // Assign Container Info
  
      let offsetRight = 12;
      let offsetTop = 0
      
      if(from.includes('titg')){ offsetRight = widthInnerWindow - domRect.right - 19};
      if(from.includes('driver')){

      }
      if(changeType.includes('move_all')){
        offsetRight = 41;
        offsetTop = 4;
      }

      console.log("Offsets:", offsetRight, offsetTop, from, changeType)

      this.containerInfo.nativeElement.style.right = offsetRight + 'px';
      this.containerInfo.nativeElement.style.top = domRect.top - 4 + offsetTop + 'px';
      this.containerInfo.nativeElement.style.position = 'fixed';
      this.containerInfo.nativeElement.style['z-index'] = '1010';
      this.containerInfo.nativeElement.style['margin'] = '0 5px';

      // Assign Button Info
      this.containerImport.nativeElement.style.height = domRect.height + 2 + 'px';
      this.containerImport.nativeElement.style.width = domRect.width + 2 + 'px';

      this.containerImport.nativeElement.style['min-height'] = domRect.height + 2 + 'px';
      this.containerImport.nativeElement.style['min-width'] = domRect.width + 2 + 'px';

      this.containerImport.nativeElement.style['border-radius'] = '6px';

      this.containerImport.nativeElement.style['background-image'] = ' url(assets/images/send_to_route.svg)'
      this.containerImport.nativeElement.style['background-size'] = '24px';
      this.containerImport.nativeElement.style['background-repeat'] = 'no-repeat';
      this.containerImport.nativeElement.style['background-position'] = 'center';
      this.containerImport.nativeElement.style['background-color'] = 'white';


    }
  }

  setupTitgDomContainer(domRect: DOMRect, from: string, changeType: string){
    if(this.containerInfo && this.containerImport){
      // Set row direction
      this.rowDirection = false;

      // Assign Container Info
      if(changeType === 'titg:dragItem'){ this.containerInfo.nativeElement.style.right = window.innerWidth - domRect.right - 14 + 'px'}
      else{this.containerInfo.nativeElement.style.left = domRect.left + 14 + 'px'};

      this.containerInfo.nativeElement.style.top = domRect.y + 'px';

      this.containerInfo.nativeElement.style.bottom = domRect.bottom;
      this.containerInfo.nativeElement.style.position = 'fixed';
      this.containerInfo.nativeElement.style['z-index'] = '1010';

      this.containerImport.nativeElement.style.width = domRect.width + 'px';
      this.containerImport.nativeElement.style.height = domRect.height + 'px';

      
      // Set text area width and height
    }


  }

  // Active board  
  setupDriverOptionContainer(domRect: DOMRect, from: string, changeType: string){
    if(this.containerInfo && this.containerImport){

      const openTitgSidebar = this.routeInfo.view.showTitgSidebar;

      // Set row direction
      this.rowDirection = true;

      // Out of bounds X axis check
      let left = domRect.x;
      if(left <= 13){left = 2}

      // Out of bounds Y axis check
      let top = domRect.y;
      const checkYBounds = (window.innerHeight - domRect.y - domRect.height) < 15;

      const checkXRightBounds = (window.innerWidth - domRect.x ) < 625;      
      const checkXYeftBounds = domRect.x < 0;

      if(checkYBounds){ top = window.innerHeight - domRect.height - 15};

      // Assign Container Info
      
      // Driver option out of bounds, offset left
      if(checkXRightBounds && from.includes('driver')&& !openTitgSidebar){ 
        this.rowDirection = false; 
        if((window.innerWidth - domRect.x - domRect.width ) < 40){this.containerInfo.nativeElement.style.right = '40px'}
        else{this.containerInfo.nativeElement.style.right = window.innerWidth - domRect.x - domRect.width + 'px'}
      }
      // Driver right offset out 
      else if(checkXYeftBounds && from.includes('driver')){ this.containerInfo.nativeElement.style.left = 60 + 'px'}
      // All other
      else { this.containerInfo.nativeElement.style.left = left + 'px'};

      this.containerInfo.nativeElement.style.top = top + 'px';

      this.containerInfo.nativeElement.style.bottom = domRect.bottom;
      this.containerInfo.nativeElement.style.position = 'fixed';
      this.containerInfo.nativeElement.style['z-index'] = '1010';

      this.containerImport.nativeElement.style.width = domRect.width + 'px';
      this.containerImport.nativeElement.style.height = domRect.height + 'px';
      
      // Set text area width and height
    }
  }

  // MARK - DRIVER AUTOMATION  
  moveAllDragItemFromTitg(driver: Driver, driverIndex: number){  
    const routeIndexes =  this.routeInfo.view.routeBtnContextView.btnContextMenuInfo!.routeIndexes
    const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver; 

    // Setup items to be moved
    const currentRouteInfo = this.routeService.currentRouteInfo();

    const dragCard = currentRouteInfo.titgCardCatalog[indexTitgCardOrDriver];
    const preDragItems: DragItem[] = dragCard.dragItems;  

    const dragItems: DragItem[] = [];
    preDragItems.forEach(item => {if(!item.routed){dragItems.push(item)}});

    const dropData: RouteDragDrop = {
      dragCard: [dragCard], 
      dragItems: dragItems,
      nextIndex: -1, 
      previousIndex: -1,
      indexTitgCardOrDriver: driverIndex,
      driver: driver,

      postItems: dragItems
    }
    
    // Initiate and Complete the moving procedure
    this.routeService.moveDragItems(dropData, 'moveTitgToDriver');

    // Dimisss Driver Selector
    //console.log(dragItemsToBeMoved, "<------ helo helo")
    this.routeService.setView('dismiss', false, null,'btnClick:move_all'); 
  }

  // MARK - Style Attributes
  getMaxWidth(optionsContainer: any){
    const containeStyle = optionsContainer.style
    console.error(optionsContainer.style.width, "Damn so much couhing")
  } 

  // MARK - Hostlistener events
}


