import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { InputViewSetting } from './inputbox';

@Injectable({
  providedIn: 'root'
})
export class InputboxService {
  
  subjectInputboxView = new Subject<InputViewSetting>();
  inputboxView$ = this.subjectInputboxView.asObservable();
  constructor() { }

  nextInputView(newChecklist: InputViewSetting, from: string) {
    this.subjectInputboxView.next(newChecklist);
  }
}
