<div class="tool-container">

    <h1 class="padding: 15px">
        Update pricing
    </h1>
    <p style="padding-top: 15px;">Welcome to the new updater. It will be for all your updating needs. You can search by item number catagory or just us the search. Let's go!</p>
    <div class="updater-container">
        <app-inputbox 
            (validData)="submitQuery($event, 0)"
            [inputSetting]="search_setting">
        </app-inputbox>
    </div>
    
    <div class="updater-search-container col-12 col-lg-6">
        <h2>Search Results</h2>
        <div *ngIf="!filteredItem">
            <div>Nothing to show for it!</div>
        </div>
        <div *ngIf="filteredItem" >
            <div 
                class="updater-item-rows"
                (mouseenter)="selectedForUpdating[0] = true" 
                (mouseleave)="selectedForUpdating[0] = false" >
                <ng-container 
                    [ngTemplateOutlet]="itemDescriptions"
                    [ngTemplateOutletContext]="{item:filteredItem, index:0}">
                </ng-container>
            </div>
            
            <h3 class="mb-2">It's Gamily</h3>
            <div class="updater-items-container">
                <div 
                    *ngFor="let item of itemNumberSearch|async; let i = index" 
                    class="updater-item-rows"
                    (mouseenter)="selectedForUpdating[i + 1] = true" 
                    (mouseleave)="selectedForUpdating[i + 1] = false">
                    <ng-container 
                        [ngTemplateOutlet]="itemDescriptions"
                        [ngTemplateOutletContext]="{item:item, index: i+1}">
                    </ng-container>
                </div>
            </div>
        </div>    
    </div>
    
    <div 
        *ngIf="pendingUpdates.length > 0" 
        class="updater-pending-container">
        <h2>Pending update</h2>
        <div *ngFor="let item of pendingUpdates; let i = index">
            <ng-container 
                [ngTemplateOutlet]="pendingUpdate"
                [ngTemplateOutletContext]="{tracker:item, index: i}">
            </ng-container>
        </div>
        <button class="btn btn-primary mt-3 float-right" (click)="submitUpdate()">Submit</button>
    </div>
    
    <div style="position: fixed; top: 110px; right: 50px; width: 100px; height: 44px;">
        <button class="btn btn-primary" (click)="runUpdateAndErrorDetect()" [disabled]="getDisabledScrapper()">Run Updater</button>
    </div>
    
    <!-- MARK: TEMPLATES -->
    
    <!-- Item descriptions -->
    <ng-template 
        #itemDescriptions 
        let-item="item" 
        let-index="index" 
        (click)="initPriceUpdate(index)">
    
        <!-- Updating Tool ACtive!-->
        <div *ngIf="!checkPendingUpdate(item.item_number)"   
            class="updater-item-container">
            <div class="flex-row">
                <!-- Item Number -->
                <div class="updater-label-container col-2">
                    <label 
                    *ngIf="selectedForUpdating[index]"
                    class="updater-item-label" 
                    for="itemNumber">Item Number</label>
                    <div class="updater-item" id="itemNumber"> {{item.item_number}}</div>
                </div>
    
                <!-- EXTRA INFO: Name & Grade | Dimension | Units-->
                <div [ngClass]="showPriceInput[index] ? 'invisible col-0': 'col-5'">
                    <div class="flex-row">
                
                        <div class="updater-label-container col-6">
                            <label 
                            *ngIf="selectedForUpdating[index]"
                            class="updater-item-label" 
                            style="margin-right: 7px;"
                            for="itemName">Name</label>
                            <div class="updater-item" id="itemName"> {{item.item_name}} {{item.item_grade}}</div>
                        </div>
                    
                        <div class="updater-label-container col-3">
                            <label 
                            *ngIf="selectedForUpdating[index]"
                            class="updater-item-label" 
                            for="itemDimension">Dimension</label>
                            <div class="updater-item " id="itemDimension"> {{item.item_dimension}}</div>
                            
                        </div>
                    
                        <div class="updater-label-container col-3">
                            <label 
                            *ngIf="selectedForUpdating[index]"
                            class="updater-item-label" 
                            for="itemLength">Length</label>
                            <div class="updater-item " id="itemLength"> {{item.item_units}}</div>
                            
                        </div>
                    </div>
                </div>
    
                <!-- Item Price -->
                <div class="updater-label-container"
                    [ngClass]="showPriceInput[index] ? 'col-5 text-right': 'col-2' ">
                    <label 
                    *ngIf="selectedForUpdating[index] && !showPriceInput[index]"
                    class="updater-item-label" 
                    for="itemPrice">Price</label>
                    <div 
                        class="updater-item"
                        id="itemPrice"
                        [ngClass]="{
                            'text-success': selectedForUpdating[index] && !showPriceInput[index],
                            'text-primary':  showPriceInput[index],
                            'text-danger': showPriceInput[index]}"> 
                        <span *ngIf="showPriceInput[index]">previous price </span>${{item.item_price}}
                    </div>
                </div> 
    
                <!-- Button Container -->
                <div
                    class="updater-editing-container" 
                    [ngClass]="showPriceInput[index] ? 'col-4': 'col-3'">
    
                    <!-- INIT mode -->
                    <div
                        *ngIf="!showPriceInput[index] "
                        [ngClass]="showPriceInput[index] ? 'col-0': 'col-12'">
                        <button 
                            *ngIf="selectedForUpdating[index] && !showPriceInput[index] && !checkPendingUpdate(item.item_number)"
                            class="btn btn-primary btn-lg float-right" 
                            (click)="initPriceUpdate(index, item.item_price)">
                            {{updateButtonMsg}}
                        </button>
                        
                        
                    </div>
    
                    <!-- Editing Mode -->
                    <div
                        class="updater-editing-btn-container"
                        *ngIf="showPriceInput[index] "
                        [ngClass]="showPriceInput[index] ? 'col-12':'col-0'">
    
                        <img
                            (click)="cancelUpdate(index)"
                            class="updater-delete-btn"
                            src="/assets/images/delete_icon_red.svg">
    
                        <button 
                            (click)="submitPriceChange(item, index)"
                            class="updater-submit-btn btn-naked"
                            [disabled] = 'disableSubmit'>
                        </button>
    
                        <app-inputbox 
                            style="display: block;"
                            class="float-left"
    
                            (validData)="updatePrice($event, item, index)" 
                            [inputSetting]="input_price_update"
                            [inputValue]="previousPrice">
                        </app-inputbox>
                    </div>
                </div> 
            </div>
        </div>
                   
        <!-- Previously updated container -->
        <div *ngIf="checkPreviousUpdated(item.item_number)" id="previousContainer"
        class="updater-previously-updated">
            <label class="updater-previously-updated-label" for="previousContainer">Recently Updated</label>
        </div> 
            
        
        <!-- Updated and awaiting SUBMIT -->
        <div *ngIf="checkPendingUpdate(item.item_number)" 
            style="display: flex;width: 100%;">
            <div class="col-12">
              
                <ng-container
                    [ngTemplateOutlet]="pendingUpdate"
                    [ngTemplateOutletContext]="{tracker:getTrackedItem(item.item_number), index:index}">
                </ng-container>
            </div>
        </div>
    
    </ng-template>
    
    <!-- Pending updates -->
    <ng-template 
        #pendingUpdate 
        let-tracker="tracker"
        let-index="index">
        <div 
            *ngIf="tracker.item"                       
            class="updater-pending-row">
            <div class="col-2 update-pending-text">{{tracker.item.item_number}}</div>
            <div class="col-3 update-pending-text text-primary">
                <span class="updater-pending-pricing-text">previous price </span>${{formatCurrency(tracker.previous_price)}}
            </div>
            <div class="col-3 update-pending-text text-success">
                <span class="updater-pending-pricing-text">new price </span>${{formatCurrency(tracker.new_price)}}
            </div>
            <div class="col-3 update-pending-text">
                <div *ngIf="priceDisplay[index]"><img class="updater-img-plus" src="assets\images\up_arrow_green.svg" alt="">price increase </div>
                <div *ngIf="!priceDisplay[index]"><img class="updater-img-minus" src="assets\images\down_arrow_red.svg" alt="">price decrease</div>
    
            </div>
            <div class="col-1 update-pending-text updater-pending-cancel-btn-container">
                <img
                (click)="delSubmittedUpdate(tracker.item.item_number)"
                class="updater-cancel-img"
                src="assets\images\delete_icon_red.svg">    
            </div>
        </div>
        
    </ng-template>

</div>