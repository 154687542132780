export class ApiView {
    isLoading: boolean =  false;
    showTrello:  boolean =  false;
    showTrelloList: boolean =  false;
    trello: TrelloView = new TrelloView;
    error: ApiError = new ApiError;
    showZoho: boolean = false;
}

export class TrelloView{
    showPictures: boolean = false;
    showPictureViewer: boolean = false;
    showMakeCard: boolean = false;
    trelloHeader: string ='I am the Trello';
    makeCardOptions: TrelloCardOptions = new TrelloCardOptions;
}

export class ApiError {
    showResponseFail: boolean = false;
    failMessage: string ='Failures abound!';
}


export class TrelloCardOptions{
    showOfW: boolean = false;
    showPD: boolean = false;
}

export class SelectedPdOption{
    
    showPD = false;
    showOfW = false;
}

export const DEFAULT_API_VIEW = {
    isLoading: false,
    showTrello: false,
    showTrelloList: false,
    trello: {
        showPictures: false,
        showPictureViewer: false,
        showMakeCard: false,
        trelloHeader: 'I am the Trello',
        makeCardOptions: {
            showOfW: false,
            showPD: false,
        }
    },
    error: {
        showResponseFail: false,
        failMessage: 'Failures abound!'
    },
    showZoho: false,
};