<!-- MARK - FULL SIZED CONTAINER -->
<animation-left-right 
    class=""
    [isLeftRight]="!view.accountView.hotbarView.isMini" 
    [id]="'toolbar'"
    [ngClass]="{'collapse.show': !view.accountView.hotbarView.isMini, 'collapse': view.accountView.hotbarView.isMini}">
  
    <div class="hotbar-container">
        <div
            class="btn-toolbar hotbar-item" 
            type="button" 
            (click)="minimizeToolbar()"
            style="text-align: left; margin: 0 15px;">
            <img class="hotbar-minor-img" src="assets\images\chevron_left.svg" alt="" style="margin-right: 20px;">
            Minimize
        </div>

        
        <div></div>

        <div class="hotbar-divider"></div>
<!-- !Logged: Go to login page -->
        <div *ngIf="!view.isLogged">
            <h2 class="hotbar-header">
                <img 
                    class="hotbar-minor-img" 
                    src="assets\images\user_blue.svg" 
                    alt="">
                <span style="margin-left: 20px;">Login|Sign up</span>
            </h2>
        </div>
<!-- Logged in: TOOLBAR -->
        <div *ngIf="view.isLogged">
            <h2 class="hotbar-header">
                <img 
                    class="hotbar-minor-img" 
                    src="assets\images\toolbox_blue.svg" 
                    alt="">
                <span style="margin-left: 20px;">Toolbar</span>
            </h2>
    
            <div class="hotbar-divider"></div>
    
            <div *ngIf="accountInfo.isAdmin">
    <!--Headers-->
                <h3 class="hotbar-minor-header" (click)="minorHeaders[0] = minorHeaders[0] ? false: true">
                    <img 
                        class="hotbar-minor-img" 
                        src="assets\images\profile_settings.svg" 
                        alt="">
                    <span style="margin-left: 10px;">Account Tools</span>
                    <span >
                        <img 
                        class="hotbar-chevron-img" 
                        style="margin-left: 15px;"
                        src="assets\images\chevron_right.svg" 
                        alt=""
                        [ngClass]="[minorHeaders[0] ? 'rotate-cw-90':'rotate-origin']">
                    </span>
                </h3>
            
                <div class="hotbar-list">
                    <animation-fly-updown [isUpDown]="minorHeaders[0]">
                        <div *ngFor="let item of hotbar; let i = index"  >
                            <div 
                                class="hotbar-item" 
                                (click)="setPosition(item.trigger)" 
                                [ngClass]="{active: item.selected}"
                                tabindex="{{i}}">
                                <img 
                                    class="hotbar-minor-img" 
                                    src="{{item.img}}" 
                                    alt="">
                                <span style="margin-left: 7.5px;">{{item.name}}</span>
                            </div>
                        </div>
                    </animation-fly-updown>
                </div>    
            </div>
        </div>

     
<!-- Bottom Container-->
    <div class="bottom-container">
<!-- Logout Button -->
        <div class="hotbar-divider"></div>
            <div 
                class="btn-toolbar btn-logout btn-logout-img btn-logout-img-offset" 
                type="button" (click)="logout('button'); $event.preventDefault()">
                Logout
            </div>
        </div>
    </div>
</animation-left-right>

<!-- MARK - MINI CONTAINER -->
<animation-left-right 
    [isLeftRight]="view.accountView.hotbarView.isMini" 
    [id]="'toolbar'"
    [ngClass]="{'collapse.show': view.accountView.hotbarView.isMini, 'collapse': !view.accountView.hotbarView.isMini }">
    <div class="hotbar-mini-container">
        <div 
            class="btn-toolbar " 
            type="button" 
            (click)="minimizeToolbar()">
            <img class="hotbar-minor-img" src="assets\images\chevron_right.svg"alt="">
        </div>
<!-- NOT LOGGED IN -->
        <div *ngIf="!view.isLogged">
            
            <div class="hotbar-divider">
                <h2 class="btn-toolbar">
                    <img 
                        class="hotbar-minor-img" 
                        src="assets\images\user_blue.svg" 
                        alt="">
                </h2>

                <div class="hotbar-divider"></div>
            </div>
        </div>

<!-- LOGGED IN -->
        <div *ngIf="view.isLogged">
    
        <div class="hotbar-divider"></div>
            <h2 class="btn-toolbar">
                <img 
                    class="hotbar-minor-img" 
                    src="assets\images\toolbox_blue.svg" 
                    alt="">
            </h2>

            <div class="hotbar-divider"></div>
    <!-- ADMIN Functions -->
            <div *ngIf="accountInfo.isAdmin && view.isLogged">

                <div 
                    class="btn-toolbar" 
                    type="button"
                    (click)="minorHeaders[0] = minorHeaders[0] ? false: true">
                    <img class="hotbar-minor-img" src="assets\images\profile_settings.svg" alt="">
                </div>
            
                <div class="hotbar-divider"></div>

                <div class="hotbar-list">
            
                    <animation-fly-updown [isUpDown]="minorHeaders[0]">
                        <div *ngFor="let item of hotbar; let i = index">
                            <div 
                                class="btn-toolbar" 
                                (click)="setPosition(item.trigger)" 
                                [ngClass]="{'mini-active': item.selected}"
                                tabindex="{{i}}">
                                <img 
                                    class="hotbar-minor-img" 
                                    src="{{item.img}}" 
                                    alt="">
                            </div>
                            
                            <div class="hotbar-divider"></div>
                        </div>
                    </animation-fly-updown>
                </div>    
            
            </div>
    <!-- Mini and Max Toolbar -->
        
    <!-- Bottom Container-->
            <div class="bottom-container ">
                <div class="hotbar-divider"></div>
            
                <div 
                    class="btn-toolbar btn-logout btn-logout-img btn-mini-logout-img-offset " 
                    type="button" 
                    (click)="logout('button'); $event.preventDefault()">
                </div>
            </div>
        </div>
    </div>
</animation-left-right>

<!-- MARK - -->