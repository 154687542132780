import { Component, OnChanges, Input } from '@angular/core';
import { trigger, state, animate, transition, style} from '@angular/animations'


@Component({
  selector : 'fly-in-out',
  styleUrls: ['fly-in-out.animation.css'],
  template: `
    <div [@flyInOut]="inOut" id="{{id}}">
        <ng-content></ng-content>            
    </div>
  `,
  
  
 animations: [
    trigger('flyInOut', [
        state('in', style({transform: 'translateX(0)'})),
        transition('void => *', [style({transform: 'translateX(-100%)'}), animate(100)]),
        transition('* => void', [animate(100, style({transform: 'translateX(100%)'}))]),
      ]),
  ]
})

export class FlyInOutAnimation implements OnChanges {
     @Input() isInOut : boolean = true;
     @Input() id: string = '';
     inOut = 'in';
    
    ngOnChanges() {
        this.inOut = this.isInOut ? 'out': 'in'; 
    }

}