export class HotbarHeader {
    name: string = '';
    trigger: string = '';
    selected: boolean = false;
    img: string = '';
    subCats: HotbarHeader[]=[];
    id: number = -1;
    metaDescription: string = '';
}


export const hotbarUser: HotbarHeader[] = [
    { 
        name: 'Update prices',
        trigger: 'updater',
        selected: false,
        img: "assets/images/update_price.svg",
        subCats: [],
        id: -1,
        metaDescription: '',
    },{ 
        name: 'Add & Remove',
        trigger: 'add',
        selected: false,
        img: "assets/images/add_remove_blue.svg",
        subCats: [],
        id: -1,
        metaDescription: '',
    },{ 
        name: 'Plot routes',
        trigger: 'routes',
        selected: true,
        img: "assets/images/route_plan_blue.svg",
        subCats: [],
        id: -1,
        metaDescription: '',
    },{ 
        name: 'Carry Calc',
        trigger: 'carry',
        selected: false,
        img: "assets/images/carry_in_blue.svg",
        subCats: [],
        id: -1,
        metaDescription: '',
    },{ 
        name: 'PD\'s',
        trigger: 'pickup',
        selected: false,
        img: "assets/images/update_price.svg",
        subCats: [],
        id: -1,
        metaDescription: '',
    },    
]

export const hotbarAccountHeaders: HotbarHeader[] = [
    {
        name: 'Login/Sign up',
        trigger: 'loginRegister',
        selected: false,
        img: "assets/images/",
        subCats: [],
        id: -1,
        metaDescription: '',
    },{
        name: 'Billing',
        trigger: 'billing',
        selected: false,
        img: "assets/images/",
        subCats: [],
        id: -1,
        metaDescription: '',
    },{
        name: 'Projects',
        trigger: 'project',
        selected: false,
        img: "assets/images/",
        subCats: [],
        id: -1,
        metaDescription: '',
    },{
        name: 'Credit Cards',
        trigger: 'credit',
        selected: false,
        img: "assets/images/",
        subCats: [],
        id: -1,
        metaDescription: '',
    },{
        name: 'Toolbox',
        trigger: 'toolbox',
        selected: false,
        img: "assets/images/",
        subCats: [],
        id: -1,
        metaDescription: '',
    },{
        name: 'Admin',
        trigger: 'admin',
        selected: false,
        img: "assets/images/",
        subCats: hotbarUser,
        id: -1,
        metaDescription: '',
    },
]

export const hotbarHeaders: HotbarHeader[]= [
    {
        name: 'Shop',
        trigger: 'shop',
        selected: false,
        img: "assets/images/",
        subCats: [],
        id: -1,
        metaDescription: '',
    }, {
        name: 'Quoting',
        trigger: 'quoting',
        selected: false,
        img: "assets/images/",
        subCats: [],
        id: -1,
        metaDescription: '',
    }, {
        name: 'Accounts',
        trigger: 'account',
        selected: false,
        img: "assets/images/",
        subCats: hotbarAccountHeaders,
        id: -1,
        metaDescription: '',
    },
]

export const shopMainHeaders: HotbarHeader[] = [
    { 
        id: 1,
        name: "Lumber", 
        trigger: 'lumber',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Lumber.jpg",
        metaDescription:"Shop Lumber at Roomerator.com, our wide selection of lumber at competitive prices."
      },{ 
        id:2,
        name: "Fasteners", 
        trigger: 'fasteners',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Screw.jpg",
        metaDescription:"Shop Fasteners at Roomerator.com, order online get it delivered in under 3hrs."
      },{ 
        id:17,
        name: "Structure & Connectors", 
        trigger: 'structureNconnectors',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/S&H.jpg",
        metaDescription:"Shop Fasteners at Roomerator.com, order online get it delivered in under 3hrs."
      },{ 
        id:3,
        name: "Sheet Goods", 
        trigger: 'sheetGoods',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Sheet_Good.jpg",
        metaDescription:"Shop Sheet Goods at Roomerator.com, plywood, osb, and various engineered sheet products delivered."
      },{ 
        id:18,
        name: "Roofing $ Gutters", 
        trigger: 'roofingNgutters',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/R&G.jpg",
        metaDescription:"Roofing at Roomerator.com, Owens Corning &  delivered."
      },{ 
        id:7,
        name: "Insulation & Wraps", 
        trigger: 'insulationNwraps',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Insulation.jpg",
        metaDescription:"Shop Insulation at Roomerator.com for your home sound and, thermal proofing needs, in a rush."
      }, { 
        id:4,
        name: "Drywalling", 
        trigger: 'drywalling',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Drywall.jpg",
        metaDescription:"Shop Drywalling at Roomerator.com for your joint tape and compound and one of the best prices on firecode. "
      },{ 
        id:9,
        name: "Blades & Bits", 
        trigger: 'bladesNbits',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Blades-&-Bits.jpg",
        metaDescription:"Shop Blades & Bits at Roomerator.com for everything that helps you cut & drill."
      },{ 
        id:6,
        name: "Plumbing", 
        trigger: 'plumbing',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Plumbing.jpg",
        metaDescription:"Shop Plumbing at Roomerator.com for those last minute changes and get it delivered FAST! "
      }, { 
        id:14,
        name: "Electrical", 
        trigger: 'electrical',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Electrical.jpg",
        metaDescription:"Shop Electrical at Roomerator.com for those last minute changes and get it delivered FAST! "
      }, { 
        id:15,
        name: "HVAC", 
        trigger: 'hvac',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/HVAC.jpg",
        metaDescription:"Shop Heating and Ventilation at Roomerator.com for those last minute changes and get it delivered FAST! "
      }, { 
        id:11,
        name: "Trim", 
        trigger: 'trim',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Moulding_&_Millwork.jpg",
        metaDescription:"Shop Moudlings & Millwork at Roomerator.com for everything that finishes the job making it look perfect."
      },{ 
        id:16,
        name: "Doors & Hardware", 
        trigger: 'doorsNhardware',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/DH.jpg",
        metaDescription:"Buy Doors at Roomerator.com for everything that helps you get the job done, better."
      },{ 
        id:5,
        name: "Adhesive & Fillers", 
        trigger: 'adhesiveNfillers',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Caulking.jpg",
        metaDescription:"Shop Adhesive & Fillers at Roomerator.com, all the contractor standards, and a few of our own favorites."
      },{ 
        id:13,
        name: "Finishing & Paints", 
        trigger: 'finishingNpaints',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Finishing_&_Paints.jpg",
        metaDescription:"Shop Finishing & Paints at Roomerator.com for everything that finishes the job making it look perfect."
      },{ 
        id:12,
        name: "Tiling", 
        trigger: 'tiling',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/Tiling.jpg",
        metaDescription:"Shop Modlings & Millwork at Roomerator.com for everything that finishes the job making it look perfect."
      },{ 
        id:10,
        name: "Cement Products", 
        trigger: 'cementProducts',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/CMC.jpg",
        metaDescription:"Shop General Needs at Roomerator.com for everything that helps you get the job done, better."
      },{ 
        id:8,
        name: "General Needs", 
        trigger: 'generalNeeds',
        selected: false,
        subCats: [],
        img:"/app/images/Headers/GeneralNeeds.jpg",
        metaDescription:"Shop General Needs at Roomerator.com for everything that helps you get the job done, better."
      },
  
]



const SubHomeData = [
    // LUMBER SUB HEADERS
    { home_id: 1, home_name: "Lumber", home_cat: [
            { top_id: 11, top_name: "SPF", top_cat: [
                    { sub_id: 111, sub_name: "Framing", sub_image: "/app/images/Headers/SubHeaders/Lumber/Framing/FLumber.jpg" },
                    { sub_id: 112, sub_name: "Structural", sub_image: "/app/images/Headers/SubHeaders/Lumber/Framing/SLumber.jpg" }
                ]
            },
            // {top_id:12, top_name: "Dressed", top_cat:
            // [ //Dressed
            //{ sub_id: 121,sub_name:"Red Oak", sub_image:"/app/images/Headers/SubHeaders/Lumber/Dressed/OakDressed.jpg"},
            //{ sub_id: 122,sub_name:"Maple", sub_image:"/app/images/Headers/SubHeaders/Lumber/Dressed/MapleDressed.jpg"},
            // { sub_id: 123,sub_name:"Walnut", sub_image:"/app/images/Headers/SubHeaders/Lumber/Dressed/WalnutDressed.jpg"},
            //{ sub_id: 124,sub_name:"Cherry", sub_image:"/app/images/Headers/SubHeaders/Lumber/Dressed/CherryDressed.jpg"},
            //]
            //}
            { top_id: 13, top_name: "Pressure Treated", top_cat: [
                    { sub_id: 131, sub_name: "Posts", sub_image: "/app/images/Headers/SubHeaders/Lumber/PT/PostPT.jpg" },
                    { sub_id: 132, sub_name: "Structural", sub_image: "/app/images/Headers/SubHeaders/Lumber/PT/StructPT.jpg" },
                    { sub_id: 133, sub_name: "Deck Boards", sub_image: "/app/images/Headers/SubHeaders/Lumber/PT/BoardsPT.jpg" },
                    { sub_id: 134, sub_name: "Railing & Lattice", sub_image: "/app/images/Headers/SubHeaders/Lumber/PT/RailPT.jpg" },
                ]
            },
            { top_id: 14, top_name: "Cedar", top_cat: [
                    { sub_id: 141, sub_name: "Cedar Posts", sub_image: "/app/images/Headers/SubHeaders/Lumber/Cedar/CedarPost.jpg" },
                    { sub_id: 142, sub_name: "Structural Cedar", sub_image: "/app/images/Headers/SubHeaders/Lumber/Cedar/CedarStruct.jpg" },
                    { sub_id: 143, sub_name: "Cedar Deck Boards", sub_image: "/app/images/Headers/SubHeaders/Lumber/Cedar/CedarBoard.jpg" },
                ]
            },
            { top_id: 15, top_name: "Engineered", top_cat: [
                    { sub_id: 151, sub_name: "LVL", sub_image: "/app/images/Headers/SubHeaders/Lumber/Eng/LVL.jpg" },
                    { sub_id: 152, sub_name: "I-Joist", sub_image: "/app/images/Headers/SubHeaders/Lumber/Eng/I_Joist.jpg" },
                    { sub_id: 153, sub_name: "LSL", sub_image: "/app/images/Headers/SubHeaders/Lumber/Eng/LSL.jpg" },
                ]
            },
        ] },
    // FASTENERS
    { home_id: 2, home_name: "Fasteners", home_cat: [
            { top_id: 21, top_name: "General Purpose", top_cat: [
                    { sub_id: 211, sub_name: "Construction", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Screw/CSFastener.jpg" },
                    { sub_id: 212, sub_name: "Flooring", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Screw/FSFastener.jpg" },
                    { sub_id: 213, sub_name: "Drywall", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Screw/DSFastener.jpg" },
                    { sub_id: 214, sub_name: "Deck", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Screw/Screw_Deck.jpg" },
                    { sub_id: 215, sub_name: "Backerboard", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Screw/Screw_Backer.jpg" },
                    { sub_id: 216, sub_name: "Tapcon", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Screw/Screw_Tapcon.jpg" },
                    { sub_id: 217, sub_name: "Hanger", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Screw/Screw_Hanger.jpg" },
                    { sub_id: 218, sub_name: "Trim", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Screw/Screw_Trim.jpg" },
                    { sub_id: 219, sub_name: "Gutter", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Screw/Screw_Gutter.jpg" },
                    { sub_id: 210, sub_name: "Structural", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Screw/Screw_Structural.jpg" },
                ] },
            { top_id: 22, top_name: "Pneumatic & Auto Feed", top_cat: [
                    { sub_id: 221, sub_name: "Brad Nails", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Nail/Nail_Brad.jpg" },
                    { sub_id: 222, sub_name: "Crown Nails", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Nail/Nail_Crown.jpg" },
                    { sub_id: 223, sub_name: "Flooring Nails", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Nail/Nail_Flooring.jpg" },
                    { sub_id: 224, sub_name: "Auto Feed", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Nail/Nail_Auto.jpg" },
                    { sub_id: 225, sub_name: "Framing", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Nail/Nail_Framing.jpg" }
                ]
            },
            { top_id: 23, top_name: "Wood Working", top_cat: [
                    { sub_id: 231, sub_name: "Particleboard", sub_image: "/app/images/Headers/SubHeaders/Fasteners/WW/WW_Particle.jpg" },
                    { sub_id: 232, sub_name: "Countersink", sub_image: "/app/images/Headers/SubHeaders/Fasteners/WW/WW_Countersink.jpg" },
                    { sub_id: 233, sub_name: "Flush", sub_image: "/app/images/Headers/SubHeaders/Fasteners/WW/WW_Flush.jpg" },
                    { sub_id: 234, sub_name: "Finish", sub_image: "/app/images/Headers/SubHeaders/Fasteners/WW/WW_Finish.jpg" },
                    { sub_id: 236, sub_name: "Dowel", sub_image: "/app/images/Headers/SubHeaders/Fasteners/WW/WW_Dowel.jpg" },
                    { sub_id: 237, sub_name: "All Materials", sub_image: "/app/images/Headers/SubHeaders/Fasteners/WW/WW_All.jpg" },
                ]
            },
            { top_id: 24, top_name: "Metal", top_cat: [
                    { sub_id: 241, sub_name: "Hex Head", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Metal/Metal_Hex.jpg" },
                    { sub_id: 242, sub_name: "Metal Stud", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Metal/Metal_Stud.jpg" },
                    { sub_id: 243, sub_name: "Sheet Metal", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Metal/Metal_Sheet.jpg" },
                    { sub_id: 244, sub_name: "Mach Flush", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Metal/Metal_Flush.jpg" },
                    { sub_id: 245, sub_name: "Mach Tappered", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Metal/Metal_Tap.jpg" },
                ]
            },
            { top_id: 25, top_name: "Variety", top_cat: [
                    { sub_id: 251, sub_name: "Brass Mach", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Variety/Variety_Brass.jpg" },
                    { sub_id: 252, sub_name: "Aluminum", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Variety/Variety_Aluminum.jpg" },
                    { sub_id: 253, sub_name: "Stainless Steel", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Variety/Variety_Stainless.jpg" },
                ]
            },
            { top_id: 26, top_name: "Anchors", top_cat: [
                    { sub_id: 261, sub_name: "Anchors", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Anchor/Anchor_Anchor.jpg" },
                ]
            },
            { top_id: 27, top_name: "Nuts & Bolts", top_cat: [
                    { sub_id: 262, sub_name: "Nuts", sub_image: "/app/images/Headers/SubHeaders/Fasteners/N&B/N&B_Nuts.jpg" },
                ]
            },
            { top_id: 28, top_name: "Specialty", top_cat: [
                    { sub_id: 271, sub_name: "Security", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Specialty/Specialty_Security.jpg" },
                    { sub_id: 272, sub_name: "Chicago & Caps", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Specialty/Specialty_Chicago.jpg" },
                    { sub_id: 273, sub_name: "Screw Caps", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Specialty/Specialty_Cap.jpg" },
                    { sub_id: 274, sub_name: "Nylon & Vinyl", sub_image: "/app/images/Headers/SubHeaders/Fasteners/Specialty/Specialty_N&V.jpg" },
                ]
            },
        ] },
    // SHEET GOODS
    { home_id: 3, home_name: "Sheet Goods", home_cat: [
            { top_id: 31, top_name: "Construction Grade", top_cat: [
                    { sub_id: 311, sub_name: "Plywood", sub_image: "/app/images/Headers/SubHeaders/Sheet/CGrade/PSheet.jpg" },
                    { sub_id: 312, sub_name: "OSB", sub_image: "/app/images/Headers/SubHeaders/Sheet/CGrade/OSBSheet.jpg" },
                    { sub_id: 313, sub_name: "MDF", sub_image: "/app/images/Headers/SubHeaders/Sheet/CGrade/MDFSheet.jpg" }
                ]
            },
            { top_id: 32, top_name: "Finished Grade", top_cat: [
                    { sub_id: 321, sub_name: "Red Oak", sub_image: "/app/images/Headers/SubHeaders/Sheet/FGrade/RedOakPlywood.jpg" },
                    { sub_id: 322, sub_name: "Maple", sub_image: "/app/images/Headers/SubHeaders/Sheet/FGrade/MaplePlywood.jpg" },
                    { sub_id: 323, sub_name: "Cherry", sub_image: "/app/images/Headers/SubHeaders/Sheet/FGrade/CherryPlywood.jpg" },
                    { sub_id: 324, sub_name: "Walnut", sub_image: "/app/images/Headers/SubHeaders/Sheet/FGrade/WalnutPlywood.jpg" }
                ]
            }
        ] },
    // DRYWALLING
    { home_id: 4, home_name: "Drywalling", home_cat: [
            { top_id: 41, top_name: "Drywall & Backer Board", top_cat: [
                    { sub_id: 411, sub_name: "Regular Drywall", sub_image: "/app/images/Headers/SubHeaders/Drywalling/Drywall/RDWall.jpg" },
                    { sub_id: 412, sub_name: "Specialty Drywall", sub_image: "/app/images/Headers/SubHeaders/Drywalling/Drywall/WDWall.jpg" },
                    { sub_id: 413, sub_name: "Backer Board", sub_image: "/app/images/Headers/SubHeaders/Drywalling/Drywall/tile_backer_413.jpg" }
                ]
            },
            { top_id: 42, top_name: "Corners", top_cat: [
                    { sub_id: 421, sub_name: "Metal", sub_image: "/app/images/Headers/SubHeaders/Drywalling/Corner/MCWall.jpg" },
                    { sub_id: 422, sub_name: "Paper", sub_image: "/app/images/Headers/SubHeaders/Drywalling/Corner/PWall.jpg" }
                ]
            },
            { top_id: 43, top_name: "Tape & Compound", top_cat: [
                    { sub_id: 431, sub_name: "Compound", sub_image: "/app/images/Headers/SubHeaders/Drywalling/Compound/CompWall.jpg" },
                    { sub_id: 432, sub_name: "Tape & Accessories", sub_image: "/app/images/Headers/SubHeaders/Drywalling/Compound/TWall.jpg" }
                ]
            },
            { top_id: 44, top_name: "Studs & Ceiling", top_cat: [
                    { sub_id: 441, sub_name: "Metal Studs", sub_image: "/app/images/Headers/SubHeaders/Drywalling/Metal/Metal_Wall.jpg" },
                    //{sub_id: 432, sub_name:"Tape & Accessories", sub_image:"/app/images/Headers/SubHeaders/Drywalling/Compound/TWall.jpg"}
                ]
            }
        ] },
    // ADHESIVE & SEALANT
    { home_id: 5, home_name: "Adhesives & Sealants", home_cat: [
            { top_id: 51, top_name: "Fillers", top_cat: [
                    { sub_id: 511, sub_name: "Acrylic", sub_image: "/app/images/Headers/SubHeaders/Caulking/Filler/Acrylic.jpg" },
                    { sub_id: 512, sub_name: "Silicon", sub_image: "/app/images/Headers/SubHeaders/Caulking/Filler/Silicon.jpg" },
                    { sub_id: 513, sub_name: "Sealants", sub_image: "/app/images/Headers/SubHeaders/Caulking/Filler/Sealants.jpg" },
                ]
            },
            { top_id: 52, top_name: "Adhesives", top_cat: [
                    { sub_id: 521, sub_name: "Construction", sub_image: "/app/images/Headers/SubHeaders/Caulking/Adhesive/A_Construction.jpg" },
                    { sub_id: 522, sub_name: "Quick Grip Glue", sub_image: "/app/images/Headers/SubHeaders/Caulking/Adhesive/A_QGG.jpg" },
                    { sub_id: 523, sub_name: "Wood & Hot Glue", sub_image: "/app/images/Headers/SubHeaders/Caulking/Adhesive/A_W&HG.jpg" },
                    { sub_id: 524, sub_name: "Epoxy & Super Glue", sub_image: "/app/images/Headers/SubHeaders/Caulking/Adhesive/A_E&SG.jpg" },
                    { sub_id: 525, sub_name: "Tape", sub_image: "/app/images/Headers/SubHeaders/Caulking/Adhesive/A_Tape.jpg" }
                ]
            },
            // {top_id:53, top_name: "Specialty", top_cat:  
            //[ // Specialty
            // {sub_id: 531, sub_name:"Specialty", sub_image:"/app/images/Headers/SubHeaders/Caulking/Specialty/SS_Adhesive.jpg"},
            //]
            //}
        ] },
    // PLUMBING 
    { home_id: 6, home_name: "Plumbing", home_cat: [
            { top_id: 61, top_name: "PEX", top_cat: [
                    { sub_id: 611, sub_name: "Pipe", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Pex/PexPipe.jpg" },
                    { sub_id: 612, sub_name: "Fittings", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Pex/PexFitting.jpg" },
                    { sub_id: 613, sub_name: "Valves", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Pex/PexValve.jpg" },
                    { sub_id: 614, sub_name: "Accessories", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Pex/PexAccessories.jpg" }
                ]
            },
            { top_id: 62, top_name: "Copper", top_cat: [
                    { sub_id: 621, sub_name: "Pipe", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Copper/CopperPipe.jpg" },
                    { sub_id: 622, sub_name: "Fittings", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Copper/CopperFitting.jpg" },
                    { sub_id: 623, sub_name: "Valves", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Copper/CopperValve.jpg" },
                    { sub_id: 624, sub_name: "Accessories", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Copper/CopperAccessories.jpg" }
                ]
            },
            { top_id: 63, top_name: "Shark Bites", top_cat: [
                    { sub_id: 631, sub_name: "Fittings", sub_image: "/app/images/Headers/SubHeaders/Plumbing/SharkBite/SharkBite.jpg" },
                ]
            },
            { top_id: 64, top_name: "ABS", top_cat: [
                    { sub_id: 641, sub_name: "Pipe", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Abs/AbsPipe.jpg" },
                    { sub_id: 642, sub_name: "3~4 Inch", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Abs/Abs3.jpg" },
                    { sub_id: 643, sub_name: "2 Inch", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Abs/Abs2.jpg" },
                    { sub_id: 644, sub_name: "1 1/2 Inch", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Abs/Abs1.5.jpg" },
                    { sub_id: 645, sub_name: "1 1/4 Inch", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Abs/Abs1.25.jpg" },
                    { sub_id: 646, sub_name: "Accessories", sub_image: "/app/images/Headers/SubHeaders/Plumbing/Abs/AbsAccessories.jpg" }
                ]
            },
            //  {top_id: 65, top_name: "PVC", top_cat:
            //[ // PVC
            //  {sub_id: 651, sub_name:"Pipe", sub_image:"/app/images/Headers/SubHeaders/Plumbing/Pvc/PvcPipe.jpg"},
            //{sub_id: 652, sub_name:"Fittings", sub_image:"/app/images/Headers/SubHeaders/Plumbing/Pvc/PvcFitting.jpg"},
            // {sub_id: 653, sub_name:"Accessories", sub_image:"/app/images/Headers/SubHeaders/Plumbing/Pvc/PvcAccessories.jpg"}
            //  ]
            //},
        ] },
    // INSULATION
    { home_id: 7, home_name: "Insulation", home_cat: [
            { top_id: 71, top_name: "Insulation", top_cat: [
                    { sub_id: 711, sub_name: "Rigid", sub_image: "/app/images/Headers/SubHeaders/Insulation/Insulation/RigidInsulation.jpg" },
                    { sub_id: 712, sub_name: "Batting", sub_image: "/app/images/Headers/SubHeaders/Insulation/Insulation/BattingInsulation.jpg" },
                ]
            },
            { top_id: 72, top_name: "Acoustic", top_cat: [
                    { sub_id: 721, sub_name: "Batting", sub_image: "/app/images/Headers/SubHeaders/Insulation/Accoustic/AccousticInsulation.jpg" },
                ]
            },
            { top_id: 73, top_name: "Foam", top_cat: [
                    { sub_id: 731, sub_name: "Spray", sub_image: "/app/images/Headers/SubHeaders/Insulation/SprayInsulation/SprayInsulation.jpg" },
                ]
            },
            { top_id: 74, top_name: "Wraps", top_cat: [
                    { sub_id: 741, sub_name: "Wraps", sub_image: "/app/images/Headers/SubHeaders/Insulation/Wraps/wraps_741.jpg" },
                ]
            },
        ] },
    // GENERAL NEEDS
    { home_id: 8, home_name: "General Needs", home_cat: [
            { top_id: 81, top_name: "Job Site", top_cat: [
                    { sub_id: 811, sub_name: "General", sub_image: "/app/images/Headers/SubHeaders/GN/General/General.jpg" },
                    { sub_id: 812, sub_name: "Lubricants", sub_image: "/app/images/Headers/SubHeaders/GN/General/Lubricants.jpg" },
                ]
            },
            { top_id: 82, top_name: "Delivery", top_cat: [
                    { sub_id: 821, sub_name: "Pickup & Dropoffs", sub_image: "/app/images/Headers/SubHeaders/GN/PD/Delivery.jpg" },
                ]
            },
        ] },
    // BLADES & BITS
    { home_id: 9, home_name: "Blades & Bits", home_cat: [
            { top_id: 91, top_name: "Saw Blades", top_cat: [
                    { sub_id: 911, sub_name: "Circular Saw", sub_image: "/app/images/Headers/SubHeaders/BB/SB/CSBlades.jpg" },
                    { sub_id: 912, sub_name: "Table & Miter Saw", sub_image: "/app/images/Headers/SubHeaders/BB/SB/TMSBlades.jpg" },
                    { sub_id: 913, sub_name: "Reciprocating Saw", sub_image: "/app/images/Headers/SubHeaders/BB/SB/RSBlades.jpg" },
                    { sub_id: 914, sub_name: "Oscillating", sub_image: "/app/images/Headers/SubHeaders/BB/SB/OBlades.jpg" },
                    { sub_id: 915, sub_name: "Jig Saw", sub_image: "/app/images/Headers/SubHeaders/BB/SB/JSBlades.jpg" },
                ]
            },
            { top_id: 92, top_name: "Knife, Staples & Marking", top_cat: [
                    { sub_id: 921, sub_name: "Utility Knives", sub_image: "/app/images/Headers/SubHeaders/BB/KSM/KBlades.jpg" },
                    { sub_id: 922, sub_name: "Staples", sub_image: "/app/images/Headers/SubHeaders/BB/KSM/Staples.jpg" },
                    { sub_id: 923, sub_name: "Pencils, Chalk, Pens & Wax", sub_image: "/app/images/Headers/SubHeaders/BB/KSM/PCPW.jpg" },
                ]
            },
            { top_id: 93, top_name: "Drill Bits", top_cat: [
                    { sub_id: 937, sub_name: "Screw Bits", sub_image: "/app/images/Headers/SubHeaders/BB/DB/SB.jpg" },
                    { sub_id: 931, sub_name: "Wood Bits", sub_image: "/app/images/Headers/SubHeaders/BB/DB/WB.jpg" },
                    { sub_id: 936, sub_name: "Bi-Metal Bits", sub_image: "/app/images/Headers/SubHeaders/BB/DB/WMB.jpg" },
                    { sub_id: 932, sub_name: "Metal Bits", sub_image: "/app/images/Headers/SubHeaders/BB/DB/MB.jpg" },
                    { sub_id: 933, sub_name: "Cement Bits", sub_image: "/app/images/Headers/SubHeaders/BB/DB/CB.jpg" },
                    { sub_id: 934, sub_name: "Hole Saw & Forstner", sub_image: "/app/images/Headers/SubHeaders/BB/DB/HSB.jpg" },
                    { sub_id: 935, sub_name: "Tile Blades & Bits", sub_image: "/app/images/Headers/SubHeaders/BB/DB/DDB.jpg" },
                ]
            },
        ] },
    // CEMENT, CONCRETE, GROUTES & MORTARS
    { home_id: 10, home_name: "Cement Products", home_cat: [
            { top_id: 101, top_name: "CMC", top_cat: [
                    { sub_id: 1011, sub_name: "Aggregate", sub_image: "/app/images/Headers/SubHeaders/CMC/CMC/CMC_Aggregate.jpg" },
                    { sub_id: 1012, sub_name: "Concrete & Mortar", sub_image: "/app/images/Headers/SubHeaders/CMC/CMC/CMC_C&M.jpg" },
                    { sub_id: 1013, sub_name: "Additives, Grout & Repair", sub_image: "/app/images/Headers/SubHeaders/CMC/CMC/CMC_AG&R.jpg" },
                    { sub_id: 1014, sub_name: "Sealants & Adhesives", sub_image: "/app/images/Headers/SubHeaders/CMC/CMC/CMC_S&A.jpg" },
                    //{sub_id: 1016, sub_name:"Structure & Reinforcements", sub_image:"/app/images/Headers/SubHeaders/CMC/CMCStructure.jpg"},
                    //{sub_id: 1017, sub_name:"Outdoor Materials", sub_image:"/app/images/Headers/SubHeaders/CMC/CMCOutdoor.jpg"},
                    //{sub_id: 1018, sub_name:"Forms & Pads", sub_image:"/app/images/Headers/SubHeaders/CMC/CMCForm.jpg"},
                ]
            },
        ] },
    // MOULDINGS, MILLWORK & CROWN
    { home_id: 11, home_name: "Trim", home_cat: [
            { top_id: 111, top_name: "MDF", top_cat: [
                    { sub_id: 1111, sub_name: "MDF Casing", sub_image: "/app/images/Headers/SubHeaders/MM/MDF/MDFCasing.jpg" },
                    { sub_id: 1112, sub_name: "MDF Baseboard", sub_image: "/app/images/Headers/SubHeaders/MM/MDF/MDFBaseboard.jpg" },
                    { sub_id: 1113, sub_name: "MDF Crown", sub_image: "/app/images/Headers/SubHeaders/MM/MDF/MDFCrown.jpg" },
                    { sub_id: 1114, sub_name: "MDF Etc.", sub_image: "/app/images/Headers/SubHeaders/MM/MDF/MDFEtc.jpg" },
                ]
            },
            { top_id: 112, top_name: "Pine", top_cat: [
                    { sub_id: 1121, sub_name: "Pine Casing", sub_image: "/app/images/Headers/SubHeaders/MM/Pine/PineCasing.jpg" },
                    { sub_id: 1122, sub_name: "Pine Baseboard", sub_image: "/app/images/Headers/SubHeaders/MM/Pine/PineBaseboard.jpg" },
                    { sub_id: 1123, sub_name: "Pine Etc.", sub_image: "/app/images/Headers/SubHeaders/MM/Pine/PineEtc.jpg" },
                ]
            },
            { top_id: 113, top_name: "Poplar Casing", top_cat: [
                    { sub_id: 1131, sub_name: "Poplar Door Stop", sub_image: "/app/images/Headers/SubHeaders/MM/Poplar/PoplarStop.jpg" },
                    { sub_id: 1132, sub_name: "Poplar Burlap", sub_image: "/app/images/Headers/SubHeaders/MM/Poplar/PoplarThreshold.jpg" },
                    { sub_id: 1133, sub_name: "Poplar Casing", sub_image: "/app/images/Headers/SubHeaders/MM/Poplar/PoplarCasing.jpg" },
                    { sub_id: 1134, sub_name: "Poplar Baseboard", sub_image: "/app/images/Headers/SubHeaders/MM/Poplar/PoplarBaseboard.jpg" },
                    { sub_id: 1135, sub_name: "Poplar Quarter Round", sub_image: "/app/images/Headers/SubHeaders/MM/Poplar/PoplarQRound.jpg" },
                ]
            },
        ] },
    // TILING MATERIALS
    { home_id: 12, home_name: "Tiling", home_cat: [
            /*{top_id: 121, top_name: "Underlayment & Heating", top_cat:
                [ // Sub Floor Prep
                    //{sub_id: 1211, sub_name:"Floor & Boards", sub_image:"/app/images/Headers/SubHeaders/Tiling/SS&H/SS&H_F&B.jpg"},
                    //{sub_id: 1213, sub_name:"Heated Flooring", sub_image:"/app/images/Headers/SubHeaders/Tiling/SS&H/SS&H_HF.jpg"},
                ]
            },*/
            { top_id: 122, top_name: "Thinsets, Groutes & Finishing", top_cat: [
                    { sub_id: 1221, sub_name: "Thinset", sub_image: "/app/images/Headers/SubHeaders/Tiling/TG&F/TG&F_Thinset.jpg" },
                    { sub_id: 1222, sub_name: "Adhesive", sub_image: "/app/images/Headers/SubHeaders/Tiling/TG&F/TG&F_Adhesive.jpg" },
                    //{sub_id: 1223, sub_name:"Groutes", sub_image:"/app/images/Headers/SubHeaders/Tiling/TG&F_Groutes.jpg"},
                    { sub_id: 1224, sub_name: "Caulking", sub_image: "/app/images/Headers/SubHeaders/Tiling/TG&F/TG&F_Caulking.jpg" },
                    //{sub_id: 1225, sub_name:"Sealents & Cleaners", sub_image:"/app/images/Headers/SubHeaders/Tiling/TG&F_S&C.jpg"},
                ]
            },
            { top_id: 123, top_name: "Tools", top_cat: [
                    { sub_id: 1231, sub_name: "Tools", sub_image: "/app/images/Headers/SubHeaders/Tiling/Tools/Tools_Tools.jpg" },
                ]
            },
        ] },
    // FINISHING AND PAINTS
    { home_id: 13, home_name: "Finishing & Paints", home_cat: [
            /*{top_id: 131, top_name: "Painting", top_cat:
                [ // Painting
                    //{sub_id: 1311, sub_name:"Primers", sub_image:"/app/images/Headers/SubHeaders/F&P/Painting/Painting_Primers.jpg"},
                    //{sub_id: 1312, sub_name:"Brushes, Rollers & Drops", sub_image:"/app/images/Headers/SubHeaders/F&P/Painting/Painting_Brushes.jpg"},
                    //{sub_id: 1312, sub_name:"Etc", sub_image:"/app/images/Headers/SubHeaders/F&P/Painting/Painting_Etc.jpg"},
                ]
            },*/
            { top_id: 132, top_name: "Sanding", top_cat: [
                    { sub_id: 1321, sub_name: "Drywall", sub_image: "/app/images/Headers/SubHeaders/F&P/Sanding/Sanding_Drywall.jpg" },
                    { sub_id: 1322, sub_name: "Belts", sub_image: "/app/images/Headers/SubHeaders/F&P/Sanding/Sanding_Rectangles.jpg" },
                    { sub_id: 1323, sub_name: "Discs", sub_image: "/app/images/Headers/SubHeaders/F&P/Sanding/Sanding_Circles.jpg" },
                    { sub_id: 1324, sub_name: "Sheets", sub_image: "/app/images/Headers/SubHeaders/F&P/Sanding/Sanding_Squares.jpg" },
                    { sub_id: 1325, sub_name: "Detail", sub_image: "/app/images/Headers/SubHeaders/F&P/Sanding/Sanding_Detail.jpg" },
                ]
            },
        ] },
    // ELECTRICAL
    { home_id: 14, home_name: "Electrical", home_cat: [
            { top_id: 142, top_name: "General", top_cat: [
                    { sub_id: 1421, sub_name: "Wires", sub_image: "/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Staples.jpg" },
                    { sub_id: 1422, sub_name: "Metal Fittings", sub_image: "/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Clamp.jpg" },
                    { sub_id: 1423, sub_name: "Pastic Fittings ", sub_image: "/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_C&M.jpg" },
                    { sub_id: 1424, sub_name: "Plates", sub_image: "/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Screws.jpg" },
                ]
            },
            /*{top_id: 143, top_name: "Breakers & Panels", top_cat:
                [ // Supplies
                    {sub_id: 1431, sub_name:"Panels", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Staples.jpg"},
                    {sub_id: 1432, sub_name:"Square D", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Clamp.jpg"},
                    {sub_id: 1433, sub_name:"QP Breaker", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_C&M.jpg"},
                ]
            },
             {top_id: 144, top_name: "Caps, Outlets & Switches", top_cat:
                [ // Supplies
                    {sub_id: 1441, sub_name:"Outles", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Staples.jpg"},
                    {sub_id: 1442, sub_name:"Switches", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Clamp.jpg"},
                    {sub_id: 1443, sub_name:"Dimmers", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_C&M.jpg"},
                    {sub_id: 1444, sub_name:"Caps", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Screws.jpg"},
                ]
            },
            
            { top_id: 141, top_name: "Supplies", top_cat:
                [ // Supplies
                    {sub_id: 1411, sub_name:"Staples", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Staples.jpg"},
                    {sub_id: 1412, sub_name:"Clamp Connector", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Clamp.jpg"},
                    {sub_id: 1413, sub_name:"Caps & Marrettes", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_C&M.jpg"},
                    {sub_id: 1414, sub_name:"Screws", sub_image:"/app/images/Headers/SubHeaders/Electrical/Supplies/Supplies_Screws.jpg"},
                ]
            },
          */
        ] },
    // ELECTRICAL
    { home_id: 15, home_name: "HVAC", home_cat: [
            { top_id: 151, top_name: "Vents", top_cat: [
                    { sub_id: 1511, sub_name: "Vents", sub_image: "/app/images/Headers/SubHeaders/HVAC/Vent/HVAC_Vent.jpg" },
                ]
            },
        ] },
    // DOORS & DOOR HARDWARE
    { home_id: 16, home_name: "Doors & Hardware", home_cat: [
            { top_id: 161, top_name: "Door", top_cat: [
                    { sub_id: 1612, sub_name: "1 Panel", sub_image: "/app/images/Headers/SubHeaders/DH/Doors/DH_Doors_Lincoln.jpg" },
                    { sub_id: 1613, sub_name: "2 Panel", sub_image: "/app/images/Headers/SubHeaders/DH/Doors/DH_Doors_Two_Panel.jpg" },
                    { sub_id: 1617, sub_name: "6 Panel", sub_image: "/app/images/Headers/SubHeaders/DH/Doors/DH_Doors_Six_Panel.jpg" },
                ]
            },
            { top_id: 162, top_name: "Hinges", top_cat: [
                    { sub_id: 1621, sub_name: "Square", sub_image: "/app/images/Headers/SubHeaders/DH/Hinges/DH_Hinges_Square.jpg" },
                    { sub_id: 1622, sub_name: "Rounded", sub_image: "/app/images/Headers/SubHeaders/DH/Hinges/DH_Hinges_Rounded.jpg" },
                ]
            },
            { top_id: 163, top_name: "Handles", top_cat: [
                    { sub_id: 1631, sub_name: "Lever", sub_image: "/app/images/Headers/SubHeaders/DH/Handles/DH_Handle_Lever.jpg" },
                    //{sub_id: 1632, sub_name:"Wave", sub_image:"/app/images/Headers/SubHeaders/DH/Handles/DH_Handle_Wave.jpg"},
                    //{sub_id: 1633, sub_name:"Round", sub_image:"/app/images/Headers/SubHeaders/DH/Handles/DH_Handle_Round.jpg"},
                ]
            },
        ] },
    // STRUCTURE & HANGERS
    { home_id: 17, home_name: "Structure & Connectors", home_cat: [
            { top_id: 171, top_name: "Support", top_cat: [
                    { sub_id: 1711, sub_name: "Posts", sub_image: "/app/images/Headers/SubHeaders/S&H/Support/S&H_Support_Post.jpg" },
                    { sub_id: 1712, sub_name: "Rebar", sub_image: "/app/images/Headers/SubHeaders/S&H/Support/S&H_Support_Rebar.jpg" },
                    { sub_id: 1713, sub_name: "Mesh", sub_image: "/app/images/Headers/SubHeaders/S&H/Support/S&H_Support_Mesh.jpg" },
                    { sub_id: 1714, sub_name: "Accessories", sub_image: "/app/images/Headers/SubHeaders/S&H/Support/S&H_Support_Asc.jpg" },
                    { sub_id: 1715, sub_name: "Forms", sub_image: "/app/images/Headers/SubHeaders/S&H/Support/S&H_Support_Forms_1715.jpg" },
                ]
            },
            { top_id: 172, top_name: "Hangers & Connectors", top_cat: [
                    { sub_id: 1721, sub_name: "Standard Hangers", sub_image: "/app/images/Headers/SubHeaders/S&H/H&C/S&H_H&C_Hangers.jpg" },
                    { sub_id: 1722, sub_name: "Standard Connectors", sub_image: "/app/images/Headers/SubHeaders/S&H/H&C/S&H_H&C_Connectors.jpg" },
                    { sub_id: 1727, sub_name: "Engineered Hangers", sub_image: "/app/images/Headers/SubHeaders/S&H/H&C/S&H_H&C_EngHangers.jpg" },
                ]
            },
        ] },
    /* // ROOFING
     { home_id: 17, home_name: "Roofing", home_cat:[
        {top_id: 171, top_name: "Shingles", top_cat:
            [ // Supplies
                {sub_id: 1711, sub_name:"Owens Corning", sub_image:"/app/images/Headers/SubHeaders/R&G/Shingles/R&G_Shingles_OC.jpg"},
                {sub_id: 1712, sub_name:"CRC", sub_image:"/app/images/Headers/SubHeaders/R&G/Shingles/R&G_Shingles_CRC.jpg"},
           ]
        },
        {top_id: 172, top_name: "Hangers & Connectors", top_cat:
            [ // Supplies
                {sub_id: 1721, sub_name:"Standard Hangers", sub_image:"/app/images/Headers/SubHeaders/S&H/H&C/S&H_H&C_Hangers.jpg"},
                {sub_id: 1722, sub_name:"Standard Connectors", sub_image:"/app/images/Headers/SubHeaders/S&H/H&C/S&H_H&C_Connectors.jpg"},
                {sub_id: 1727, sub_name:"Engineered Hangers", sub_image:"/app/images/Headers/SubHeaders/S&H/H&C/S&H_H&C_EngHangers.jpg"},
           ]
        },
        
    ]},
    */
];
