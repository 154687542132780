import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Driver, RouteDragDrop, DragItem, ToolRoutes } from '../classes/tool-routes';
import { ToolRoutesService } from '../tool-routes.service';
import { ToolRouteOptionsService } from '../tool-route-options/tool-route-options.service';
import { TrelloCard } from 'src/app/api/classes/trello';

@Component({
  selector: 'app-tool-route-driver-selection',
  templateUrl: './tool-route-driver-selection.component.html',
  styleUrl: './tool-route-driver-selection.component.css', 
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ToolRouteDriverSelectionComponent implements OnInit, OnDestroy{
  @ViewChild('driverSelection', { static: true }) driverSelection!: ElementRef;

  @Input() domRect: DOMRect|null = null;
  @Input() optionSetting: any = {};

  @Output() closeContainer = new EventEmitter<boolean>();
  
  routeInfo = new ToolRoutes;
  routesSub: Subscription;
  dragCard: TrelloCard = new TrelloCard;

  activeDrivers: Driver[] = []

  constructor(
    private routeService: ToolRoutesService,
    private detectChanges: ChangeDetectorRef, 
    private btnOptionService: ToolRouteOptionsService,

  ){
    this.routesSub = routeService.routeView$.subscribe(newRoute => {
      this.routeInfo = newRoute;
      this.detectChanges.markForCheck();
    });
  }

  ngOnInit(): void {
    //console.log(this.optionSetting, "Init Teams selector?");

    if(this.optionSetting.dragCard){this.dragCard = this.optionSetting.dragCard}

    this.setOptionContainerBounding();
    const activeDrivers = this.routeService.currentRouteInfo().activeDrivers;
    if(this.optionSetting.type === 'team'){
      const driverIndex = this.optionSetting.driverIndex;
  
      const newActiveDrivers: Driver[] = [];
      activeDrivers.forEach(driver=> {
        const findDriver = driver === activeDrivers[driverIndex];
        if(!findDriver){newActiveDrivers.push(driver)};
      });
  
      this.activeDrivers = newActiveDrivers;
    } else { this.activeDrivers = activeDrivers}
 
  }

  ngOnDestroy(): void {
    this.routesSub.unsubscribe();
  }

  // MARK - DOM Setup
  setOptionContainerBounding(){
    const switchAction = this.routeInfo.view.routeBtnContextView.showSubOption;
    console.log(switchAction, "<-----")
    if(this.domRect){
      const x = this.domRect.x;
      const y = this.domRect.y;
      const h = window.innerHeight;

      let xOffset = 0;
      let yOffset = 0;

      if(this.optionSetting.type === 'team' || this.optionSetting.type === 'move' || !this.optionSetting.type){
        // Set position
        let top = '0px'

  
        if(x < 0 ){xOffset = x};
        if( (y + 235) > h){
          const countDrivers = this.activeDrivers.length;
          const total = (55 * countDrivers * -1) - 15
          console.log(total, " <00000000000000")
          top =  total + 'px'
        };
  
  
        this.driverSelection.nativeElement.style.top = top;
        
        
      } else { this.driverSelection.nativeElement.style.right = '60px'};
    }


  }

  // MARK - Click Actions
  btnClick(driver: Driver|null, driverIndex: number, type: string){



    let dragCard = new DragItem;
    let idCard = '';

    let switchAction = this.routeInfo.view.routeBtnContextView.showSubOption;
    if(switchAction === 'move'){ switchAction = type};    

    console.log(switchAction, "Actions and options", this.optionSetting, type)

    switch (switchAction){
      case 'move_all': 
        this.moveAllDragItemFromTitg(driver!, driverIndex);   
        this.routeService.setView('dismiss', false, null,'btnClick:move_all'); 
      break;

      case 'team': 
        this.generateTeamCard(driver!, driverIndex); 
        this.routeService.setView('dismiss', false, null, 'btnClick:team'); 
      break;
      
      case 'move_titg':
        // Special Case for moving from
        const moveData = this.routeInfo.view.infoToolOptions;
        console.log(this.optionSetting, 'move_titg', moveData);
        
        if(moveData[0] && moveData[0].idCard){ idCard = moveData[0].idCard}
        else if(moveData[0] && moveData[0].id){ idCard = moveData[0].id};

        // Set view properties
        
        //this.btnOptionService.moveCard(idCard, 'btnClick:move_titg');
        this.routeService.setView('dismiss', false, null, 'btnClick:move_titg'); 
      break;
      
      case 'move_photo':
        // Special Case for moving from
        const currentDragItem = this.routeInfo.view.infoToolOptions;

        if(this.optionSetting.dragCard && this.optionSetting.dragCard.idCard){ idCard = this.optionSetting.dragCard.idCard; console.log("This is defenitly a card")}
        else if(this.optionSetting.dragIem && this.optionSetting.dragIem.idCard){idCard = this.optionSetting.dragItem.idCard; console.log("This mighjt be a card and might nto")}
        else if(this.optionSetting.dragIem && this.optionSetting.dragIem.id){idCard = this.optionSetting.dragItem.id; console.log("Who knows wwhat this will be")}
        
        
        console.log(this.optionSetting.dragItem.id, 'move_photo', idCard, "<=-----==-ds", currentDragItem);

        // Set view properties
        this.routeService.setView('show_btn_sub_options', 'move_photo',null,'btnClick:move_photo'); 
        //this.btnOptionService.moveCard(idCard, 'btnClick:move_photo');
      break;
    }
  }
  
  // MARK - DRIVER AUTOMATION  
  moveAllDragItemFromTitg(driver: Driver, indexTitgCardOrDriver: number){
    console.log('Iniyt move you there sucker')
    const listIndex = this.optionSetting.itemIndex; 
    // Setup items to be moved
    const currentRouteInfo = this.routeService.currentRouteInfo();

    const dragCard = currentRouteInfo.titgCardCatalog[listIndex];
    //console.log(dragCard, "<--------DRAG CARD")
    const preDragItems: DragItem[] = dragCard.dragItems;  
    

    const dragItems: DragItem[] = [];
    preDragItems.forEach(item => {if(!item.routed){dragItems.push(item)}});

    const dropData: RouteDragDrop = {
      dragCard: [dragCard], 
      dragItems: dragItems,
      nextIndex: -1, 
      previousIndex: -1,
      indexTitgCardOrDriver: indexTitgCardOrDriver,
      driver: driver,

      postItems: dragItems
    }
    
    // Initiate and Complete the moving procedure
    this.routeService.moveDragItems(dropData, 'moveTitgToDriver');

    // Dimisss Driver Selector
    this.closeContainer.emit(false);
    //console.log(dragItemsToBeMoved, "<------ helo helo")

  }

  generateTeamCard(driver: Driver, teamDriverIndex: number){
    const activeDrivers = this.routeService.currentRouteInfo().activeDrivers;

    // Assign driver team
    const driverFirst: Driver = activeDrivers[this.optionSetting.driverIndex];
    const driverSecond: Driver = driver;

    const dragItem: DragItem = this.optionSetting.dragItem;

    this.routeService.implementTeamCreation([driverFirst,driverSecond],dragItem);
  }

  // MARK - SHOW
  showOptions(): boolean{

    const currentRouteInfo = this.routeService.currentRouteInfo();
    const currentSubOption = currentRouteInfo.view.routeBtnContextView.showSubOption;

    const teamCheck = currentSubOption === 'team' || currentSubOption === 'move_all';

    if(teamCheck){return true}
    return false;
  }
}
