import { InputWindow } from "../classes/input-window";

export const toolUpdaterPopupSetting: InputWindow = {
        // General Settings
        window_type: 'updater-submit',
        title: 'Updater',
        title_css: '',
        message_1: 'Submit price updates?',
        message_1_container_css: 'text-center my-2',
        message_1_css: '',
        show: 'updater-submit',
        window_info: [145, 163, 163],
        window_clean: false,
        window_css: 'modal-window',
        rows: 1,
        process_message: '',
        passed_data: null,
        row: [{
                logic: null,
                row_type: [{
                        logic: null,
                        row_type: 3,
                        setting: [{
                            button_setting: {
                                container_class: 'col-6',
                                button_message: 'submit',
                                button_css_setting: 'btn btn-primary',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'admin_price_update',
                                button_route: null
                            },
                            input_setting: null,
                            message_setting: {
                                container_class: "flex-row",
                                message_css_setting: "text-center w-100 pb-2",
                                message_visible: false,
                                message_output: ''
                            },
                            output_setting: null,
                        },{
                            button_setting: {
                                container_class: 'col-6 text-right',
                                button_message: 'cancel',
                                button_css_setting: 'btn btn-warning ',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'dismiss',
                                button_route: null
                            },
                            input_setting: null,
                            message_setting: {
                                container_class: "flex-row",
                                message_css_setting: "text-center w-100 pb-2",
                                message_visible: false,
                                message_output: ''
                            },
                            output_setting: null,
                        }],
                    }]
            }]
};

export const iframePopupSetting: InputWindow = {
    // General Settings
    window_type: 'iframe',
    title: 'Windows??',
    title_css: '',
    message_1: '',
    message_1_container_css: '',
    message_1_css: '',
    show: 'iframe',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 0,
    process_message: '',
    passed_data: null,
    row: []
}
