import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TrelloChecklist } from '../../classes/trello';
import { DragItem } from 'src/app/accounts/account-tools/tool-routes/classes/tool-routes';
import { TrelloService } from '../../services/trello.service';
import { TrelloChecklistService } from './trello-checklist.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-trello-checklist',
  templateUrl: './trello-checklist.component.html',
  styleUrl: './trello-checklist.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrelloChecklistComponent implements OnInit, OnDestroy {
  @Input() idChecklists: string[] = [];

  checklists: TrelloChecklist[] = [];
  subChecklist: Subscription;

  constructor(
    private trelloService: TrelloService,
    private checklistService: TrelloChecklistService,
    private detectChanges: ChangeDetectorRef,
  ){
    this.subChecklist = checklistService.checklist$.subscribe(newChecklists => {
      this.checklists = newChecklists;
      console.log("Sub herer")
      this.detectChanges.markForCheck();
    })
  }

  ngOnInit(): void {
    // Request Checklists
    if(this.idChecklists){ 
      console.log('REquesting a new checklist', this.idChecklists)
      this.checklistService.getChecklist(this.idChecklists)};
  }

  ngOnDestroy(): void {
    this.subChecklist.unsubscribe();
  }

}
