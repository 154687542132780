import { ChangeDetectorRef, OnInit,Component, Renderer2, OnDestroy } from '@angular/core';
import { ToolRoutesService } from '../tool-routes.service';
import { Subscription } from 'rxjs';
import { EmailOrder } from '../classes/tool-routes';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tool-route-email',
  templateUrl: './tool-route-email.component.html',
  styleUrls: ['./tool-route-email.component.css']
})
export class ToolRouteEmailComponent implements OnInit, OnDestroy{

  emailInfo: EmailOrder[] = [];
  emailSub: Subscription;

  formEmail = new FormGroup({
    email_from: new FormControl(''),
    email_to: new FormControl(''),
    email_cc: new FormControl(''),
    email_subject: new FormControl(''),
    email_body: new FormControl(''),
  })  

  constructor(  
    private routeService: ToolRoutesService,
    private detectChanges: ChangeDetectorRef, 
    private renderer: Renderer2,
  ){
    this.emailSub = routeService.emailOrder$.subscribe((newEmail: EmailOrder[]) => {
      this.emailInfo = newEmail;      
      this.detectChanges.markForCheck();
    });
  }

  ngOnInit(){
    this.renderer.addClass(document.body, 'modal-open');

    const newEmailValues = this.emailInfo[0];

    console.log(newEmailValues, "<----------")

    this.formEmail.setValue({
      email_from: newEmailValues.email_from,
      email_to: newEmailValues.email_to,
      email_cc: newEmailValues.email_cc,
      email_subject: newEmailValues.email_subject,
      email_body: newEmailValues.email_body,
    })    
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'modal-open');

  }

  sendEmail(){
    console.log("This is the email and form control", this.formEmail);

    const newEmail: EmailOrder = new EmailOrder

    if(this.formEmail.value.email_from){newEmail.email_from = this.formEmail.value.email_from }
    if(this.formEmail.value.email_to){newEmail.email_to = this.formEmail.value.email_to }
    if(this.formEmail.value.email_cc){newEmail.email_cc = this.formEmail.value.email_cc }
    if(this.formEmail.value.email_subject){newEmail.email_subject = this.formEmail.value.email_subject }
    if(this.formEmail.value.email_body){newEmail.email_body = this.formEmail.value.email_body } 
    
    this.routeService.sendEmail(newEmail)

  }

}
