import { HttpClient } from '@angular/common/http';
import { Injectable }from'@angular/core';
import { Observable, Subject, catchError, debounceTime, distinctUntilChanged, filter, map, of, retry, switchMap }from'rxjs';
import { DefaultAppConfig } from 'src/app/app.config';
import { Item } from 'src/app/cart-item/classes/item';
import { ServerService } from 'src/app/general-services/server.service';


@Injectable({
  providedIn: 'root'
})

export class SearchService{
  
  searchSubject = new Subject<Item[]>();
  searchSubject$ = this.searchSubject.asObservable();

  searchTerm = new Subject();
  search$ = this.searchTerm.asObservable();

  constructor(// private http:Http,
    private serverService: ServerService,
    private http: HttpClient) {
      // private catalogUrl='/app_forward/service_request.php';
      //isArrowAnimated = false;
    }

  // MARK: - Search Subject
  nextSearch(term: any) {
    this.searchTerm.next(term);
  }

  search(term: any) {
    
    let prepend = '';

    if (DefaultAppConfig.DEFAULT_TESTING) {  prepend = 'http://192.168.112.128/app_forward';
    } else { prepend = '/app_forward'; }

    const requestURI = prepend + '/service_request.php' + '?action_id=' + 1 + '&query=' + term;
    
    const observed = this.http
        .get(requestURI)
        .pipe(
          (retry)(3),
          filter((value): value is Item[] => Array.isArray(value)), 
          (catchError)(this.handleError));
        
      
      //this.searchBreadcrumbs(observed);
      return observed;
  }

  getItems() {
    const searchResults =this.searchTerm.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: any) => term
          ? this.search(term)
          : of <Item[]>([])
      ),
      catchError(() => {
          return of <Item[]>([]);
      })   
    )
    return searchResults;
  }

  

  // MARK: - Get's
  // MARK: - Error Handling
  handleError(error:any) {
      console.error('An error occured', error);
      return Promise.reject(error.message || error);
  }
}