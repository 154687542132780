import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DEFAULT_WINDOW_INFO, InputWindowInfo } from './classes/input-window-info';

const DEFAULT_MODAL_WINDOW_INFO: InputWindowInfo = DEFAULT_WINDOW_INFO;


@Injectable({
  providedIn: 'root'
})
export class InputWindowService {

  constructor() { }
  subjectModalWindowInfo = new BehaviorSubject(DEFAULT_MODAL_WINDOW_INFO);
  modalWindowInfo$ = this.subjectModalWindowInfo.asObservable();
  currentModalWindowInfo() {
    return this.subjectModalWindowInfo.value;
  }
  nextModalWindowInfo(modalWindowInfo: InputWindowInfo) {
      // console.log(modalWindowInfo, "OPrder of opoerations")
      this.subjectModalWindowInfo.next(modalWindowInfo);
  }
  
  changeModalWindowInfo(change: string, currentType: string,  to: any) {
      const newModalWindowInfo = this.currentModalWindowInfo();
      switch (change) {
          case 'loading':
              newModalWindowInfo.isLoading = to;
              break;
          case 'pass_project_checkout':
              newModalWindowInfo.passProjectCheckout = to;
              break;
          case 'inputWindow':
              newModalWindowInfo.passedData = to;
              newModalWindowInfo.currentType = currentType;
              break;
          case 'type':
              
      }
      this.nextModalWindowInfo(newModalWindowInfo);
  }
}
