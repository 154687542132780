export class Trello {
    trello_token: string = "";
    trello_key: string = "";
    trello_card: any[] = [];
    active_trello_card: TrelloCard[] = [];
}

export class TrelloBoard{
    closed: boolean = false;
    creationMethod: null| string = null;
    dateClosed: null| string = null;
    dateLastActivity: string = "";
    dateLastView: string = "";
    datePluginDisable: null| string = null;
    desc: string = "";
    descData: null| string = null;
    enterpriseOwned: boolean = false;
    id: string = "";
    idBoardSource: null| string = null;
    idEnterprise: null| string = null;
    idMemberCreator: string = "";
    idOrganization: string = "";
    idTags:string[] = [];
    ixUpdate: string = "";
    name: string = "";
    nodeId: string = "";
    pinned: boolean = false;
    shortLink: string = "";
    shortUrl: string = "";
    
    starred: boolean = false;
    subscribed: boolean = false;
    templateGallery: null| string = null;
    url: string = "";
}

export  class TrelloList{
    closed: boolean = false
    id: string = '';
    idBoard: string = '';
    name: string = '';
    pos: number = 16383.75
    softLimit: string|null = null
    status: string|null = null
    subscribed: boolean = false;

    idChecklists: string[] = [];
    
     // View
     showAddCard: boolean = false;
}

export class TrelloCard {
    name: string = '';
    desc: string = '';

    id: string = '';
    idList: string = '';
    idCardSource: string = '';
    idBoard: string = '';
    idChecklists:  string[] = [];
    idAttachmentCover: string = '';

    checkItemStates: TrelloCheckItemStates | null = null;

    //contact: any[] = [];
    //emails: any[] = [];
    //telephone: any[] = [];
    //pictures: TrelloPictures[] = [];
    //date: string = '';
    
    labels: TrelloLabel[] = [];
    keepFromSource: string  = 'all'; //all, attachments, checklists, comments, customFields, due, start, labels, members, start, stickers
    
    pos: number = 0;
    due: string = '';
    start: string = '';
    dueComplete: boolean = false;
    idLabels: string[]  = [];
    url: string = '';
    state: string = '';

    address: string = '';
    locationName: string = '';
    coordinates: string = '';

    attachements: TrelloAttachment[] = [];
    
    shortUrl: string = "";
    shortLink: string = "";

     // View
     showAddChecklist: boolean = false;
}

export class TrelloChecklist{
   
    name: string = '';
    id: string = '';
    idBoard: string = '';
    idCard: string = '';
    pos: number = -1;
    state: string = 'incomplete';
    checkItems: TrelloCheckItem[] = [];
    //checked: boolean = false;
    //due: string | null = null;
    //dueReminder: number | null = null;
}

export class TrelloCheckItem{
    id: string = '';
    name: string = '';
    idChecklist: string = '';
    pos: number = -1;
    state: string = 'incomplete';
    //checked: boolean = false;
    due: string | null = null;
    dueReminder: number | null = null;
    idMember: string|null = null;

    // View
    showAddCheckItem: boolean = false;
}

export class TrelloCheckItemStates{
    idCheckItem: string = '';
    state: string = 'incomplete';
}

export class TrelloAttachment{
    id: string = "";
    bytes: number = -1;
    date: string = "";
    edgeColor: string = "";
    idMember: string = "";
    isUpload: boolean = false;
    mimeType: string = "";
    name: string = "";
    previews: TrelloAttachmentPreview[] = []
    url: string = "";
    pos: number = 16384;
    fileName: string = "";
}

export class TrelloAttachmentPreview{
    id: string =  "";
    _id: string =  "";
    scaled: boolean = true;
    url: string =  "";
    bytes: number =  -1;
    height: number =  -1;
    width: number =  -1;
}

export class TrelloLabel {
    color: string = '';
    id: string = '';
    idBoard: string = '';
    name: string = '';
    uses: number = -1;
    font_color: string = 'white';
}

export const CARD_DAILY_MAINTENANCE: TrelloCard = {
    name:  'Van Maintenance - Daily',
    desc: '',
    
    id: '',
    idBoard: '',
    idCardSource: '64e483ee0769d53ed2666ff9',
    idList: '',
    idLabels: ['654d4fba790c48381633fef5'],
    idChecklists:['64e483ee0769d53ed2667021','65046b0cd1ae403068f982da'],

    checkItemStates: null,

    keepFromSource: 'all',

    //contact: [],
    //emails: [],
    //telephone: [],
    //pictures: [],
    //date: '',

    pos: 0,
    due:  '',
    start:  '',
    dueComplete: false,
    url: 'https://trello.com/c/juBfuS7r/11517-van-maintenance-daily',
    
    address:  '',
    locationName:  '',
    coordinates:  '',
    state: 'incomplete',
    labels: [],
    idAttachmentCover: '',
    
    
    attachements: [],
    shortLink: '',
    shortUrl: '',

    showAddChecklist: false,
}

export const ROUTE_CARD: TrelloCard = {
    name:  '',
    desc: '',

    id: '',    
    idBoard: '',
    idCardSource: "64f6f19b57995647d32cadc3",

    keepFromSource: 'all',
    //contact: [],
    //emails: [],
    //telephone: [],
    //pictures: [],
    //date: '',

    pos: 0,
    due:  '',
    start:  '',
    dueComplete: false,
    idLabels: [],
    url: '',

    address:  '',
    locationName:  '',
    coordinates:  '',
    state: 'incomplete',
    
    idChecklists:['64f6f19b57995647d32cadef'],
    idList: '',
    labels: [],
    idAttachmentCover: '',
    
    checkItemStates: null,

    attachements: [],
    
    shortLink: '',
    shortUrl: '',
    
    showAddChecklist: false,
}

export const TRELLO_TitG_LIST: TrelloList = {
    closed: false,
    id: "609a6bc7ed92fd8bec9c3c08",
    idBoard: "5ea969ad22a3425649825fa5",
    name: "TODAY is the GREATEST!! 🎉",
    pos: 108287.5859375,
    softLimit: null,
    status: null,
    subscribed: false,
    idChecklists: [],

    showAddCard: false
}

// MARK - All the labels that trello has for us
export const DEFAULT_TRELLO_LABELS: TrelloLabel[] = [{
    id: "5efd1dc28f8b928c7f4c3e59",
    idBoard: "",
    name: "Cabinet Maker - CLIENT",
    color: "green_dark",
    uses: 12,
    font_color: 'white',
},{
    id: "5eaf67849de4005ab27537a3",
    idBoard: "",
    name: "Materials - DELIVERY",
    color: "green",
    uses: 409,
    font_color: 'white',
},{
    id: "62b48a17d97614798223e69e",
    idBoard: "",
    name: "Flooring Retailer - CLIENT",
    color: "green_dark",
    uses: 4,
    font_color: 'white',
},{
    id: "654b7f0db44cef3c4e8c082a",
    idBoard: "",
    name: "Contractor - CLIENT",
    color: "green_dark",
    uses: 78,
    font_color: 'white',
},{
    id: "6495a6cda9cf41fb597228a8",
    idBoard: "",
    name: "Vendor - CLIENT",
    color: "green_dark",
    uses: 1,
    font_color: 'white',
},{
    id: "60887ad98749e47e854035d8",
    idBoard: "",
    name: "CH Returns - BILLING",
    color: "yellow",
    uses: 4,
    font_color: 'white',
},{
    id: "64621f7dc504d1963746dc5d",
    idBoard: "",
    name: "Homeowner - CLIENT",
    color: "green_dark",
    uses: 9,
    font_color: 'white',
},{
    id: "5eb80f5f3aecf18aa376bef7",
    idBoard: "",
    name: "NC Returns - BILLING",
    color: "yellow",
    uses: 19,
    font_color: 'black',
},{
    id: "60490ce1f26cce0c49c2f949",
    idBoard: "",
    name: "RAQ - REFERRAL",
    color: "purple_light",
    uses: 25,
    font_color: 'white',
},{
    id: "62df6932fe75fd128d288f76",
    idBoard: "",
    name: "Rework Order - BILLING",
    color: "yellow",
    uses: 3,
    font_color: 'white',
},{
    id: "61b9d174ae03d12b72f2f92b",
    idBoard: "",
    name: "Solo Carry - MANPOWER",
    color: "sky",
    uses: 28,
    font_color: 'black',
},{
    id: "5f9854ed59e62e33957edf6a",
    idBoard: "",
    name: "Solo Carry In - Dave JR! - MANPOWER",
    color: "sky",
    uses: 7,
    font_color: 'black',
},{
    id: "64baf1942d45dea925dd63aa",
    idBoard: "",
    name: "Awesome",
    color: "yellow_dark",
    uses: 1,
    font_color: 'white',
},{
    id: "63c58674170b8e0064b22f06",
    idBoard: "",
    name: "Apartment - CARRY",
    color: "orange",
    uses: 12,
    font_color: 'white',
},{
    id: "62f156c6b0401e250f73e17e",
    idBoard: "",
    name: "Basement - CARRY",
    color: "orange",
    uses: 31,
    font_color: 'white',
},{
    id: "63612a5977b186016462f5a5",
    idBoard: "",
    name: "Main Floor - CARRY",
    color: "orange",
    uses: 36,
    font_color: 'white',
},{
    id: "63c9205d4d11f5013e8a2dab",
    idBoard: "",
    name: "Second floor - CARRY",
    color: "orange",
    uses: 8,
    font_color: 'white',
},{
    id: "6374d22f89c20b01f63b193d",
    idBoard: "",
    name: "Garbage Men - PD",
    color: "purple",
    uses: 2,
    font_color: 'white',
},{
    id: "63612a6701064b003d7f7905",
    idBoard: "",
    name: "Second Floor + - CARRY",
    color: "orange",
    uses: 6,
    font_color: 'white',
},{
    id: "6544cfe677c64d2fd4e8ca7f",
    idBoard: "",
    name: "PAYMENT REQ - ISSUE",
    color: "red",
    uses: 1,
    font_color: 'white',
},{
    id: "5f88d774fed80583570f15c7",
    idBoard: "",
    name: "PPE REQUIRED (personal protective equipment)  - REQUIREMENT",
    color: "pink",
    uses: 2,
    font_color: 'white',
},{
    id: "653686ffdcc716127e954fb4",
    idBoard: "",
    name: "Curbside - DROP",
    color: "blue_dark",
    uses: 27,
    font_color: 'white',
},{
    id: "63a22a3d47067c011821ffa1",
    idBoard: "",
    name: "Tamarack People - REFERRAL",
    color: "purple_light",
    uses: 11,
    font_color: 'white',
},{
    id: "617a77a1a5dcb3459caa492d",
    idBoard: "",
    name: "Front Door - DROP",
    color: "blue_dark",
    uses: 5,
    font_color: 'white',
},{
    id: "5ebe5f1e82d95832baa459a8",
    idBoard: "",
    name: "Vendor Pickup - PD",
    color: "purple",
    uses: 172,
    font_color: 'white',
},{
    id: "653686de0e5561cc51359d50",
    idBoard: "",
    name: "Multi Story - CARRY",
    color: "orange",
    uses: 5,
    font_color: 'white',
},{
    id: "6078e50dcd8f3c2fe1dd1bdf",
    idBoard: "",
    name: "Boom - DELIVERY",
    color: "green",
    uses: 6,
    font_color: 'white',
},{
    id: "5f4852517ae1f5109b071d15",
    idBoard: "",
    name: "Install",
    color: "blue",
    uses: 2,
    font_color: 'white',
},{
    id: "5fbbca7c7149da5fa2f01aa4",
    idBoard: "",
    name: "Office",
    color: "blue",
    uses: 18,
    font_color: 'white',
},{
    id: "60cb71ef1e655f6e6d35f7f8",
    idBoard: "",
    name: "Tool Repair",
    color: "blue",
    uses: 17,
    font_color: 'white',
},{
    id: "6536860c595eef01385e8b79",
    idBoard: "",
    name: "Driveway - DROP",
    color: "blue_dark",
    uses: 10,
    font_color: 'white',
},{
    id: "63bf2da5461a770019161df1",
    idBoard: "",
    name: "Equipment: Warehouse",
    color: "pink_dark",
    uses: 4,
    font_color: 'white',
},{
    id: "6539655a603b10541cc56dc3",
    idBoard: "",
    name: "Garage - DROP",
    color: "blue_dark",
    uses: 4,
    font_color: 'white',
},{
    id: "6386559f76ddc200676ae260",
    idBoard: "",
    name: "Two Vans Needed",
    color: "blue_dark",
    uses: 2,
    font_color: 'white',
},{
    id: "61b9d187a22a0a46ed43b3cb",
    idBoard: "",
    name: "Co-op Carry - MANPOWER",
    color: "sky",
    uses: 111,
    font_color: 'white',
},{
    id: "63bc3919c89d1c004b4714fd",
    idBoard: "",
    name: "On Site Assistance - CARRY",
    color: "orange",
    uses: 5,
    font_color: 'white',
},{
    id: "5f58be8f244bff0ebae2c1e3",
    idBoard: "",
    name: "First Time - REFERRAL",
    color: "purple_light",
    uses: 74,
    font_color: 'white',
},{
    id: "65368724dee8556d25f3b64e",
    idBoard: "",
    name: "Backyard - CARRY",
    color: "orange",
    uses: 4,
    font_color: 'white',
},{
    id: "63612ae627036a01ab44b106",
    idBoard: "",
    name: "Multi Pass - PD and DELIVERY",
    color: "lime_dark",
    uses: 5,
    font_color: 'white',
},{
    id: "5eff02fbce0afe201fe12c57",
    idBoard: "",
    name: "Delicate - REQUIREMENT",
    color: "pink",
    uses: 8,
    font_color: 'white',
},{
    id: "60c088a70f9d8c2a45833747",
    idBoard: "",
    name: "Fix Required - BEANS",
    color: "red_dark",
    uses: 3,
    font_color: 'white',
},{
    id: "64ac2005eecd1206a426582a",
    idBoard: "",
    name: "Have Payment Information - BILLING",
    color: "yellow",
    uses: 1,
    font_color: 'white',
},{
    id: "6422028f5b6aecf3742748b1",
    idBoard: "",
    name: "Painting - In House Services",
    color: "pink_dark",
    uses: 2,
    font_color: 'white',
},{
    id: "64148606194c58778fe382a7",
    idBoard: "",
    name: "Return to Store - RETURNS",
    color: "orange_dark",
    uses: 1,
    font_color: 'white',
},{
    id: "641485faf484b158cf20495c",
    idBoard: "",
    name: "Return to Warehouse - RETURNS",
    color: "orange_dark",
    uses: 1,
    font_color: 'white',
},{
    id: "654d4fba790c48381633fef5",
    idBoard: "",
    name: "Maintenance",
    color: "black",
    uses: 18,
    font_color: 'white',
},{
    id: "60ac74bf68d5228686ccb6d2",
    idBoard: "",
    name: "On Site Materials - CARRY",
    color: "orange",
    uses: 12,
    font_color: 'white',
},{
    id: "5f02f6537cb06d35a6f5e671",
    idBoard: "",
    name: "QUOTE - INFO",
    color: "black_dark",
    uses: 145,
    font_color: 'white',
},{
    id: "623cf7eb1c133d46fc187d9c",
    idBoard: "",
    name: "Scout - INFO",
    color: "black_dark",
    uses: 72,
    font_color: 'white',
},{
    id: "64e379c46fca80989e52133d",
    idBoard: "",
    name: "Shop Drop - DROP",
    color: "blue_dark",
    uses: 3,
    font_color: 'white',
},{
    id: "631385aaa5e32800fb9ee1e4",
    idBoard: "",
    name: "Damages - BEANS",
    color: "red_dark",
    uses: 3,
    font_color: 'white',
},{
    id: "654d0a09255207bfacdb3e48",
    idBoard: "",
    name: "Takeoff",
    color: "yellow_dark",
    uses: 13,
    font_color: 'white',
},{
    id: "654d31757c3c5e147c48f2c1",
    idBoard: "",
    name: "Side of House - DROP",
    color: "blue_dark",
    uses: 1,
    font_color: 'white',
},{
    id: "654d337f78e03bc11497b4bc",
    idBoard: "",
    name: "Follow Up Needed - ISSUE",
    color: "red",
    uses: 1,
    font_color: 'white',
},{
    id: "654d33ae150f973c47583643",
    idBoard: "",
    name: "Cabinets - PD",
    color: "purple",
    uses: 1,
    font_color: 'white',
},{
    id: "654d33bca6d209fc4e41434b",
    idBoard: "",
    name: "Material Transfer - PD",
    color: "purple",
    uses: 2,
    font_color: 'white',
},{
    id: "654d33cfbc9d0ae3dc9bbd15",
    idBoard: "",
    name: "Client Pickup - PD",
    color: "purple",
    uses: 6,
    font_color: 'white',
},{
    id: "654d57a0206b5aeb9a88e8f5",
    idBoard: "",
    name: "Stock - WAREHOUSE",
    color: "pink_dark",
    uses: 4,
    font_color: 'white',
},{
    id: "6571e5641fb373dcf1cc97de",
    idBoard: "",
    name: "Cutting - In House Services",
    color: "pink_dark",
    uses: 2,
    font_color: 'white',
},{
    id: "65a017f704a6c9fcec6bfd91",
    idBoard: "",
    name: "Equipment - Vehicles",
    color: "pink_dark",
    uses: 1,
    font_color: 'white',
},{
    id: "65c251319252585723bba6db",
    idBoard: "",
    name: "Vendor Dropping Off - DELIVERY",
    color: "green",
    uses: 1,
    font_color: 'white',
},{
    id: "661686a718839bf3f282e1f3",
    idBoard: "",
    name: "Pickup - WAREHOUSE",
    color: "pink_dark",
    uses: 1,
    font_color: 'white',
},{
    id: "661aab0f90a66bb9aab82195",
    idBoard: "",
    name: "API - Routed",
    color: "black_dark",
    uses: 2,
    font_color: 'white',
}]
