import { Injectable } from '@angular/core';
import { TrelloChecklist } from '../../classes/trello';
import { Subject } from 'rxjs';
import { TrelloService } from '../../services/trello.service';

@Injectable({
  providedIn: 'root'
})
export class TrelloChecklistService {
  
  subjectChecklist = new Subject<TrelloChecklist[]>();
  checklist$ = this.subjectChecklist.asObservable();


  constructor(
    private trelloService: TrelloService,
  ) { }
  
  nextChecklist(newChecklist: TrelloChecklist[], from: string) {
    this.subjectChecklist.next(newChecklist);
  }

  getChecklist(idChecklists: string[]){

    const requestBatchUrl: string[] = [];
    idChecklists.forEach(idChecklist => {
      const requestChecklist = this.trelloService.buildGetPath('checklists', idChecklist);
      requestBatchUrl.push(requestChecklist)

    });

    const requestBatchChecklist = this.trelloService.getBatchRequest(requestBatchUrl)
    if (requestBatchChecklist){
      requestBatchChecklist.subscribe({
        next: (newChecklist: any[]) => {
          const mapChecklist: TrelloChecklist[] = newChecklist.map( checklist => checklist['200'])
          this.nextChecklist(mapChecklist, 'getChecklist');
          console.log(newChecklist, "Whats up with this!@#@!");
        },
        error: (error: any) => { console.error(error)}
      })
     
    }
  }

}
