import { Component, OnChanges, Input } from '@angular/core';
import { trigger, state, animate, transition, style} from '@angular/animations'


@Component({
  selector : 'animation-fly-updown',
  template: `
    <div [@flyUpDown]="UpDown" id="{{id}}">
        <ng-content></ng-content>            
    </div>
  `,
  
 animations: [
    trigger('flyUpDown', [
      state('up', style({transform: 'translateY(-100%)'})),
      state('down', style({transform: 'translateY(0%)'})),
      transition('up => down', [
        style({transform: 'translateY(-100%)'}),
        animate(250)
      ]),
      
      transition('down => up', [
        animate(200)
      ])
    ])
  ]
})

export class UpDownAnimation implements OnChanges {
    @Input() isUpDown : boolean = true;
    @Input() id: string = '';
    visible = false;
    UpDown = 'up';
    
    ngOnChanges() {
        this.UpDown = this.isUpDown ? 'down': 'up'; 
    }

}