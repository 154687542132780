import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AccountService } from '../services/accounts.service';
import { ViewService } from 'src/app/general-services/view/view.service';
import { View } from 'src/app/general-services/view/view';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-tools',
  templateUrl: './account-tools.component.html',
  styleUrls: ['./account-tools.component.css']
})
export class AccountToolsComponent implements OnInit, OnDestroy{


  view = new View;
  viewSub: Subscription;

  constructor(
    private accountSevice: AccountService,
    private viewService: ViewService,
    private detectChanges: ChangeDetectorRef
  ){
    this.viewSub = viewService.view$.subscribe(view => {
      this.view = view;
      this.detectChanges.markForCheck();
    });
  };
  

  ngOnInit(): void {
    
  }

  

  ngOnDestroy(): void {
    this.viewSub.unsubscribe();
  }

}
