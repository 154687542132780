import { Component, OnInit } from '@angular/core';
import { ViewService } from '../view/view.service';

@Component({
  selector: 'app-copy-text',
  templateUrl: './copy-text.component.html',
  styleUrl: './copy-text.component.css'
})
export class CopyTextComponent implements OnInit {
  message: string = '';

  constructor(
    private viewService: ViewService
  ){}
  
  ngOnInit(): void {
    
    this.message = this.viewService.currentView().messageView.message;
    console.log("Loaded hecopy context",this.message)
  }

}
