export class InputWindowInfo {

    isLoading: boolean = false;
    passProjectCheckout: boolean = false;
    passedData: any = null;
    currentType: string = '';
}

export const DEFAULT_WINDOW_INFO: InputWindowInfo = {

    isLoading: false,
    passProjectCheckout: false,
    passedData: null,
    currentType: ''
}
