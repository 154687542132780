import { Component, OnDestroy, OnInit, ChangeDetectorRef, ApplicationRef, Renderer2, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/cart-item/services/item.service';
import { ServerService } from 'src/app/general-services/server.service';

import { Subscription} from 'rxjs'
import { Item } from 'src/app/cart-item/classes/item';
import { ViewService } from 'src/app/general-services/view/view.service';
import { AccountService } from 'src/app/accounts/services/accounts.service';
import { SearchService } from '../search/search.service';
import { InputWindowService } from './input-window.service';
import { InputWindowInfo } from './classes/input-window-info';
import { View } from 'src/app/general-services/view/view';
import { InputWindow, OutputSetting, RowSetting, RowType } from './classes/input-window';
import { CartList, CartListInfo, Toolbox } from 'src/app/accounts/classes/account-settings';
import { iframePopupSetting, toolUpdaterPopupSetting } from './input-window.setting/input-window.setting';
import { ToolUpdaterService } from 'src/app/accounts/account-tools/tool-updater/tool-updater.service';
import { ADMIN_CONFIRM, ADMIN_SEARCH, CARD_DELETE, CART_SAVE, CART_UPLOAD, DEFAULT_WINDOW, DOWNLOAD_PDF, LOCATION_CHECK, PROJECT_DELETE, PROJECT_MODIFY, PROJECT_SAVE } from './classes/input-window-const';
import { GeneralService } from 'src/app/general-services/general/general.service';

@Component({
  selector: 'app-input-window',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-window.component.html',
  styleUrls: ['./input-window.component.css']
})

export class InputWindowComponent implements OnInit, OnDestroy {
    @Output() dismissModal = new EventEmitter<any>();


    modalWindowSubscription: Subscription;
    viewInfoSubscription: Subscription;
    searchSub: Subscription;

    view!: View;
    modalWindowInfo!: InputWindowInfo;
    searchResults!: Item[];

    // Input Box Constraints SUB NOTE: This input box system needs to be scaled to accomendate multiple inputs
    // CART MODAL WINDOW SETTINGS

    cartSave: InputWindow = CART_SAVE;
    cartUpload: InputWindow = CART_UPLOAD;

    // ACCOUNT MODAL WINDOWS SETTINGS
    projectSave: InputWindow = PROJECT_SAVE;
    projectDelete: InputWindow = PROJECT_DELETE;
    projectModify: InputWindow = PROJECT_MODIFY;

    downloadPdf: InputWindow = DOWNLOAD_PDF;

    cardDelete: InputWindow = CARD_DELETE;

    adminConfirm: InputWindow = ADMIN_CONFIRM;
    adminSearch: InputWindow = ADMIN_SEARCH;

    defaultWindow: InputWindow = DEFAULT_WINDOW;
    
    // LOCATION Check
    locationCheck: InputWindow = LOCATION_CHECK

    // Row Array
    arrayInput: RowSetting[] = [];
    arrayInputButton: RowSetting[] = [];
    arrayOutputButton: RowSetting[] = [];
    arrayButtons: RowSetting[] = [];
    arrayDoubleButton: RowSetting[] = [];
    arrayCancelButton: RowSetting[] = [];
    arrayMessage: RowSetting[] = [];
    arraySearch: RowSetting[] = [];

    // Downloads
    downloadedFiles = [{ fileName: '' }];
    modalOptions = {isLoading: false};

    locationIndex = 0;

    // Input Fail Count
    failCount: boolean[] = [];

    // Loading
    style = "window";

    // Injects
    injectInput = null;
    modalSelected: any[] = [];
    passedData: any;

    modalType: string = '';
    currentType!: InputWindow;

    showMessage: boolean = false;
    showAction: boolean = false;
    showInput: boolean = false;
    showInputButton: boolean = false;
    showOutputButton: boolean = false;
    showButtons: boolean = false;
    showDoubleButton: boolean = false;
    showCancelButton: boolean = false;
    showDownload: boolean = false;
    showAutocomplete: boolean = false;
    showSearch: boolean = false;
    showIframe: boolean = false;

    showPassFail: boolean| null = false;
    hasPassed: boolean = false;
    hasFailed: boolean| null = false;

    arrayPassFail!: OutputSetting

    foundCheck: boolean = false;
    btnCount: number = -1;
    btnDisabled: boolean | null = null;
    inputData: any[] = [];

    sanitizedURL: any;
    iframeUrl!: SafeResourceUrl;

    
    constructor(
        private itemService: ItemService,
        private viewService: ViewService,
        private router: Router,
        private accountService: AccountService,
        private serverService: ServerService,
        private sanitizer: DomSanitizer,
        private inputWindowService: InputWindowService,
        private detectChange: ChangeDetectorRef,
        //private locationService: loca,
        private renderer: Renderer2,
        private searchService: SearchService,
        private applicationRef: ApplicationRef,
        private updaterService: ToolUpdaterService,
        private generalService: GeneralService
    ){
        this.modalWindowSubscription = inputWindowService.modalWindowInfo$.subscribe((modalWindowInfo: InputWindowInfo) => {
        //console.log(modalWindowInfo, " NEW WINDOW STUFF!@#@!#!@#!@#!@#!@")
        this.modalWindowInfo = modalWindowInfo;
        detectChange.markForCheck();
        });

        this.viewInfoSubscription = viewService.view$.subscribe((newView: View) => {
        this.view = newView;
        detectChange.markForCheck();
        });
        this.searchSub = searchService.searchSubject$.subscribe((newSearch:any) => {
        this.searchResults = newSearch;
        detectChange.markForCheck();
        });
    }

    // MARK: - NG's
    ngOnInit() {
        let newWindowInfo: InputWindowInfo = this.inputWindowService.currentModalWindowInfo();
        console.log(newWindowInfo, "Windows served!@#!#")
        if (this.view.showInputWindow) {
            this.renderer.addClass(document.body, 'modal-open');
            newWindowInfo.isLoading = true;
            this.inputWindowService.nextModalWindowInfo(newWindowInfo);
        }
        this.modalType = newWindowInfo.currentType;
        // Refresh Logged In status
        if (this.view.isLogged == null) {
            this.viewService.changeView('logged', false, null, 'input-window.component:ngOnInit');
        }


        switch (this.modalType) {
            case  (true && 'cart_save'):
                //console.log("CART SHARING AND SHAVING!@#!@#!")
                this.currentType = this.cartSave;
                this.showAction = true;
                this.buildPost('cart_list');
                break;
            case 'cart_upload':
                this.currentType = this.cartUpload;
                this.showAction = true;
                this.passedData = newWindowInfo.passedData;
                break;
            case 'project_add':
                {
                    this.currentType = this.projectSave;
                    this.showAction = true;
                    this.locationIndex = 1;
                    const checkData = newWindowInfo.passedData;
                    if (checkData !== null && checkData !== undefined) {
                        const newData = [];
                        if (checkData['project_name']) {
                            newData.push(checkData['project_name']);
                        }
                        if (checkData['delivery_addr']) {
                            const projectAddress = checkData['delivery_addr'] + ", " + checkData['addr_2'];
                            newData.push(projectAddress);
                        }
                        this.passedData = newData;
                    }
                }
                break;
            case 'project_modify': {
                this.currentType = this.projectModify;
                this.showAction = true;
                this.locationIndex = 1;
                const checkModifyData = newWindowInfo.passedData;
                console.log(checkModifyData, "MOLDifying some more of the stupid shitss...7.7.7.7.7.7.7.7.7.");
                if (checkModifyData) {
                    const newData = [];
                    if (checkModifyData) {
                        this.currentType.message_1 = this.currentType.message_1 + " " + checkModifyData.project_name;
                        newData.push(checkModifyData.project_name);
                    }
                    if (checkModifyData.delivery_addr) {
                        const projectAddress = checkModifyData.delivery_addr + ", " + checkModifyData.addr_2;
                        newData.push(projectAddress);
                    }
                    const checkLocalProject = checkModifyData.local_project_id;
                    if (checkModifyData.local_project_id >= 0) {
                        newData.push(checkModifyData.local_project_id);
                    }
                    if (checkModifyData.default >= 0) {
                        newData.push(checkModifyData.default);
                    }
                    console.log(newData, " <----------+++++++++++++++++++++++++++++++++++++++");
                    this.passedData = newData;
                }
                break;
            }
            case 'project_delete': {
                this.currentType = this.projectDelete;
                this.showAction = true;
                const projectName = newWindowInfo.passedData;
                this.passedData = projectName['project_name'];
                break;
            }
            case 'download_pdf':
                this.currentType = this.downloadPdf;
                this.showAction = true;
                this.passedData = newWindowInfo.passedData;
                this.sanitizeData();
                this.setupFiles();
                break;
            case 'card_delete':
                this.currentType = this.cardDelete;
                this.showAction = true;
                this.passedData = newWindowInfo.passedData;
                break;
            case 'location':
                this.currentType = this.locationCheck;
                this.showAction = true;
                break;
            case 'admin_price_update':
                this.currentType = this.adminConfirm;
                this.showAction = true;
                this.passedData = newWindowInfo.passedData;
                break;
            case 'admin_search':
                this.currentType = this.adminSearch;
                this.showAction = true;
                this.passedData = newWindowInfo.passedData;
                this.currentType.passed_data = this.passedData;
                //console.log("PASSING ?THIS ONE ARO?UND**************************************", this.passedData)
                this.modalSelected = new Array(this.passedData.length).fill(false);
                break;
           
            case 'updater_submit': 
                this.currentType = toolUpdaterPopupSetting;
                this.showAction = true;
                this.passedData = newWindowInfo.passedData;

                break;

            case 'iframe':
                console.log('Framed!!@#!@#!@#!@#!@#!@#');
                this.currentType = iframePopupSetting;
                this.showAction = true;
                this.passedData = newWindowInfo.passedData
                
                const sanitizeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.passedData);
                this.iframeUrl = sanitizeUrl;
                console.log(this.iframeUrl)
                this.showIframe = true;

                break;

            default:
                this.currentType = this.passedData;
                this.showAction = true;
                break;
        }
        this.setupRows();
        newWindowInfo.isLoading = false;
        //console.log(newWindowInfo ," ______________________________________________________________", this.currentType)
      
        this.itemService.setShowTop(true);
        // Reset Scrolls
        window.scrollTo(0, 0);
    }
    
    ngOnDestroy() {
        this.renderer.removeClass(document.body, 'modal-open');
        if (this.viewInfoSubscription) {
            this.viewInfoSubscription.unsubscribe();
        }
        if (this.modalWindowSubscription) {
            this.modalWindowSubscription.unsubscribe();
        }
    }

    // MARK: - SETUP FORM
    setupRows() {
        let rowCount;
        if (this.currentType &&
            this.currentType &&
            this.currentType.rows) {
            rowCount = this.currentType.rows;
            if (rowCount) {
                for (let i = 0; i < rowCount; i++) {
                    let row;
                    if (this.currentType &&
                        this.currentType &&
                        this.currentType.row &&
                        this.currentType.row[i]) {
                        row = this.currentType.row[i];
                        let rowLogic = row.logic;
                        if (rowLogic) {
                            let elementCount;
                            let rowType;
                            if (row.row_type && row.row_type.length) {
                                elementCount = row.row_type.length;
                                rowType = row.row_type;
                                // isLOGGED LOGIC
                                if (rowLogic === 'isLogged') {
                                    //console.log('IS logged time baby!@#!@#@!#!@#', elementCount, rowType)
                                    rowType.forEach((row) => {
                                        const logic = row.logic;
                                        const logged = this.view.isLogged;
                                        if (logic == logged) {
                                            this.setupRow(row);
                                        }
                                    });
                                    /*
                                    for(let j = 0; j < elementCount; j++){
                                        if(rowType.length && rowType[j]){
                                            const button = rowType[j];
                                            if (button && button.logic){
                                                const buttonLogic = button.logic;
                                                console.log("ROW logic &&&&&&&&&&&&&&&&&&&&&&&&&& ", buttonLogic)
                                                const logged = this.viewService.isLogged
                                                console.log(logged, " Learnin g more of the g logge d thingss")
                                                if(buttonLogic === this.viewService.isLogged){this.setupRow(button)}
                                            }
                                        }
                                    }*/
                                }
                                if (rowLogic === 'passed_data') {
                                    for (let j = 0; j < elementCount; j++) {
                                        if (rowType.length && rowType[j]) {
                                            const button = rowType[j];
                                            if (button && button.logic) {
                                                const buttonLogic = button.logic;
                                                let nullCheck = this.passedData;
                                                if (nullCheck !== null && nullCheck !== undefined) {
                                                    nullCheck = true;
                                                }
                                                if (nullCheck === undefined) {
                                                    nullCheck = false;
                                                }
                                                if (buttonLogic === nullCheck) {
                                                    this.setupRow(button);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        // NULL LOGIC
                        else {
                            if (row && row.row_type && row.row_type.length) {
                                const buttonCount = row.row_type.length;
                                for (let j = 0; j < buttonCount; j++) {
                                    const button = row.row_type[j];
                                    //console.log(button, "IS this the doubleeeee")
                                    this.setupRow(button);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    setupRow(rowType: RowType) {
        if (rowType.row_type || rowType.row_type === 0) {
            const rowCheck = rowType.row_type;
            if (rowType.setting) {
                // Set Button Settings
                let setting;
                let settings;
                //const lengthCheck: number = rowType.setting.length;
                //if(lengthCheck > 1 & rowType.setting){button = rowType.setting}
                if (rowType.setting && rowType.setting.length && rowType.setting.length) {
                    setting = rowType.setting[0];
                    settings = rowType.setting;
                    //this.btnCount = this.btnCount + 1;
                    if (settings && setting) {
                        switch (rowCheck) {
                            // INPUT
                            case 0:
                                this.showInput = true;
                                this.arrayInput.push(setting);
                                break;
                            // INPUT & BUTTON
                            case 1:
                                this.showInputButton = true;
                                this.arrayInputButton.push(setting);
                                break;
                            // OUTPUT & BUTTON
                            case 2:
                                this.showOutputButton = true;
                                this.arrayOutputButton.push(setting);
                                break;
                            // SINGLE BUTTON
                            case 3:
                                this.showButtons = true;
                                settings.forEach(setting => { this.arrayButtons.push(setting); });
                                break;
                            // DOUBLE BUTTON (****)
                            /*case 4:
                                this.showDoubleButton = true;
                                settings.forEach(setting => { this.arrayDoubleButton.push(setting); });
                                break;
                            */
                            // CANCEL BUTTON
                            case 5:
                                //console.log("Cancel");
                                this.showCancelButton = true;
                                this.arrayCancelButton.push(setting);
                                break;
                            // HYPERRLINK
                            case 6:
                                this.showDownload = true;
                                //console.log('CASE 6',this.downloadedFiles)
                                break;
                            // AUTOCOMPLETE
                            case 7:
                                this.showAutocomplete = true;
                                //console.log("LOADING LOCATION AGENT ---->",this.locationCheck)
                                break;
                            // MESSAGES
                            case 8: {
                                if (this.btnCount) {
                                    this.showMessage = true;
                                    let messageOuput = this.passedData[this.btnCount - 1];
                                    const msgArray = [];
                                    //console.log(messageOuput, "What is this?AA")
                                    if (this.modalType === 'project_delete') {
                                        msgArray.push(this.passedData);
                                    }
                                    if (this.modalType === 'card_delete') {
                                        messageOuput = "Ending in " + this.passedData[1]['last4'];
                                        msgArray.push(messageOuput);
                                    }
                                    if (this.modalType === 'admin_price_update') {
                                        this.passedData.forEach((element: any) => {
                                            const messageOuput = element.item_name + " " + element.item_dimension + " " + element.item_units;
                                            msgArray.push(messageOuput);
                                        });
                                    }
                                    if (this.modalType === 'admin_search') {
                                        let messageOuput = 'No search results...';
                                        if (this.passedData && this.passedData.length && this.passedData.lenth > 0) {
                                            if (this.modalSelected) {
                                                this.modalSelected.fill(false, 0, this.passedData.length);
                                                this.passedData.forEach((element: any) => {
                                                    messageOuput = element.item_name + " " + element.item_dimension + " " + element.item_units;
                                                });
                                            }
                                        }
                                        msgArray.push(messageOuput);
                                    }
                                    if (setting &&
                                        setting.message_setting &&
                                        setting.message_setting.message_output) {
                                        setting.message_setting.message_output = msgArray;
                                    }
                                    this.arrayMessage.push(setting);
                                }
                                break;
                            }
                            // SEARCH
                            case 9: {
                                this.showSearch = true;
                                const term = this.passedData;
                                const checkTerm = term;
                                if (Object.keys(checkTerm).length > 1) {
                                    this.foundCheck = true;
                                }
                                else {
                                    this.foundCheck = false;
                                }
                                if (this.passedData && settings && setting.message_setting) {
                                    setting.message_setting.message_output = this.passedData;
                                    this.arraySearch.push(setting);
                                }
                                //this.search(term);
                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    searchClick(items: Item[], index: number) {
        this.modalSelected = new Array(this.passedData.length).fill(false);
        this.modalSelected[index] = true;
        //this.aerService.nextItem(items)
    }

    buttonClick(button: RowSetting) {
        let newWindowInfo: InputWindowInfo = this.inputWindowService.currentModalWindowInfo();

        // Get Button Setting
        const button_setting = button.button_setting;
        // Get Action
        const button_action = button_setting.button_click_action;

        // Complete Action
        console.log("Click action!@#!@#!@#!@#: ", button_action)
        switch (button_action) {
            case 'dismiss':
                this.dismissModal.emit({continue:false, from: 'dismiss'});
                this.viewService.changeView('show_window', false, null, 'input-window.component:buttonClick');
                break;
            case 'route': {
                const route = button_setting.button_route;
                //Dismiss Pop up
                this.dismiss();
                // Activate Route
                if (route) {
                    this.router.navigate([route[0], route[1]]);
                }
                break;
            }
            case 'copy': {if(this.injectInput){this.generalService.copyText(this.injectInput, 'Copied to Clipboard', 'input-window.component:buttonClick')}}
                break;
            case 'cart_save':
                // Build Post
                this.buildPost('cart_save');
                break;
            case 'cart_upload': {
                // Inject Cart
                console.log("This should be a reference number so you better be", this.passedData);
                this.itemService.savedCart(this.passedData);
                // Close modal window
                this.viewService.changeView('close', false, null, 'input-window.component:buttonClick');
                this.router.navigate(['/cart']);
                break;
            }
            case 'project_add': {
                if (this.modalType && this.inputData) {
                    const addPost = this.accountService.buildPost(this.modalType, this.inputData);
                    //console.log("Adding something new to the mix .....................", addPost)
                    this.accountService.postProject(addPost, 'show_window');
                }
                break;
            }
            case 'project_delete': {
                const delData = newWindowInfo.passedData;
                const idCheck = delData.local_project_id;
                console.log(idCheck, "<--------123123123123");
                if (this.modalType && idCheck !== -1) {
                    console.log(this.modalType, delData);
                    const delPost = this.accountService.buildPost(this.modalType, delData.local_project_id);
                    this.accountService.postProject(delPost, 'show_window');
                }
                break;
            }
            case 'project_modify': {
                if (this.passedData && this.passedData[1] >= 0 && this.inputData) {
                    console.log(this.inputData, this.passedData, "<------------------");
                    this.inputData.push(this.passedData[1]);
                    this.inputData.push(this.passedData[2]);
                    const modifyData = this.accountService.buildPost(this.modalType, this.inputData);
                    this.accountService.postProject(modifyData, 'show_window');
                }
                else {
                    this.hasFailed = true;
                }
                ;
                break;
            }
            case 'card_delete':
                this.initDelete();
                break;
            case 'location': {
                //console.log("This should initiate a range check!@#!@#!@#!@#!@#!@")
                const locationTrigger = this.checkDeliveryRange();
                //console.log(locationTrigger, " LOCATIONS IS TRIGGGFEREEEDD!!!@#")
                this.locationOutput(locationTrigger);
                break;
            }
            case 'checkout_project_cancel':
                this.viewService.changeView('show_window', false, null, 'input-window.component:buttonClick');
                this.inputWindowService.changeModalWindowInfo('pass_project_checkout', '',true);
                break;
            case 'checkout_project_add': {
                const post = newWindowInfo.passedData;
                const checkoutAddPost = this.accountService.buildPost('checkout_project_add', post);
                this.accountService.postProject(checkoutAddPost, 'show_window');
                break;
            }
            case 'admin_price_update':{
                const data = this.updaterService.currentPendingUpdate()
                console.log(data, "confirming updatere!!@#@!!#!@#!@#!#!@#");
                const post = this.accountService.buildPost(button_action, data);
                this.accountService.postAdmin(post, "updater");
                break;
            }
            case 'continue':{
                console.log("Firing dismiss that shit")
                this.dismissModal.emit({continue:true, from: 'continue'});
            }
        }
    }

    buildPost(reason:string) {
        // Set Loading
        this.inputWindowService.changeModalWindowInfo('loading', '', true);
        // Assign Logic
        if (reason && (reason === 'cart_save' || reason == 'cart_list')) {
            // CART SHARE SUB
            const cartItems = this.itemService.getCurrentCart().items;
            const list: any[] = [];
            //console.log(cartItems, "-----------------------Working on this dog now")
            
            // Assign Cart Item to list
            cartItems.forEach(element => {
                const item_number = element['item_number'];
                const item_quantity = element['item_quantity'];
                list.push({ item_number: item_number, item_quantity: item_quantity });
            });
            // Get OUTPUT Settings
            let name = '';
            if (reason === 'cart_save') {
                if (this.inputData && this.inputData[0]) {
                    name = this.inputData[0];
                }
                const currentOutput = this.currentType.row[0].row_type[0].setting[0].output_setting
                if ( currentOutput && !(currentOutput instanceof OutputSetting) ) {this.arrayPassFail = currentOutput[0];}      
            }
            // INIT Action Id
            let action_id = reason;
            if (reason === 'cart_list') {
                action_id = 'cart_share';
            }
            const post = { action_id: action_id, cart: { name: name, share: true, list: list } };
            //console.log(post, "POST CHECKING ADKFKAKFKAK")
            let request = this.serverService.postRequest(post, 'account_endpoint', 'json', 'buildPost');
            if (request) { 
            request.subscribe({
                next: (data: any) => {
                if (data.url !== undefined || data.url !== null) {
                    console.log(data, "Durrttaaaa !!!!!!", reason);
                    // Share Cart: Output URL
                    if (reason == 'cart_list') {
                        const url = data['url'];
                        this.injectInput = url.toLowerCase();
                    }
                    // Save Cart: Assing new toolbox Item
                    if (reason == 'cart_save') {
                        //console.log(this.arrayInputButton);
                        this.showPassFail = true;
                        this.showAction = false;
                        this.hasPassed = true;
                        const checkLength = data.toolbox.cart_list.cart_list_get_count;
                        const cartList: CartListInfo[] = data.toolbox.cart_list;
                        const newCartListInfo = this.accountService.mapCartList(cartList, checkLength);
                        const currentAccountInfo = this.accountService.currentAccountInfo();
                        let toolboxCheck = currentAccountInfo.toolbox;

                        if (!toolboxCheck) { toolboxCheck = new Toolbox;}
                        const newCartList = new CartList;

                        newCartList.cart_list_get_count = checkLength;
                        newCartList.cart_list_info = newCartListInfo;

                        toolboxCheck.cart_list = newCartList;
                        currentAccountInfo.toolbox = toolboxCheck;
                        this.accountService.newAccountInfo(currentAccountInfo);
                        this.modalOptions.isLoading = false;
                    }
                }
                else {
                    //console.log("INJECTION ERROR during Data retrieval");
                    if (reason == 'cart_save') {
                        //console.log("Display Fail and retryu button")
                    }
                }
                this.inputWindowService.changeModalWindowInfo('loading', '', false);
            },
            error: error => {
                console.error(error);
                this.showAction = false;
                this.showPassFail = true;
                this.hasFailed = true;
                this.inputWindowService.changeModalWindowInfo('loading', '', true);
            }
            });
            }else {
            this.showPassFail = true;
            this.showAction = false;
            this.hasFailed = true;
            this.inputWindowService.changeModalWindowInfo('loading', '', true);
            }
        }
    }
    
    initDelete() {
        // Delete selected project
        this.viewService.changeView('loading_account', true, null, 'input-window.component:initDelete');
        const token = this.passedData;
        const post = { action_id: 'card_delete', card_hash: token };
        let request = this.accountService.sendRequest(post, 'json', 'input-window:initDelete');
        if (request) {
            request.subscribe((data: any) => {
                if (data.status != 'Error') {
                    // SUCCESS During wallet creation
                    const newAccountInfo = this.accountService.populateAccount(data);
                    this.accountService.newAccountInfo(newAccountInfo);
                }
                const newViewInfo = this.viewService.currentView();
                newViewInfo.isLoading = false;
                newViewInfo.showInputWindow = false;
                this.viewService.nextView(newViewInfo, "initDelete");
            }), (error: any) => {
                console.log(error);
                const newViewInfo = this.viewService.currentView();
                newViewInfo.isLoading = false;
                newViewInfo.showInputWindow = false;
                this.viewService.nextView(newViewInfo, "initDelete");
            };
        }
    }

    /* formValidation(){
        console.log("Form Validation")
    }

    requestAccount(){
        let post = { action_id: 'user_data' };
        this.accountService.sendRequest(post, 'json')
        .subscribe(data => {
            if (data.status != 'Error'){
                // Setup User Data
                this.accountService.populateAccount(data);
                this.injectTransactions(this.local_project_id,null)

                // Complete Loading
                this.Transactions = false;
            }else{this.accountService.accountService.clearAccount()}
        },(error)=>{this.accountService.accountService.clearAccount()})
    }*/
    copyInputMessage(val: string) {
        const selBox = document.createElement('textarea');
        if (selBox && selBox.style) {
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            if (val) {
                selBox.value = val;
            }
            else {
                selBox.value = 'Error';
            }
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            document.body.removeChild(selBox);
        }
    }

    checkInput(emit: any, index: number) {
        let data = null;
        let focus = null;
        let blur = null;
        if (emit) {
            if (emit.data) {
                data = emit.data;
            }
            if (emit.focus) {
                focus = emit.focus;
            }
            if (emit.blur) {
                blur = emit.blur;
            }
            if (focus && !blur) {
                this.failCount = [];
            }
            if (blur && data) {
                this.failCount = new Array(1).fill(false);
            }
            if (!data && !focus) {
                if (emit.reqPass) {
                    this.failCount = emit.reqPass;
                }
            }
        }
        //console.log(emit, index, this.inputData);
        if (index > -1 && this.inputData !== undefined) {
            this.inputData[index] = emit;
            //console.log(this.inputData, "<------------------lost !!!!!!!!!!!!!!??????")
        }
        if (index > -1 && this.inputData === undefined) {
            this.inputData = new Array();
            this.inputData[index] = emit;
            //console.log(this.inputData, "<------------------lost ??????")
        }
    }

    // MARK: - Show Variables
    dismiss() {
        //console.log("Dismissing!!@#!@#!@#!@#")
        this.viewService.changeView('show_window', false, null, 'input-window.component:dismiss');
        this.applicationRef.tick();
    }

    /*minimize(): void{
        //const currentWindowInfo =this.inputWindowService.currentWindowMini()

        if(this.modalType === 'admin_search'){
            console.log(this.passedData, "Passed it to the left and nothing good happened")
            this.currentType.passed_data = this.passedData
        }


        currentWindowInfo.push(this.currentType);

        console.log("Catch this sucka%%%%%%%%%%%%%%%%%%%%%%%%%%", currentWindowInfo)
        this.inputWindowService.nextModalWindowInfo(currentWindowInfo);
        this.dismiss();
    }*/
    retry() {
        this.hasFailed = null;
        this.showPassFail = null;
        this.showAction = true;
    }
    closeShow() {
        switch (this.modalType) {
            case 'cart_save':
                this.viewService.changeView('show_window', true, null, 'input-window.component:closeShow');
                break;
        }
    }
    // MARK: Set's
    setTriggers(disabled: null| boolean, fail: any) {
        if (disabled) {
            this.btnDisabled = disabled;
        }
        else {
            this.btnDisabled = null;
        }

    }
    // MARK: Get's
    getModalStyle() {
        let style = {};
        let modalHeight = 0;
        // Get InputWindow Size
        const windowSize = window.innerWidth;
        let currentHeight = null;
        if (this.currentType &&
            this.currentType &&
            this.currentType.window_info)
            currentHeight = this.currentType.window_info;
        let failCount = 0;
        // Fail Count
        if (this.failCount &&
            this.failCount.length) {
            for (let i = 0; i < this.failCount.length; i++) {
                if (!this.failCount[i]) {
                    failCount++;
                }
            }
        }
        // Calculate Height for Failed Inputs
        let failOffset = 0;
        if (windowSize < 600) {
            failOffset = failCount * 34;
        }
        if (windowSize >= 600) {
            failOffset = failCount * 30;
        }
        // Adjust Height for Failed
        if (failOffset) {
            modalHeight = modalHeight + failOffset;
        }
        // Set Modal InputWindow Height
        if (currentHeight &&
            currentHeight.length) {
            if (windowSize < 600 &&
                currentHeight[0]) {
                modalHeight = modalHeight + currentHeight[0];
            }
            if (windowSize < 992 &&
                windowSize >= 600 &&
                currentHeight[1]) {
                modalHeight = modalHeight + currentHeight[1];
            }
            if (windowSize >= 992 && currentHeight[2]) {
                modalHeight = modalHeight + currentHeight[2];
            }
        }
        // Add Button Count to Height
        if (this.btnCount) {
            modalHeight = modalHeight + (this.btnCount - 1) * 75.5 + 34;
        }
        // Min Height Check
        if (windowSize >= 600) {
            if (modalHeight < 230) {
                modalHeight = 230;
            }
        }
        if (windowSize < 600) {
            if (modalHeight < 210) {
                modalHeight = 210;
            }
        }
        // Set Height
        const height = (modalHeight) + "px";
        // Create Style
        style = { height: height };
        return style;
    }

    getVisibleRow(index: number) {
        //console.log("Visible Row checking", index);
        return false;
        /*
        // Assign Requirements
        let check: InputWindow = null
        if ( this.currentType &&
            this.currentType &&
            this.currentType.row &&
            this.currentType.row[index]){ check = this.currentType.row[index];}
        //let check = this.currentType['button'][index]['button_visible'];


        switch(index){
            // ROW 1
            case 0:

            break;
            // ROW 2
            case 1:

            break;
            // ROW 3
            case 2:

            break;
            // ROW 4
            case 3:

            break;
        }
        return false;*/
    }

    getDisabled(index: number, setting: RowSetting) {
        //let inputReq: [number, string|RegExp] | null = null
        let btnDisabled = true;
        let btnDisabledReq = null;
        const nullDisabledCheck = setting.button_setting.button_enable;
        if (nullDisabledCheck === null) {
            return null;
        }
        if (setting &&
            setting.button_setting &&
            nullDisabledCheck) {
            btnDisabledReq = setting.button_setting.button_enable;
            if (btnDisabledReq === null) {
                return null;
            }
            else {
                let charReq = btnDisabledReq[0];
                let test = btnDisabledReq[1];
                // LOCATION CHECK
                if (btnDisabledReq &&
                    this.inputData &&
                    this.modalType === 'location') {
                    if (test instanceof RegExp) {
                        const inputData = this.inputData[0];
                        const testInputData = test;
                        if (testInputData.test(inputData)) {
                            return null;
                        }
                    }
                    /*
                    if( this.inputData &&
                        this.inputData[index] &&
                        (this.inputData[index].length >= btnDisabledReq)){
                            return null }
                    */
                }
                // CART SAVE AND SHARE
                if (btnDisabledReq &&
                    this.inputData &&
                    index > -1 &&
                    this.inputData[index] &&
                    this.modalType === 'cart_save') {
                    if (this.inputData[0].length >= charReq) {
                        return null;
                    }
                }
                // PROJECT ADD & MODIFY
                if ((this.modalType === 'project_add' || this.modalType === 'project_modify') &&
                    btnDisabledReq &&
                    this.inputData &&
                    this.inputData.length >= 2) {
                    const nameCheck = typeof this.inputData[0] === 'string';
                    const objectCheck = typeof this.inputData[1] === 'object';
                    if (nameCheck && objectCheck) {
                        //console.log(" NULKLLER FID!!!!!")
                        return null;
                    }
                }
            }
        }
        return btnDisabled;
    }
    // MARK: - Autocomplete && Get Position
    validCheck(valid: boolean) {
        let disabled = null;
        if (this.modalType === 'location') {
            if (valid) {
                disabled = null;
            }
            if (this.currentType &&
                this.currentType &&
                this.currentType.row &&
                this.currentType.row[1] &&
                this.currentType.row[1].row_type &&
                this.currentType.row[1].row_type[0] &&
                this.currentType.row[1].row_type[0].setting &&
                this.currentType.row[1].row_type[0].setting[1].input_setting &&
                this.currentType.row[1].row_type[0].setting[1].input_setting.input_pass) {
                this.currentType.row[1].row_type[0].setting[1].input_setting.input_pass = [-1, ''];
            }
        }
        /*

        if(this.modalType === 'project_add' || this.modalType === 'project_modify'){

            if(this.inputData.length >= 2){

                valid = this.inputData[1]['geometry'];}
                else {valid = false;}

            if(valid && this.inputData && this.inputData[0]){
                disabled = null
            }
        }*/
    }
    setPlace(place: any) {
        if (this.modalType === 'location') {
            if (place && typeof place !== 'string') {
                //console.log(place, "It's happening herere <---------------------")
                if (place.postal_code) {
                    const subPostalCode = place.postal_code.substring(0, 3);
                    if( this.currentType.row[0] &&
                    this.currentType.row[0].row_type[0] &&
                    this.currentType.row[0].row_type[0].setting[0] &&
                    this.currentType.row[0].row_type[0].setting[0].input_setting &&
                    this.currentType.row[0].row_type[0].setting[0].input_setting.input_data &&
                    this.currentType.row[0].row_type[0].setting[0].input_setting.input_data.place) {
                        this.currentType.row[0].row_type[0].setting[0].input_setting.input_data.place = subPostalCode;
                    }
                    
                    this.inputData = [place.postal_code];
                    const locationTrigger = this.checkDeliveryRange();
                    console.log(locationTrigger, " LOCATIONS IS TRIGGGFEREEEDD!!!@#");
                    this.locationOutput(locationTrigger);
                }
                else {
                if( this.currentType.row[0] &&
                    this.currentType.row[0].row_type[0] &&
                    this.currentType.row[0].row_type[0].setting[0] &&
                    this.currentType.row[0].row_type[0].setting[0].input_setting &&
                    this.currentType.row[0].row_type[0].setting[0].input_setting.input_data &&
                    this.currentType.row[0].row_type[0].setting[0].input_setting.input_data.place) {
                    this.currentType.row[0].row_type[0].setting[0].input_setting.input_data.place =  "Error... we missed the spot"
                }
                }
            }
            else {
            if( this.currentType.row[0] &&
                this.currentType.row[0].row_type[0] &&
                this.currentType.row[0].row_type[0].setting[0] &&
                this.currentType.row[0].row_type[0].setting[0].input_setting &&
                this.currentType.row[0].row_type[0].setting[0].input_setting.input_data &&
                this.currentType.row[0].row_type[0].setting[0].input_setting.input_data.place) {
                this.currentType.row[0].row_type[0].setting[0].input_setting.input_data.place = place;
                }
            
                this.inputData = [place];
            }
        }
        if (this.modalType === 'project_add' || this.modalType === 'project_modify') {
            if (this.inputData) {
                this.inputData[1] = place;
            }
            else {
                this.inputData = new Array();
                this.inputData[1] = place;
            }
        }
    }
    checkDeliveryRange() {
        //console.log("Initiating the delivery range Checker -------------------------------------")
        let inRange = null;
        let placeCheck = null;
        if (this.currentType &&
            this.currentType &&
            this.currentType.row &&
            this.currentType.row[0] &&
            this.currentType.row[0].row_type &&
            this.currentType.row[0].row_type[0] &&
            this.currentType.row[0].row_type[0].setting &&
            this.currentType.row[0].row_type[0].setting[0] &&
            this.currentType.row[0].row_type[0].setting[0].input_setting &&
            this.currentType.row[0].row_type[0].setting[0].input_setting.input_data &&
            this.currentType.row[0].row_type[0].setting[0].input_setting.input_data.place) {
            placeCheck = this.currentType.row[0].row_type[0].setting[0].input_setting.input_data.place;
        }
        //console.log(placeCheck, " Checking thos places and things =================================================")
        if (placeCheck !== null && typeof placeCheck === 'string') {
            let postalCode = placeCheck;
            postalCode = postalCode.toUpperCase();
            const placeCheckPrimary = postalCode.substring(0, 3);
            const placeCheckSecondary = postalCode.substring(0, 2);
            //this.locationService.setLocalLocation(placeCheckPrimary);
            const allowedLocation = ['L4J', 'L4k', 'L4L', 'M6A', 'M6B', 'M6C', 'M8X', 'M6L', 'M6M', 'M6N', 'M9V', 'M9M', 'M9N', 'M5M', 'M4N', 'M3L', 'M3N', 'M3M', 'M3J', 'M3h', 'M2N', 'M2M', 'M2r', 'L4J', 'M9W',
                'L8R', 'L8P', 'L8M', 'L8N', 'L8L', 'L8K', 'L8H', 'L8S', 'L9H', 'L4Z', 'L5A', 'L5G', 'L5B', 'L5R', 'L5V', 'L5M', 'L5L', 'L5K', 'L5J', 'L6H', 'L6H', 'L6J', 'L6K', 'L6L', 'L6M', 'L7K', 'L7L', 'L7M', 'L7N', 'L7P', 'L7R', 'L7S', 'L7T', 'L4W', 'L4X', 'L4Z', 'L5A', 'L5B', 'L5C', 'L5E', 'L5G', 'L5H', 'L5J', 'L5K', 'L5L', 'L5M', 'L5N', 'L5R', 'L5V', 'M9C'];
            const secondaryLocation = ['M2', 'M3', 'M5', 'M6', 'M8', 'M9', 'L4', 'L6', 'L3', 'L7', 'L8', 'L9'];
            const checkPrimaryLocation = allowedLocation.indexOf(placeCheckPrimary);
            const checkSecondaryLocation = secondaryLocation.indexOf(placeCheckSecondary);
            if (checkPrimaryLocation > -1) {
                inRange = 0;
            }
            else if (checkSecondaryLocation > -1) {
                inRange = 1;
            }
            else {
                inRange = 2;
            }
            return inRange;
        }
        else
            return -1;
    }
    locationOutput(trigger: number) {
        const passFail = 
        this.currentType &&
        this.currentType &&
        this.currentType.row &&
        this.currentType.row[1] &&
        this.currentType.row[1].row_type &&
        this.currentType.row[1].row_type[0] &&
        this.currentType.row[1].row_type[0].setting &&
        this.currentType.row[1].row_type[0].setting[1] &&
        this.currentType.row[1].row_type[0].setting[1].output_setting 
        
        if( passFail && !(passFail instanceof OutputSetting)){passFail[trigger];}
        else if(passFail !== null) {this.arrayPassFail = passFail;}

        // SET hasLocation
        this.viewService.changeView('location', true, null, 'input-window.component:locationOutput');
        if (trigger === 0) { // IN RANG
            this.showAction = false;
            this.showPassFail = true;
            //console.log("In Range");
            this.hasPassed = true;
        }
        else if (trigger === 1 || trigger === 2) { // JUST OUT OF RANGE
            this.showAction = false;
            this.showPassFail = true;
            //console.log('Just in Range')
            this.hasPassed = true;
        }
        else { // OUT OF RANGE
            //Show Input and two buttons
            const pathSettings = this.arrayPassFail;
            //this.currentType = pathSettings;
            console.log(this.currentType, " Out daT RANGE");
            //this.arrayCancelButton = pathSettings['input_setting'][0]['setting'][0];
            //this.arrayDoubleButton = pathSettings['input_setting'][1]['setting'][0];
            //console.log(this.arrayCancelButton, this.arrayDoubleButton);
            this.showPassFail = false;
            // SHOW Input & buttons
            //this.showInput = true;
            // this.showDoubleButton = true
            // SHOW
            this.showAction = false;
            this.showAutocomplete = false;
        }
    }
    lCheck(checking: string, modalInfo: InputWindow) {
        var _a;
        let drilledDown = (_a = modalInfo.row[this.locationIndex].row_type[0].setting[0].input_setting) === null || _a === void 0 ? void 0 : _a.input_data;
        if (drilledDown) {
            switch (checking) {
                case 'useCase':
                    if (drilledDown.useCase) {
                        return drilledDown.useCase;
                    }
                    ;
                    break;
                case 'validCheck':
                    if (drilledDown.validCheck) {
                        return drilledDown.validCheck;
                    }
                    ;
                    break;
                case 'inject':
                    if (drilledDown.place) {
                        return drilledDown.place;
                    }
                    ;
                    break;
                case 'isPlaceHolder':
                    if (drilledDown.placeholder) {
                        return drilledDown.placeholder;
                    }
                    ;
                    break;
            }
        }
        return null;
    }
    // MARK: Download's
    sanitizeData() {
        // ADD ARRAY CHECK
        const data = this.passedData[0]['data'];
        const blob = new Blob([data], { type: 'application/pdf' });
        this.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    }
    setupFiles() {
        this.downloadedFiles = this.passedData;
    }
    // MARK: - Search
    // MARK: - Error Handling
    handleError(error: any) {
        //console.error('An error occured', error);
        return Promise.reject(error.message || error);
    }
  
}
