import {  ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { InputData, InputSetting, InputViewSetting, OutputData } from './inputbox';
import { ToolRoutesService } from 'src/app/accounts/account-tools/tool-routes/tool-routes.service';
import { InputboxService } from './inputbox.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-inputbox',
  templateUrl: './inputbox.component.html',
  styleUrls: ['./inputbox.component.css']
})

export class InputboxComponent implements OnInit, OnChanges, OnDestroy {
    // ELEMENTS
    @ViewChild("inputbox", { static: true }) public inputbox!: ElementRef;
    @ViewChild("inputboxContainer") public inputboxContainer!: ElementRef;

    @Output() validData = new EventEmitter<any>();
    @Output() keyPressed = new EventEmitter<string>();
    
    @Input() inputValue: any = null;
    @Input() inputSetting = new InputSetting;
    @Input() autofocus: boolean = false;
    @Input() isDisabled: boolean|null = null 

    
    inputboxView = new InputViewSetting;
    inputboxSub: Subscription;


    // Content Value
    value = '';
    // Tracking Arrays
    focus = false;
    entry = false;
    check = true;
    submit = false;
    input = true;
    uncommited = false;

    error = false;
    dirty = false;

    show_password = false;
    
    // Tracking Object
    output = {
        data: "",
    };
    // Input Requirements
    dataReq = [];
    modelData: any = null;

    input_place_holder = '';
    input_autofocus = false;
    input_name = '';
    input_type = '';
    input_autocomplete = "off";
    input_min = '';
    input_disabled: boolean|null = null;
    input_readonly: boolean | null = null;
    input_action = '';
    input_css_setting = '';
    input_telephone = ['(', null, null, null, ') ',];
    input_submit = null;
    input_submit_to = null;
    input_tabindex = -1;
    input_error: any = ''
    input_pass: ([number, RegExp|string] )| null = null;
    input_pass_reqirements = null;

    input_placeholder_width = -1;
    input_error_width = -1;


    input_data: InputData | null = null
    output_data: OutputData | null = null;

    showCancelSubmit: boolean = false;

    constructor(
        private detectChange: ChangeDetectorRef,
        private inputboxService: InputboxService,
        private renderer: Renderer2,
    ) {
        this.inputboxSub = inputboxService.inputboxView$.subscribe(newInputBoxView => {
            this.inputboxView = newInputBoxView;
            this.detectChange.markForCheck();
          });
    }

    ngOnInit() {
        this.renderer.addClass(document.body, 'modal-open');

        this.modelData = this.inputValue;
        this.value = this.modelData;
        if (this.inputValue) {
            this.entry = true;
        }
        // Assign Variables
        if (this.inputSetting) {
            this.input_place_holder = this.inputSetting.input_place_holder;
            this.input_placeholder_width = (this.input_place_holder.length * 8)
            this.input_name = this.inputSetting.input_name;
            this.input_type = this.inputSetting.input_type;
            this.input_autofocus = this.inputSetting.input_autofocus;
            this.input_autocomplete = this.inputSetting.input_autocomplete;
            if (this.inputSetting.input_min) {
                this.input_min = this.inputSetting.input_min;
            }
            
            if (this.inputSetting.input_tabindex) {
                this.input_tabindex = this.inputSetting.input_tabindex;
            }
            this.input_error = this.inputSetting.input_error;
            this.input_error_width = (this.input_error.length + 4) / .6

            this.input_pass = this.inputSetting.input_pass;
            if (this.inputSetting.input_pass_reqirements) {
                this.input_pass_reqirements = this.inputSetting.input_pass_reqirements;
            }
            this.input_disabled = this.inputSetting.input_disabled;
            this.input_readonly = this.inputSetting.input_readonly;
            if (this.inputSetting.input_action) {
                this.input_action = this.inputSetting.input_action;
            }
            if (this.inputSetting.input_css_setting) {
                this.input_css_setting = this.inputSetting.input_css_setting;
            }
            if (this.inputSetting.input_data) {
                this.input_data = this.inputSetting.input_data;
            }
            if (this.inputSetting.output_data) {
                this.output_data = this.inputSetting.output_data;
            }
            // Date type check
            if (this.input_type === 'date') {
                const today = new Date();
                const year = today.getFullYear().toString();
                let month = (today.getMonth() + 1).toString();
                let date = today.getDate().toString();
                if (month.length === 1) {
                    month = '0' + month;
                }
                if (date.length === 1) {
                    date = '0' + date;
                }
                const fullDate = year + '-' + month + '-' + date;
                this.modelData = fullDate;
            }
            if(this.inputSetting.input_dom_rect){this.setupDomRect(this.inputSetting.input_dom_rect)}

        }
    }

    ngOnChanges(changes: SimpleChanges) {
        var _a;
        const checkInput = changes['inputValue'];
        const checkInputName = (_a = this.inputSetting) === null || _a === void 0 ? void 0 : _a.input_name;
        const disabledCheck = Object.keys(changes);
        const subCheck = checkInputName === null || checkInputName === void 0 ? void 0 : checkInputName.substring(0, 4);
        if (checkInput) {
            let currentInput = checkInput.currentValue;
            if (checkInputName === 'share_link') {
                this.value = currentInput;
                this.modelData = currentInput;
                this.entry = true;
            }
            if (checkInputName !== 'share_link' && checkInputName !== 'save_name' && subCheck !== 'post:card') {
                if (!checkInput.firstChange) {
                    this.value = currentInput;
                    this.modelData = currentInput;
                    if (!currentInput) {
                        this.entry = false;
                    }
                    else {
                        this.entry = true;
                    }
                }
            }
            if (subCheck === 'post:card') {
                this.value = currentInput;
                this.modelData = currentInput;
            }
        }
        if (disabledCheck.includes('isDisabled') && this.input_name !== 'project_notes') {
            this.input_disabled = changes['isDisabled'].currentValue;
        }
        this.detectChange.detectChanges();
    }

    ngOnDestroy(): void {
        this.inputboxSub.unsubscribe();
        this.renderer.removeClass(document.body, 'modal-open');
    }
    // MARK: - Input Form Action
    setupDomRect(domRect: DOMRect){
        const from = this.inputSetting.input_action;
        let offset = 15;
        //console.log(from,"<----------- checking this stuff out!@#!@#!@#")
        if(from?.includes('driver')){offset = 0};
 
        this.inputboxContainer.nativeElement.style.position = 'fixed';
        this.inputboxContainer.nativeElement.style.left = `${domRect.left + offset - 1}px`;
        this.inputboxContainer.nativeElement.style.top = `${domRect.top}px`;
        this.inputboxContainer.nativeElement.style.width = `${domRect.width +2}px`;
        this.inputboxContainer.nativeElement.style.height = `${domRect.height +2}px`;
        this.inputboxContainer.nativeElement.style['z-index']='1010';
    }

    edit() {
        this.uncommited = false;
        this.submit = true;
        this.inputbox.nativeElement.focus();
        window.scrollTo(0, 0);
    }

    userInput() {
        if (this.modelData) {
            //console.log("Entering information and stuff", this.modelData)
            this.check = true;
            this.entry = true;
            this.submit = false;
            const testInput = this.testInput();
            if (testInput) {
                this.validData.emit(this.modelData);
            }
        }
        if (!this.modelData) {
            this.entry = false;
            this.validData.emit(null);
        }
    }

    keydownTracker(key: string) { 
        switch(key){
            case 'enter':
                const testing = this.testInput();
                if(testing){this.keyPressed.emit('enter')};
            break;
            
            case 'esc':
                this.keyPressed.emit('esc')
            break;

            case 'ctrlB':
                const newValue = this.inputbox.nativeElement.value + '****'
                this.inputbox.nativeElement.value = newValue;
                
                // Get position and back up 2 characters
                const charCount = newValue.length;
                const newCaretPosition = charCount - 2;
                
                this.inputbox.nativeElement.setSelectionRange(newCaretPosition,newCaretPosition);
            break;
        }
    }

    getContainerClass() {
        let failCheck = false;
        let style = {};
        if (this.check == null || this.check == true) {
            failCheck = true;
        }
        style = {
            inputfocus: this.focus,
            inputfail: !failCheck
        };
        return style;
    }

    testInput(): boolean {
        let testOut = false;
        
        if (this.modelData && this.input_pass) {
            if (typeof this.modelData === 'number') {this.modelData = this.modelData.toString();}
            
            const inputReqs = this.input_pass;
            const checkCharCountReq = this.modelData.length < inputReqs[0];
            const regexReg = inputReqs[1];
            const checkRegexReq = new RegExp(regexReg);
            const passCharCount = checkCharCountReq <= this.modelData.length;
            const passRegex = checkRegexReq.test(this.modelData);
            this.entry = true;

            //console.log(this.modelData,passCharCount, passRegex, "</////////////./././././.")
            
            if (passCharCount && passRegex) {
                //console.log("GEE TO THE EmitTIGNng", this.modelData)
                this.error = false;
                this.check = true;
                this.input = false;
                this.validData.emit(this.modelData);
                return true;
            }
            else {
                this.error = true;
                this.validData.emit(null);
            }
        }
        else if (this.input_pass === null) {
            if (!this.modelData) {
                this.validData.emit(null)
                this.error = true;
                this.entry = false;
                this.check = true;
                return false;
            }
            else {
                this.error = false;
                this.entry = true;
                this.check = true;
                this.validData.emit(this.modelData)
                return true;
            }
        }
        // Fail Case
        //this.entry = false;
        this.check = false;
        return testOut;
        //this.validData.emit(null)
    }

    subBtnClick(type:string){
        switch (type){
            case 'password_unmask':
                this.inputSetting.input_type = 'text'
                this.show_password = true
                break;
            case 'password_mask':
                this.inputSetting.input_type = 'password'
                this.show_password = false
                break
            case 'clear':
                this.modelData = null;
                break;
        }
    }

    getValue() {
        return this.value;
    }

    clearInputBox() {
        //console.log("CLEARERERE");
        this.modelData = '';
        this.entry = false;
        this.check = false;
        this.validData.emit(this.modelData);
    }

    // MARK - Mouse Events
    mouseEvent(event: string){

    }
}
