import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { ServerService } from 'src/app/general-services/server.service';
import { ViewService } from 'src/app/general-services/view/view.service';
import { InputWindowService } from 'src/app/modal/input-window/input-window.service';
import { AccountInfo, Billing, CardInfo, CartList, CartListInfo, DEFAULT_ACCOUNT_INFO, Project, Toolbox, Transactions, User, UserWallet } from '../classes/account-settings';
import { Item } from 'src/app/cart-item/classes/item';
import { StorageService } from 'src/app/general-services/storage.service';
import { ItemService } from 'src/app/cart-item/services/item.service';
import { Router } from '@angular/router';
import { DefaultAppConfig } from 'src/app/app.config';
import { PriceUpate, UpdaterView } from '../account-tools/tool-updater/classes/tool-updater';
import { ToolUpdaterService } from '../account-tools/tool-updater/tool-updater.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

    allow_list = ['jeff@roomerator.com', 'chris@roomerator.com', 'laura@roomerator.com', 'jeffrey_garand@hotmail.com']
    allow = false;

    // Favorites
    favSimple: any[] = [];
    toolList: Item[] = [];
    favDetail: Item[] = [];
    savedCart: Item[] = [];
    regStatus = -1;

    // User Info
    billingInfo: Billing[] = [];
    projectInfo: Project[] = [];
    transInfo: Transactions[] = [];
    userInfo = null;
    favInfo = [];
    cartListInfo = [];

    // Show Switches
    showBilling = false;
    showProject = false;
    showAccount = false;
    showCredit = false;
    showToolbox = false;
    showLoginRegister = false;
    showAdmin = false;

    // User Info
    defaultIndex: number = -1;
    userName = '';
    isLoadingAccount = false;
    wallet_id: boolean = false;
    timeOutId = -1;
    loginTimer: number = -1;
    timerStatus = false;
    defaultTimeout = 6;

    // Error Handling
    hasFailed: boolean[] = [];
    projectState: boolean[] = [];

    // Project Message
    projectMessage: any;

    // User Info
    selectedProject = { project_name: '', project_postal_code: '' };

    // MARK: - ACCOUNT Observable
    subjectAccountInfo = new BehaviorSubject(DEFAULT_ACCOUNT_INFO);
    account$ = this.subjectAccountInfo.asObservable();
    accountInfo!: AccountInfo;
  
  constructor( 
    private viewService: ViewService, 
    private serverService: ServerService,
    private updaterService: ToolUpdaterService,
    private inputWindowService: InputWindowService,
    private storageService: StorageService,
    private itemService: ItemService,
    private router: Router) { }

    currentAccountInfo(): AccountInfo {
        return this.subjectAccountInfo.value;
    }

    newAccountInfo(newAccountData: AccountInfo) {
        const newAccountInfo = this.parseNewAccountInfo(newAccountData);
        if (newAccountInfo && newAccountInfo.secure !== 0 ) {
            //console.log(newAccountData, this.allow, "<----------find out how")
            this.subjectAccountInfo.next(newAccountInfo);
            this.viewService.changeView('logged', true, null, 'account.service:newAccountInfo');
        } else if(!DefaultAppConfig.DEFAULT_TESTING) { this.logout();}
    }
    
    parseNewAccountInfo(newAccountData: AccountInfo): AccountInfo|null {
        
        if(newAccountData.user.user_email){
            const newAccountInfo = this.currentAccountInfo();
            newAccountInfo.project_count = newAccountData.project_count;
            newAccountInfo.secure = newAccountData.secure;
            newAccountInfo.user_billing_count = newAccountData.user_billing_count;
            newAccountInfo.billing = newAccountData.billing;
            newAccountInfo.project = newAccountData.project;
            newAccountInfo.toolbox = newAccountData.toolbox;
            newAccountInfo.user = newAccountData.user;
            newAccountInfo.transactions = newAccountData.transactions;
    
            
            const current_user = newAccountData.user.user_email.toLowerCase();
            const checkAllow = this.allow_list.find( element =>  element === current_user);
    
            if(checkAllow){newAccountInfo.isAdmin = true}
            if(DefaultAppConfig.DEFAULT_TESTING){newAccountInfo.secure = 1}
            
            return newAccountInfo;
        }
        return null
       
    }

    login(form: any, from: string): Observable<Object> | null {
        let server_request = {}

        if(from === 'loading'){
            server_request = {action_id: 'user_data'}
        }

        if(from === 'login'){
            const username = form.user_email;
            const password = form.user_password;

            server_request = {
                action_id: 'user_login',
                user:{
                    user_email: username,
                    user_password: password
                }
            }
        }

        const post_request = this.serverService.postRequest( server_request, 'account', 'json', 'login');
        return post_request;
    }

    logout(): Observable<Object> | null {
        const server_request = {
            action_id: 'user_logout'
        }

        const post_request = this.serverService.postRequest(server_request,'account', 'json', 'logout');
        return post_request;
    }

    buildPost(type: string, passed_data: any) {
        
        let post = {};
        let action_id = 'project_update';
        let project = new Project;
        // Build post from MODAL
        if (type && type === 'project_add' || type === 'project_delete' || type === 'project_update' || type === 'project_fav_update' || type === 'project_modify') {
            if (type === 'project_add' || type === 'project_modify') {
                const overideCheck = passed_data[1]['geometry'];
                if (type === 'project_add') {
                    action_id = 'project_create';
                }
                if (overideCheck) {
                    //project = this.locationService.decodeAddress(passed_data);
                    const projectLocation = passed_data[1];
                    action_id = 'project_create';
                    project.project_name = passed_data[0];
                    project.local_project_id = -1;
                    if (type === 'project_modify') {
                        project.local_project_id = passed_data[2];
                        project.default = 0;
                        project.default = passed_data[3];
                        action_id = 'project_update';
                    }
                    const currentAccountInfo = this.currentAccountInfo();
                    const projectZeroCheck = currentAccountInfo.project && currentAccountInfo.project.length === 0;
                    if (projectZeroCheck) {
                        project.default = 1;
                    }
                    if (projectLocation) {
                        project.project_addr = projectLocation.street_number + ' ' + projectLocation.route;
                        project.project_postal_code = projectLocation.postal_code;
                        project.project_addr_2 = projectLocation.locality;
                    }
                    else {
                        console.log("The wrongness is upon us!!!");
                    }
                }
                else {
                    const projectInfo = passed_data;
                    if (projectInfo) {
                        const projectName = projectInfo[0];
                        const projectAddressInfo = projectInfo[1];
                        if (projectName && projectAddressInfo) {
                            project.project_name = projectName;
                            project.project_addr = projectAddressInfo.street_number + " " + projectAddressInfo.route;
                            project.project_addr_2 = projectAddressInfo.locality + ", " + projectAddressInfo.locality;
                            project.project_postal_code = projectAddressInfo.postal_code;
                        }
                    }
                }
                // Assign Post
                post = { action_id, project };
                return post;
            }
            if (type === 'project_update') {
                let updateProject = passed_data;
                if (updateProject) {
                    post = {
                        action_id,
                        project: updateProject
                    };
                }
                return post;
            }
            if (type === 'project_delete') {
                action_id = 'project_delete';
                project = {
                    active: -1,
                    default: -1,
                    deleted: 0,
                    linked_card: '',
                    local_project_id: passed_data,
                    project_addr: '',
                    project_addr_2: '',
                    project_name: '',
                    project_note: '',
                    project_phone_num: '',
                    project_postal_code: ''
                };
                return { action_id, project };
            }
        }
        if (type && type === 'checkout_project_add') {
            action_id = 'project_create';
            const checkout_project = passed_data;
            project.project_addr = checkout_project['delivery_addr'];
            project.project_addr_2 = checkout_project['addr_2'];
            project.project_postal_code = checkout_project['postal_code'];
            project.project_name = checkout_project['project_name'];
            project.default = 0;
            project.local_project_id = -1;
        }
        // Favorites
        if (type && type === 'favorites_update') {
            return { action_id: type, toolbox: { favorites: passed_data } };
        }
        if (type && type === 'cart_remove') {
            return { action_id: type, toolbox: { carts: passed_data[0] } };
        }
        // STANDARD POST
        if (type && type === 'update_project') {
            action_id = type;
            post = {
                project: {
                    active: 1,
                    default: project['default'],
                    deleted: 0,
                    linked_card: null,
                    local_project_id: project['local_project_id'],
                    project_addr: project['project_addr'],
                    project_addr_2: project['project_addr_2'],
                    project_name: project['project_name'],
                    project_notes: project['project_note'],
                    project_phone_num: project['project_phone_num'],
                    project_postal_code: project['project_postal_code'],
                },
                action_id: action_id
            };
        }

// MARK: Admin Post
        if(type === 'admin_price_update' && passed_data ){
            const updatedPrices: PriceUpate[] = passed_data
            const updatedItems: Item[] = updatedPrices.map( el => el.item);
            let i = 0
            updatedPrices.forEach(el => {
                updatedItems[i].item_price = el.new_price;
                i++
            });

            const userInfo = this.currentAccountInfo().user

            return {user:userInfo,action_id: type, price_update: updatedItems}
        }

        return post;
    }

    postProject(post: any, from: string) {
        if (from !== 'show_window' && from !== null && from !== 'favorites' && from !== 'project_update' && from !== '') {
            this.viewService.changeView('loading', true, null, 'account.service:postProject');
        }
        //if( from === 'show_window' || from === 'default'){ this.windowService.changeModalWindowInfo('loading', '', true);}
        const request = this.sendRequest(post, 'json', 'account.service:postProject');
        if (request) {
            request.subscribe({
                next: (data: any) => {
                    if (data.status !== 'Error') {
                        //console.log(from, "Checking from coMMANDS")
                        const projectCheck = data['project'];
                        //if(projectCheck){this.setProject(projectCheck);}
                        if (from !== 'favorites') {
                            //const account = this.populateAccount(data);
                            //this.newAccountInfo(account);
                        }
                        if (from !== 'show_window' && from !== null) {
                            this.viewService.changeView('loading', false, null, 'account.service:postProject');
                        }
                        ;
                        if (from === 'show_window' || from === 'default') {
                            this.inputWindowService.changeModalWindowInfo('loading', '', false);
                            this.viewService.changeView('show_window', false, null, 'account.service:postProject');
                            this.inputWindowService.changeModalWindowInfo('pass_project_checkout', '', true);
                        }
                    }
                    else {
                        if (data.status === 'Error') {
                            const checkSecure = data.secure;
                            if (!checkSecure) {
                                this.logout();
                            }
                        }
                        this.viewService.changeView('show_window', false, null, 'account.service:postProject');
                        this.inputWindowService.changeModalWindowInfo('loading', '',false);
                    }
                },
                error: error => {
                    this.viewService.changeView('show_window', false, null, 'account.service:postProject');
                    this.inputWindowService.changeModalWindowInfo('loading', '', false);
                }
            });
        }
    }

    postAdmin(post: any, from: string){
        console.log("Posting adimin stuff")
        const request = this.sendRequest(post, 'json', 'account.service:postAdmin');
        if (request) {
            request.subscribe({
                next: (data: any) => {
                    if (data.status !== 'Error') {

                        if(from ==='updater'){
                            // Set message
                            this.updaterService.setUpdateMessage(true,data['message']);

                            // Append updated item list
                            this.updaterService.appendUpdatedList();

                            // Blank Price Updates
                            this.updaterService.clearUpdates();
                        }


                        this.inputWindowService.changeModalWindowInfo('loading', '', false);
                        this.viewService.changeView('show_window', false, null, 'account.service:postAdmin');
                        
                    }
                    else {
                        if (data.status === 'Error') {
                            // Set message
                            this.updaterService.setUpdateMessage(false,data['message']);

                            // Check Secure
                            const checkSecure = data.secure;
                            if (!checkSecure) { this.logout()};
                        }
                        this.viewService.changeView('show_window', false, null, 'account.service:postAdmin');
                        this.inputWindowService.changeModalWindowInfo('loading', '',false);
                    }
                },
                error: error => {
                    // Set message
                    this.updaterService.setUpdateMessage(false,"Diss is das public error message!");

                    console.error(error, "Diss is das public error message!")
                    this.viewService.changeView('show_window', false, null, 'account.service:postAdmin');
                    this.inputWindowService.changeModalWindowInfo('loading', '', false);
                }
            });
        }
    }    

        
    requestAdminUpdate(action_id: string, from: string): Observable<any>| null{
        const type = 'requestAdminUpdate'
        const requestPriceUpdate = this.serverService.postRequest({action_id: action_id}, 'account', 'json', type );
        //const requestPriceError = this.serverService.postRequest({action_id:'admin_price_error'}, 'account', 'json', type );

        if(requestPriceUpdate){ return forkJoin([requestPriceUpdate,])}
        return null;
    }

    // MARK: - Making things move
    fillNewAccoutInfo(userRegFrom: any) {
        const newUser = new AccountInfo;
        const billingInfo = new Billing;
        const userInfo = new User;
        newUser.toolbox = new Toolbox;
        billingInfo.bill_email = userRegFrom['username'];
        userInfo.user_email = userRegFrom['username'];
        newUser.user = userInfo;
        newUser.billing = [];
        newUser.project = [];
        newUser.transactions = [];
        newUser.billing.push(billingInfo);
        newUser.user_billing_count = 0;
        newUser.project_count = 0;
        newUser.secure = 1;
        return newUser;
    }

    // MARK NEED TO CHANGE
    /*populateCheckoutInfo() {
        const newAccountData = this.currentAccountInfo();
        const user = newAccountData.user;
        const billing = newAccountData.billing;
        const projects = newAccountData.project;
        const checkInfo = this.checkoutService.currentCheckoutInfo();
        const newCheckoutInfo = new CheckoutInfo;
        if (checkInfo.cartInfo) {
            newCheckoutInfo.cartInfo = checkInfo.cartInfo;
        }
        // Assign Checkout Billing
        if (user && billing) {
            const currentBilling = billing[0];
            const newCheckoutBilling = new CheckoutBilling;
            newCheckoutBilling.form_type = 'billing';
            if (currentBilling.bill_company) {
                newCheckoutBilling.company_name = currentBilling.bill_company;
            }
            newCheckoutBilling.email = user.user_email;
            if (user.user_first_name) {
                newCheckoutBilling.first_name = user.user_first_name;
            }
            if (user.user_last_name) {
                newCheckoutBilling.last_name = user.user_last_name;
            }
            newCheckoutBilling.addr = currentBilling.bill_addr;
            newCheckoutBilling.postal_code = currentBilling.bill_postal_code;
            newCheckoutBilling.phone_number = currentBilling.bill_phone_num;
            if (currentBilling.bill_addr_2) {
                newCheckoutBilling.addr_2 = currentBilling.bill_addr_2;
            }
            if (currentBilling.bill_phone_alt) {
                newCheckoutBilling.phone_alt = currentBilling.bill_phone_alt;
            }
            newCheckoutInfo.billing = newCheckoutBilling;
        }
        if (projects) {
            let projectArray: any[] = [];
            projects.forEach(project => {
                const newCheckoutProject = new CheckoutProject;
                newCheckoutProject.delivery_addr = project.project_addr;
                if (project.project_postal_code) {
                    newCheckoutProject.postal_code = project.project_postal_code;
                }
                if (project.project_addr_2) {
                    newCheckoutProject.addr_2 = project.project_addr_2;
                }
                if (project.project_name) {
                    newCheckoutProject.project_name = project.project_name;
                }
                newCheckoutProject.local_project_id = project.local_project_id;
                projectArray.push(newCheckoutProject);
            });
            newCheckoutInfo.project = projectArray;
        }
        this.checkoutService.nextCheckInfo(newCheckoutInfo, 'account service');
    }*/

    extractData(res: any) {
        const body = res.json();
        return body || {};
    }

    // MARK: - Show Set/Get's for Accounts
    setLogged(logged: null| boolean ) {
        //console.log("Setting Logged to: ", logged)
        if (!logged) {logged = false;}

        this.storageService.setStorage(logged, 'isLogged');
        this.viewService.changeView('logged', logged, null, 'account.service:setLogged');
    }

    setShowLoginRegister(show: boolean) { this.showLoginRegister = show; }
    getShowLoginRegister() { return this.showLoginRegister; }

    setShowBilling(show: boolean) { this.showBilling = show; }
    getShowBilling() { return this.showBilling; }

    setShowProject(show: boolean) { this.showProject = show; }
    getShowProject() { return this.showProject; }

    setShowCredit(show: boolean) { this.showCredit = show; }
    getShowCredit() { return this.showCredit; }

    setShowAccount(show: boolean) { this.showAccount = show; }
    getShowAccount() { return this.showAccount; }

    setShowToolbox(show: boolean) { this.showToolbox = show; }
    getShowToolbox() { return this.showToolbox; }

    setShowAdmin(show: boolean) { this.showAdmin = show; }
    getShowAdmin() { return this.showAdmin; }


    // MARK: - Account position for Checkout
    getCheckoutPosition() {
    // Routed Options
    // billing, project, delivery, payment, completition, continue
    let position = 'deliveryInfo';
    const currentAccountInfo = this.currentAccountInfo();
    if (!currentAccountInfo.billing) {
        position = 'billing';
    }
    if (!currentAccountInfo.project || !this.getDefaultIndex()) {
        position = 'project';
    }
    return position;
    }

    // MARK: - SET's && Get's
    setUserName(name: string) { this.userName = name; }
    getUserName() {
        const userName = this.userName;
        if (userName) {
            return userName;
        }
        else {
            return null;
        }
    }

    setLoadingAccount(loading: boolean) { this.isLoadingAccount = loading; }

    setDefaultIndex(index: number) { this.defaultIndex = index; }
    getDefaultIndex() { return this.defaultIndex; }

    getDefaultProject() {
        let defaultProject = undefined;
        const searchProject = this.accountInfo.project;
        if (searchProject) {
            defaultProject = searchProject.find((project) => project.default === 1);
            if (defaultProject) {
                return defaultProject;
            }
        }
        return null;
    }

    getUser() {
        let user = new User;
        if (this.userInfo) {
            user = this.userInfo;
        }
        return user;
    }

    // Project
    setProject(project: Project[]) {
        const defaultIndex = project.findIndex((element) => element.default === 1);
        if (defaultIndex > -1) {
            this.defaultIndex = defaultIndex;
            this.setDefaultIndex(this.defaultIndex);
            const defaultProject = project[defaultIndex];
            //const checkoutProject: CheckoutProject = this.checkoutService.convertProjecttoCheckout(defaultProject);
            //this.checkoutService.setSelectedProject(checkoutProject, "setProjects (accountService)");
        }
        this.projectInfo = project;
    }
    getProject() { return this.projectInfo; }

    // STORED TRANSACTIONS
    setTransactions(transInfo: Transactions[]) { this.transInfo = transInfo; }
    getTransactions() { return this.transInfo; }

    // Billing
    setBilling(billing: Billing) { 
        if (billing) {
        this.billingInfo.push(billing);
        } 
    }
    getBilling() { return this.billingInfo; }

    setFav(favDetail: Item[]| null) {
        if (favDetail) {
            favDetail.forEach((element: Item) => {
                if (typeof element !== 'number') {
                    if (element.item_number) {
                        this.favSimple.push(element.item_number);
                    }
                    if (element) {
                        this.favDetail.push(element);
                    }
                }
            });
        }
        else {
            this.favSimple = [];
        }
    }

    // Favorites
    getFavDetail() { return this.favDetail; }
    getFavSimple() { return this.favSimple; }

    // Wallet
    setWalletId(wallet_id: boolean) { this.wallet_id = wallet_id; }
    getWalletId() {
        const user = this.accountInfo.user;
        if (user) {
            const walletCheck = user.wallet_id;
            return walletCheck;
        }
        return false;
    }
    getWallet() {
        const userWallet: any[] = [];
        const checkUser = this.accountInfo.user;
        if (checkUser) {
            const checkWallet = checkUser.user_wallet;
            if (checkWallet) {
                return checkWallet;
            }
        }
        return userWallet;
    }
    setToolList(tools: Item[]) { this.toolList = tools; }
    getToolList() { return this.toolList; }

    // MARK: - GET's
    getCreditCardArray() {
        const accCheck = this.accountInfo;
        if (accCheck) {
            const userCheck = this.accountInfo.user;
            if (userCheck) {
                const walletCheck = userCheck.user_wallet;
                if (walletCheck) {
                    return walletCheck;
                }
            }
        }
        return [];
    }
    // TRACKS LOGIN or REGISTERING STATS
    setAccountMobile(stats: number) { this.regStatus = stats; }
    getAccountMobile() { return this.regStatus; }
    setAccountLoginFailed(index: number, failed: boolean) { if (index === null) {
        this.hasFailed = [];
    }
    else {
        this.hasFailed[index] = failed;
    } }
    getAccountLoginFailed(index:number) { return this.hasFailed[index]; }

    setProjectCreationState(index: number, state: boolean) { this.projectState[index] = state; }
    getProjectCreationState(index: number) { return this.projectState; }

    setProjectMessage(message: any) { this.projectMessage = message; }
    getProjectMessage() { return this.projectMessage; }

    getSavedCart() { return this.savedCart; }
    // MAIN ACCOUNT SET
    showAccountOptions(show: string) {
        //this.setLoadingAccount(true);
        // Clear Views
        this.clearShow();
        if (show !== 'logout') {
            // Open Router
            this.router.navigate(['/user-account', show]);
        }
        // Open Selected View
        switch (show) {
            case 'admin':
                this.setShowAdmin(true);
                break;
            case 'shop':
                this.router.navigate(['/']);
                break;
            case 'login':
                this.setShowLoginRegister(true);
                break;
            case 'register':
                this.setShowLoginRegister(false);
                break;
            case 'reset':
                this.router.navigate(['password']);
                break;
            case 'postal':
                this.itemService.setLocationOpen(true);
                break;
            case 'account_option':
                this.setShowAccount(true);
                break;
            case 'billing':
                this.setShowBilling(true);
                break;
            case 'project':
                this.setShowProject(true);
                break;
            case 'credit':
                this.setShowCredit(true);
                break;
            case 'toolbox':
                this.setShowToolbox(true);
                break;
            case 'logout':
                this.logout();
                break;
        }
    }

    clearShow() {
        this.setShowToolbox(false);
        this.setShowAdmin(false);
        this.setShowBilling(false);
        this.setShowProject(false);
        this.setShowCredit(false);
        this.setShowAccount(false);
    }
    // MARK: - ADD and REMOVE
    addProject(form: any, index: number) {
        if (index === 0) { // ADD
            if (form) {
                this.projectInfo.push(form);
            }
            ;
        }
        if (index !== 0) { // UPDATE
            this.projectInfo.splice(index - 1, 1, form);
        }
    }
    removeProject(index:number) {
        // -1 for Removing add project as index
        this.projectInfo.splice(index - 1, 1);
    }

    // STORED CREDIT CARD FUNCTION
    parseCredit(creditString: string) {
        const newCards = [];
        // Decode Wallet
        const decode = JSON.parse(creditString);
        // Form Keys
        const creditKey = Object.keys(decode);
        for (let i = 0; i < creditKey.length; i++) {
            let creditIndex = +creditKey[i];
            let newCard = {
                card_hash: creditKey[i],
                card_info: decode[creditIndex]
            };
            newCards.push(newCard);
        }
        return newCards;
    }
    getTimerStat() { return this.timerStatus; }

    getLoginCounter() { return this.loginTimer; }

    // MARK: - Function's
    favCheck(sub_id: number) {
        const checked = this.favDetail.filter((element: Item) => element.sub_id === sub_id);
        return checked;
    }

    populateAccount(newAccountInfo: any) {
        var _a, _b;
        //const locationCheck = this.locationService.getLocalLocation();

        // THIS WILL NEED TO BE CHANGE TO ACCOMADATE FOR
        newAccountInfo.isAdmin = true;

        const locationCheck = false;
        if (!locationCheck) {
            const billingInfo = newAccountInfo.billing;
            if (billingInfo && billingInfo.length > 0) {
        
                const currentUserBilling = billingInfo[0];
                if (currentUserBilling) {
                    if (currentUserBilling.bill_postal_code) {
                        const currentLocation = billingInfo[0].bill_postal_code.substring(0, 3);
                        //this.locationService.setLocalLocation(currentLocation);
                    }
                }
            }
        }

        // Reset Timer
        this.loginTimer = this.defaultTimeout;
        this.timerStatus = true;
        if (newAccountInfo.billing) {
            this.billingInfo = newAccountInfo.billing;
        }
        if (newAccountInfo.project) {
            if (newAccountInfo['project'].length > 0) {
                this.setProject(newAccountInfo.project);
            }
        }
        if (!newAccountInfo.project) {
            //this.checkoutService.setSelectedProject(null, "populate account");
        }
        // User Information, Transactions and Billing
        const userCheck = newAccountInfo.user;
        if (userCheck) {
            newAccountInfo.user = userCheck;
            const walletCheck: string = userCheck.user_wallet;
            if (walletCheck && newAccountInfo.user.user_wallet) {
                newAccountInfo.user.user_wallet = this.mapWallet(walletCheck);
            }
        }

        if (newAccountInfo.user && newAccountInfo.billing) {
            const emailCheck = newAccountInfo.user.user_email;
            if (emailCheck) {
                const checkBillEmail = newAccountInfo.billing;
                if (checkBillEmail.length <= 0) {
                    const newBilling = new Billing;
                    newAccountInfo.billing.push(newBilling);
                }
                newAccountInfo.billing[0].bill_email = newAccountInfo.user.user_email;
            }
        }
        if (newAccountInfo.transactions) {
            this.transInfo = newAccountInfo.transactions;
            newAccountInfo.transactions = this.mapTransactions(this.transInfo);
        }
        //_____________________________________________
        // Toolboxs && Fav's
        const favorites = (_a = newAccountInfo.toolbox) === null || _a === void 0 ? void 0 : _a.favorites;
        const cartList = (_b = newAccountInfo.toolbox) === null || _b === void 0 ? void 0 : _b.cart_list;
        if (favorites || cartList) {
            newAccountInfo.toolbox = new Toolbox;
            if (cartList) {
                const newCartList = new CartList;
                newCartList.cart_list_get_count = cartList.cart_list_get_count;
                newCartList.cart_list_info = this.mapCartList(cartList, cartList.cart_list_get_count);
                newAccountInfo.toolbox.cart_list = newCartList;
            }
            if (favorites && favorites.length && favorites.length > 0) {
                newAccountInfo.toolbox.favorites = favorites;
                if (favorites.length) {
                    this.setFav(favorites);
                }
                ;
            }
        }
        this.accountInfo = newAccountInfo;
        this.setLogged(true);
        //this.setLoadingAccount(false);

        return newAccountInfo;
    }

    mapCartList(cartList: CartListInfo[] | CartList, max: number) {
        const cartListCheck = cartList instanceof CartList;
        let newList: CartListInfo[];
        if (cartListCheck) {
            newList = cartList.cart_list_info;
        }
        else {
            newList = cartList;
        }
        const newCLInfo: any[] = [];
        const listKeys = Object.keys(newList);
        let i = 0;
        listKeys.forEach((element: any) => {
            if (i < max) {
                newCLInfo.push(newList[element]);
            }
            i++;
        });
        return newCLInfo;
    }

    mapTransactions(transactions: Transactions[]) {
        let newTransactions = Object.keys(transactions).map((key) => transactions[+key]);
        let filteredTransactions: Transactions[] = [];
        newTransactions.forEach((element: Transactions) => {
            if (element) {
                if (element.confirm_num &&
                    element.delivery_fee &&
                    element.insertion_date &&
                    element.sub_total &&
                    element.gst) {
                    element.date = this.formatDate(element.insertion_date); // NOTE: Needs to be fixed as date processing should be done on server side so to not effect user experience
                    //console.log('Push Element', element, element.local_project_id)
                    filteredTransactions.push(element);
                }
            }
        });
        return filteredTransactions;
    }

    assignBilling() {
        const billingInfo = new Array(6);
        // Postions
        // company, email, first name, last name, telephone, billing address
        const currentBillingArray = this.currentAccountInfo().billing;
        const currentUserInfo = this.currentAccountInfo().user;
        if (currentBillingArray && currentUserInfo && currentBillingArray[0]) {
            const bill_company = currentBillingArray[0].bill_company;
            const bill_email = currentBillingArray[0].bill_email;
            const user_first_name = currentUserInfo.user_first_name;
            const user_last_name = currentUserInfo.user_last_name;
            const bill_phone_num = currentBillingArray[0].bill_phone_num;
            const bill_addr = currentBillingArray[0].bill_addr;
            if (bill_company) {
                billingInfo[0] = currentBillingArray[0].bill_company;
            }
            else {
                billingInfo[0] = '';
            }
            ;
            if (bill_email) {
                billingInfo[1] = currentBillingArray[0].bill_email;
            }
            else {
                billingInfo[0] = '';
            }
            ;
            if (user_first_name) {
                billingInfo[2] = currentUserInfo.user_first_name;
            }
            else {
                billingInfo[0] = '';
            }
            ;
            if (user_last_name) {
                billingInfo[3] = currentUserInfo.user_last_name;
            }
            else {
                billingInfo[0] = '';
            }
            ;
            if (bill_phone_num) {
                billingInfo[4] = currentBillingArray[0].bill_phone_num;
            }
            else {
                billingInfo[0] = '';
            }
            ;
            if (bill_addr) {
                billingInfo[5] = currentBillingArray[0].bill_addr;
            }
            else {
                billingInfo[0] = '';
            }
            ;
        }
        return billingInfo;
    }

    mapWallet(jsonWallet: string) {
        let mappedWallet: any[] = [];
        const parsedWallet = JSON.parse(jsonWallet);
        const newArrayWallet = Object.entries(parsedWallet);
        newArrayWallet.forEach((element) => {
            const newWallet = new UserWallet;
            const newCard = new CardInfo;
            const card_hash = element[0];
            const cardInfo = element[1];
            if (card_hash && cardInfo && cardInfo instanceof CardInfo) {
                newWallet.card_hash = card_hash;
                newCard.brand = cardInfo.brand;
                newCard.card_num = cardInfo.card_num;
                newCard.default = cardInfo.default;
                newCard.description = cardInfo.description;
                newCard.exp_month = cardInfo.exp_month;
                newCard.exp_year = cardInfo.exp_year;
                newCard.last4 = cardInfo.last4;
                newCard.linked = cardInfo.linked;
                newWallet.card_info = newCard;
            }
            mappedWallet.push(newWallet);
        });
        return mappedWallet;
    }

    formatDate(date: string):Date {
        let newDate = new Date;

        if (date) {
            const year = +date.substring(0, 4);
            const month = +date.substring(5, 7);
            const day = +date.substring(8, 10);

            newDate.setDate(day);
            newDate.setMonth(month);
            newDate.setFullYear(year);
        }

        return newDate;

    }

    clearAccount() {
        // Clear Variables
        this.setDefaultIndex(-1);
        //this.checkoutService.setSelectedProject(null, 'accountService');
        // this.checkout.setSelectedProject(null);
        //this.setBilling([]);
        this.setProject([]);
        this.setUserName('');
        // this.setCredit(null);
        this.setFav(null);

        // Complete Loading
        const nullAccount = new AccountInfo;
        this.newAccountInfo(nullAccount);
        this.storageService.setStorage(false, 'isLogged');

        // Clear view
        const newView = this.viewService.currentView();
        newView.isLogged = false;
        newView.isLoading = false;
        this.viewService.nextView(newView, "clearAccount");

        this.router.navigate(['/user-account', 'login']);
    }

    // Saved Cart Functions
    delSavedCart(cartId: number) {
        // Need Cart ID
        const actionId = {};
    }

    // MARK: - Temp Error management
    // MARK: - POST
    sendRequest(serviceRequest: any, dataType: string, from: string) {
        //const post = this.serverService.postRequest(serviceRequest, 'account_endpoint', dataType, 'sendRequest;accountServices');

        const actionId = serviceRequest['action_id'];

        if(actionId){
            return this.serverService.postRequest(serviceRequest, 'account', dataType,'sendRequest;accountServices' );
        }
        return this.serverService.postRequest(serviceRequest, 'account', dataType,'sendRequest;accountServices');
    
    }

    checkLogged() {
        const post = { action_id: 'user_status' };
        const request = this.sendRequest(post, 'json', 'account.service:checkLogged');
        if (request) {
            request.subscribe({
                next: (data: any) => {
                    if (data.status !== 'Error') {
                        if (data.secure === 0) {
                            this.setLogged(false);
                        }
                        if (data.secure === 1) {
                            this.setLogged(true);
                            this.setUserName(data.user.user_first_name);
                        }
                        this.setLoadingAccount(false);
                    }
                    else {
                        this.clearAccount();
                    }
                },
                error: error => {
                    console.error("Checking Logged", error);
                    this.clearAccount();
                }
            });
        }
    }

}

  

