
<div class="btn-group-vertical driver-selection-items-container" #driverSelection>
    <!-- ACTIVE DRIVERS-->
    <div *ngIf="showOptions()">
        <div style="font-size: small;
                    font-weight: 100;
                    border-bottom: 1px solid #d9d9d9;
                    margin-bottom: 5px;">
            Active
        </div>
        <div class="my-2" *ngFor="let activeDriver of activeDrivers; let driverIndex = index">
            <button class="btn btn-primary" (click)="btnClick(activeDriver, driverIndex,'drivers')">        
                {{[activeDriver.driverInfo.initial]}}
            </button>
        </div>
    </div>    
    <!-- ACTIVE DRIVERS-->
    <div *ngIf="!showOptions()">
        <div style="font-size: small;
                    font-weight: 100;
                    border-bottom: 1px solid #d9d9d9;
                    margin-bottom: 5px;">
            Move
        </div>
        <div class="my-2" >
            <button class="btn btn-primary" (click)="btnClick(null, -1, 'move_titg')">        
                TiTG
            </button>
           
        </div>
        <div class="my-2" >
            <button class="btn btn-primary" (click)="btnClick(null, -1, 'move_teams')">
                Team's
            </button>
        </div>   

        <div class="my-2" 
            *ngIf="routeInfo.view.infoToolOptions[0] && routeInfo.view.infoToolOptions[0].dueComplete">
            <button class="btn btn-primary" (click)="btnClick(null, -1, 'move_photo')">
                Photo's
            </button>
        </div>
        
    </div> 
</div>
