
<div class="route-email-container" (click)="$event.stopPropagation()">
    <div *ngFor="let mailer of emailInfo" style="width: 100%;padding: 15px;">
        <form [formGroup]="formEmail">
            <h1 class="mt-3">
                <img 
                src="/assets/images/envelope_resize.svg" 
                alt="/assets/images/envelope_resize.svg" 
                class="route-email-img"> 
                Email
            </h1>
    
            <div class="route-email-general-row">
                <div>To:</div><input class="route-email-input mx-3" #email_to type="email" formControlName="email_to">
            </div>
    
            <div class="route-email-general-row">
                <div>Cc:</div><input class="route-email-input mx-3" #email_cc type="email" formControlName="email_cc">
            </div> 
    
            <div class="route-email-general-row ">
                <input class="route-email-input mr-3 pb-2" #email_subject type="text"  formControlName="email_subject">
            </div>
    
            <div class="route-email-general-row route-email-body-container" >
                <textarea
                    class="route-email-body"
                    #email_body
                    formControlName="email_body">
                </textarea>
           
            </div>
    
            <div class="route-email-send">
                <button 
                    class="btn btn-primary btn-lg w-100"
                    (click)="sendEmail()"
                >Send order</button>
            </div>
        </form>
      
    </div>
</div>
