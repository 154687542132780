import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HotbarHeader } from './classes/hotbar';

const DEFAULT_HOTBAR: HotbarHeader[] = []
@Injectable({
  providedIn: 'root'
})
export class HotbarService { 

  subjectHotbar = new BehaviorSubject(DEFAULT_HOTBAR);
  hotbar$ = this.subjectHotbar.asObservable();
  
  constructor() { }

  currentView(): HotbarHeader[] {
    return this.subjectHotbar.value;
  }

  nextView(views: HotbarHeader[]) {
    this.subjectHotbar.next(views);
  }
}
