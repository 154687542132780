<div *ngIf="view.accountView.showLogin">
  <div class="login-container">
    <div class="container" style="width: 600px;margin: auto;">
      <h1 style="text-align: center;font-size: 43px;margin: 15px; padding-bottom: 15px;height: 100%;">Welcome</h1>
    </div>
    
    <app-inputbox class="mb-3" (validData)="submit($event, 0)" (keyDown)="login()" [inputSetting]="user_username"></app-inputbox>
    <app-inputbox class="mb-3" (validData)="submit($event, 1)" (keyDown)="login()" [inputSetting]="user_password"></app-inputbox>
    
    <h3 *ngIf="showErrResponse" class="text-center text-danger">{{errResponse}}</h3>
  
    <div class="btn-submit-container" >
      <button class="btn btn-primary btn-submit"  tabindex="3" [disabled]="btn_disabled" (click)="login()">Login</button>
    </div>
    
  </div>
</div>
<app-account-tools *ngIf="view.accountView.showTools"></app-account-tools>

