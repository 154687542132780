import { InputSetting } from "../inputbox";

export const inputBoardId: InputSetting = {
    input_place_holder: "Board Id",
    input_has_action_btn: false,
    input_surpress_header: false,
    input_name: "",
    input_type: "text",
    input_autofocus: true,
    input_autocomplete: "none",
    input_error: "",
    input_pass: [2, ''],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'mb-3',

    input_min: '',
    input_value: null,

    input_data: null,
    output_data: null,

    input_dom_rect: null,
}

export const inputListId: InputSetting = {
    input_place_holder: "Get cards (listId)",
    input_has_action_btn: false,
    input_surpress_header: false,
    input_name: "",
    input_type: "text",
    input_autofocus: true,
    input_autocomplete: "none",
    input_error: "",
    input_pass: [2, ''],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'mb-3',

    input_min: '',
    input_value: null,

    input_data: null,
    output_data: null,
    input_dom_rect: null,
}

export const InputSettingChecklist: InputSetting = {
    input_place_holder: "Add a new checklist",
    input_has_action_btn: false,
    input_surpress_header: true,
    input_name: "",
    input_type: "text",
    input_autofocus: true,
    input_autocomplete: "off",
    input_error: "",
    input_pass: [5, ''],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'mb-3',

    input_min: '',
    input_value: null,

    input_data: null,
    output_data: null,
    input_dom_rect: null,

}

export const InputSettingCheckItem: InputSetting = {
    input_place_holder: "Add a new item",
    input_has_action_btn: false,
    input_surpress_header: true,
    input_name: "",
    input_type: "text",
    input_autofocus: true,
    input_autocomplete: "off",
    input_error: "",
    input_pass: [5, ''],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'mb-3',

    input_min: '',
    input_value: null,

    input_data: null,
    output_data: null,
    
    input_dom_rect: null
    
}

export const InputSettingDriverTask: InputSetting = {
    input_place_holder: "Add a new driver task",
    input_has_action_btn: false,
    input_surpress_header: true,
    input_name: "",
    input_type: "text",
    input_autofocus: true,
    input_autocomplete: "off",
    input_error: "",
    input_pass: [5, ''],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'mb-3',

    input_min: '',
    input_value: null,

    input_data: null,
    output_data: null,
    
    input_dom_rect: null
}

export const InputSettingToolRoute: InputSetting = {
    input_place_holder: "A General message? Sargent!",
    input_has_action_btn: false,
    input_surpress_header: true,
    input_name: "",
    input_type: "text",
    input_autofocus: true,
    input_autocomplete: "off",
    input_error: "",
    input_pass: [5, ''],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'mb-3',

    input_min: '',
    input_value: null,

    input_data: null,
    output_data: null,

    input_dom_rect: null
}



