<div 
    *ngFor="let card of trelloInfo.active_trello_card"
    class="trello-card-container"
    (click)="$event.stopPropagation()">
    <div *ngIf="card">
        
        <h1>{{card.name}}</h1>
    
        <!-- LABELS -->
        <div class="flex-row">
            <div *ngFor="let label of card.labels">
                <div 
                    style="margin: 15px; padding: 15px;" 
                    [ngStyle]="{'color': label.color}">{{label.name}}</div>
            </div>
        </div>
    
        <!-- DUE DATE-->
        <div class="flex-row">
            <div class="pl-3">{{card.due}}</div>
        </div>
    
        <div 
            *ngIf="card.desc"
            class="trello-card-description-container"
            role="textbox" 
            aria-multiline="true" 
            contenteditable="true">
            {{card.desc}}
        </div >

        <app-trello-checklist
            [idChecklists]="card.idChecklists">
        </app-trello-checklist>
    </div>
</div>

