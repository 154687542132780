import { Injectable } from '@angular/core';
import { DefaultAppConfig } from 'src/app/app.config';

import { retry,catchError } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http';

import { Subject, BehaviorSubject } from 'rxjs'
import { Item } from 'src/app/cart-item/classes/item';
import { DEFAULT_PRICE_UPDATE, DEFAULT_UPDATER_VIEW, PriceUpate, UpdatedItems, UpdaterView } from './classes/tool-updater';
import { AccountService } from '../../services/accounts.service';

@Injectable({
  providedIn: 'root'
})
export class ToolUpdaterService {


  subjectItems = new Subject<Item[]>();
  searchResults$ = this.subjectItems.asObservable();

  subjectFilteredItem = new Subject<Item>();
  filteredItemResults$ = this.subjectFilteredItem.asObservable();

  subjectPendingUpdates = new BehaviorSubject<PriceUpate[]>(DEFAULT_PRICE_UPDATE);
  pendingUpdates$ = this.subjectPendingUpdates.asObservable();

  subjectUpdaterView = new BehaviorSubject<UpdaterView>(DEFAULT_UPDATER_VIEW);
  updateView$ = this.subjectUpdaterView.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  nextSearchResult(newItems: Item[]){ this.subjectItems.next(newItems)}
  nextFilteredResult(newItems: Item){ this.subjectFilteredItem.next(newItems)}
  
  nextPendingUpdate(newUpdate: PriceUpate[]){ this.subjectPendingUpdates.next(newUpdate)}
  currentPendingUpdate(): PriceUpate[] { return this.subjectPendingUpdates.value}

  nextUpdaterView(newView:UpdaterView){ this.subjectUpdaterView.next(newView)}
  currentUpdaterView(): UpdaterView{ return this.subjectUpdaterView.value}


  getSearchedItem(sub_id: number){
    
    let prepend = '';

    if (DefaultAppConfig.DEFAULT_TESTING) {  prepend = 'http://192.168.112.128/app_forward';
    } else { prepend = '/app_forward'; }

    const requestURI = prepend + '/service_request.php' + '?action_id=' + 99 + '&category=' + sub_id;
    
    const observed = this.http
        .get<Item[]>(requestURI)
        .pipe(
          retry(3),
          catchError(this.handleError));
        
      
      //this.searchBreadcrumbs(observed);
      return observed;
  }

  setUpdateMessage(success: boolean, message: string){
    const newUpdaterView = new UpdaterView;

    // Set Message
    newUpdaterView.isUpdateSuccessful = success;
    newUpdaterView.submitMessage = message;
  }

  appendUpdatedList(){
    const updatedItems = this.currentUpdaterView()
    let updatedItemNumbers = this.currentUpdaterView().updatedItems.item_numbers;

    const currentPendingUpdates = this.currentPendingUpdate();
    const pendingItemNumber = currentPendingUpdates.map(el => el.item.item_number);


    const holdingArray: number[] = []

    updatedItemNumbers.forEach(element => {
      const check = pendingItemNumber.findIndex(el => el === element);
      console.log(check, element)
      if(check === -1 ){ holdingArray.push(element)}
    });



    const mergedArrays = [...pendingItemNumber, ...holdingArray];
    mergedArrays.sort();

    updatedItems.updatedItems.item_numbers = mergedArrays
    this.nextUpdaterView(updatedItems);

  }

  requestUpdate(){

  }

  clearUpdates(){
    const newPendingUpdates:PriceUpate[] = []
    this.nextPendingUpdate(newPendingUpdates);
  }

   
  handleError(error: any) {
    console.error('An error occured', error);
    return Promise.reject(error.message || error);
  }


}
