
<!--div class="input-surround"-->
<div #inputboxContainer (click)="$event.stopPropagation()">
    <div 
        class="input-container {{inputSetting.input_container_css_setting}}" 
        [ngClass]="[focus ? 'active' : '', (error&&dirty) ? 'error': '']">
        <div class="flex-row" style="align-items: center;height: 100%;">
            <app-fade-in-out-animation [isInOut]="entry" *ngIf="entry && !inputSetting.input_surpress_header" >
                <label class="input-placeholder w-100" [ngStyle]="{'max-width': input_placeholder_width + 'px' }">{{inputSetting.input_place_holder}}</label>
            </app-fade-in-out-animation>
            <input                
                class="w-100 input-box {{inputSetting.input_css_setting}}"
                name="inputbox"
                #inputbox
                placeholder="{{inputSetting.input_place_holder}}"
                tabindex="{{inputSetting.input_tabindex}}"
                type="{{inputSetting.input_type}}" 
                min="{{inputSetting.input_min}}" 
                autocomplete="{{inputSetting.input_autocomplete}}"
                [(ngModel)]="modelData"
                
                [disabled]="inputSetting.input_disabled"
                [autofocus]="inputSetting.input_autofocus"
                
                (keyup)="userInput()"
                (focus)="focus = true; dirty = false;"
                (blur)="focus = false; dirty = true; testInput()"
                (keydown.enter)="keydownTracker('enter')"
                (keydown.esc)="keydownTracker('esc')"

                (keydown.control.b)="keydownTracker('ctrlB')">
            <div class="input-container-action-btn" (click)="$event.stopPropagation(); focus = true">
                <img src="assets\images\password_hide.svg"  class="input-btn-img" alt="" *ngIf="input_type === 'password' && !show_password" (click)="subBtnClick('password_unmask')">
                <img src="assets\images\password_show.svg" class="input-btn-img" alt="" *ngIf="input_type === 'password' && show_password" (click)="subBtnClick('password_mask')">    
            </div>

            <div 
                *ngIf="inputSetting.input_has_action_btn"
                class="flex-row input-container-action-btn">
                <button 
                    class="btn btn-naked float-right"
                    style="padding: 7px !important;"
                    type="button"
                    tabindex="-1"
                    (click)="
                        $event.stopPropagation();
                        keydownTracker('enter');">
                    <img
                        class="updater-delete-btn"
                        style="height: 18px !important;"
                        src="/assets/images/checkmark_black.svg">
                </button>
                <button 
                    class="btn btn-naked float-right"
                    style="padding: 7px !important;" 
                    type="button"
                    tabindex="-1"
                    (click)="
                        $event.stopPropagation();
                        keydownTracker('esc');">
                    <img
                        class="updater-delete-btn"
                        style="height: 18px !important;"
                        src="/assets/images/delete_icon_red.svg">
                </button>       
            </div>
                
            <app-fade-in-out-animation 
                *ngIf="(dirty && error) " 
                [isInOut]="(dirty && error)" 
                [ngStyle]="{'max-width': input_error_width + '%' }">
                <span class="text-danger input-error"> {{inputSetting.input_error}}</span> 
            </app-fade-in-out-animation>
        </div>
    </div>
</div>


