import { ChangeDetectorRef, Component } from '@angular/core';
import { ViewService } from '../general-services/view/view.service';
import { AccountService } from '../accounts/services/accounts.service';
import { Subscription } from 'rxjs';
import { AccountInfo } from '../accounts/classes/account-settings';
import { View } from '../general-services/view/view';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  accountInfo = new AccountInfo;
  view = new View;

  accountInfoSub: Subscription;
  viewSub: Subscription;

  constructor(
    private viewService: ViewService,
    //private serverService: ServerService,
    //private hotbarService: AccountHotbarService,
    private accountService: AccountService,
    private detectChanges: ChangeDetectorRef
  ){
    
    this.accountInfoSub = accountService.account$.subscribe(accountInfo => {
      this.accountInfo = accountInfo;
      this.detectChanges.markForCheck();
    });

    this.viewSub = viewService.view$.subscribe(view => {
      this.view = view;
      this.detectChanges.markForCheck();
    });
  
  }
  
  // MARK - CLICK ACTION
  
  clickDropDownMenu(){
    console.log('Click stuff',)
    const newView = this.viewService.currentView();
    newView.navbarView.showDropDownMenu = newView.navbarView.showDropDownMenu ? false: true;
  
    this.viewService.nextView(newView, 'clickDropDownMenu')
  }
}
