import { OnInit, Component, Input,ChangeDetectorRef, OnDestroy, Renderer2 } from '@angular/core';
import { Trello } from '../../classes/trello';
import { TrelloService } from '../../services/trello.service';
import { Subscription } from 'rxjs';
import { ToolRoutesService } from 'src/app/accounts/account-tools/tool-routes/tool-routes.service';

@Component({
  selector: 'app-trello-card',
  templateUrl: './trello-card.component.html',
  styleUrls: ['./trello-card.component.css']
})
export class TrelloCardComponent implements OnInit, OnDestroy {
    trelloInfo!: Trello;
    trelloSub: Subscription;


    constructor(
      private trelloService: TrelloService,
      private detectChanges: ChangeDetectorRef, 
      private renderer: Renderer2,
      private routeService: ToolRoutesService,
    ){
      this.trelloSub = trelloService.trello$.subscribe(newTrello => {
        this.trelloInfo = newTrello;
        this.detectChanges.markForCheck();
      });
    }

    @Input() css: any = null

  ngOnInit(): void{
    this.renderer.addClass(document.body, 'modal-open');

    const currentTrelloInfo = this.trelloService.currentTrelloInfo();
    const activeCard = currentTrelloInfo.active_trello_card;

    //console.log(activeCard, '<=== active card capture!@#@!#')

  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'modal-open');
  }
}

