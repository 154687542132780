import { Component, OnChanges, Input } from '@angular/core';
import { trigger, state, animate, transition, style} from '@angular/animations'


@Component({
  selector : 'animation-fly-downup',
  template: `
    <div [@flyDownUp]="downUp" id="{{id}}">
        <ng-content></ng-content>            
    </div>
  `,
  styleUrls: ['./down-up-animation.css'],
  
 animations: [
    trigger('flyDownUp', [
      state('up', style({transform: 'translateY(100%)'})),
      state('down', style({transform: 'translateY(0)'})),
      transition('up => down', [
        style({transform: 'translateY(100%)'}),
        animate(350)
      ]),
      
      transition('down => up', [
        animate(350)
      ])
    ])
  ]
})

export class DownUpAnimation implements OnChanges {
     @Input() isDownUp : boolean = true;
     @Input() id: string = ''
     downUp = 'up';
    
    ngOnChanges() {
        this.downUp = this.isDownUp ? 'up': 'down'; 
    }

}