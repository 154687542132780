import { InputSetting } from "../inputbox";

var currencyRegex = /^(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/

export const AccountUpdaterSearchSetting: InputSetting = {
    input_place_holder: "Search",
    input_has_action_btn: false,
    input_surpress_header: false,
    input_name: "",
    input_type: "text",
    input_autofocus: true,
    input_autocomplete: "none",
    input_error: "",
    input_pass: [2, ''],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'mb-3',

    input_min: '',
    input_value: null,

    input_data: null,
    output_data: null,
    
    input_dom_rect: null,
}

export const AccountUpdaterInputSetting: InputSetting = {
    input_place_holder: "New Price",
    input_has_action_btn: false,
    input_surpress_header: false,
    input_name: "",
    input_type: "number",
    input_autofocus: true,
    input_autocomplete: "none",
    input_error: "INVALID!",
    input_pass: [2, currencyRegex],
    input_pass_reqirements: null,
    input_disabled: false,
    input_readonly: false,
    input_action: null,
    input_css_setting: "text-right",
    input_submit: null,
    input_submit_to: null,
    input_tabindex: 1,
    input_container_css_setting: 'updater-new-price-container',

    input_min: '0.01',
    input_value: 0,

    input_data: null,
    output_data: null,
    input_dom_rect: null,
}