import { DEFAULT_INPUT_SETTING, InputSetting } from "../../inputbox/inputbox";

export class InputWindow {
    window_type: string = '';
    title: string = '';
    title_css: string = '';
    message_1: string = '';
    
    message_1_container_css: string = '';
    message_1_css: string = '';
    show: string = '';
    window_info: number[] = [];
    window_clean: boolean = false;
    window_css: string = 'modal-window';
    rows: number = -1;
    process_message: string = '';
    passed_data: any = null;
    row: Row[] = []
}

export class Row {
    logic: string| null = null;
    row_type: RowType[] = [];
}

export class RowType {
    logic: boolean| null = null;
    row_type: number = -1;
    setting: RowSetting[] = [];
}

export class RowSetting {
    button_setting: ButtonSetting = new ButtonSetting;
    input_setting: InputSetting | null = null;
    output_setting: OutputSetting[] | OutputSetting | null = null;
    message_setting: MessageSetting = new MessageSetting;
}

export class ButtonSetting{
    container_class: string = "";
    button_message: string = "";
    button_css_setting: string = "";
    button_visible: [string, boolean] | boolean = ['', false]
    button_enable: [number, RegExp| string] | null = null;
    button_click_action: string = "";
    button_route: any;
}

export class OutputSetting {
    confirm_message: string = '';
    confirm_button_pass: string = '';
    confirm_button_cancel: string = '';
    pass_title: string = "";
    pass_title_css: string = "";
    pass_message: string = "";
    pass_message_css: string = "";
    pass_button_1: string = "";
    pass_button_1_css: string = "";
    fail_title: string = "";
    fail_message: string = "";
    button_css_setting: string = '';
    fail_button_1: string = "";
    fail_button_1_css: string = "";
    fail_button_2: string = "";
    fail_button_2_css: string = "";
}


export class MessageSetting {
    container_class: string = '';
    message_css_setting: string = '';
    message_visible: boolean = false;
    message_output: any;
}

export const DEFAULT_MESSAGE_SETTING: MessageSetting = {
    
    container_class: '',
    message_css_setting: '',
    message_visible: false,
    message_output: null
}

export const DEFAULT_OUTPUT_SETTING: OutputSetting = {
    confirm_message: '',
    confirm_button_pass: '',
    confirm_button_cancel: '',
    pass_title: '',
    pass_title_css: '',
    pass_message: '',
    pass_message_css: '',
    pass_button_1: '',
    pass_button_1_css: '',
    fail_title: '',
    fail_message: '',
    button_css_setting: '',
    fail_button_1: '',
    fail_button_1_css: '',
    fail_button_2: '',
    fail_button_2_css: '',
}

export const DEFAULT_BUTTON_SETTING: ButtonSetting = {
    container_class: '',
    button_message: '',
    button_css_setting: '',
    button_visible: ['', false],
    button_enable: null,
    button_click_action: '',
    button_route: null
}

export const DEFAULT_ROW_SETTING: RowSetting[] = [{
    button_setting: DEFAULT_BUTTON_SETTING,
    input_setting: DEFAULT_INPUT_SETTING,
    output_setting: DEFAULT_OUTPUT_SETTING,
    message_setting: DEFAULT_MESSAGE_SETTING
}]


export const DEFAULT_ROW_TYPE: RowType[] = [{
    logic:  null,
    row_type: -1,
    setting: DEFAULT_ROW_SETTING
}]


export const DEFAULT_ROW: Row[] =[{
    logic: null,
    row_type: DEFAULT_ROW_TYPE
}]

export const DEFAULT_INPUT_WINDOW: InputWindow = {

    window_type: '',
    title: '',
    title_css: '',
    message_1: '', 
    message_1_container_css: '',
    message_1_css: '',
    show: '',
    window_info: [],
    window_clean: false,
    window_css: '',
    rows: -1,
    process_message: '',
    passed_data: null,
    row: DEFAULT_ROW
}



