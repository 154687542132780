import { Component, OnChanges, Input } from '@angular/core';
import { trigger, state, animate, transition, style} from '@angular/animations'


@Component({
  selector : 'animation-left-right',
  styleUrls: ['left-right-animation.css'],
  template: `
    <div [@flyLeftRight]="leftRight" id="{{id}}">
        <ng-content></ng-content>            
    </div>
  `,
  
  
 animations: [
    trigger('flyLeftRight', [
      state('right', style({transform: `translateX(0)`})),
      state('left', style({transform: 'translateX(-100%)'})),
      transition('right => left', [
        style({transform: 'translateX(0)'}),
        animate(350)
      ]),
      
      transition('left => right', [
        animate(350)
      ])
    ])
  ]
})

export class LeftRightAnimation implements OnChanges {
     @Input() isLeftRight : boolean = true;
     @Input() id: string = '';
     @Input() movePercaentage = '100%'
     leftRight = 'right';
    
    ngOnChanges() {
        this.leftRight = this.isLeftRight ? 'right': 'left'; 
    }

}