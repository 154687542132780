import { Item } from "./item";

export class CartInfo {
    
    items: Item[] = [];
    cartCompare: CartCompare = new CartCompare;
    cartFee: CartFee = new CartFee;
}

export class CartCompare{
    home_depot: number = -1;
    rona: number = -1;
    tamarack: number = -1;
    lowes: number = -1;
}

export class CartFee{
    subtotal: number = -1;
    delivery_fee: number = -1;
    tax: number = -1;
    total: number = -1;
    percentage: number = -1;
    cart_count: number = -1;
}

export class CartStats{
    volume: number = -1;
    weight: number = -1;
    wPer: number = -1;
    vPer: number = -1;
}

// MARK: DEFAULTS 
export const DEFAULT_CART_COMPARE: CartCompare = {
    home_depot: -1,
    rona: -1,
    tamarack: -1,
    lowes: -1,
}

export const DEFAULT_CART_FEE_INFO: CartFee = {
    subtotal: -1,
    delivery_fee: -1,
    tax: -1,
    total: -1,
    percentage: -1,
    cart_count: -1,
}

export const DEFAULT_CART_INFO: CartInfo = {
    items: [],
    cartCompare: DEFAULT_CART_COMPARE,
    cartFee: DEFAULT_CART_FEE_INFO
}
