import { Component, OnChanges, Input} from '@angular/core';
import {trigger, animate, transition, style} from '@angular/animations';


@Component({
  selector : 'app-fade-in-out-animation',
  template: `
    <div [@fadeInOut]="inOut" id="{{id}}">
      <ng-content></ng-content>            
    </div>
  `,
  
 animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms',style({opacity: 1}))
      ]),
      transition(':leave', [
        animate('300ms', style({opacity: 0}))
      ])
    ])
  ]
})

export class FadeInOutAnimation implements OnChanges {
     @Input() isInOut : boolean = true;
     @Input() id: string = ''
     inOut = 'in';
    
    ngOnChanges() {
        this.inOut = this.isInOut ? 'in': 'out'; 
    }

      // MARK: - Error Handling
      private handleError(error: any): Promise<any> {
        console.error('An error occured', error);
        return Promise.reject(error.message || error);
    }
}