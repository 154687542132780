<div 
    class="tools-container"
    [ngClass]="{'push-right': !view.accountView.hotbarView.isMini,
                'push-left': view.accountView.hotbarView.isMini,
                'hide-left': !view.navbarView.showDropDownMenu}">
    <app-tool-updater *ngIf="view.accountView.hotbarView.showUpdater"></app-tool-updater>
    <app-tool-add *ngIf="view.accountView.hotbarView.showAddRemove"></app-tool-add>
    <app-tool-routes *ngIf="view.accountView.hotbarView.showRoute"></app-tool-routes>
    <app-tool-pickup *ngIf="view.accountView.hotbarView.showPickup"></app-tool-pickup>
    <app-tool-carry *ngIf="view.accountView.hotbarView.showCarry"></app-tool-carry>
</div>