import { DefaultAppConfig } from "src/app/app.config";
import { Item } from "src/app/cart-item/classes/item";
import { HotbarHeader } from "src/app/modal/hotbar/classes/hotbar";


// MARK - CHANGE HERE
export class GoogleLocation {
    location: string = ';'
}

export class AccountInfo {
    project_count: number = -1;
    secure: number = 0;
    billing: null | Billing[] = null;
    project: null | Project[] = null;
    toolbox: null | Toolbox = null;
    transactions: Transactions[] = [];
    user: User  =  new User;
    user_billing_count: number = -1;
    isAdmin: boolean = false;
}

export class Billing {
    bill_name: string = '';
    bill_email: string = ''
    active: number = -1;
    bill_addr: string = '';
    bill_addr_2: string = "";
    bill_company: string = "";
    bill_phone_alt: string = "";
    bill_phone_num = -1;
    bill_postal_code: string = "";
    default: number = -1;
    deleted: number = -1;
    local_user_billing_id: number = -1;
    billing_location = new GoogleLocation;
}

export class Project {
    active: number = -1;
    default: number = -1;
    deleted: number = -1;
    linked_card: string = "";
    local_project_id: number = -1;
    project_addr: string = "";
    project_addr_2: string = "";
    project_name: string = "";
    project_note: string = "";
    project_phone_num: string = "" ;
    project_postal_code: string = "";
}


export class Toolbox {
    cart_list: CartList = new CartList;
    favorites: Item[] = [];
}

export class CartList{
    cart_list_info: CartListInfo[] = []
    cart_list_get_count: number = -1;
}

export class CartListInfo{
    active: number = -1;
    hash:  string = ""
    list:  string = ""
    name:  string = ""
    ref:  string = ""
    save: number = -1
    share: number = -1
    url: string = ""
}

export class Transactions {
    confirm_num: string = "";
    delivery_fee: number = -1;
    gst: number = -1;
    insertion_date: string = "";
    pst: number = 0;
    sub_total: number = -1;
    date: Date = new Date;
}

export class User {
    active: number = -1;
    user_email: string ="";
    user_first_name: string ="";
    user_last_name: string = "";
    user_opt_in: number = -1;
    user_wallet: UserWallet[] = [];
    wallet_id: boolean = false;
}

export class UserWallet{
    card_hash: string = '';
    card_info: CardInfo = new CardInfo;
}

export class CardInfo{
    description: string = '';
    brand: string = "";
    card_num: string = "";
    default: boolean = false;
    cardInfo: string = "";
    exp_month: number = -1
    exp_year: number = -1;
    last4: number = -1;
    linked: boolean = false;
}

// MARK: USER ACCOUNT DEFAULTS
export const DEFAULT_CARD_INFO: CardInfo = {
    description: '',
    brand: "",
    card_num: "",
    default: false,
    cardInfo: "",
    exp_month: -1,
    exp_year: -1,
    last4: -1,
    linked: false,
}

export const DEFAULT_USER_WALLET_INFO: UserWallet[] = [{
    card_hash: '',
    card_info: DEFAULT_CARD_INFO
}]

export const DEFAULT_USER_INFO: User = {
    active: -1,
    user_email: "jeff@roomerator.com",
    user_first_name: "jeff",
    user_last_name:  "garand",
    user_opt_in: -1,
    user_wallet: DEFAULT_USER_WALLET_INFO,
    wallet_id: false,
}

export const DEFAULT_GOOGLE_LOCATION: GoogleLocation = {
    location: '',
}

export const DEFAULT_BILLING_INFO: Billing[] = [{
    bill_name: '',
    bill_email: '',    
    active: -1,
    bill_addr: '',
    bill_addr_2: "",
    bill_company: "",
    bill_phone_alt: "",
    bill_phone_num: -1,
    bill_postal_code: "",
    default: -1,
    deleted: -1,
    local_user_billing_id: -1,
    billing_location: DEFAULT_GOOGLE_LOCATION,
}]

export const DEFAULT_PROJECT_INFO: Project[] = [{
    active: -1,
    default: -1,
    deleted: -1,
    linked_card: "",
    local_project_id: -1,
    project_addr: "",
    project_addr_2: "",
    project_name: "",
    project_note: "",
    project_phone_num: "" ,
    project_postal_code: "",
}]


export const DEFAULT_TRANSACTIONS_INFO: Transactions[] = [{
    confirm_num: "",
    delivery_fee: -1,
    gst: -1,
    insertion_date: "",
    pst: 0,
    sub_total: -1,
    date: new Date
}]


export const DEFAULT_CART_LIST: CartList = {
    cart_list_info: [],
    cart_list_get_count: -1
}

export const DEFAULT_TOOLBOX_INFO: Toolbox = {
    cart_list: DEFAULT_CART_LIST,
    favorites: []
}

export const DEFAULT_ACCOUNT_INFO: AccountInfo = {
    project_count: -1,
    secure: 1,
    billing: DEFAULT_BILLING_INFO,
    project: DEFAULT_PROJECT_INFO,
    toolbox: DEFAULT_TOOLBOX_INFO,
    transactions: DEFAULT_TRANSACTIONS_INFO,
    user: DEFAULT_USER_INFO,
    user_billing_count: -1,
    isAdmin: true
};

// MARK - TOOLS SETTINGS

export class AccountSettings {
    hotbar = new HotbarHeader;
}

