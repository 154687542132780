import { Injectable } from '@angular/core';
import { ServerService } from 'src/app/general-services/server.service';

import { CartInfo, CartStats } from '../classes/cart';

import { BehaviorSubject, Subject } from 'rxjs'
import { Item } from '../classes/item';
import { ViewService } from 'src/app/general-services/view/view.service';
import { StorageService } from 'src/app/general-services/storage.service';
import { InputWindowService } from 'src/app/modal/input-window/input-window.service';
import { HttpClient } from '@angular/common/http';


const DEFAULT_CART: CartInfo = {
  items: [],
  cartCompare: {
      home_depot: 0,
      rona: 0,
      tamarack: 0,
      lowes: 0
  },
  cartFee: {
      subtotal: 0,
      delivery_fee: 0,
      tax: 0,
      total: 0,
      percentage: 0,
      cart_count: 0
  }
};

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(
    private storageServie: StorageService,
    private serverService : ServerService,
    private viewService: ViewService,
    private inputWindowService: InputWindowService,
    private http: HttpClient
  ) { }

    percentageCheck = false;
    // Delivery ONLY
    isODelivery = false;
    // Stat Save
    currentStat = 0;
    // private cartUrl = '/app_forward/service_request.php';
    lsCart = 'cart';
    lsClientCost = 'checkoutCost';
    //checkoutCost!: CheckoutCost;
    hasStorage = false;
    searchOpen = false;
    locationOpen = false;
    fDelivery = false;
    cOpen = false;
    showTop = false;
    // Modal Variable
    modalType = "IF OUND YA SUCKA";
    // Show Variables
    showActionBar = true;
    // Action Bar
    actionBarMsg = '';
    percentageBar = 0;
    cartItems: Item[] = [];
    cart = new CartInfo

    passedData: any;

    subjectCart = new BehaviorSubject(DEFAULT_CART);
    cart$ = this.subjectCart.asObservable();


    handleError(error: any) {
    //console.error('An error occured', error);
    return Promise.reject(error.message || error);
    }

    nextCart(newCart: CartInfo, from: string) {
    //console.log(from, ">><><><><>>><><><><><>", newCart, newCart.cartFee);
    this.setLocalStoredCart(newCart.items);
    this.subjectCart.next(newCart);
    }

    currentCart() { return this.subjectCart.value; }


    // Communication Services

    // MARK: - Set's & Get's

    // MARK: Calc's
    calcSubtotal() {
    let sum = 0;
    for (const item in this.cartItems) {
        const itemQuantity = this.cartItems[item].item_quantity;
        const itemPrice = this.cartItems[item].item_price;
        let totalItemPrice = 0;
        if (itemQuantity && itemPrice) {
            totalItemPrice = itemQuantity * itemPrice;
        }
        sum += totalItemPrice;
    }
    return (((sum * 100) | 0) / 100);
    }
    /*
    calHDSubtotal(): number {
        let sum = 0
        for (const item in this.cartItems) {
        {
                    const stuff = this.cartItems[item].item_quantity * this.cartItems[item].hd_price;
                    const foo = this.cartItems[item].item_quantity * this.cartItems[item].item_price;
                    if (stuff === 0) { sum += foo * 1000} else {sum += stuff}
                }

            }
        }
        sum = ((( Number(sum) * 100) | 0) / 100) / 1000

        return sum ;
    }
    */
    calcTaxes(subTotal: number, deliveryFee: number) {
        // ADD: post for local taxes in the future
        let taxes = 0;
        taxes = (subTotal + deliveryFee) * 0.13;
        taxes = Math.ceil((taxes * 100));
        taxes = taxes / 100;
        return taxes;
        /*
        this.checkoutCost = this.getClientCost()
        if (this.checkoutCost === undefined || this.checkoutCost === null || !this.checkoutCost){
            this.checkoutCost = this.getLocalClientCost();
        }
        if(this.checkoutCost &&
            this.checkoutCost.tax &&
            this.checkoutCost.tax.gst &&
            this.checkoutCost.tax.pst){
            const gst = (subTotal * 100 + deliveryFee * 100 ) * this.checkoutCost.tax.gst;
            const pst = (subTotal * 100 + deliveryFee * 100 ) * this.checkoutCost.tax.pst;

            let total = 0
            if(this.checkoutCost.tax.divisor){ total = (gst + pst) / this.checkoutCost.tax.divisor;}

            return Math.round(total) / 100;

        }*/
        return 0;
    }

    calcDelivery(percentage: number) {
        //console.log("PERCENTAGE CHECK", percentage)
        if (percentage === 0) {
            return 0;
        }
        // if (this.this.cartItemsPercentageTracker <= 2) {return 10};
        // if (percentage > 5 && percentage <= 12.5) { return 25}
        // if (percentage > 2 && percentage <= 7) { return 20}
        if (percentage <= 9) {
            return 29;
        }
        // if (percentage > 20 && percentage <= 27.5) { return 35}
        if (percentage > 9 && percentage <= 25) {
            return 39;
        }
        // if (percentage > 35 && percentage <= 42.5) { return 45}
        if (percentage > 25 && percentage <= 50) {
            return 49;
        }
        // if (percentage > 50 && percentage <= 57.5) { return 55}
        if (percentage > 50 && percentage <= 100) {
            return 59;
        }
        // if (percentage > 65 && percentage <= 72.5) { return 65}
        // if (percentage > 65 && percentage <= 80) { return 69}
        // if (percentage > 80 && percentage <= 87.5) { return 75}
        // if (percentage > 80 && percentage <= 100) { return 79}
        // let percentageMultiplier = Math.ceil(percentage / 100);
        const variable = 59 * 1;
        return variable;
    }

    // MARK: - SET's
    setLocalStoredCart(localCart: any) {
        this.storageServie.setStorage(localCart, this.lsCart);
    }
    setCartItems(items: Item[]) {
        this.cartItems = items;
    }

    clearCart(from:string) {
        console.log(from, 'itemService, cartClear');
        const clearCart = DEFAULT_CART;
        const blankItems: any[] = [];
        clearCart.items = blankItems;
        clearCart.cartCompare.home_depot = 0;
        clearCart.cartCompare.lowes = 0;
        clearCart.cartCompare.rona = 0;
        clearCart.cartCompare.tamarack = 0;
        clearCart.cartFee.cart_count = 0;
        clearCart.cartFee.delivery_fee = 0;
        clearCart.cartFee.percentage = 0;
        clearCart.cartFee.subtotal = 0;
        clearCart.cartFee.tax = 0;
        clearCart.cartFee.total = 0;
        this.setLocalStoreCart(blankItems);
        this.nextCart(clearCart, "itemService, clearCart");
    }

    modifyCart(modifyType:string, item: Item, passed: any) {
        let x = -1;
        if (typeof passed === 'string') {
            x = parseInt(passed);
        }
        else if (typeof passed === 'number') {
            x = passed;
        }
        if (x > -1) {
            //const currentRoute = window.location.pathname;
            //console.log(currentRoute, "Route check")
            //const currentCart = this.getCurrentCart();
            this.cartItems = this.getCurrentCart().items;
            const index = this.cartItems.map(l => l.item_number).indexOf(item.item_number);
            //  ADD ITEMS
            if (modifyType === 'add') {
                if (index !== -1) {
                    if (this.cartItems &&
                        this.cartItems[index] &&
                        this.cartItems[index].item_quantity) {
                        let itemQuantity = (this.cartItems[index].item_quantity);
                        if (itemQuantity) {
                            itemQuantity = itemQuantity + x;
                            this.cartItems[index].item_quantity = itemQuantity;
                        }
                    }
                }
                else {
                    item.item_quantity = Math.abs(x);
                    this.cartItems.unshift(item);
                    if (this.viewService.currentView().isMobile) {
                        this.viewService.changeView('sidecart', true, null, 'item.service:modifyCart');
                    }
                }
            }
            // REMOVE  ITEMS
            if (modifyType === 'remove') {
                const cartQuantity = this.cartItems[index].item_quantity;
                const xOffset = 1 + x;
                if (cartQuantity) {
                    if (cartQuantity >= xOffset) {
                        this.cartItems[index].item_quantity = cartQuantity - x;
                    }
                    else if (cartQuantity < xOffset) {
                        this.cartItems.splice(index, 1);
                        if (this.viewService.currentView().isMobile) {
                            if (this.cartItems.length <= 0) {
                                this.viewService.changeView('sidecart', false, null, 'item.service:modifyCart');
                            }
                        }
                    }
                }
            }
        }
        // RESET Cart
        /* STUFF THAT MIGHT BE NEEDED
        this.setLocalStoreCart(cart);
        this.setCartVolume(item, -x)
        if (this.cartPercentageTracker >= 4) { this.setDelivery(false)
        } else { this.setDelivery(true)}
        */
        /* Cart to be made up of 3 parts
            Cart Items
            Cart Fees
            Cart Compare
        */
        const newCartInfo = this.calculateCartInfo(this.cartItems);
        // this._cart[0].compareInfo = this.updateCompareInfo() to be add at a later date
        this.nextCart(newCartInfo, ("itemService: ModifyCart"));
    }

    calculateCartInfo(items: Item[]) {
        // Init Cart Info
        this.cartItems = items;
        const newCartInfo = new CartInfo;
        // Initiate Volume Check
        const percentage = this.setCartVolume();
        const item_quantity = [];
        for (let i = 0; i < items.length; i++) {
            item_quantity.push(items[i].item_quantity);
        }
        // Calculate Cart Totals
        const estDeliveryFee = this.calcDelivery(percentage);
        const subTotal = this.calcSubtotal();
        const estTax = this.calcTaxes(subTotal, estDeliveryFee);
        let total = subTotal + estDeliveryFee + estTax;
        // const hdTotal = this.calHDSubtotal();
        // Set Cart Info Variables
        newCartInfo.cartFee.cart_count = this.cartItems.length;
        newCartInfo.cartFee.percentage = percentage;
        newCartInfo.cartFee.delivery_fee = estDeliveryFee;
        newCartInfo.cartFee.subtotal = subTotal;
        newCartInfo.cartFee.tax = estTax;
        newCartInfo.items = items;
        total = (Math.ceil(total * 100)) / 100;
        newCartInfo.cartFee.total = total;
        //newCartInfo.compareInfo.home_depot = hdTotal;
        this.percentageBar = percentage;
        return newCartInfo;
    }
    setODelivery(isODelivery: boolean) {
        this.isODelivery = isODelivery;
    }
    setDelivery(fDelivery: boolean) {
        this.fDelivery = fDelivery;
    }
    setCActive(cOpen: boolean) {
        this.cOpen = cOpen;
    }
    setLocalStoreCart(cart: any) {
        //console.log('Checking!!@#',cart)
        this.storageServie.setStorage(cart, this.lsCart);
    }

    setSearchOpen(sOpen: boolean) { this.searchOpen = sOpen; }
    setLocationOpen(lOpen: boolean) { this.locationOpen = lOpen; }
    setStorage(hasStorage: boolean) { this.hasStorage = hasStorage;}

    setZeroVolume() {
        this.compare(0, 0);
    }

    setCartVolume( /*item: Item, qty: number*/) {
        this.percentageBar = 0;
        let totalVolume = 0;
        let totalWeight = 0;
        const cartCount = this.cartItems.length;
        if (cartCount !== 0) {
            // Init Volumes and Weights
            let volumes = 0;
            let weights = 0;
            // Cart Check for mismatch
            const cartList = this.cartItems;
            for (let i = 0; i < cartList.length; i++) {
                const cartItemWeight = cartList[i].item_weight;
                const cartItemVolume = cartList[i].item_volume;
                const cartItemQuantity = cartList[i].item_quantity;
                // VOLUME
                if (cartItemVolume && cartItemQuantity) {
                    if (cartItemVolume > 0) {
                        volumes = cartItemVolume * cartItemQuantity;
                        totalVolume = totalVolume + volumes;
                    }
                }
                // WIEGHT
                if (cartItemWeight && cartItemQuantity) {
                    if (cartItemWeight > 0) {
                        weights = cartItemWeight * cartItemQuantity;
                        totalWeight = totalWeight + weights;
                    }
                }
            }
        }
        const largerPercentage = this.compare(totalVolume, totalWeight);
        return largerPercentage;
    }
    compare(v: number, w: number) {
        let maxVolume = 4147200000000;
        let maxWeight = 30000;
        const pV = v / maxVolume;
        const pW = w / maxWeight;
        let percentage = 0;
        if (pV > pW) {
            percentage = pV;
        }
        else {
            percentage = pW;
        }
        if (percentage > 0 && percentage < 1) {
            percentage = 1;
        }
        return Math.round(percentage);
    }
    setShowTop(show: boolean) {
        this.showTop = show;
    }
    setCurrentStat(stat: number) {
        this.currentStat = stat;
    }
    // MARK: - GET's
    getCurrentCart() {
        const curEnt = this.subjectCart.value;
        return curEnt;
    }
    getODelivery() {
        return this.isODelivery;
    }
    calculateWeightVolume(cart: CartInfo) {
        const newCartStats = new CartStats;
        const maxVolume = 4147200;
        const maxWeight = 3000;
        let weight = 0;
        let volume = 0;
        console.log(cart.items, "What is happening!!!!!!");
        cart.items.forEach((item: Item) => {
            weight = weight + (item.item_weight * item.item_quantity);
            volume = volume + (item.item_volume * item.item_quantity);
        });
        weight = (weight / Math.pow(10, 3));
        volume = (volume / Math.pow(10, 9));
        const vPer = Math.ceil((volume / maxVolume) * 100);
        const wPer = Math.ceil((weight / maxWeight) * 100);
        newCartStats.weight = weight;
        newCartStats.volume = volume;
        newCartStats.vPer = vPer;
        newCartStats.wPer = wPer;
        return newCartStats;
    }

    getLocalStoredCart() {
        let cart = [];
        cart = this.storageServie.getStorage(this.lsCart);
        return cart;
    }
    getCartInfo(): CartInfo {
        return this.cart;
        }
    //updateCart(){ return this.getLocalCart('updateCart')}
    /*getClientCost():CheckoutCost {
        return this.storageServie.getStorage(this.lsClientCost);
    }

    getLocalClientCost(): CheckoutCost {
        const temp = {tax:
            { gst: 100, pst: 100, divisor: 100},
            max_weight: 1500000, max_volume: 122688000, delivery_fee: 5000000, service_level: 2, date: '2015-10-21'
        }

        const checkoutCost = this.storageServie.getStorage(this.lsClientCost);
        if (!checkoutCost) {
            return temp
        }
        return checkoutCost;
    }*/
    /* getDelivery(): boolean {
        // this.setCartVolume();
        if (this.cartPercentageTracker >= 5) { this.setDelivery(false)}
        return this.fDelivery;
    }*/
    getSearchOpen() { return this.searchOpen; }
    getLocationOpen() {
        return this.locationOpen;
    }
    getStorageCheck() {
        return this.hasStorage;
    }
    getServiceLevel(response: number) {
        // do this in the template with css highlighting the 3 hr and same day?
        let text;
        // Access Stored Cost
        let storedCost = this.storageServie.getStorage('checkoutCost');
        // Null Check on Stored cost
        if (!storedCost) {
            // Create Todays Date
            const newDate = new Date();
            const today = newDate.getFullYear() + '-' + newDate.getMonth() + '-' + newDate.getDate();
            //console.log(today);
            const delivery_fee = [10000, 10000, 59000];
            const max_volume = [414720000, 4907520];
            const max_weight = [3000000, 60000];
            const service_level = 0;
            const tax = { gst: 13, pst: 0, divisor: 100 };
            storedCost = {
                date: today,
                delivery_fee: delivery_fee,
                max_volume: max_volume,
                max_weight: max_weight,
                service_level: service_level,
                tax: tax
            };
        }
        // Set Service Level
        storedCost.service_level = response;
        // Set Clien Cost in Local Storage
        this.storageServie.setStorage(storedCost, 'checkoutCost');
        // Set Items Service Level
        switch (response) {
            case 0:
                text = '3 Hour Delivery';
                break;
            case 1:
                text = 'Same Day Delivery';
                break;
            case 2:
                text = 'Tomorrow Morning';
                break;
            case 3:
                text = 'Monday Morning';
                break;
            default:
                text = 'Next Day Delivery';
        }
        return text;
    }
    getShowTop() {
        return this.showTop;
    }
    getCurrentStat() {
        return this.currentStat;
    }
    
    // MARK: - FUNCTION's
    savedCart(injectRef: string) {
        this.inputWindowService.changeModalWindowInfo('loading', '', true);
        const testCart = this.subjectCart.value;
        //console.log('TESTING=============================', testCart)
        const post = { action_id: 'cart_get', cart: { ref: injectRef } };
        const serverRequest = this.serverService.postRequest(post, 'account_endpoint', 'json', 'savedCart');
        if (serverRequest) {
            serverRequest.subscribe((cart: any) => {
                console.log('Inject New Cart', cart);
                const checkCartInfo = cart['cart'];
                if (checkCartInfo) {
                    this.injectSavedCart(checkCartInfo);
                }
                // STOP LOADING
                this.inputWindowService.changeModalWindowInfo('loading', '', false);
            });
        }
    }

    injectSavedCart(cartListInfo: any) {
        const itemCheck = cartListInfo.list;
        if (itemCheck) {
            const injectNewCart = this.calculateCartInfo(itemCheck);
            this.nextCart(injectNewCart, "injectSaved Cart");
        }
    }



    checkState(itemNumber: number) {
        const index = this.cartItems.map(l => l.item_number).indexOf(itemNumber);
        if (index === -1) {
            return index;
        }
        const itemQuantity = this.cartItems[index].item_quantity;
        if (itemQuantity) {
            return itemQuantity;
        }
        return -1;
    }

    /*
    addlocation(location: Location): void { // Save Location Once Selected
        const newLocation = Location;
    }*/
    // Clear Actions
    generalCleanup(items: Item[]) {
        items.forEach(currentItem => {
            this.setCartVolume( /*currentItem, -currentItem.item_quantity*/);
        });
    }

    // MARK: - RESPONSES
    getCatalog() {
        return this.serverService.getRequest(99, '');
    }

    getSales() {
        const serverInfo = this.serverService.getRequest(94, '');
        return serverInfo;
    }

    requestCategory(sub_id: number) {
        const options = '&category=' + sub_id;
        return this.serverService.getRequest(99, options);
    }

    findItem(item_number: number, catalog: Item[]) {
        return catalog.find(chequeItem => chequeItem.item_number === item_number);
    }

    getItem(item_number: number, sub_id: number) {
        const itemSubject = new Subject<Item>();
        const items$ = itemSubject.asObservable();

        let item: Item = {
            info: -1,
            item_name: 'error',
            item_number: item_number,
            item_image: '',
            item_length: -1,
            item_volume: -1,
            item_weight: -1,
            item_grade: 'error',
            item_dimension: 'error',
            item_units: '0',
            item_price: -1,
            item_quantity: -1,
            top_price: -1,
            top_retailer: "",
            sub_id: -1,
            top_id: -1,
            top_sub_id: -1
        };

        // Get Category and Catalog
        const category = this.storageServie.getStorage('category');
        let catalog = [];
        //console.log(category)
        if (category) {
            catalog = category['catalog'];
            const foundItem = this.findItem(item_number, catalog);
            if (foundItem) {
                item = foundItem;
            }
        }
        else {/*
            this.requestCategory(sub_id).subscribe((catalog: any) => {
                if (catalog) {
                    catalog = catalog['catalog'];
                }
                const foundItem = this.findItem(item_number, catalog);
                if (foundItem) {
                    itemSubject.next(foundItem);
                }
            });*/
        }
        return items$;
    }

    getCart() {
        return Promise.resolve(this.getLocalStoredCart());
    }
}
