

<!-- Loading Container -->
<div *ngIf="routeInfo.view.isLoading">
    <div class="" style="background: transparent;width: 100%; height: 100%;">
        Loading container
    </div>
</div>

<div *ngIf="!routeInfo.view.showRoutePage">Failure to Launch, may the Trello be with ya...</div>

<h1 class="mt-4"> ROUTeAraTOR!</h1>


<div class="tool-container"
    [ngClass]="{'push-left': routeInfo.view.showTitgSidebar,
                'push-right': !routeInfo.view.showTitgSidebar}">
               
   <!-- MARK - MODAL COMMIT SCREEN-->
    <div *ngIf="routeInfo.view.showBackground"  >
        <div 
            class="route-background"    
            [ngStyle]="{background: routeInfo.view.showInputbox? '': '#000000a3'}"          
            (click)="dismissModalContainers();"

            (contextMenu)="dismissModalContainers()">
                <!--div *ngIf="routeInfo.view.show"></div-->
            <app-inputbox 
                *ngIf="routeInfo.view.showInputbox" 
                (keyPressed)="keyPressedInputbox($event)"  
                (validData)="outputValue = $event"
                [inputSetting]="inputSettingGeneral"
                [inputValue]="inputValue">
            </app-inputbox>
            <app-tool-route-options *ngIf="routeInfo.view.showOptions"></app-tool-route-options>
            <app-trello-card *ngIf="routeInfo.view.trelloView.showCard"></app-trello-card>
            <app-tool-route-email *ngIf="routeInfo.view.showEmailOrder"></app-tool-route-email>
        
        </div>
        
    
    
    </div>
    <!-- Show Route page-->
    <div *ngIf="routeInfo.view.showRoutePage">
    <!-- MARK - ROUTE VIEW: Show Boards-->
        <div *ngIf="routeInfo.view.trelloView.showBoards">
            <div *ngIf="true">
                <h2 class="route-minor-header" (click)="setMinorHeader(0);getTrelloBoards()">
                    Available Trello Boards
                    <span >
                        <img 
                        class="route-minor-img" 
                        src="assets\images\chevron_right.svg" 
                        alt=""
                        [ngClass]="[routeInfo.view.routeViewObjects.minorHeaders[0] ? 'rotate-cw-90':'rotate-origin']">
                    </span>
                </h2>
                <div 
                    *ngIf="routeInfo.view.routeViewObjects.minorHeaders[0]" 
                    class="trello-board-container"
                    [ngStyle]="{'z-index': routeInfo.view.showTitgSidebar ? '': 1}">
                    <div 
                        *ngFor="let board of routeInfo.boards; let index = index" 
                        class="trello-boards"
                        >
                        <ng-container
                            [ngTemplateOutlet]="templateTrelloBoard"
                            [ngTemplateOutletContext]="{board: board, index: index}">
                        </ng-container>
                    </div>
                </div>    
            </div>
        </div>

<!-- MARK - ROUTE PLANNER-->
        
        <h2 class="mt-5" >Driver Setup Program</h2>
        <div class="flex-row" cdkDropListGroup>

<!-- MARK - CURRENT DRIVERs -->
            <div style="overflow: hidden; flex: 1;">    
    <!-- MARK - Driver Selection-->        
                <h2 class="route-minor-header" (click)="setMinorHeader(2)">
                    Available Drivers
                    <span >
                        <img 
                        class="route-minor-img" 
                        src="assets\images\chevron_right.svg" 
                        alt=""
                        [ngClass]="[routeInfo.view.routeViewObjects.minorHeaders[2] ? 'rotate-cw-90':'rotate-origin']">
                    </span>
                </h2>    
                <div class="flex-row">
                    <div class="route-drivers-container">
                        <div class="trello-board-container" *ngIf="routeInfo.view.routeViewObjects.minorHeaders[2]">
                            <div class="route-information-text">Click to on drivers to begin the routing process!!!</div>
                            <div class="flex-row" style="overflow: auto;">
                                <div *ngFor="let driver of routeInfo.availableDrivers; let index = index" 
                                    class="route-driver-container"
                                    (click)="generateDriversRoutes(driver)"
                                    [ngClass]="{'route-driver-container-selected': setupDriverSelected[index]}">                                  
                                    <ng-container
                                        [ngTemplateOutlet]="selectableDriverBoard"
                                        [ngTemplateOutletContext]="{driver: driver, index: index}">
                                    </ng-container>     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
    <!-- MARK - Driver Boards -->
                <div *ngIf="routeInfo.activeDrivers && routeInfo.activeDrivers.length && routeInfo.activeDrivers.length > 0" class="flex-row">
                    <div class="route-drivers-container">
                        <h3 class="route-minor-header" (click)="setMinorHeader(4)">
                            Active Boards
                            <span >
                                <img 
                                class="route-minor-img" 
                                src="assets\images\chevron_right.svg" 
                                alt=""
                                [ngClass]="[routeInfo.view.routeViewObjects.minorHeaders[4] ? 'rotate-cw-90':'rotate-origin']">
                            </span>
                        </h3>
                        <div  *ngIf="routeInfo.view.routeViewObjects.minorHeaders[4]">
                            <div 
                                class="route-information-text mt-3"
                                [ngStyle]="{'z-index': !routeInfo.view.showTitgSidebar ? '1': ''}">
                                <div style="width: 90%;"> You can drag and drop route into the setup drivers</div>
                               
                                <div class="btn-group">
                                    
                                    <button 
                                        class="float-right btn btn-primary" 
                                        (click)="setMinifyDriver(-1)"
                                        type="button"
                                        tabindex="-1"
                                        (keydown.enter)="keyPressedEnter('suppress')">
                                        Mini all
                                    </button>
                                    <button
                                        class="btn btn-primary ml-2"
                                        type="button"
                                        tabindex="-1"
                                        (keydown.enter)="keyPressedEnter('suppress')"
                                        (click)="initRefresh(null, 'titg')" 
                                        (keydown.enter)="keyPressedEnter('suppress')">
                                        Refresh
                                    </button>
                                </div>
                            </div>
                            <div class="route-driver-board">
                                <div 
                                    class="container" 
                                    style="
                                        overflow-x: auto;
                                        overflow-y: hidden;
                                        box-shadow: #6d8091  1px 1px 6px 0px ;"> 
                                    <div 
                                    *ngFor="let driver of routeInfo.activeDrivers; let indexDriver = index; first as isFirst" 
                                    class="route-driver-card-container container"
                                    [ngClass]="{'route-driver-container-mini': routeInfo.trackerMini[indexDriver]}"
                                    [ngStyle]="{'z-index': routeInfo.view.showTitgSidebar ? '': 2}"
                                    tabindex="0"
                                    
                                    #activeDriveItems

                                    ><!--(keydown.c)="archiveCard(true,driver.routeCard.id, 'archiveRoutec')"-->
                                    
    <!-- MARK: Options - Options Btn's -->
                                   
                                    <ng-container
                                        [ngTemplateOutlet]="templateOptionsRouteDeliveries"
                                        [ngTemplateOutletContext]="{driver: driver, typeIndex: 1, indexDriver: indexDriver}">
                                    </ng-container>
                                
                                    
<!-- MARK: Route || Card container  -->                                        
                                    
<!-- MARK - DRIVER - ITEMS -->                                            
                                    <div 
                                        class="route-item-list-container"  
                                        *ngIf="showItemOrCard[1][indexDriver] && !routeInfo.trackerMini[indexDriver]" 
                                        #dragItemDriver = "cdkDropList"

                                        id="dragItemDriver"
                                        cdkDropList

                                        [cdkDropListData]="driver.dragItems"
                                        [cdkDropListSortPredicate]="canDropActiveItem"
                                        
                                        (cdkDropListDropped)="onDrop($event, 'moveDrivertoDriver')">
<!-- Driver Drag Items-->
                                        <div 
                                            *ngFor="let dragItem of driver.dragItems; let indexDragItem = index;first as isFirst" 
                                         
                                            #activeItem

                                            style="padding: 0;"
                                            tabindex="0"
                                            cdkDrag

                                            (contextmenu)="
                                                showContextMenu(
                                                    dragItem,
                                                    {   indexTitgCardOrDriver:indexDriver,
                                                        indexTitgChecklistOrRouteDragItem: indexDragItem,
                                                        indexDragItem: -1
                                                    },
                                                    activeItem.getBoundingClientRect(),'driver:route:checkItem', 'driver:route:checkItem')"

                                            [cdkDragData]="dragItem"
                                            [cdkDragDisabled]="dragItem.state === 'complete'|| dragItem.action.includes('MAINTENANCE') || dragItem.disableDrag" 
                                            [ngClass]="{
                                                'route-checkitem': (driver.driverView.showCompleteRouteItems.show || 
                                                (!driver.driverView.showCompleteRouteItems.show &&
                                                dragItem.state !== 'complete'))}"
                                            [ngStyle]="{'cursor': dragItem.pos !== 999999999999 ? 'not-allowed !important':''}">     
                                            
                                            <div 
                                                *ngIf="
                                                    driver.driverView.showCompleteRouteItems.show || 
                                                    (!driver.driverView.showCompleteRouteItems.show &&
                                                    dragItem.state !== 'complete')"
                                                class="flex-row" 
                                                style="align-items: center;">
                                            
                                                <!-- D Type Information (General Drag Template)-->
                                                <div class="route-driver-drag-item-container"
                                                    [ngClass]="{'text-lt':dragItem.state === 'complete'}">
                                                    
                                                    <ng-container 
                                                        [ngTemplateOutlet]="templateDragItem"
                                                        [ngTemplateOutletContext]="{
                                                            dragItem: dragItem, 
                                                            dragItemDriverOrCard: driver,
                                                            routeIndexes: {
                                                                indexTitgCardOrDriver: indexDriver,
                                                                indexTitgChecklistOrRouteDragItem:indexDragItem,
                                                                indexDragItem: indexDragItem
                                                            }, 
                                                            from:'driver:checkItem'}">
                                                    </ng-container>                                                    
                                                </div> 
                                            </div>                            
                                        </div>
                                        <div 
                                            *ngIf=" 
                                                driver.dragItems.length === 0 ||
                                                (!driver.driverView.showCompleteRouteItems.show && 
                                                driver.driverView.showCompleteRouteItems.count === driver.dragItems.length) "
                                            style="padding: 15px;
                                                word-wrap: break-word;
                                                white-space: normal;
                                                text-align: center;">
                                            <h2>Driver Route Complete, time for updates or the pool!!</h2>
                                        </div>
<!-- MARK - SHOW ADD ROUTE ITEM-->
                                        <div
                                            #containerRouteItem
                                            class="route-custom-item-container" 
                                            style="cursor: pointer !important;"
                                            (click)="    
                                                showInputContainer(
                                                    containerRouteItem.getBoundingClientRect(),
                                                    driver,
                                                    null,
                                                    null,{ 
                                                        indexTitgCardOrDriver: indexDriver,
                                                        indexTitgChecklistOrRouteDragItem: -1,
                                                        indexDragItem: -1}, 
                                                    'driver:dragItem')">
                                            <div  class="route-custom-checkitem-btn">
                                                + Add a route item
                                            </div>
                                        </div>
                                        
                                    </div>
<!-- MARK - DRIVER - CARDS -->
                                    <div 
                                        *ngIf="!showItemOrCard[1][indexDriver] && !routeInfo.trackerMini[indexDriver]"
                                        #activeDragCard = "cdkDropList"

                                        id="activeDragCard"
                                        cdkDropList

                                        [cdkDropListData]="driver.routeCards"
                                        [cdkDropListConnectedTo]="['activeDragCard']"
                                        [cdkDropListEnterPredicate]="onDriverCardPredicate"
                                        
                                        (cdkDropListDropped)="onDrop($event, 'moveDrivertoDriver')">
                                        <div 
                                            *ngFor="let card of driver.routeCards; let indexDragItem = index;first as isFirst" 
                                            #activeCardItem
                                            class="route-checkitem"
                                            cdkDrag
                                            tabindex="0"

                                            (contextmenu)="showContextMenu(
                                                card,{ 
                                                    indexTitgCardOrDriver: indexDriver,
                                                    indexTitgChecklistOrRouteDragItem: indexDragItem,
                                                    indexDragItem: -1},
                                                activeCardItem.getBoundingClientRect(), 'active_driver_card',  'driver:card')"                        
                                            [cdkDragData]="card"
                                            [cdkDragDisabled]="card.disableDrag" >     
                                        
                                            <!--(keydown.c)="archiveCard(true,card.id, 'activeDriverBoard')"-->
                                            <div class="flex-row" style="align-items: center;">
                                                <input class="m-3" 
                                                    type="checkbox" 
                                                    [checked]="card.dueComplete"
                                                    (change)="updateDragItem(
                                                        'put:card_due_complete', 
                                                        driver, 
                                                        card, 
                                                        null,{ 
                                                            indexTitgCardOrDriver: indexDriver,
                                                            indexTitgChecklistOrRouteDragItem: indexDragItem,
                                                            indexDragItem: -1}, 
                                                        'driver:card')">   
                                                <div>{{card.name}}</div>
                                            </div>
                                            
                                            
                                            <!-- MARK - SHOW Options-->
                                            
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
<!-- MARK - Utilities (Pictures , etc...)-->
                <div class="flex-row">
                    <div class="route-drivers-container">
                        <h3 class="route-minor-header" (click)="setMinorHeader(5);getPictureBoard()">
                            Pictures
                            <span >
                                <img 
                                class="route-minor-img" 
                                src="assets\images\chevron_right.svg" 
                                alt=""
                                [ngClass]="[routeInfo.view.routeViewObjects.minorHeaders[5] ? 'rotate-cw-90':'rotate-origin']">
                            </span>
                        </h3>
                        <div  *ngIf="routeInfo.view.routeViewObjects.minorHeaders[5]" class="flex-row" style="overflow: auto;flex-wrap: wrap;">
                            <div 
                                *ngFor="let pictureCard of routeInfo.utilities.pictures; let i = index" 
                                style="position: relative;">
                                <div class="route-checkitem route-picture-card-container" 
                                    style="cursor: pointer !important; min-height: 160px;"
                                    (click)="getPictures(pictureCard, i)">   
                                    {{pictureCard.name}}                                  
                                </div>


                                <div *ngIf="routeInfo.view.showPictures">
                       
                                    <div *ngFor="let attachment of pictureCard.attachements" class="route-picture-container">
                                        <div class="container">
                                            <div>{{attachment.date}}</div> 
                                            <a 
                                                href="{{attachment.url}}"
                                                style="background-image: url('https://trello.com/1/cards/65b156bdfe1c1caa84885a5a/attachments/65b3b05ff40f0ad836eeb11d/previews/65b3b060f40f0ad836eeb381/download/image.jpg');"></a>           
                                        </div>
                                    </div>                 
                                </div>  
                            </div>

                            <div *ngIf="routeInfo.view.showPictures">
                                <div style="min-height: 200px;">

                                    <h3>Nothing to show for it</h3> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
<!-- MARK - Available Deliveries --> 
<!-- TITG-->
            <animation-right-left
                class="route-titg-animation-container"

                [isRightLeft]="routeInfo.view.showTitgSidebar" 
                [id]="'toolbar'"
                [ngStyle]="{'z-index': 
                    (routeInfo.view.showTitgSidebar 
                        ? '1':'')}">
                <div class="tool-route-mini-titg-container"
                    [ngStyle]="{'z-index': (routeInfo.view.showTitgSidebar ? '': -1)}">
                    <button
                        class="btn btn-naked tool-route-mini-titg-button" 
                          type="button"
                        tabindex="-1"
                        (click)="showTitgSidebar()"
                        (keydown.enter)="keyPressedEnter('suppress')"> 
                        <img src="assets/images/chevron_right.svg" 
                            alt=""
                            style="height: 22px;"
                            [ngClass]="{'rotate-cw-180': !routeInfo.view.showTitgSidebar,
                                        'rotate-origin': routeInfo.view.showTitgSidebar}">
                    </button>

                </div>
                <div class="route-titg-container" >                
<!-- TITG: Board Select -->
                    <h3>TitG
                        <button 
                            class="btn btn-naked" 
                            style="margin: 10px;" 
                            type="button"
                            tabindex="-1"
                            [ngClass]="{'route-selected-option': routeInfo.view.showBoard === 0}"
                            (click)="initRefresh(0, 'titg')" 
                            (keydown.enter)="keyPressedEnter('suppress')">
                            Live
                        </button>
                        ||
                        <button 
                           
                            class="btn btn-naked" 
                            style="margin: 10px;"
                            type="button"
                            tabindex="-1" 
                            [ngClass]="{'route-selected-option': routeInfo.view.showBoard === 1}"
                            (click)="initRefresh( 1, 'teams')"
                            (keydown.enter)="keyPressedEnter('suppress')">
                            Team Board
                        </button>
                        ||
                        <button 
                            class="btn btn-naked" 
                            style="margin: 10px;" 
                            type="button"
                            tabindex="-1"

                            [ngClass]="{'route-selected-option': routeInfo.view.showBoard === 2}"

                            (click)="initRefresh(2, 'testing')" 
                            (keydown.enter)="keyPressedEnter('suppress')">
                            Testing
                        </button>
                    </h3>
                    <div  class="route-information-text">You can drag and drop the following checklists items into the drivers routes!!</div>
<!-- TITG: Card container -->
                    <div class="route-titg-sub-container" >
                        <div class="route-titg-cards-container"  >
                            <div  
                                class="route-titg-cards-list"
                                id="titgDragCard">
                                <div 
                                    *ngFor="
                                        let dragCard of routeInfo.titgCardCatalog; 
                                        let indexCard = index" 
                                    class="route-titg-checklists-items"
                                    #idDragCard>  
<!-- TITG: Button group for card actions -->
                                    <div 
                                        class="btn-group-vertical float-right" 
                                        style="
                                            position: absolute;

                                            top: -15px;
                                            right: -10px;

                                            padding: 5px;
                                            
                                            border-radius: 7px;
                                            border:  1px #c9c9c9 solid;  
                                            background-color: white;" >
    <!-- Move ALL DRAGITEMS to active or prototype driver (Not where the move happens) -->
                                       <button 
                                            #btnMove
                                            class="route-options-btn btn-naked" 
                                            style="background-image: url(assets/images/send_to_route.svg);"
                                            type="button"
                                            tabindex="-1"

                                            (click)="showContextMenu(
                                                dragCard, 
                                                { 
                                                    indexTitgCardOrDriver: indexCard,
                                                    indexTitgChecklistOrRouteDragItem: -1,
                                                    indexDragItem: -1
                                                }, btnMove.getBoundingClientRect(), 'titg:card:move_all', 'titg:card:checkItem')"
                                            (keydown.enter)="keyPressedEnter('suppress')">                                      
                                        </button>
    <!-- Share link -->
                                        <button 
                                            #btnMove
                                            class="route-options-btn btn-naked" 
                                            type="button"
                                            tabindex="-1"
                                            style="background-image: url(assets/images/URL.svg);"

                                            (click)="copyUrl(dragCard.url)"
                                            (keydown.enter)="keyPressedEnter('suppress')">                                      
                                        </button>
    <!-- Card Information -->
                                       <button 
                                            class="route-options-btn btn-naked"
                                            style="background-image: url(assets/images/info_blue.svg);"
                                            type="button"
                                            tabindex="-1"

                                            (click)="showCardInfo(dragCard, 'tool-routes:titg:card')"
                                            (keydown.enter)="keyPressedEnter('suppress')">
                                        </button>
                                   </div>
<!-- TITG: Card header-->
                                    <h3 class="mb-1">{{dragCard.name}} ( {{dragCard.dragItems.length}} ) </h3>
                                    <div class="route-titg-date-container">
                                        <input                                                             
                                            type="checkbox" 
                                            [checked]="dragCard.dueComplete"
                                            (change)="updateDragItem(
                                                'put:card_due_complete', 
                                                null, dragCard, null,
                                                { 
                                                    indexTitgCardOrDriver: indexCard,
                                                    indexTitgChecklistOrRouteDragItem: -1,
                                                    indexDragItem: -1
                                                }, 
                                                'titg:card')">
                                        <div>{{dragCard.due}}</div>
                                    </div>      
    
                                    <app-trello-labels [labels]="dragCard.labels"></app-trello-labels>
    
    
                                    <h4 class="my-2" style="border-bottom: 1px solid black; padding: 10px 0px;" hidden>
                                        Checklist items
                                    </h4>
<!-- TITG: Drag items-->
                                    <div class="flex-row">
                                        <div       
                                            #titgDragItem="cdkDropList"   
    
                                            cdkDropList
                                            id="titgDragItem"
                                            class="col-12 float-left"
                                        
                                            [cdkDropListData]="dragCard.dragItems"
                                            [cdkDropListEnterPredicate]="onTitgPredicate"
                                            
                                            (cdkDropListDropped)="onDrop($event, 'moveTitgToDriver')">
 
                                            <div 
                                                class="route-checkitem container" 
                                                *ngFor="
                                                    let dragItemChecklist of dragCard.dragItems; 
                                                    let indexChecklist = index;
                                                    first as isFirst; "
                                                #titgItem
                                                cdkDrag
    
                                                (contextmenu)="showContextMenu(
                                                    dragItemChecklist,  
                                                    { 
                                                        indexTitgCardOrDriver: indexCard,
                                                        indexTitgChecklistOrRouteDragItem: indexChecklist,
                                                        indexDragItem: -1
                                                    },
                                                    titgItem.getBoundingClientRect(), 'titg:dragItem', 'titg:checklist')"                                             
    
                                                [cdkDragData]="dragItemChecklist"
                                                [cdkDragDisabled]="dragItemChecklist.disableDrag" 
                                                [style.margin-top.px]="isFirst? '35px' : '15px'">
                                                <div 
                                                    class="flex-row" 
                                                    style="align-items: center;"
                                                    (click)="
                                                        $event.stopPropagation();
                                                        activeDragItem = dragCard;activeDragItem = dragCard; 
                                                        showChecklist(
                                                            dragItemChecklist,{
                                                                indexTitgCardOrDriver:indexCard,
                                                                indexTitgChecklistOrRouteDragItem:indexChecklist,
                                                                indexDragItem: -1}, 
                                                            'titg:card');">
                                                    <div 
                                                        *ngIf="dragItemChecklist.name !== 'Missing drop LAbel!'" 
                                                        style="width: 370px; cursor: pointer;">
                                                        {{dragItemChecklist.name}}
                                                    </div>
                                                    
                                                    <!-- Missing drop label -->
                                                    <div *ngIf="dragItemChecklist.name === 'Missing drop LAbel!'" class="w-100">
                                                        {{dragItemChecklist.name}} 
                                                        <span class="float-right">
                                                            <button 
                                                                class="btn btn-danger"
                                                                type="button"
                                                                tabindex="-1"

                                                                (click)="$event.stopPropagation();setDropLabel(dragItemChecklist)"
                                                                (keydown.enter)="keyPressedEnter('suppress')">                                                                
                                                                Set Drop Label
                                                            </button>
                                                        </span>
                                                    </div>

                                                    <!-- Drop Drag Item-->
                                                    <div *ngIf="(dragItemChecklist.action.toLowerCase() === 'drop' || dragItemChecklist.action.toLowerCase() === 'carry') && !dragItemChecklist.idChecklist">
                                                        <span class="float-right">
                                                            <button 
                                                                class="btn btn-naked"
                                                                type="button"
                                                                tabindex="-1"
                                                                [disabled]="true"
                                                                (click)="$event.stopPropagation();setDropLabel(dragItemChecklist)"
                                                                (keydown.enter)="keyPressedEnter('suppress')">                                                                
                                                                Change drop 
                                                            </button>
                                                        </span>
                                                    </div>
                                                    
                                                    <!-- Show CheckItems in Checklist-->
                                                    <div 
                                                        *ngIf="indexChecklist < dragCard.dragItems.length -1 "
                                                        style="position: relative;z-index: 1;cursor: pointer;">
                                                        <img 
                                                            class="route-minor-img" 
                                                            src="assets\images\chevron_right_grey.svg" 
                                                            alt=""
                                                            style="float: none;"
                                                            [ngClass]="[dragCard.showChecklist[indexChecklist] ? 'rotate-cw-90':'rotate-origin']">
                                                    </div>
                                                </div>                                            
<!-- DragItems in Checklist TITG -->
                                                <div *ngIf="
                                                    dragCard.showChecklist[indexChecklist] &&
                                                    dragItemChecklist.idCard !== '666'" 
                                                    style="border-top: 1px solid #696969; margin-top: 10px; padding-top: 7px;">
                                                    <ng-container 
                                                        [ngTemplateOutlet]="templateGeneralCheckItem"
                                                        [ngTemplateOutletContext]="{
                                                            driver: null,
                                                            dragItem: dragItemChecklist,
                                                            routeIndexes: {
                                                                indexTitgCardOrDriver:indexCard,
                                                                indexTitgChecklistOrRouteDragItem:indexChecklist,
                                                                indexDragItem: -1
                                                            }, 
                                                            from: 'titg:checkItem'}">
                                                    </ng-container>

                                                 

                                                </div>


                                                <div *ngIf="dragItemChecklist.idCard === '666'"> 
                                                    MISSING THE CHECKLIST SUCKA!!!! Add it now
                                                </div>

                                                
                                            </div>  

                                            <h3 *ngIf="dragCard && dragCard.dragItems && dragCard.dragItems.length === 0">
                                                ALL DONE! (You've routed the stuff!)
                                            </h3>
                                        </div>
                                    </div>
<!-- TRIGGER - Add checklist -->              
                                    <div
                                        #containerChecklist
                                        class="route-custom-item-container" 
                                        style="cursor: pointer !important;"
                                        (click)="
                                            activeDragItem = dragCard;
                                            showInputContainer(
                                                containerChecklist.getBoundingClientRect(),
                                                null,
                                                dragCard,
                                                null,
                                                {indexTitgCardOrDriver: indexCard,
                                                indexTitgChecklistOrRouteDragItem: -1,
                                                indexDragItem: -1}, 'titg:checklist')">
                                        <div  class="route-custom-checkitem-btn">
                                            + Add a new checklist
                                        </div>

                                    </div>
                                </div>
    
                                <h3 *ngIf="routeInfo.titgCardCatalog.length < 1" class="route-titg-card-message" >
                                    Nothing to be routed
                                </h3>                          
                            </div>
                            
                          
                        </div>
                    </div>
                </div>
            </animation-right-left> 

        </div>

    </div>


</div>
<!--h2>Trello information repository</h2-->
<!--button class="btn btn-primary flex-row m-5" style="min-width: 300px" (click)="getTrelloBoards()">Get me some boards iD'S</button-->

<!-- MARK - TEMPLATES -->

<!-- Trello Drill Down-->
    <!-- TEMPLATE: Boards -->
    <ng-template
        #templateTrelloBoard
        let-board="board"
        let-index="index">
        <div >  
            <div class="trello-row-board">
                <div class="col-6">
                    <div>{{board.name}}</div>
                </div>
                <div class="col-5 text-right flex-row" style="align-items: center;">
                    <div class="mr-5">id - {{board.id}}</div>
                    <button 
                        class="btn btn-primary" 
                        style="z-index: 1;"
                        (click)="$event.stopPropagation();copyUrl(board.id)">
                        Copy
                    </button>
                    
                </div>
                <div class="col-1" >
                    <div class="text-right float-right">
                        <img 
                        class="route-minor-img" 
                        src="assets\images\chevron_right.svg" 
                        alt=""
                        [ngClass]="[(routeInfo.view.trelloView.showLists && showList[index]) ? 'rotate-cw-90':'rotate-origin']"
                        (click)="getMasterTrelloLists(board, index)">
                    </div>
                   
                </div>
                
                
            </div> 
                
            <div 
                *ngIf="routeInfo.view.trelloView.showLists && showList[index]" 
                class="trello-row-list">
                <div 
                    *ngFor="let list of routeInfo.lists" 
                    style="margin-right: 10px"
                    >
                    <ng-container
                        [ngTemplateOutlet]="templateTrelloList"
                        [ngTemplateOutletContext]="{list: list}">
                    </ng-container>
                </div>
                
            </div>
        </div>
        <!-- ROUTE VIEW: Show Lists-->

    </ng-template>

    <!-- TEMPLATE: Lists -->
    <ng-template
        #templateTrelloList
        let-list="list">
        <div class="trello-list-headers">
            {{list.name}}
            <br>{{list.id}}<br>
            <button 
                class="btn btn-primary" 
                (click)="$event.stopPropagation();copyUrl(list.id)">
                Copy
            </button>
        </div>
    </ng-template>

    <!-- TEMPLATE: Cards -->
    <ng-template
        #templateTrelloCard
        let-card="card">
        <div class="btn btn-naked">{{card.name}}</div>
    </ng-template>

<!-- Roomerator Drill Down-->
    <!-- TEMPLATE: Driver -->
    <ng-template
        #selectableDriverBoard
        let-driver="driver"
        let-index="index">
        <div *ngIf="driver">
            <div class="route-driver-img-container">
                <img
                    class="route-driver-img" 
                    src="{{driver.driverInfo.driverImg}}" 
                    alt="/assets/images/roomerator_icon_grey.svg">
            </div>
            <div class="col-5 float-left">
                <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">First name</div>
                <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">Middle name</div>
                <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">Last name</div>
                <br>
                <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">Start time</div>
                <br>
                <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">Vehicle Name</div>
                <div class="route-driver-label-header" [ngClass]="{'route-driver-label-header-select': setupDriverSelected[index]}">Vehicle Id</div>
            </div>
            <div class="col-7 float-right">
                <div>{{driver.driverInfo.firstName}}</div>
                <div class="route-driver-label">{{driver.driverInfo.middleName}}</div>
                <div>{{driver.driverInfo.lastName}}</div>
                <br>
                <div>{{driver.driverInfo.startTime}}</div>
                <br>
                <div>{{driver.driverInfo.vehicleName}}</div>
                <div>{{driver.driverInfo.vehicleId}}</div>
            </div>
        </div>
        
    </ng-template>

    <!-- TEMPLATE: Drag Item-->
    <ng-template 
        #templateDragItem
        let-dragItem="dragItem"
        let-driver="driver"
        let-routeIndexes="routeIndexes"
        let-from="from">
    <!-- Tool Container -->           
        <!-- Button Containers -->
        <div 
            class="route-driver-drag-item-major-header"
            style="
                background: white;
                border-top-left-radius: 9px;
                border-top-right-radius: 9px;
                margin-bottom: 7.5px;
                border-bottom: 1px solid #8b8b8b;
                display: flex;"
            [ngClass]="{
                'hide-element': !dragItem.url,
                'p-3': dragItem.url,
                'pt-1': !dragItem.url}">
            <div 
                class="flex-row" 
                style="align-items: center; min-height: 27px;">
        <!-- Hyperlink -->
                <a  
                    *ngIf="dragItem.shortUrl"
                    href="{{dragItem.shortUrl}}"
                    style=" 
                        width: 225px;
                        overflow: hidden;">
                    {{dragItem.shortUrl}}
                </a> 
        
                <div class="btn-group" style="position: absolute; right: 3px;">
        <!-- Set/Remove Ordered -->    
                    <button 
                        *ngIf="dragItem.action.includes('pickup')||dragItem.action.includes('return')|| dragItem.action.includes('p&p')"
                        
                        class=" btn btn-naked" 
                        type="button"
                        tabindex="-1"

                        style="padding: 9px !important;"

                        (keydown.enter)="keyPressedEnter('suppress')"
                        (click)="toggleEmailOrdered(driver, dragItem, routeIndexes, from+':order')"
                        
                        [disabled]="dragItem.completed">
                        <img 
                            class="route-driver-drag-item-img"
                            style="height: 14px;padding: 0 !important;" 
                            src="assets/images/envelope_resize.svg" 
                            alt=""> 
                        <img 
                            *ngIf="dragItem.ordered"
                            style="height: 20px !important;position: absolute; top: 10px;right: 5px;" 
                            src="assets/images/checkmark_green.svg" 
                            alt=""> 
                    </button>
        <!-- Copy Short URL-->   
                    <button 
                        *ngIf="dragItem.url"
                        class=" btn btn-naked" 
                        type="button"
                        tabindex="-1"
                        style="padding: 9px !important;"
                        
                        (click)="copyUrl(dragItem.url)"
                        (keydown.enter)="keyPressedEnter('suppress')">
                        <img 
                            class="route-driver-drag-item-img" 
                            style="height: 24px !important; padding: 0 !important;" 
                            src="assets\images\URL.svg" 
                            alt=""> 
                    </button>
                </div>
            </div>
        </div>
        <!-- New hotness -->
        <div class="flex-row p-3 mb-3" style="align-items: center;">
            <input 
                type="checkbox" 
                [checked]="dragItem.state === 'complete'"
                (change)="updateDragItem('update_check_item_state', driver, dragItem, null, routeIndexes, from)">
            <div 
                class="flex-row"
                *ngIf="dragItem.displayName" 
                style="
                    width: 395px;
                    padding-left: 15px;
                    margin: 0;
                    align-items: center;"
                [ngStyle]="{
                    cursor: (
                        dragItem.action.includes('return')|| 
                        dragItem.action.includes('pickup')||
                        dragItem.action.includes('p&p') ? 
                            'pointer' : '')}"
                (click)="
                    $event.stopPropagation(); 
                    activeDragItem = dragItem;
                    showChecklist(
                        dragItem, 
                        routeIndexes, 
                        from);">
                <div class="mr-3" style="text-transform: capitalize;">{{dragItem.displayName}}</div>        
                <div 
                    *ngIf="
                        dragItem.action.includes('return')|| 
                        dragItem.action.includes('pickup')||
                        dragItem.action.includes('p&p')">
                    <img 
                        class="route-minor-img float-right" 
                        src="assets\images\chevron_right_grey.svg" 
                        alt=""
                        style="float: none;"
                        [ngClass]="[(dragItem.checklists.length > 0) ? 'rotate-cw-90':'rotate-origin']">
                </div> 
            </div>
              
        </div>
        
        <div 
            *ngFor="let checklist of dragItem.checklists"
            class="container py-3"
            style="
                border-top: 1px solid #898989;
                background: #ebebeb;
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;">
            <div 
                *ngFor="
                    let checkItem of checklist.checkItems; 
                    let indexCheckItem = index "
                class="flex-row " 
                style="align-items: center; padding-left: 15px; position: relative;">
                
                <ng-container 
                    [ngTemplateOutlet]="templateCheckItem"
                    [ngTemplateOutletContext]="{
                        indexCheckItem: indexCheckItem,
                        driver:driver,
                        dragItem:dragItem,
                        checkItem:checkItem, 
                        routeIndexes: routeIndexes, 
                        from: from
                    }">
                </ng-container>
                
            </div>
            <h4 
                *ngIf="!checklist.checkItems && !dragItem.showAddCheckItem"
                class="m-5 text-center">Nothing to show!</h4>

    <!-- TRIGGER - Add CheckItem -->              
            <div
                #containerAddCheckItem
                class="route-custom-item-container" 
                style=" background-color: white;"
                (click)=" 
                    activeDragItem = dragItem; 
                    showInputContainer(
                        containerAddCheckItem.getBoundingClientRect(),
                        null,
                        dragItem,
                        null,
                        routeIndexes, 
                        from)">
                <div class="route-custom-checkitem-btn" >
                    + Add a new item !!
                </div>
            </div>

        </div>
    
    </ng-template>
    
    <!-- TEMPLATE: CheckItem A-->
    <ng-template
        #templateGeneralCheckItem
        let-driver="driver"
        let-dragItem="dragItem"
        let-routeIndexes="routeIndexes"
        let-from="from">
            <div 
                #checkItemGeneral
                *ngFor="
                    let checkItem of dragItem.checkItems; 
                    let indexCheckItem = index;"
                class="flex-row " 
                style="align-items: center; position: relative;">

                <ng-container 
                    [ngTemplateOutlet]="templateCheckItem"
                    [ngTemplateOutletContext]="{
                        indexCheckItem: indexCheckItem,
                        driver:driver,
                        dragItem:dragItem,
                        checkItem:checkItem, 
                        routeIndexes: routeIndexes, 
                        from: from
                    }">
                </ng-container>

            </div>
            <h4 
                *ngIf="
                    (dragItem.checkItems && 
                    dragItem.checkItems.length === 0 || 
                    !dragItem.checkItems) && 
                    (   !dragItem.showAddCheckItem ||
                        (dragItem.showAddCheckItem &&
                        !dragItem.showAddCheckItem[routeIndexes.indexTitgChecklistOrRouteDragItem])
                    )"
                class="m-5 text-center">Nothing to show!</h4>

    <!-- TRIGGER - Add CheckItem -->              
            <div
                #containerAddCheckItem
                class="route-custom-item-container" 
                style="background-color: white;"
                (click)=" 
                    activeDragItem = dragItem; 
                    showInputContainer(
                        containerAddCheckItem.getBoundingClientRect(),
                        null,
                        dragItem,
                        null,
                        routeIndexes, 
                        from)">
                <div class="route-custom-checkitem-btn" >
                    + Add a new item
                </div>
            </div>

    </ng-template> 

    <!-- TEMPLATE: CheckItem B-->
    <ng-template 
        #templateCheckItem
        let-indexCheckItem="indexCheckItem"
        let-driver="driver"
        let-dragItem="dragItem"
        let-checkItem="checkItem"
        let-routeIndexes="routeIndexes"
        let-from="from">
        <input                                                             
            type="checkbox" 
            [checked]="checkItem.state === 'complete'"
            (change)="updateDragItem('update_check_item_state', driver, dragItem, checkItem, routeIndexes, from + ':checkItem')">
    <!-- MARK - Check Item Display-->    
        <div
            *ngIf="!dragItem.showEditCheckItem.show || (dragItem.showEditCheckItem.show && dragItem.showEditCheckItem.index !== indexCheckItem)"
            #containerCheckItem
            class="tool-route-driver-checklist-checkitem"

            (click)="
                activeDragItem = dragItem;

                initModifyCheckItem(
                    indexCheckItem,
                    containerCheckItem.getBoundingClientRect(),
                    dragItem, 
                    checkItem, 
                    routeIndexes, 
                    'titg:checkItem', 
                    from)"
            
            (mouseenter)="showCheckItemsBtns('enter', indexCheckItem, from)"
            (mouseleave)="showCheckItemsBtns('leave', indexCheckItem, from)">
            <div class="ml-2" style="width: 290px;"> {{checkItem.name}}</div>
            <div 
                *ngIf="showCheckItemOptionsBtn(from,indexCheckItem)"
                style="
                    position: absolute;
                    right: 10px;
                    top: 14px;
                    background: white;">    
           
                <button 
                    class="btn btn-naked float-right"
                    style="padding: 7px !important;"
                    (click)="  
                        $event.stopPropagation(); 
                        initDelCheckItem(
                            dragItem,checkItem, {
                                indexTitgCardOrDriver: routeIndexes.indexTitgCardOrDriver,
                                indexTitgChecklistOrRouteDragItem: routeIndexes.indexTitgChecklistOrRouteDragItem,
                                indexDragItem: indexCheckItem}, 
                            'del:checkItem', 
                            from )">
                    <img
                        class="updater-delete-btn"
                        style="height: 18px !important;"
                        src="/assets/images/delete_icon_red.svg">
                </button>

                <button 
                    class="btn btn-naked float-right"
                    style="padding: 7px !important;">
                    <img
                        class="updater-delete-btn"
                        style="height: 18px !important;"
                        src="/assets/images/modify.svg">
                </button>     
               
            </div>
        </div>


    </ng-template>

    <!-- TEMPLATE: Active Driver BTN Console -->
    <ng-template 
        #templateOptionsRouteDeliveries
        let-driver="driver"
        let-typeIndex = "typeIndex"
        let-indexDriver="indexDriver">
        <div *ngIf="!routeInfo.trackerMini[indexDriver]" class="flex-row" style="align-items: center;justify-content: center;">    
            <div class="route-driver-card-container-header">{{driver.routeCard.name}} ({{driver.dragItems.length}})</div>
        </div>
        <div *ngIf="routeInfo.trackerMini[indexDriver]">{{driver.driverInfo.initial}}</div>
    <!-- Bottom Button Menu (Archive & Update/Refresh)-->                                    
        <div class="route-btn-container" [ngStyle]="{'top': routeInfo.trackerMini[indexDriver] ? '44px': '0px' }">
            <div class="m-2" 
            style="display: inline-flex;position: relative; vertical-align: middle;"
            [ngClass]="{'list-group': routeInfo.trackerMini[indexDriver]}">
                <button
                    class="btn btn-naked route-options-btn"
                    type="button"
                    tabindex="-1"
                    [ngStyle]="{'background-image': (driver.driverView.showCompleteRouteItems.show ? 'url(assets/images/show_visible.svg)' :'url(assets/images/show_hidden.svg)') }"
                    (click)="toggleShowCompletedRouteItems(driver)"
                    (keydown.enter)="keyPressedEnter('suppress')">
                    <div 
                        *ngIf="!driver.driverView.showCompleteRouteItems.show"
                        class="btn-navbar-count-container route-driver-hidden-count">
                        {{driver.dragItems.length}}
                    </div>
                </button>

                <button
                    class="btn btn-naked route-options-btn"
                    type="button"
                    tabindex="-1"
                    [ngStyle]="{
                        'background-image': showItemOrCard[1][indexDriver] ? 'url(assets/images/route_plan_2.svg)' :'url(assets/images/cards.svg)' }"
                    (click)="setItemCardSelected(1, indexDriver, driver)"
                    (keydown.enter)="keyPressedEnter('suppress')">
                    <div *ngIf="showItemOrCard[1][indexDriver]"
                        class="btn-navbar-count-container route-driver-hidden-count">
                        {{routeInfo.activeDrivers[indexDriver].dragItems.length}}
                    </div>
                    <div *ngIf="!showItemOrCard[1][indexDriver]"
                        class="btn-navbar-count-container route-driver-hidden-count">
                        {{routeInfo.activeDrivers[indexDriver].routeCards.length}}
                    </div>
                </button>

                <button
                    class="btn btn-naked route-options-btn"
                    style="background-image: url(assets/images/archive_red.svg);"
                    type="button"
                    tabindex="-1"
                    (click)="showArchiveConfirm(driver.routeCard.id, 'archiveRoute')"
                    (keydown.enter)="keyPressedEnter('suppress')">
                </button>

                <button 
                    class="btn btn-naked route-options-btn"
                    [ngStyle]="{'background-image': routeInfo.trackerMini ? 'url(assets/images/minimize.svg)': 'url(assets/images/maximize.svg)'}"
                    type="button"
                    tabindex="-1"
                    (click)="setMinifyDriver(indexDriver)"
                    (keydown.enter)="keyPressedEnter('suppress')">
                </button>

                <button 
                    class="route-options-btn btn-naked"
                    style="background-image: url(assets/images/info_blue.svg);"
                    type="button"
                    tabindex="-1"

                    (click)="showCardInfo(driver.routeCard, 'driver:card')"
                    (keydown.enter)="keyPressedEnter('suppress')">
                </button>

            </div>
        </div>
    </ng-template>
    



