import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { InputboxComponent } from './modal/inputbox/inputbox.component';
import { FadeInOutAnimation } from './animation/fade-in-out/fade-in-out-animation';
import { AutofocusDirective } from './directives/autofocus.directive';
import { FooterComponent } from './footer/footer.component';
import { AccountLandingComponent } from './accounts/account-landing/account-landing.component';
import { AccountToolsComponent } from './accounts/account-tools/account-tools.component';
import { ToolCarryComponent } from './accounts/account-tools/tool-carry/tool-carry.component';
import { ToolPickupComponent } from './accounts/account-tools/tool-pickup/tool-pickup.component';
import { ToolUpdaterComponent } from './accounts/account-tools/tool-updater/tool-updater.component';
import { ToolRoutesComponent } from './accounts/account-tools/tool-routes/tool-routes.component';
import { ToolAddComponent } from './accounts/account-tools/tool-add/tool-add.component';
import { UpDownAnimation } from './animation/up-down/up-down-animation';
import { SearchComponent } from './modal/search/search.component';
import { InputWindowComponent } from './modal/input-window/input-window.component';
import { FailPageComponent } from './modal/fail-page/fail-page.component';
import { LoadingComponent } from './modal/loading/loading.component';
import { NavbarComponent } from './navbar/navbar.component';
import { TrelloCardComponent } from './api/trello/trello-card/trello-card.component';
import { ToolRouteEmailComponent } from './accounts/account-tools/tool-routes/tool-route-email/tool-route-email.component';
import { ToolRouteOptionsComponent } from './accounts/account-tools/tool-routes/tool-route-options/tool-route-options.component'
import { HotbarComponent } from './modal/hotbar/hotbar.component';
import { TrelloLabelsComponent } from './api/trello/trello-card/trello-labels/trello-labels.component';
import { ToolRouteDriverSelectionComponent } from './accounts/account-tools/tool-routes/tool-route-driver-selection/tool-route-driver-selection.component';
import { CopyTextComponent } from './general-services/copy-text/copy-text.component';

//Animation
import { DownUpAnimation } from './animation/down-up/down-up-animation';
import { RightLeftAnimation } from './animation/right-left/right-left-animation';
import { LeftRightAnimation } from './animation/left-right/left-right-animation';
import { FlyInOutAnimation } from './animation/fly-in-out/fly-in-out.animation';
import { TrelloChecklistComponent } from './api/trello/trello-checklist/trello-checklist.component';


@NgModule({
  declarations: [
    AppComponent,
    CopyTextComponent,
    LandingPageComponent, 
    InputboxComponent,
    AutofocusDirective,
    FooterComponent,
    AccountLandingComponent,
    AccountToolsComponent,
    ToolCarryComponent,
    ToolPickupComponent,
    ToolUpdaterComponent,
    ToolRoutesComponent,
    ToolAddComponent,
    SearchComponent,
    InputWindowComponent,
    FailPageComponent,
    LoadingComponent,
    NavbarComponent,
    TrelloCardComponent,
    TrelloLabelsComponent,
    ToolRouteEmailComponent,
    ToolRouteDriverSelectionComponent,
    ToolRouteOptionsComponent,
    HotbarComponent,

    FadeInOutAnimation,
    DownUpAnimation,
    UpDownAnimation,
    RightLeftAnimation,
    LeftRightAnimation,
    FlyInOutAnimation,
    TrelloChecklistComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DragDropModule,
    FormsModule,
    HttpClientModule, 
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
