import { Item } from "src/app/cart-item/classes/item";


export class UpdaterView {
  isUpdateSuccessful: boolean| null = null;

  submitMessage: string = 'What a terrible failure!....';

  updatedItems: UpdatedItems = new UpdatedItems;
  scraperInfo: ScraperInfo = new ScraperInfo;
}


export class PriceUpate {
  
  commiteDate: Date = new Date;
  previous_price:number = -1;
  new_price: number = -1;
  item = new Item;
}

export class ScraperInfo{
  scraperStatus: string = '';
  scraperErrorReport: any = null;
}

export class UpdatedItems {
  item_numbers: number[] = [];
}

export const DEFAULT_PRICE_UPDATE: PriceUpate[] = []

export const DEFAULT_UPDATER_VIEW: UpdaterView = {
  isUpdateSuccessful: null,
  submitMessage: "What can I say.... fail!!!!",

  updatedItems: new UpdatedItems,
  scraperInfo: new ScraperInfo,
}

