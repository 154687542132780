import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/cart-item/services/item.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit, OnDestroy {
  @Input() public loadingStyle: string = '' 
  
  intervalId = 0;
  message = '';
  seconds = 14;
 
    constructor(
        private router: Router,
        private itemService: ItemService
    ) { }
    
  clearTimer() { clearInterval(this.intervalId); }

  ngOnInit()    { this.start()}
  ngOnDestroy() { this.clearTimer()}

  start() { this.countDown(); }
  stop() { this.clearTimer(); }
  
  getLoadingClass(){
    let rClass = {}
    switch (this.loadingStyle){
      case 'delivery_location':
        rClass = {'modal-loading-img-map': true }; 
        break;   
      case 'general':
        rClass = {'modal-loading-img':true};
        break;
      case 'checkout':
        rClass = {'modal-loading-checkout':true};
        break;
      case 'project':
        rClass = {'modal-loading-project':true};
        break;
    }
    return rClass;
  }

  private countDown() {
    this.clearTimer();
    this.intervalId = window.setInterval(() => {
      this.seconds -= 1;
      console.log(this.seconds + " seconds")
      if (this.seconds === 0) { 
        this.message = 'You win again Mr.Bond'; 
      } else {
        if (this.seconds < 0) { 
          if (this.itemService.getLocationOpen()){this.itemService.setLocationOpen(false)}
          this.router.navigate(['fail-page'])}
      }
    }, 1000);
  }
    // MARK: - Error Handling
    private handleError(error: any): Promise<any> {
      console.error('An error occured', error);
      return Promise.reject(error.message || error);
  }
}