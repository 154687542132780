import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError, retry} from'rxjs/operators';

import { Observable } from 'rxjs'
import { DefaultAppConfig } from '../app.config';
import { Item } from '../cart-item/classes/item';


@Injectable({
  providedIn: 'root'
})

export class ServerService {

  constructor(
    private http: HttpClient
  ) { }

  private isTest = DefaultAppConfig.DEFAULT_TESTING;
  private prepend: string = '';

  private extractJson(res: Response) {
    let body = res.json();
    return body || { };
  }

  private extractBlob(res: Response){
    let body = res.blob();
    return body || {};
  }


  postRequest(data: any, processUrl: string, dataType: string, from: string) {
    let url = '';
    // let options = new HttpRequest( {headers:headers});
    if (data === null || data === undefined) {
        return null;
    }

    if (this.isTest) {
        this.prepend = 'http://192.168.112.128/app_forward';
    }
    if (!this.isTest) {
        this.prepend = '/app_forward';
    }

    switch (processUrl) {
      case 'activate':
        url = this.prepend + '/user_activate.php';
        break;
      case 'password':
        url = this.prepend + '/password_set.php';
        break;
      case 'checkout':
        url = this.prepend + '/initiate_checkout.php';
        break;
      case 'billing':
        url = this.prepend + '/initiate_billing.php';
        break;
      case 'finalize':
        url = this.prepend + '/initiate_finalize.php';
        break;
      case 'mailer':
        url = this.prepend + '/mailer.php';
        break;
      case 'query':
        url = this.prepend + '/prod_search.php';
        break;
      case 'update_cart':
        url = this.prepend + '/update_cart.php';
        break;
      case 'promo_code':
        url = this.prepend + '/promo_code';
        break;
      case 'account':
        url = this.prepend + '/account_endpoint.php';
        break;
      default:
        console.error("Missing out on something special!@#")
        break;
    }

    // let extractData: any;
    // if (dataType === 'json') { extractData = this.extractJson }
    // if (dataType === '') { extractData = this.extractBlob }
    if(dataType == 'json'){
      let toPost=this.http
      .post(url,data)
      .pipe(
        retry(3),
        catchError(this.handleError)
      )
      return toPost;
    }

    if(dataType == 'blob'){
      let headers = new HttpHeaders
      headers.set('accept', 'application/pdf')

      const toPost=this.http
      .post(url, data, {
          responseType: 'blob',
          headers: headers
      })

      console.log(toPost, "<-- this is what we are posting!@#!@@#!@#!@#")

      return toPost;
    }
    return null;
  }

  getRequest(actionId: number, addition: string) {
    // let options:string;
    // options='&request_num='+addition;
    if (this.isTest === true) {
        this.prepend = 'http://192.168.112.128/app_forward';
    }
    if (this.isTest !== true) {
        this.prepend = '/app_forward';
    }
    const requestURI = this.prepend + '/service_request.php' + '?action_id=' + actionId + addition;
    
    let checker = {}
    // REQUIRES LOOKING INTO 
    checker = this.http
      .get(requestURI)
      .pipe((retry)(3), (catchError)(this.handleError));

    return checker;
  }

  
  sendRequest(serviceRequest:any, dataType: string):Observable<any>|null{
    console.log(serviceRequest, dataType, "Send request!!@#@#")
    return this.postRequest(serviceRequest,"account_endpoint", dataType, 'sendRequest');
}

  
  handleError(error: any) {
    console.error('An error occured', error);
    return Promise.reject(error.message || error);
  }
}
