import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ViewService } from 'src/app/general-services/view/view.service';

@Component({
  selector: 'app-fail-page',
  templateUrl: './fail-page.component.html',
  styleUrls: ['./fail-page.component.css']
})
export class FailPageComponent {
  toRoute = '/';
  constructor(
    private router: Router,
    private viewService: ViewService,
    //private breadcrumbService: BreadcrumbService,
    //private countdownTimer: 
    ) {}
  seconds() { return 111111116; }
  ngOnInit() {
      window.scrollTo(0, 0);
      this.viewService.changeView('checkout', false, null, 'fail-page.component:ngOnInit');
      this.viewService.changeView('loading_account', false, null, 'fail-page.component:ngOnInit');
      this.viewService.changeView('loading', false, null, 'fail-page.component:ngOnInit');
      this.viewService.changeView('closeAll', false, null, 'fail-page.component:ngOnInit');
      //this.breadcrumbService.setBreadcrumb('Error Page', '');
  }
  ngDoCheck() {
      if (this.seconds() <= 0) {
          this.router.navigate(['']);
      }
  }
  ngOnDestroy() {
      //this.countdownTimer.stop();
  }
  // MARK: - GET's 
  getTimer() {
      ///setTimeout(() => this.seconds = () => this.countdownTimer.seconds, 0);
  }
  // MARK: - Timer Functions
  //start() { this.countdownTimer.start(); }
  
  handleError(error: any) {
      console.error('An error occured', error);
      return Promise.reject(error.message || error);
  }
}
