import { InputWindow } from "./input-window";




export const CART_SAVE: InputWindow = {
    // General Settings
    window_type: 'cart_save',
    title: 'Save & Share',
    title_css: '',
    message_1: 'Let\'s save that cart for you... ',
    message_1_container_css: '',
    message_1_css: '',
    show: 'cart_save',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 3,
    process_message: '',
    passed_data: null,
    row: [{
        logic: "isLogged",
        row_type: [{
        // SAVE CART ROW: LOGGED IN
        logic: true,
        row_type: 1,
        setting: [{
            button_setting: {
            container_class: '',
            button_message: "Save",
            button_css_setting: "btn btn-primary btn-lg w-100",
            button_visible: ["isLogged", true],
            button_enable: [1, /^.{1,15}$/],
            button_click_action: 'cart_save',
            button_route: null
            },
            input_setting: {
                input_min: '',
                input_surpress_header: false,
                input_has_action_btn: false,
                input_value: null,
                input_tabindex: 1,
                input_place_holder: "Cart Name",
                input_name: "save_name",
                input_type: 'text',
                input_autofocus: false,
                input_autocomplete: "off",
                input_error: 'What\'s in a name?',
                input_pass: [1, /^.{1,15}$/],
                input_pass_reqirements: null,
                input_disabled: false,
                input_readonly: false,
                input_action: 'cart_save',
                input_css_setting: '',
                input_container_css_setting: '',
                input_data: null,
                output_data: null,
                input_submit: null,
                input_submit_to: null,
                input_dom_rect: null
                
            },
            message_setting: {
            container_class: '',
            message_css_setting: '',
            message_visible: false,
            message_output: '',
            },
            output_setting: [{
                confirm_message: '',
                confirm_button_pass: '',
                confirm_button_cancel: '',
                pass_title: "Cart Saved",
                pass_title_css: "py-3 ",
                pass_message: "Your cart has been saved and can be found in your Toolbox.",
                pass_message_css: "px-1 py-2 medium text-center",
                pass_button_1: "Yes Queen!",
                pass_button_1_css: "btn btn-lg btn-primary w-100 mb-3",
                fail_title: "Saved Failed",
                fail_message: "Fail!",
                button_css_setting: '',
                fail_button_1: "Cancel",
                fail_button_1_css: "btn btn-lg btn-warning w-100",
                fail_button_2: "Retry",
                fail_button_2_css: "btn btn-lg btn-warning w-100",
            }]
        }]
        }, {
            // SAVE CART ROW: NOT LOGGED IN
            logic: false,
            row_type: 3,
            setting: [{
                    button_setting: {
                        container_class: '',
                        button_message: "Save your cart?",
                        button_css_setting: "w-100 btn btn-white-blue btn-lg",
                        button_visible: true,
                        button_enable: null,
                        button_click_action: 'route',
                        button_route: ['/user-account', 'save_cart']
                    },
                    input_setting: null,
                    message_setting: {
                        container_class: '',
                        message_css_setting: '',
                        message_visible: false,
                        message_output: '',
                    },
                    output_setting: null,
                }]
        }]
        }, {
        logic: null,
        row_type: [{
                // NO LOGIC FOR CANCEL
                logic: null,
                row_type: 2,
                setting: [{
                        button_setting: {
                            container_class: '',
                            button_message: "COPY",
                            button_css_setting: "btn btn-lg btn-label-primary btn-copy",
                            button_visible: true,
                            button_enable: null,
                            button_click_action: 'copy',
                            button_route: null,
                        },
                        input_setting: {
                            input_min: '',
                            input_surpress_header: false,
                            input_has_action_btn: false,
                            input_value: null,
                            input_tabindex: 1,
                            input_place_holder: "Share Link",
                            input_name: "share_link",
                            input_type: "text",
                            input_autofocus: false,
                            input_autocomplete: "off",
                            input_error: '',
                            input_pass: [-1, ''],
                            input_pass_reqirements: null,
                            input_disabled: false,
                            input_readonly: true,
                            input_action: null,
                            input_css_setting: "text-transrom': lowercase !important;",
                            input_container_css_setting: 'mb-3',
                            input_data: null,
                            output_data: null,
                            input_submit: null,
                            input_submit_to: null,
                            input_dom_rect: null
                        },
                        message_setting: {
                            container_class: '',
                            message_css_setting: '',
                            message_visible: false,
                            message_output: '',
                        },
                        output_setting: [{
                                confirm_message: '',
                                confirm_button_pass: '',
                                confirm_button_cancel: '',
                                pass_title: '',
                                pass_title_css: '',
                                pass_message: '',
                                pass_message_css: '',
                                pass_button_1: '',
                                pass_button_1_css: '',
                                fail_title: '',
                                fail_message: '',
                                button_css_setting: '',
                                fail_button_1: '',
                                fail_button_1_css: '',
                                fail_button_2: '',
                                fail_button_2_css: '',
                            }]
                    }]
            }]
        }, {
        logic: null,
        row_type: [{
                // NO LOGIC FOR CANCEL
                logic: null,
                row_type: 5,
                setting: [{
                        button_setting: {
                            container_class: '',
                            button_message: "Nevermind",
                            button_css_setting: "w-100 btn btn-primary btn-warning btn-lg",
                            button_visible: true,
                            button_enable: null,
                            button_click_action: 'dismiss',
                            button_route: null,
                        },
                        input_setting: null,
                        message_setting: {
                            container_class: '',
                            message_css_setting: '',
                            message_visible: false,
                            message_output: '',
                        },
                        output_setting: null,
                    }]
            }]
        }],
};

export const CART_UPLOAD: InputWindow = {
    // General Settings
    window_type: 'cart_upload',
    title: 'Upload Cart',
    title_css: '',
    message_1: 'Stuffed!? Overwrite exsiting cart?',
    message_1_container_css: '',
    message_1_css: '',
    show: 'cart_upload',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 1,
    process_message: '',
    passed_data: null,
    row: [{
            logic: null,
            row_type: [{
                    logic: true,
                    row_type: 3,
                    setting: [{
                            button_setting: {
                                container_class: "col-6 pr-2",
                                button_message: "Nope",
                                button_css_setting: "btn btn-warning btn-lg w-75",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'dismiss',
                                button_route: null
                            },
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            output_setting: [{
                                    confirm_message: '',
                                    confirm_button_pass: '',
                                    confirm_button_cancel: '',
                                    pass_title: '',
                                    pass_title_css: '',
                                    pass_message: '',
                                    pass_message_css: '',
                                    pass_button_1: '',
                                    pass_button_1_css: '',
                                    fail_title: '',
                                    fail_message: '',
                                    button_css_setting: 'col-3 pl-2 w-100',
                                    fail_button_1: '',
                                    fail_button_1_css: '',
                                    fail_button_2: '',
                                    fail_button_2_css: '',
                                }],
                            input_setting: {
                                    input_min: '',
                                    input_surpress_header: false,
                                    input_has_action_btn: false,
                                    input_value: null,
                                    input_tabindex: 1,
                                    input_place_holder: '',
                                    input_name: '',
                                    input_type: '',
                                    input_autofocus: false,
                                    input_autocomplete: "off",
                                    input_error: '',
                                    input_pass: [-1, ''],
                                    input_pass_reqirements: null,
                                    input_disabled: false,
                                    input_readonly: false,
                                    input_action: null,
                                    input_css_setting: '',
                                    input_data: null,
                                    output_data: null,
                                    input_submit: null,
                                    input_submit_to: null,
                                    input_dom_rect: null,
                                    input_container_css_setting: 'mb-3',
                            }
                        }, {
                            button_setting: {
                                    container_class: "col-6 pl-2",
                                    button_message: "Do It!",
                                    button_css_setting: "btn btn-primary btn-lg w-100",
                                    button_visible: ["isLogged", true],
                                    button_enable: null,
                                    button_click_action: 'cart_upload',
                                    button_route: null
                            },
                            output_setting: [{
                                    confirm_message: "Confirm",
                                    confirm_button_pass: 'Yes Queen!' ,
                                    pass_title: '',
                                    pass_title_css: '',
                                    pass_message_css: "",
                                    pass_button_1: '',
                                    pass_button_1_css: '',
                                    fail_title: '',
                                    confirm_button_cancel: "No!",
                                    pass_message: "PaSS!",
                                    fail_message: "Fail!",
                                    button_css_setting: 'col-3 pl-2 w-100',
                                    fail_button_1: '',
                                    fail_button_1_css: '',
                                    fail_button_2: '',
                                    fail_button_2_css: '',
                                }],
                            message_setting: {
                                    container_class: '',
                                    message_css_setting: '',
                                    message_visible: false,
                                    message_output: '',
                            },
                            input_setting: {
                                    input_min: '',
                                    input_surpress_header: false,
                                    input_has_action_btn: false,
                                    input_value: null,
                                    input_tabindex: 1,
                                    input_place_holder: '',
                                    input_name: '',
                                    input_type: '',
                                    input_autofocus: false,
                                    input_autocomplete: "off",
                                    input_error: '',
                                    input_pass: [-1, ''],
                                    input_pass_reqirements: null,
                                    input_disabled: false,
                                    input_readonly: true,
                                    input_action: null,
                                    input_css_setting: '',
                                    input_data: null,
                                    output_data: null,
                                    input_submit: null,
                                    input_submit_to: null,
                                    input_dom_rect: null,
                                    input_container_css_setting: 'mb-3',
                            }
                        }]
                }],
        }]
};

export const PROJECT_SAVE: InputWindow = {
    // General Settings
    window_type: 'project_add',
    title: 'Project Creation',
    title_css: '',
    message_1: 'A project? Why? Where?',
    message_1_container_css: '',
    message_1_css: '',
    show: 'project_add',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 3,
    process_message: '',
    passed_data: null,
    row: [{
            logic: 'isLogged',
            row_type: [{
                    // SAVE PROJECT ROW: False passed data
                    logic: true,
                    row_type: 0,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: '',
                                button_route: null
                            },
                            input_setting: {
                                    input_min: '',
                                    input_surpress_header: false,
                                    input_has_action_btn: false,
                                    input_value: null,
                                    input_tabindex: 1,
                                    input_place_holder: "Project Name",
                                    input_name: "project_name",
                                    input_type: 'text',
                                    input_autofocus: false,
                                    input_autocomplete: "off",
                                    input_error: 'What\'s in a name?',
                                    input_pass: [1, ''],
                                    input_pass_reqirements: [{ char_length: 1 }],
                                    input_disabled: false,
                                    input_readonly: false,
                                    input_action: 'project_update',
                                    input_css_setting: 'text-normal',
                                    input_data: null,
                                    output_data: null,
                                    input_submit: null,
                                    input_submit_to: null,
                                    input_dom_rect: null,
                                    input_container_css_setting: 'mb-3',
                            },
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            output_setting: null,
                        }]
                }, {
                    // SAVE PROJECT ROW: LOGGED IN
                    logic: false,
                    row_type: 8,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: '',
                                button_route: null
                            },
                            input_setting: null,
                            message_setting: {
                                container_class: "flex-row",
                                message_css_setting: "text-center w-100 pb-2 overflow-hidden modal-window-message-large",
                                message_visible: false,
                                message_output: ''
                            },
                            output_setting: null
                        }]
                }]
        }, {
            logic: 'isLogged',
            row_type: [{
                    // Save project Account section
                    logic: true,
                    row_type: 7,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'route',
                                button_route: null,
                            },
                            input_setting: {
                                    input_min: '',
                                    input_surpress_header: false,
                                    input_has_action_btn: false,
                                    input_value: null,
                                
                                    input_tabindex: 1,
                                    input_place_holder: '',
                                    input_name: '',
                                    input_type: '',
                                    input_autofocus: false,
                                    input_autocomplete: "off",
                                    input_error: '',
                                    input_pass: [1, ''],
                                    input_pass_reqirements: null,
                                    input_disabled: false,
                                    input_readonly: false,
                                    input_action: null,
                                    input_css_setting: '',
                                    input_container_css_setting: 'mb-3',

                                    // Autocomplete
                                    input_data: {
                                        useCase: 'auto_complete',
                                        validCheck: null,
                                        place: null,
                                        placeholder: 'Project Address'
                                },
                                output_data: {
                                    valid: null,
                                    place: []
                                },
                                input_submit: null,
                                input_submit_to: null,
                                input_dom_rect: null,
                            },
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            output_setting: null,
                        }]
                }, {
                    // Save project during checkout
                    logic: false,
                    row_type: 8,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: '',
                                button_route: null
                            },
                            input_setting: null,
                            message_setting: {
                                container_class: "flex-row",
                                message_css_setting: "text-center w-100 pb-2 overflow-hidden modal-window-message-large",
                                message_visible: false,
                                message_output: ''
                            },
                            output_setting: null
                        }]
                }
            ]
        }, {
            logic: 'isLogged',
            row_type: [{
                    logic: true,
                    row_type: 3,
                    setting: [
                        {
                            button_setting: {
                                container_class: "col-5 pr-2",
                                button_message: "Cancel",
                                button_css_setting: "btn btn-warning btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'dismiss',
                                button_route: null
                            },
                            input_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            output_setting: [{
                                    confirm_message: '',
                                    confirm_button_pass: '',
                                    confirm_button_cancel: '',
                                    pass_title: '',
                                    pass_title_css: '',
                                    pass_message: '',
                                    pass_message_css: '',
                                    pass_button_1: '',
                                    pass_button_1_css: '',
                                    fail_title: '',
                                    fail_message: '',
                                    button_css_setting: 'col-3 pl-2 w-100',
                                    fail_button_1: '',
                                    fail_button_1_css: '',
                                    fail_button_2: '',
                                    fail_button_2_css: '',
                                }]
                        },
                        {
                            button_setting: {
                                container_class: "col-7 pl-2",
                                button_message: "Create",
                                button_css_setting: "btn btn-primary btn-lg w-100",
                                button_visible: true,
                                button_enable: [2, ''],
                                button_click_action: 'project_add',
                                button_route: null
                            },
                            input_setting: {
                                input_min: '',
                                input_surpress_header: false,
                                input_has_action_btn: false,
                                input_value: null,
                                input_tabindex: 1,
                                input_place_holder: '',
                                input_name: '',
                                input_type: '',
                                input_autofocus: false,
                                input_autocomplete: "off",
                                input_error: '',
                                input_pass: [1, 'address_components'],
                                input_pass_reqirements: null,
                                input_disabled: false,
                                input_readonly: false,
                                input_action: null,
                                input_css_setting: '',
                                input_container_css_setting: 'mb-3',
                                input_data: null,
                                output_data: null,
                                input_submit: null,
                                input_submit_to: null,
                                input_dom_rect: null,
                            },
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            output_setting: null
                        }
                    ]
                }, {
                    logic: false,
                    row_type: 3,
                    setting: [
                        {
                            button_setting: {
                                container_class: "col-5 pr-2",
                                button_message: "Move on...",
                                button_css_setting: "btn btn-warning btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'checkout_project_cancel',
                                button_route: null
                            },
                            input_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            output_setting: [{
                                    confirm_message: '',
                                    confirm_button_pass: '',
                                    confirm_button_cancel: '',
                                    pass_title: '',
                                    pass_title_css: '',
                                    pass_message: '',
                                    pass_message_css: '',
                                    pass_button_1: '',
                                    pass_button_1_css: '',
                                    fail_title: '',
                                    fail_message: '',
                                    button_css_setting: 'col-3 pl-2 w-100',
                                    fail_button_1: '',
                                    fail_button_1_css: '',
                                    fail_button_2: '',
                                    fail_button_2_css: '',
                                }]
                        }, {
                            button_setting: {
                                container_class: "col-7 pl-2",
                                button_message: "Let's go!",
                                button_css_setting: "btn btn-primary btn-lg w-100",
                                button_visible: true,
                                button_enable: [2, ''],
                                button_click_action: 'checkout_project_add',
                                button_route: null
                            },
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            input_setting: null,
                            output_setting: null
                        }
                    ]
                }]
        }]
};

export const PROJECT_DELETE: InputWindow = {
    // General Settings
    window_type: 'project_delete',
    title: 'Project Deletion',
    title_css: '',
    message_1: 'Do you wish to delete the following project?',
    message_1_container_css: '',
    message_1_css: '',
    show: 'project_delete',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 2,
    process_message: '',
    passed_data: null,
    row: [{
            logic: null,
            row_type: [{
                    logic: null,
                    row_type: 8,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: '',
                                button_route: null
                            },
                            input_setting: null,
                            message_setting: {
                                container_class: "flex-row",
                                message_css_setting: "text-center w-100 pb-2",
                                message_visible: false,
                                message_output: ''
                            },
                            output_setting: null,
                        }]
                }]
        }, {
            logic: null,
            row_type: [{
                    logic: null,
                    row_type: 3,
                    setting: [
                        {
                            button_setting: {
                                container_class: "col-5 pr-2",
                                button_message: "Cancel",
                                button_css_setting: "btn btn-warning btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'dismiss',
                                button_route: null
                            },
                            input_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            output_setting: [{
                                    confirm_message: '',
                                    confirm_button_pass: '',
                                    confirm_button_cancel: '',
                                    pass_title: '',
                                    pass_title_css: '',
                                    pass_message: '',
                                    pass_message_css: '',
                                    pass_button_1: '',
                                    pass_button_1_css: '',
                                    fail_title: '',
                                    fail_message: '',
                                    button_css_setting: 'col-3 pl-2 w-100',
                                    fail_button_1: '',
                                    fail_button_1_css: '',
                                    fail_button_2: '',
                                    fail_button_2_css: '',
                                }]
                        }, {
                            button_setting: {
                                container_class: "col-7 pl-2",
                                button_message: "Delete",
                                button_css_setting: "btn btn-danger btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'project_delete',
                                button_route: null
                            },
                            input_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            output_setting: null
                        }
                    ]
                }]
        }]
};

export const PROJECT_MODIFY: InputWindow = {
    // General Settings
    window_type: 'project_modify',
    title: 'Modify Project',
    title_css: '',
    message_1: 'Changing it up?',
    message_1_container_css: '',
    message_1_css: '',
    show: 'project_modify',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 3,
    process_message: '',
    passed_data: null,
    row: [{
            logic: 'passed_data',
            row_type: [{
                    // SAVE PROJECT ROW: False passed data
                    logic: true,
                    row_type: 0,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: '',
                                button_route: null
                            },
                            input_setting: {
                                input_min: '',
                                input_surpress_header: false,
                                input_has_action_btn: false,
                                input_value: null,
                                input_container_css_setting: 'mb-3',
                                input_tabindex: 1,
                                input_place_holder: "Project Name",
                                input_name: "project_name",
                                input_type: 'text',
                                input_autofocus: false,
                                input_autocomplete: "off",
                                input_error: 'What\'s in a name?',
                                input_pass: [1, ''],
                                input_pass_reqirements: [{ char_length: 1 }],
                                input_disabled: false,
                                input_readonly: false,
                                input_action: 'project_update',
                                input_css_setting: 'text-normal',
                                input_data: null,
                                output_data: null,
                                input_submit: null,
                                input_submit_to: null,
                                input_dom_rect: null,
                            },
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            output_setting: null,
                        }]
                }]
        }, {
            logic: 'passed_data',
            row_type: [{
                    // Save project Account section
                    logic: true,
                    row_type: 7,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'route',
                                button_route: null,
                            },
                            input_setting: {
                                input_min: '',
                                input_surpress_header: false,
                                input_has_action_btn: false,
                                input_value: null,
                                input_tabindex: 1,
                                input_place_holder: '',
                                input_name: '',
                                input_type: '',
                                input_autofocus: false,
                                input_autocomplete: "off",
                                input_error: '',
                                input_pass: [1, ''],
                                input_pass_reqirements: null,
                                input_disabled: false,
                                input_readonly: false,
                                input_action: null,
                                input_css_setting: '',
                                input_submit: null,
                                input_submit_to: null,
                                input_dom_rect: null,
                                input_container_css_setting: 'mb-3',

                                // Autocomplete
                                input_data: {
                                    useCase: 'auto_complete',
                                    validCheck: null,
                                    place: null,
                                    placeholder: 'Project Address'
                                },
                                output_data: {
                                    valid: null,
                                    place: []
                                }
                            },
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            },
                            output_setting: null,
                        }]
                }
            ]
        }, {
            logic: 'passed_data',
            row_type: [{
                    logic: true,
                    row_type: 3,
                    setting: [
                        {
                            button_setting: {
                                container_class: "col-5 pr-2",
                                button_message: "Cancel",
                                button_css_setting: "btn btn-warning btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'dismiss',
                                button_route: null
                            },
                            input_setting: null,
                            output_setting: [{
                                    confirm_message: '',
                                    confirm_button_pass: '',
                                    confirm_button_cancel: '',
                                    pass_title: '',
                                    pass_title_css: '',
                                    pass_message: '',
                                    pass_message_css: '',
                                    pass_button_1: '',
                                    pass_button_1_css: '',
                                    fail_title: '',
                                    fail_message: '',
                                    button_css_setting: 'col-3 pl-2 w-100',
                                    fail_button_1: '',
                                    fail_button_1_css: '',
                                    fail_button_2: '',
                                    fail_button_2_css: '',
                                }],
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            }
                        },
                        {
                            button_setting: {
                                container_class: "col-7 pl-2",
                                button_message: "Update",
                                button_css_setting: "btn btn-primary btn-lg w-100",
                                button_visible: true,
                                button_enable: [2, ''],
                                button_click_action: 'project_modify',
                                button_route: null
                            },
                            input_setting: {
                                input_min: '',
                                input_surpress_header: false,
                                input_has_action_btn: false,
                                input_value: null,
                                input_container_css_setting: 'mb-3',
                                input_tabindex: 1,
                                input_place_holder: '',
                                input_name: '',
                                input_type: '',
                                input_autofocus: false,
                                input_autocomplete: "off",
                                input_error: '',
                                input_pass: [1, 'address_components'],
                                input_pass_reqirements: null,
                                input_disabled: false,
                                input_readonly: false,
                                input_action: null,
                                input_css_setting: '',
                                input_data: null,
                                output_data: null,
                                input_submit: null,
                                input_submit_to: null,
                                input_dom_rect: null,
                            },
                            output_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            }
                        }
                    ]
                }]
        }]
};

export const DOWNLOAD_PDF: InputWindow = {
    // General Settings
    window_type: 'download_pdf',
    title: 'Download PDF',
    title_css: '',
    message_1: 'Confirm your download... ',
    message_1_container_css: '',
    message_1_css: '',
    show: 'download_pdf',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 2,
    process_message: '',
    passed_data: null,
    row: [{
            // DOWNLOAD
            logic: null,
            row_type: [{
                    // NO LOGIC FOR CANCEL
                    logic: null,
                    row_type: 6,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'route',
                                button_route: null,
                            },
                            input_setting: null,
                            output_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            }
                        }]
                }]
        }, {
            // CANCEL
            logic: null,
            row_type: [{
                    // NO LOGIC FOR CANCEL
                    logic: null,
                    row_type: 5,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: "Nevermind",
                                button_css_setting: "w-100 btn btn-primary btn-warning btn-lg",
                                button_enable: null,
                                button_visible: true,
                                button_click_action: 'route',
                                button_route: null,
                            },
                            input_setting: null,
                            output_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            }
                        }]
                }]
        }],
};

export const CARD_DELETE: InputWindow = {
    // General Settings
    window_type: 'card_delete',
    title: 'Card Deletion',
    title_css: '',
    message_1: 'Do you wish to delete the following card?',
    message_1_container_css: '',
    message_1_css: '',
    show: 'card_delete',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 2,
    process_message: '',
    passed_data: null,
    row: [{
            logic: null,
            row_type: [{
                    logic: null,
                    row_type: 8,
                    setting: [{
    button_setting: {
        container_class: '',
        button_message: '',
        button_css_setting: '',
        button_visible: true,
        button_enable: null,
        button_click_action: '',
        button_route: null
    },
    message_setting: {
        container_class: "flex-row",
        message_css_setting: "text-center w-100 pb-2",
        message_visible: false,
        message_output: ''
    },
    input_setting: null,
    output_setting: null,
    }]
                }]
        }, {
            logic: null,
            row_type: [{
                    logic: null,
                    row_type: 3,
                    setting: [
                        {
                            button_setting: {
                                container_class: "col-5 pr-2",
                                button_message: "Cancel",
                                button_css_setting: "btn btn-warning btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'dismiss',
                                button_route: null
                            },
                            input_setting: null,
                            output_setting: [{
                                    confirm_message: '',
                                    confirm_button_pass: '',
                                    confirm_button_cancel: '',
                                    pass_title: '',
                                    pass_title_css: '',
                                    pass_message: '',
                                    pass_message_css: '',
                                    pass_button_1: '',
                                    pass_button_1_css: '',
                                    fail_title: '',
                                    fail_message: '',
                                    button_css_setting: 'col-3 pl-2 w-100',
                                    fail_button_1: '',
                                    fail_button_1_css: '',
                                    fail_button_2: '',
                                    fail_button_2_css: '',
                                }],
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            }
                        }, {
                            button_setting: {
                                container_class: "col-7 pl-2",
                                button_message: "Delete",
                                button_css_setting: "btn btn-danger btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'card_delete',
                                button_route: null
                            },
                            input_setting: null,
                            output_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            }
                        }
                    ]
                }]
        }]
};

export const ADMIN_CONFIRM: InputWindow =  {
    // General Settings
    window_type: 'admin_price_update',
    title: 'Confirm',
    title_css: '',
    message_1: 'Continue with updating the following?',
    message_1_container_css: '',
    message_1_css: '',
    show: 'admin_price_update',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 2,
    process_message: '',
    passed_data: null,
    row: [{
            logic: null,
            row_type: [{
                    logic: null,
                    row_type: 8,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: '',
                                button_route: null
                            },
                            input_setting: null,
                            output_setting: null,
                            message_setting: {
                                container_class: 'updater-container',
                                message_css_setting: "text-center medium w-100",
                                message_visible: false,
                                message_output: ''
                            },
                        }]
                }]
        }, {
            logic: null,
            row_type: [{
                    logic: null,
                    row_type: 3,
                    setting: [
                        {
                            button_setting: {
                                container_class: "col-5 pr-2",
                                button_message: "Cancel",
                                button_css_setting: "btn btn-warning btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'dismiss',
                                button_route: null
                            },
                            input_setting: null,
                            output_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            }
                        }, {
                            button_setting: {
                                container_class: "col-7 pl-2",
                                button_message: "Update",
                                button_css_setting: "btn btn-primary btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'admin_price_update',
                                button_route: null
                            },
                            input_setting: null,
                            output_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            }
                        }
                    ]
                }]
        }]
};

export const ADMIN_SEARCH: InputWindow = {
    // General Settings
    window_type: 'admin_search',
    title: 'Search Results',
    title_css: '',
    message_1: '',
    message_1_container_css: '',
    message_1_css: '',
    show: 'admin_search',
    window_info: [145, 300, 400],
    window_clean: true,
    window_css: 'modal-window account-search-window',
    rows: 1,
    process_message: '',
    passed_data: null,
    row: [{
            // MESSAGE
            logic: null,
            row_type: [{
                    logic: null,
                    row_type: 9,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: '',
                                button_route: null
                            },
                            input_setting: null,
                            output_setting: null,
                            message_setting: {
                                container_class: 'account-search-results-container',
                                message_css_setting: "text-center medium w-100",
                                message_visible: false,
                                message_output: ''
                            },
                        }]
                }]
        }]
};

export const LOCATION_CHECK: InputWindow = {
    // General Settings
    window_type: 'location',
    title: 'Before we begin.',
    title_css: '',
    message_1: 'Where\'s your project? (First 3 letters of your postal code)',
    message_1_container_css: '',
    message_1_css: 'modal-window-location-message-1',
    show: 'get_position',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 2,
    process_message: '',
    passed_data: null,
    row: [{
        logic: null,
        row_type: [{
        logic: null,
        row_type: 7,
        setting: [{
            button_setting: {
                container_class: '',
                button_message: '',
                button_css_setting: '',
                button_visible: true,
                button_enable: null,
                button_click_action: 'route',
                button_route: null,
            },
            input_setting: {
                input_min: '',
                input_surpress_header: false,
                input_has_action_btn: false,
                input_value: null,
                input_container_css_setting: 'mb-3',
                input_tabindex: 1,
                input_place_holder: '',
                input_name: '',
                input_type: '',
                input_autofocus: false,
                input_autocomplete: "off",
                input_error: '',
                input_pass: [3, /^([A-Za-z]\d[A-Za-z])$|^([A-Za-z]\d[A-Za-z]\s{1})$|^([A-Za-z]\d[A-Za-z]\s{1}\d[A-Za-z]\d)|^([A-Za-z]\d[A-Za-z]\d[A-Za-z]\d)$/],
                input_pass_reqirements: null,
                input_disabled: false,
                input_readonly: false,
                input_action: null,
                input_css_setting: '',
                input_submit: null,
                input_submit_to: null,
                input_dom_rect: null,
                // Autocomplete
                input_data: {
                    useCase: 'get_position',
                    validCheck: null,
                    place: null,
                    placeholder: 'Postal Code'
                },
                output_data: {
                    valid: null,
                    place: []
                }
            },
            output_setting: null,
            message_setting: {
                container_class: '',
                message_css_setting: '',
                message_visible: false,
                message_output: '',
            }
        }]
        }]
    }, {
        logic: null,
        row_type: [{
        logic: null,
        row_type: 3,
        setting: [{
                // Cancel Button
                button_setting: {
                    container_class: "col-5 pr-2",
                    button_message: "Nope",
                    button_css_setting: "btn btn-warning btn-lg w-100",
                    button_visible: true,
                    button_enable: null,
                    button_click_action: 'dismiss',
                    button_route: null
                },
                input_setting: null,
                output_setting: [{
                        confirm_message: '',
                        confirm_button_pass: '',
                        confirm_button_cancel: '',
                        pass_title: '',
                        pass_title_css: '',
                        pass_message: '',
                        pass_message_css: '',
                        pass_button_1: '',
                        pass_button_1_css: '',
                        fail_title: '',
                        fail_message: '',
                        button_css_setting: 'col-3 pl-2 w-100',
                        fail_button_1: '',
                        fail_button_1_css: '',
                        fail_button_2: '',
                        fail_button_2_css: '',
                    }],
                message_setting: {
                    container_class: '',
                    message_css_setting: '',
                    message_visible: false,
                    message_output: '',
                }
            }, {
                button_setting: {
                    container_class: "col-7 pl-2",
                    button_message: "Do It!",
                    button_css_setting: "btn btn-primary btn-lg w-100",
                    button_visible: true,
            button_enable: [3, /^([A-Za-z]\d[A-Za-z])$|^([A-Za-z]\d[A-Za-z]\s{1})$|^([A-Za-z]\d[A-Za-z]\s{1}\d[A-Za-z]\d)|^([A-Za-z]\d[A-Za-z]\d[A-Za-z]\d)$/],
            button_click_action: 'location',
                button_route: null
            },
            input_setting: null,
            output_setting: [{
                    confirm_message: '',
                    confirm_button_pass: '',
                    confirm_button_cancel: '',
                    pass_title: "You're in Our Range!",
                    pass_title_css: "text-center pt-4",
                    pass_message: "Enjoy our service, we are looking forward to your order!",
                    pass_message_css: "small text-center",
                    pass_button_1: "Let's go!",
                    pass_button_1_css: "btn btn-lg btn-primary w-100 mb-3 ",
                    fail_title: '',
                    fail_message: '',
                    button_css_setting: '',
                    fail_button_1: '',
                    fail_button_1_css: '',
                    fail_button_2: '',
                    fail_button_2_css: '',
                }, {
                    confirm_message: '',
                    confirm_button_pass: '',
                    confirm_button_cancel: '',
                    pass_title: "You're pretty close...",
                    pass_title_css: "text-center pt-4 text-warning",
                    pass_message: "Give us a call 647-476-8337, we'll make it work!",
                    pass_message_css: "text-center medium py-4",
                    pass_button_1: "Let's go!",
                    pass_button_1_css: "btn btn-lg btn-primary w-100 mb-3 ",
                    fail_title: '',
                    fail_message: '',
                    button_css_setting: '',
                    fail_button_1: '',
                    fail_button_1_css: '',
                    fail_button_2: '',
                    fail_button_2_css: '',
                }, {
                    confirm_message: '',
                    confirm_button_pass: '',
                    confirm_button_cancel: '',
                    pass_title: "Out of service range...   We'll get to you!",
                    pass_title_css: "text-center pt-4 text-warning modal-location-out-of-range",
                    pass_message: "Give us a call 647-476-8337, to see when!",
                    pass_message_css: "text-center medium py-4",
                    pass_button_1: "On it!",
                    pass_button_1_css: "btn btn-lg btn-primary w-100 mb-3 ",
                    fail_title: '',
                    fail_message: '',
                    button_css_setting: '',
                    fail_button_1: '',
                    fail_button_1_css: '',
                    fail_button_2: '',
                    fail_button_2_css: '',
                }
                /*{ // REQUIRES A BUTTON AND ROW
                    title: "You're too far out...",
                    title_css: "text-danger",
                    message_1: "Out of service range. Leave your email!",
                    message_1_css: "",
                    input_setting: [{

                        row_type: 0, // Row type 0 is an input
                        setting:[{
                            input_setting:{
                                input_place_holder: "Email",
                                input_name: "email",
                                input_type: "email",

                                input_error:'Please enter a valid email.',
                                input_pass: [-1,''],
                                input_disabled: false,
                                input_readonly: false,

                                input_action: 'send_email',
                                input_css_setting: 'text-normal',
                            }
                        }]
                    },{
                        row_type: 3, // Row type 4 (Two buttons)
                        setting:[{
                            button_setting:{
                                container_class: "col-6 pr-2",

                                button_message: "Nope",
                                button_css_setting: "btn btn-warning btn-lg w-75",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'route',
                                button_route: null
                            },
                            input_setting:{
                                input_place_holder: '',
                                input_name: '',
                                input_type: '',
                                input_autofocus: false;

                                input_error: '',
                                input_pass: [-1,''],
                                input_disabled: false,
                                input_readonly: false,

                                input_action: null,
                                input_css_setting: '',
                            },
                            output_setting:{
                                confirm_message: '',
                                confirm_button_pass: '',
                                confirm_button_cancel: '',
                                pass_message: '',
                                fail_message: '',

                                button_css_setting: 'col-3 pl-2 w-100'
                            }},{
                            button_setting:{
                                container_class: "col-6 pl-2",

                                button_message: "Do It!",
                                button_css_setting: "btn btn-primary btn-lg w-100",
                                button_visible: ["isLogged",true],
                                button_enable: "input[0]",
                                button_click_action:'cart_upload',
                                button_route: null
                            },
                            input_setting:{
                                input_place_holder: '',
                                input_name: '',
                                input_type: '',
                                input_autofocus: false;

                                input_error: '',
                                input_pass: [1],
                                input_disabled: false,
                                input_readonly: false,

                                input_action: null,
                                input_css_setting: '',
                            },
                            output_setting:{
                                confirm_message: "Confirm",
                                confirm_button_pass: '' Queen!",
                                pass_message_css: "",

                                confirm_button_cancel: "No!",
                                pass_message: "PaSS!",
                                fail_message: "Fail!",

                                button_css_setting: 'col-3 pl-2 w-100'

                        }}]

                    }],
                    pass_title: "You're far out man...",
                    pass_title_css: "text-center pt-4",

                    pass_message: "We aren't there yet. Leave us your email, we'll inform you when we open in your area",
                    pass_message_css: "text-center medium py-4",

                    pass_button_1: "To the shop...",
                    pass_button_1_css: "btn btn-lg btn-primary w-100 mb-3 ",
                }*/ 
            ],
            message_setting: {
                container_class: '',
                message_css_setting: '',
                message_visible: false,
                message_output: '',
            }
        }]
        }]
    }]
};

export const DEFAULT_WINDOW:  InputWindow =  {
    // General Settings
    window_type: '',
    title: '',
    title_css: '',
    message_1: '',
    message_1_container_css: '',
    message_1_css: '',
    show: '',
    window_info: [145, 163, 163],
    window_clean: false,
    window_css: 'modal-window',
    rows: 2,
    process_message: '',
    passed_data: null,
    row: [{
            logic: null,
            row_type: [{
                    logic: null,
                    row_type: 8,
                    setting: [{
                            button_setting: {
                                container_class: '',
                                button_message: '',
                                button_css_setting: '',
                                button_visible: true,
                                button_enable: null,
                                button_click_action: '',
                                button_route: null
                            },
                            input_setting: null,
                            output_setting: null,
                            message_setting: {
                                container_class: 'updater-container',
                                message_css_setting: "text-center medium w-100",
                                message_visible: false,
                                message_output: ''
                            },
                        }]
                }]
        }, {
            logic: null,
            row_type: [{
                    logic: null,
                    row_type: 3,
                    setting: [
                        {
                            button_setting: {
                                container_class: "col-5 pr-2",
                                button_message: "Cancel",
                                button_css_setting: "btn btn-warning btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'dismiss',
                                button_route: null
                            },
                            input_setting: null,
                            output_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            }
                        }, {
                            button_setting: {
                                container_class: "col-7 pl-2",
                                button_message: "Let's go!",
                                button_css_setting: "btn btn-primary btn-lg w-100",
                                button_visible: true,
                                button_enable: null,
                                button_click_action: 'continue',
                                button_route: null
                            },
                            input_setting: null,
                            output_setting: null,
                            message_setting: {
                                container_class: '',
                                message_css_setting: '',
                                message_visible: false,
                                message_output: '',
                            }
                        }
                    ]
                }]
        }]
};