import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TrelloService } from './trello.service';
import { BehaviorSubject } from 'rxjs';
import { ApiView, DEFAULT_API_VIEW } from '../classes/api-view';
import { StorageService } from 'src/app/general-services/storage.service';
import { ToolRoutesService } from 'src/app/accounts/account-tools/tool-routes/tool-routes.service';

declare var Trello: any;

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
  jrg_trello_key = '097d608a9c59562645c3fc58675e770e';
  defaultError = 'Oh my more failures abound...';

  subjectApiView = new BehaviorSubject<ApiView>(DEFAULT_API_VIEW);
  apiView$ = this.subjectApiView.asObservable();

  // Trello Key's
  constructor(
    private http: HttpClient, 
    private trelloService: TrelloService,
    private storageService: StorageService,
    private routeService: ToolRoutesService
  ) {

  }
  nextApiView(newApiView: ApiView) {
      this.subjectApiView.next(newApiView);
  }

  currentApiView() {
      return this.subjectApiView.value;
  }

  changeApiView(change: string, to: boolean) {
    const currentView = this.currentApiView();
    switch (change) {
        case 'trello':
            currentView.showTrello = to;
            break;
    }
    this.nextApiView(currentView);
  }

  clearErrors() {
      const newApiView = this.currentApiView();
      newApiView.error.showResponseFail = false;
      newApiView.error.failMessage = this.defaultError;
      this.nextApiView(newApiView);
  }

  // MARK - API's Requests
  //TRELLO
  requestTrelloToken(from: string) {
    this.routeService.setView('loading', true, null, 'requestTrelloToken')
    
    Trello.authorize({
        type: 'popup',
        name: 'Roomerator',
        scope: {
            read: 'true',
            write: 'true'
        },
        return_url: 'http://localhost/',
        expiration: 'never',
        success: () => { this.trellAuthSuccess(from); },
        error: () => { this.trelloAuthFailure(from); },
    });
  }

  trellAuthSuccess(from: string) {
    console.log("logged in");
    const newTrelloInfo = this.trelloService.currentTrelloInfo();
    newTrelloInfo.trello_key = this.jrg_trello_key;
    newTrelloInfo['trello_token'] = localStorage['trello_token'];
    this.trelloService.nextTrelloInfo(newTrelloInfo, "trellAuthSuccess");

    const newRouteView = this.routeService.currentRouteInfo();
    newRouteView.view.isLoading = false;
    newRouteView.view.showDrivers = true;
    newRouteView.view.showRoutePage = true;
    this.routeService.nextRouteInfo(newRouteView, 'trellAuthSuccess')
    
    console.log(newTrelloInfo, "Before the send the real send");
    

  }

  trelloAuthFailure(from: string) {
    console.error('Can\'t string this shit together from: ', from);
    console.log("Couldn't authenticate successfully.");

    if(from === 'routes'){
      const newRouteView = this.routeService.currentRouteInfo();
      newRouteView.view.isLoading = false;
      newRouteView.view.showDrivers = false;
      this.routeService.nextRouteInfo(newRouteView, 'trelloAuthFailure');
    }
  }

  // ZOHO
  requestZohoToken() {
    // This variable will be changed to a variable provided during user sign in
    const jrg_zoho_id = '1000.02Z12LZRYYPEL9Z3I1OKXHSXOOTCVX';
    const jrg_zoho_secret = 'ea4f0d13bd78229eb9697ef22b6dd364b048d51b5d';
      `https://accounts.zoho.com/oauth/v2/token?
      code=1000.dd7e47321d48b8a7e312e3d6eb1a9bb8.b6c07ac766ec11da98bf6a261e24dca4
      &client_id=${jrg_zoho_id}
      &client_secret=${jrg_zoho_secret}
      &redirect_uri=http://www.zoho.com/books
      &grant_type=authorization_code
      `;
    const requestZohoAuthURL = `
      https://accounts.zoho.com/oauth/v2/auth&client_id=${jrg_zoho_id}&
      response_type=token&
      scope=AaaServer.profile.Read&
      redirect_uri=https://www.zylker.com/oauthredirect`;
    const requestZohoAuth = this.http.get(requestZohoAuthURL);
    return requestZohoAuth;
  }

  handleError(error: any) {
      console.error('An error occured', error);
      return Promise.reject(error.message || error);
  }
}