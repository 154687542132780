import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  freezer = localStorage;
  fridge = sessionStorage;

  trello_token = localStorage['trello_token']

  constructor() { }

  testStorage() {
    let result;
    try {
        const data = JSON.stringify(new Date);
        this.freezer.setItem(data, data);
        result = this.freezer.getItem(data) != data;
        this.freezer.removeItem(data);
    }
    catch (exception) {
        console.error("testing failure!@#!#")
        return "Error";
    }
    if (result !== false) {
        return "Error";
    }
    return "Success";
  }

  // MARK: - Get's
  getStorage(entry: string) {
      try {
          let frozen = this.freezer.getItem(entry);
          if (frozen) {
              return JSON.parse(frozen);
          }
          else {
              return null;
          }
      }
      catch (e) {
          this.cleanStorage(entry);
          return null;
      }
  }
  getFridge(entry: string) {
      try {
          let fridge = this.fridge.getItem(entry);
          if (fridge) {
              return JSON.parse(fridge);
          }
          else {
              return null;
          }
      }
      catch (e) {
          this.cleanFridge(entry);
          return null;
      }
  }

  getFullStorage() {
      var values = [], keys = Object.keys(localStorage), i = keys.length;
      while (i--) {
          values.push(localStorage.getItem(keys[i]));
      }
      return values;
  }

  // MARK: - Set's
  setStorage(data: any, entry: any) {
    this.freezer.setItem(entry, JSON.stringify(data));
  }
  setFridge(data: any, entry: string) {
      this.fridge.setItem(entry, JSON.stringify(data));
  }
  // MARK: Functions's
  cleanStorage(entry: string) {
      this.freezer.removeItem(entry);
  }
  cleanFridge(entry: string) {
      this.fridge.setItem(entry, '');
  }
  clearFridge(entry: string) {
      this.fridge.clear();
  }
}

