<form >
  <div class="full-container">
    <app-navbar></app-navbar>

    <animation-left-right
      class="hotbar-animation-container"

      [isLeftRight]="view.navbarView.showDropDownMenu" 
      [id]="'toolbar'"
      [ngClass]="{  'hotbar-animation-show-container': view.navbarView.showDropDownMenu,
                    'hotbar-animation-hide-container': !view.navbarView.showDropDownMenu,
                    'collapse.show': view.navbarView.showDropDownMenu,
                    'collapse': !view.navbarView.showDropDownMenu}">
      <app-hotbar ></app-hotbar>
    </animation-left-right> 
    


    <!-- Landing Page-->
    <div class="content-container">
      <app-account-landing></app-account-landing>
    </div>
      <!--App command success message-->

  
  </div>

  <!-- Footer -->
  <app-footer></app-footer>
  
  <animation-fly-downup 
    class="animation-down-up-container" 
    [isDownUp]="view.showMessage" 
    [id]="'action-container'"
    [ngClass]="{  'hotbar-animation-show-container': view.showMessage,
                  'hotbar-animation-hide-container': !view.showMessage,
                  'collapse.show': view.showMessage,
                  'collapse': !view.showMessage}">
    <app-copy-text></app-copy-text>
  </animation-fly-downup>

 

  <!-- Input Window -->
  <app-fade-in-out-animation [isInOut]="view.showInputWindow" *ngIf="view.showInputWindow">
    <app-input-window></app-input-window>
  </app-fade-in-out-animation>

    

  
  

    
</form>