import { Component } from '@angular/core';

@Component({
  selector: 'app-tool-carry',
  templateUrl: './tool-carry.component.html',
  styleUrls: ['./tool-carry.component.css']
})
export class ToolCarryComponent {

}
