import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { Item } from 'src/app/cart-item/classes/item';
import { ItemService } from 'src/app/cart-item/services/item.service';
import { AccountUpdaterInputSetting, AccountUpdaterSearchSetting } from 'src/app/modal/inputbox/inputbox.setting/account.updater';
import { ToolUpdaterService } from './tool-updater.service';

import { Subscription, Observable } from 'rxjs' 
import { ViewService } from 'src/app/general-services/view/view.service';
import { InputSetting } from 'src/app/modal/inputbox/inputbox';
import { StorageService } from 'src/app/general-services/storage.service';
import { PriceUpate, UpdaterView } from './classes/tool-updater';
import { InputWindowService } from 'src/app/modal/input-window/input-window.service';
import { AdminLocalStorage } from 'src/app/general-services/class/admin-local-storage';
import { AccountService } from '../../services/accounts.service';

@Component({
  selector: 'app-tool-updater',
  templateUrl: './tool-updater.component.html',
  styleUrls: ['./tool-updater.component.css']
})
export class ToolUpdaterComponent implements OnInit, OnDestroy {
  search_setting = AccountUpdaterSearchSetting;

  check_room_number = /^\d{8}/;

  itemNumberSearch: Observable<Item[]>;

  items: Item[] = [];

  filteredItem!: Item;
  filteredItemSub: Subscription;


  pendingUpdate: PriceUpate = new PriceUpate;
  pendingUpdates: PriceUpate[] = [];
  pendingUpdatesSub: Subscription;

  updaterView!: UpdaterView;
  updaterViewSub: Subscription;

  selectedForUpdating: boolean[] = [];
  showPriceInput: boolean[] = [];

  input_price_update: InputSetting = AccountUpdaterInputSetting;

  updateButtonMsg = "Update Dis?";
  previousPrice: number = -1;
  priceDisplay: boolean[] = [];

  disableSubmit = true;

  updateTracker: number[] = [];
  previouslyUpdateTracker: boolean[] = [];

  scrapperBtnMsg = 'Run Scrapper'

  @HostListener('document:keydown.enter', ['$event']) onEnterKeyPressed(event: KeyboardEvent): void {
    // Perform the action you want here
    console.log('Enter key pressed', event);
  }

  constructor(
    private windowService: InputWindowService,
    private itemService: ItemService,
    private updaterService: ToolUpdaterService,
    private storageService: StorageService,
    private viewService: ViewService,
    private changeDector: ChangeDetectorRef,
    private accountService: AccountService
  ){
    
    this.filteredItemSub = this.updaterService.filteredItemResults$
      .subscribe( (newItems: Item)  => { 
        this.filteredItem = newItems;
        changeDector.markForCheck();
    })

    this.pendingUpdatesSub = this.updaterService.pendingUpdates$
      .subscribe( (newUpdate: PriceUpate[])  => { 
        this.pendingUpdates = newUpdate;
        changeDector.markForCheck();
    })

    this.updaterViewSub = this.updaterService.updateView$
      .subscribe( (newView: UpdaterView)  => { 
        this.updaterView = newView;
        changeDector.markForCheck();
    })


    this.itemNumberSearch = this.updaterService.searchResults$
  }

  ngOnInit(): void {
    // What does what???      
      //scraper_status': STATUS OF CURRENT SCRAP: null|pending|running 
      //scraper_run': STARTS THE SCARPPER
      //price_error: FAILED PRICES, options are item codes 
      //price_cold: AGE OF UPDATES 
      //price_update": USED FOR UPDATING PRICE... DUH
      //"catalog": [{
        //"item_number": 11200278,
        //"item_price": "113.26"
      //}]
        

    
    const checkDate: AdminLocalStorage = this.storageService.getStorage('admin');
    console.log(checkDate, "Date Checking");
    this.getScrapperStatus();

    /*this.runUpdateAndErrorDetect();
    if(checkDate && checkDate.admin_settings && checkDate.admin_settings.update_requested_date){
      this.runUpdateAndErrorDetect();
    }*/

    // First thing is to check if there is a scapper running
  }

  ngOnDestroy(): void {
    if(this.pendingUpdates.length > 0){
      console.log("PRompt submit checkl!@#!?")
    }
    this.filteredItemSub.unsubscribe();
    this.pendingUpdatesSub.unsubscribe();
  }

  // MARK - Updater
  checkPendingUpdate(itemNumber: number): any{
    const check = this.pendingUpdates.find(el => el.item.item_number === itemNumber);
    if(check){return true}
    return false
  }

  checkPreviousUpdated(itemNumber: number): boolean{
    
    
    const currentlyUpdated = this.updaterView.updatedItems.item_numbers;

    const check = currentlyUpdated.find(el => el === itemNumber)

    if(check){return true}

    return false;
  }

  getTrackedItem(itemNumber: number): PriceUpate|null{
    const trackedIndex = this.pendingUpdates.findIndex(el=> el.item.item_number === itemNumber)
    if(trackedIndex >= 0){return this.pendingUpdates[trackedIndex]}

    return null
  }

  submitQuery(value: string, index: number){

    if(this.check_room_number.test(value) && value.length === 8){

      const check_digit = +(value.charAt(3));

      let diviser = 10000;

      if(check_digit === 0){diviser = 100000}

      const id = +value;
      const sub_id = Math.floor(id/diviser);
      this.completeQuery(sub_id, id)
    }   
  }

  completeQuery(sub_id: number, id: number){
    this.updaterService.getSearchedItem(sub_id).subscribe({
        next:(data:any) => { 
          const items: Item[] = data.catalog;
          const itemIndex = items.findIndex((item: Item ) => item.item_number === id);

          this.selectedForUpdating.fill(false, items.length);

          const filteredItem = items.splice(itemIndex);
          
          this.updaterService.nextSearchResult(items);
          this.updaterService.nextFilteredResult(filteredItem[0]);
        },
        error: (error:any) => { this.handleError(error) }
      })
  }

  initPriceUpdate(index: number, previous_price: number){
    this.showPriceInput = [];

    this.previousPrice = previous_price;
    
    this.showPriceInput.fill(false, this.selectedForUpdating.length);
    this.showPriceInput[index] = true;
  }

  updatePrice(value: string|null, item: Item, index: number){
    this.previousPrice = item.item_price;

    const checkValue = value
    
    if(checkValue && +checkValue !==this.previousPrice){
      this.disableSubmit = false;

      const pendingUpdate = new PriceUpate

      pendingUpdate.item = item;
      pendingUpdate.previous_price = item.item_price;
      pendingUpdate.new_price = +checkValue;
      pendingUpdate.commiteDate = new Date;

      if(pendingUpdate.previous_price > pendingUpdate.new_price){this.priceDisplay[index] = false}else{this.priceDisplay[index]=true}

      this.pendingUpdate = pendingUpdate;

    }

    if(value && +this.previousPrice === +value){
      this.disableSubmit = true;
      console.log('should be disabling');
    }

    if(!value){this.disableSubmit = true;}

  }

  formatCurrency(value: string){
    return parseFloat(value).toFixed(2);
  }

  cancelUpdate(index: number){this.showPriceInput[index] = false; console.error("Cancelling!@#!@#")}

  delSubmittedUpdate(item_number: number){ 
    const index = this.pendingUpdates.findIndex(el => el.item.item_number === item_number)

    if(index >= 0){this.pendingUpdates.splice(index, 1);} else { console.error("Something is up!?!?!?!")}
    

    console.log(this.pendingUpdates, "Hello and cancel", index)
    this.updaterService.nextPendingUpdate(this.pendingUpdates)
  }

  enterBtnUpdate (value: string|null, index: number, item: Item){
    if(value && +value !==this.previousPrice){

      this.disableSubmit = false;
      
      this.submitPriceChange(item, index);
    }

  }

  submitPriceChange(item: Item, index: number){
    
    this.showPriceInput[index] = false;
    const updateTracker = item.item_number;

    const newUpdateList = this.pendingUpdates;
    newUpdateList.push(this.pendingUpdate);

    this.updaterService.nextPendingUpdate(newUpdateList);
    this.updateTracker.push(updateTracker);
    
  }

  // MARK - Pending updates
  editUpdatingList( action: string, updateTracker: PriceUpate){
    console.log(action,updateTracker)
  }

  submitUpdate(){
    const newWindow = this.windowService.currentModalWindowInfo();

    newWindow.passedData = this.pendingUpdates
    newWindow.currentType = 'updater_submit'
    newWindow.isLoading = true

    this.windowService.nextModalWindowInfo(newWindow);
    this.viewService.changeView('show_window', true, null, 'tool-update.component:submitUpdate');
  }

  // MARK - Price Scrapper//Updater
  getScrapperStatus(){
    const requestScrapperStatus = this.accountService.requestAdminUpdate('scraper_status', 'tool-updated:getScrapperStatus');
    if(requestScrapperStatus){
      requestScrapperStatus.subscribe({
        next:(data:any) => { 
          console.log(data, "Scrapper is a thing of thing!")
        },
        error: (error:any) => { this.handleError(error) }
      })
      
    }
  }


  getDisabledScrapper():boolean{
    /*
    const admin: AdminLocalStorage = this.storageService.getStorage('admin');
    if(admin){
      const adminSettings = admin.admin_settings;
      if(adminSettings){
        const checkDate = adminSettings.update_requested_date;
        if(checkDate){
          const today = new Date;
          const timeCompare = today.getTime() - checkDate;
          const millisecondsIn24Hours = 24 * 60 * 60 * 1000;

          // Compare the time difference to the number of milliseconds in 24 hours
          if (timeCompare <= millisecondsIn24Hours){ 
            
            return true };
        }
      }
    }*/

    return false
  }

  runPriceScrapper(){
    console.log("Should be running this scrapper thing");

    const today = new Date;

    let admin_settings: AdminLocalStorage = this.storageService.getStorage('admin');
    if(!admin_settings){ admin_settings = new AdminLocalStorage};

    admin_settings.admin_settings.update_requested_date = today.getTime();
    this.storageService.setStorage(admin_settings, 'admin');

    const requestUpdatedPrice = this.accountService.buildPost('admin_update_price', null);


  }

  runUpdateAndErrorDetect(){
    const request = this.accountService.requestAdminUpdate('admin_price_cold', 'tool-updater:runUpdateAndErrorDetect');
    if(request){
      console.log("Running scrap request!")
      request.subscribe({
        next:(data:any) => { 
          console.log(data, "<-----  more data")
        },
        error: (error:any) => { this.handleError(error) }
      })
    }
  }

  private handleError(error: any): Promise<any> {
    console.error("Query submit: ERROR 1001")
    return Promise.reject(error.message || error);
  }



}
