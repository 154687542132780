import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, find, findIndex, forkJoin, map, observable, retry } from 'rxjs';
import { CURRENT_DRIVERS, DEFAULT_EMAIL_ORDER, DEFAULT_ROUTE_CHANGES, DEFAULT_TOOL_ROUTE, DEFAULT_VENDORS, Driver, EmailOrder, RouteChange, RouteDragDrop, DragItem, TESTING_DRIVER, ToolRoutes, RouteBtnContextView, DEFAULT_ROUTE_OPTIONS_BUTTON, RouteIndexes, ShowCompeteRouteItems,  } from './classes/tool-routes';
import { CARD_DAILY_MAINTENANCE, ROUTE_CARD, TrelloCard, TrelloCheckItem, TrelloChecklist, TrelloLabel, } from 'src/app/api/classes/trello';
import { TrelloService } from 'src/app/api/services/trello.service';
import { AccountService } from '../../services/accounts.service';
import { DefaultAppConfig } from 'src/app/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ToolRoutesService {
  token = localStorage['trello_token'];
  key = '097d608a9c59562645c3fc58675e770e';

  idBoardJRG = "5ceb1acc784b294a81c1e647";
  idBoardHolding = '5ea969ad22a3425649825fa5';
  idListTesting = '609a6bc7ed92fd8bec9c3c08';
  idListTitg = '652da076674df197bee13726';
  idListRNS = "5eb80fee9cbbb947fcc06cdb";

  idBoardTeam = "5d9d218d17b87227bfe9980a";
  idListTeam = "5d9d21938f451d773ed3b1ef";

  subjectToolRoute = new BehaviorSubject(DEFAULT_TOOL_ROUTE);
  routeView$ = this.subjectToolRoute.asObservable();

  subjectEmailOrder = new BehaviorSubject(DEFAULT_EMAIL_ORDER);
  emailOrder$ = this.subjectEmailOrder.asObservable()

  constructor(
    private trelloService: TrelloService, 
    private accountService: AccountService,
    private http: HttpClient,
  ) {}
  
  currentRouteInfo(): ToolRoutes { return this.subjectToolRoute.value};
  nextRouteInfo(routeInfo:  ToolRoutes, from: string) { this.subjectToolRoute.next(routeInfo)};

  currentEmail():EmailOrder[]{return this.subjectEmailOrder.value};
  nextEmail(email: EmailOrder[], from:string){this.subjectEmailOrder.next(email)};

  setView(what: string, to:any, data: any, from:string){
    const currentView = this.currentRouteInfo()

    switch(what){
      case 'show_board': currentView.view.showBoard = to; break;
      case 'show_info': currentView.view.trelloView.showCard = to; currentView.view.showBackground = to;currentView.view.showOptions = false;  break;
      case 'show_email': 
        currentView.view.showEmailOrder = to; 
        currentView.view.showBackground = to;  
        currentView.view.showOptions = false;
        currentView.view.showConfirmWindow = false;
        break;
      case 'show_drivers': currentView.view.showDrivers = to; break;
      case 'show_confirm_window': {
        currentView.view.showConfirmWindow = to; 
        currentView.view.showBackground = to; 
        currentView.view.showOptions = to;
        if(data){currentView.view.confirmWindow = data };

        break; 
      }

      case 'show_titg_sidebar':{currentView.view.showTitgSidebar = currentView.view.showTitgSidebar ? false:true;break }

      case 'show_btn_options': 

        currentView.view.showBackground = true;
        currentView.view.showOptions = true;
        currentView.view.showInputbox = false
        
        const newRouteBtnContextView = new RouteBtnContextView;

        newRouteBtnContextView.btnContextMenuInfo = data.btn;
        newRouteBtnContextView.domRect = data.domRect;

        currentView.view.routeBtnContextView = newRouteBtnContextView;
      break;

      case 'loading': currentView.view.isLoading = to; break;

      case 'mini': 
        if(typeof data === 'number' && data === -1){  
          for (let index = 0; index < currentView.activeDrivers.length; index++) {currentView.trackerMini[index] = true;}}
        else{ currentView.trackerMini[data] = currentView.trackerMini[data] ? false: true; };
      break;

      case 'dismiss': 
        currentView.view.showInputbox = false;
        currentView.view.showConfirmWindow = false;
        currentView.view.showEmailOrder = false;
        currentView.view.showOptions = false;
        currentView.view.showBackground = false;
        currentView.view.trelloView.showCard = false;
        currentView.view.showPictures = false;
      break;
    }
    
    this.nextRouteInfo(currentView,"routeService");
  }

  // MARK - Making Routes (Mapping and moving)
  moveCards(post: any, from: string){
    
    post.subscribe({
      next: (data: any[]) => {
        this.refreshAllBoards();
       },
      error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
    })
    
  }

  movePendingToTitg(){
    const idBoardHolding = ''
    const idListPending = '5ea969ad22a3425649825fa5';
    const idListTitg = this.idListTitg;

    const requestPending = this.trelloService.getRequest('list_card', idListPending );

    if(requestPending){
      requestPending.subscribe(

      )
    }

    // Get all Pending List
  }

  moveDragItems(dropData: RouteDragDrop, type: string){   
    /*function routeCheck(dragItem: DragItem){
      let returnCheck = false;
      const dragName = dragItem.name;
      
      const includeList = ['pickup', 'drop', 'carry', 'p&p', 'pickup & pay', 'build', 'load', 'return', 'returns'];

      includeList.forEach(check => {if(dragName.includes(check)){returnCheck = true}});
      return returnCheck;
    }*/

    const postItems = dropData.postItems;
    const driver = dropData.driver;

    // Setup observable and Route Changes
    let postData: Observable<Object>[] = [];
    const routeChanges: RouteChange[] = [];
 

    // Index counter
    let index = 0;'';
    let pos: any = 'bottom';
    
    postItems.forEach(dragItem => {
      // Setup RouteChange Tracker;
      let newRouteChange = new RouteChange;
      const routeIndexes: RouteIndexes = {
        indexTitgCardOrDriver: dropData.indexTitgCardOrDriver,
        indexTitgChecklistOrRouteDragItem: -1,
        indexDragItem: -1,
      }
      
      // Move Logic, 2 parts, 1 part make a new checklist item in the drivers route and the second update the checklist on the card to say to was routed
      /*const idBoard = driver.trelloInfo.idChecklistDriverRoute;
      const idChecklistRoute = driver.routeCard.idChecklists[0];
      const idChecklistCard = dragItem.idChecklist;
      const idCheckItem = dragItem.idCheckItem;

      let passDropData = dropData;
    */
      if(dropData.nextIndex !== -1 && dropData.previousIndex !== -1){ 
        pos = this.trelloService.setPos(dropData, type);
      } else if (postItems.length > 1) { pos = 'bottom' }
      else{ 
        pos = index * 10000 + 100000000;
        dropData.nextIndex = index;
      };
 
      const newPostData = this.setupItemToItemCreation(type,driver,dragItem,dropData,pos,'moveDragItems');
     
      postData = [...postData, ...newPostData];

      index++;
    });

    // Move the route card to the driver board during mass move
    if(dropData.dragCard.length > 0){
      const idList = dropData.driver.trelloInfo.idChecklistDriverCards;
      const idBoard = dropData.driver.trelloInfo.idBoardDriver;
      const idCard = dropData.dragCard[0].idCard;

      const moveCardData= {idList: idList, idBoard: idBoard};
      postData.push(this.trelloService.putRequest('put:card',idCard, '', '', moveCardData)); 
    }
    
    const request = forkJoin(postData);
    if(request){this.generalSubscription(request, routeChanges, type) };    
  }

  generalSubscription(request: any, routeChanges: RouteChange[]|null, from: string){

    request.subscribe({
      next: (data: any) => { 
        this.refreshAllBoards();      
      },
      error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
    } )
  }

  // MARK - MAPPING Stuff
  mapActiveCards( responseCard: any[]): TrelloCard[]{
    const newActiveCards: TrelloCard[] = []

    responseCard.forEach((data:any) => {
      if(data){
        if('200'in data){
          const responseData: any[] = data['200'];
          if(responseData.length>0){responseData.forEach(card => { newActiveCards.push(card)})};
        }else{newActiveCards.push(data)}; 
      };
    });

    return newActiveCards;
  }

  mapRouteCard(data: any[], from: string){
    const driverRoutes = data.map(el =>{return el['200']})

    const currentRoutes = this.currentRouteInfo();
    let i = 0
    currentRoutes.activeDrivers.forEach((driver: Driver) => {
      driver.routeCard = driverRoutes[i]
      i++
    });
    
    this.nextRouteInfo(currentRoutes, "mapRoutes")
  }  
  
  mapActiveCardtoDrag(data: any, driver: Driver, from: string): DragItem[]{
    const newDragItems: DragItem[] =[];
    let checkItems: any = null;

    if(data[0]&&data[0]['checkItems']){ checkItems = data[0]['checkItems']}
    else if(data['checkItems']){ checkItems = data['checkItems']}
    else{checkItems = data};
    if(checkItems){
      checkItems.forEach((el: TrelloCard) => {
        let pos = -1;
        if(typeof el.pos === 'number' ){pos = el.pos};
        const newDragCity = this.mapActiveDriverDragItem(el, driver, pos, 'mapActiveCardtoDrag');
        
        newDragItems.push(newDragCity);
      });
    }

    newDragItems.sort((a,b) => a.pos - b.pos);

    return newDragItems;
  }

  mapTitgCatalog(cards: TrelloCard[], dataChecklists: any[]): DragItem[]{
   
    let dragItems: DragItem[] = [];
    const newCardCatalog: DragItem[] = [];

    cards.forEach((card: TrelloCard) => {
      // Find the associated checklist 
      const indexFindAssociatedChecklist = dataChecklists.findIndex((checklist:TrelloChecklist[]) => checklist[0].idCard === card.id)

      // Map Checklist to Card (Not every card may have an associated checklist)
      if(indexFindAssociatedChecklist >= 0){
        const checklist = dataChecklists.splice(indexFindAssociatedChecklist,1);
        checklist[0].forEach((checklist: TrelloChecklist) => {
          // MARK - Push the completed drag Item
          dragItems.push(this.mapTitgDragItem(card, checklist, 'titg'));
        });
      } else { // Empty Checklist
        const emptyChecklist = new DragItem;
        emptyChecklist.idCard = '666';
        dragItems.push;
      }
      
      // Setup/Make Drop Item
      const  labelSearchArrayValues= ['DROP','CARRY', 'INFO'];
      const dropLabel = this.searchTrelloLabels(card.labels, labelSearchArrayValues, 'makeTrelloChecklist');

      if(dropLabel && dropLabel.name){
        const newChecklist = new TrelloChecklist;
        newChecklist.name = dropLabel.name;
        const dragItem = this.mapTitgDragItem(card, newChecklist, 'titg');
        dragItem.pos = 999999999999;

        //console.log(" Drop lables!@#!@#", dropLabel)

        dragItems.push(dragItem);
      } else {    
        const dragItem = new DragItem

        dragItem.displayName = 'Missing drop LAbel!';
        dragItem.name ='Missing drop LAbel!';
        dragItem.action = 'mia-drop-label'
        dragItem.driver = 'TBD';
        dragItem.pos = 999999999999;
        dragItems.push(dragItem);

      }

      // Make upper card and assign the drag items
      const newTitgCard: DragItem = this.mapTitgDragItem(card, null, 'newTitgCard')

      // Sort Drag Items
      //const sortedChecklistInDragItems = dragItems.sort((a,b) =>a.pos - b.pos);

      newTitgCard.dragItems = dragItems;
      //newTitgCard.dragItems = sortedChecklistInDragItems;

      newTitgCard.dueComplete = card.dueComplete;
      newTitgCard.action = 'card';
      newCardCatalog.push(newTitgCard);

      dragItems = [];
    })

    return newCardCatalog;
  }

  mapTitgDragItem(dataAssociatedCardOrDragItem: any, checklist: any, from: string):DragItem {
    
    // Discover Action and Location 
    let passName = '';
    if(!checklist){passName = dataAssociatedCardOrDragItem.name}else{passName = checklist.name}
    
    // Setup the Drag item by mapping Action Location Result to a DragItem
    const dragItem: DragItem = this.mapActionLocation(null, passName, dataAssociatedCardOrDragItem.name, dataAssociatedCardOrDragItem.labels, null, 'mapTitgDragItem');
    //this.newMapActionLocation(passName, dataAssociatedCardOrDragItem.name, dataAssociatedCardOrDragItem.labels, 'mapTitgDragItem')
    
    if(dragItem.idCard === '' && dataAssociatedCardOrDragItem instanceof TrelloCard){dragItem.idCard = dataAssociatedCardOrDragItem.id}
    else if(dragItem.idCard === '' && dataAssociatedCardOrDragItem instanceof DragItem){ dragItem.idCard = dataAssociatedCardOrDragItem.idCard}
    else{dragItem.idCard = dataAssociatedCardOrDragItem.id}
   
    if(checklist){
      dragItem.idChecklist = checklist.id;
      dragItem.checkItems = checklist.checkItems;
    } 

    if(checklist && typeof dataAssociatedCardOrDragItem.pos === 'number' && checklist){dragItem.pos = checklist!.pos} 
    else if(dataAssociatedCardOrDragItem && typeof dataAssociatedCardOrDragItem.pos === 'number'){dragItem.pos = dataAssociatedCardOrDragItem.pos!};

    // Assign Basic Stuff
    dragItem.state = dataAssociatedCardOrDragItem.state;
    dragItem.idChecklists = dataAssociatedCardOrDragItem.idChecklists;
    
    
    
    // Assign Blank elements for the amount of idChecklist 
    dragItem.url = "https://trello.com/c/" + dragItem.idCard;

    let nameSearch = '';

    if(checklist){nameSearch = checklist.name}else{nameSearch = dataAssociatedCardOrDragItem.name!}
    if(dragItem.name.toLowerCase().includes('(r)')){ dragItem.routed = true; dragItem.disableDrag = true;};

    if(dataAssociatedCardOrDragItem.labels){dragItem.labels = dataAssociatedCardOrDragItem.labels};
    if(dataAssociatedCardOrDragItem.idLabels){dragItem.idLabels = dataAssociatedCardOrDragItem.idLabels};

    // Check for team board
    const idBoard = dataAssociatedCardOrDragItem.idBoard;
    if(idBoard){ dragItem.idBoard = idBoard; if(idBoard === this.idBoardTeam){dragItem.team = true; dragItem.disableDrag = true}};

    const idList = dataAssociatedCardOrDragItem.idList;
    if(idList){dragItem.idList = idList};

    if(dragItem.name === ''){console.log('failed name setup!@#!@#', dragItem)}

    return dragItem;
  }

  mapActiveDriverDragItem(data: any, driver: Driver, pos: number, from: string): DragItem {
    let dragItem = new DragItem;

    if(data.id && data.idBoard){  dragItem = this.mapTrelloCardToDragItem(data)} // Trello Card
    else if(data.id && !data.idBoard){
      dragItem.idCheckItem = data.id;
      const checkLinkRegex = /\bhttps:\/\/trello\.com\/c\/([A-Za-z0-9]{24}$)\b/g;
      const checkShortLinkRegex =  /\bhttps:\/\/trello\.com\/c\/([A-Za-z0-9]{8}$)\b/g

      const checkLink = data.name.match(checkLinkRegex);
      const checkShortLink = data.name.match(checkShortLinkRegex);


      if(checkLink || checkShortLink){
        // Map Action, Card ID, and location 
          dragItem = this.mapActionLocation(null, data.name, data.name, data.labels, driver,  'mapActiveDriverDragItem');
      } else {
        dragItem.displayName = data.name;
        dragItem.name = data.name
      };
      
      dragItem.pos = pos;
      dragItem.name = data.name.trim();
    } else{
      console.log("<------------------What is this funny thing ---------------->");
    }

  
    // ID's data assignments
    if(data.state){dragItem.state = data.state; if(dragItem.state === 'completed'){dragItem.dueComplete = true}};
    if(data.idList){dragItem.idList}
    if(data.idChecklist){ dragItem.idChecklist = data.idChecklist};
    if(data.id){dragItem.idCheckItem = data.id};
    if(data.shortUrl){dragItem.shortUrl = data.shortUrl};
    if(data.idBoard){dragItem.idBoard = data.idBoard};

    if(driver.routeCard.id){dragItem.idRouteOrTitgCard = driver.routeCard.id};
    
    if(data.name.toLowerCase().includes('(o)')){dragItem.ordered = true};
   

    // Driver assignments
    if(driver){ 
      if(driver.routeCard.idChecklists[0]){dragItem.idRouteChecklist = driver.routeCard.idChecklists[0]}

      dragItem.idDriver = driver.driverInfo.idDriver;
      dragItem.driver = driver.driverInfo.firstName;
    };

    //if(dragItem.action.toLowerCase().includes('main')){console.log(dragItem, " HMMMMMMMMMMMMMMMMMMMMMMMM")}

    return dragItem;
  }

  mapIdChecklist(trelloObjects: any[], from: string): string[]{
    let mapObject = trelloObjects; 
    if(from === 'titg' || from === 'activeDrivers'){
      const mapIds = mapObject.map( object => object['idChecklists']);
      const filteredIds = mapIds.filter(object => object.length >0);
      return filteredIds
    }
    const mapIdString = mapObject.map( object => object['id']);
    return mapIdString;
  }

  mapActionLocation(data: any, passedChecklistName: string, passedCardName: string, trelloLabels: TrelloLabel[], driver: Driver|null, from: string): DragItem{
    
    function checkForCardIdAndUrl(checkString: string): any{
      const idCheckRegex = /https:\/\/trello\.com\/c\/[a-zA-Z0-9]{24}$/;
      const idCheckMatch = checkString.match(idCheckRegex);

      const idCheckShortlinkRegex = /https:\/\/trello\.com\/c\/[a-zA-Z0-9]{8}$/;
      const idCheckShortLinkMatch = checkString.match(idCheckShortlinkRegex);

      let foundUrl = '';
      
      if((idCheckMatch && idCheckMatch[0])){ 
        foundUrl = idCheckMatch[0];
        dragItem.url = foundUrl;
     
      } else if(idCheckShortLinkMatch && idCheckShortLinkMatch[0]){
        foundUrl = idCheckShortLinkMatch[0];
        dragItem.shortUrl = foundUrl;
        //console.log('short url', foundUrl, "------------",idCheckShortLinkMatch, "------------",checkString)
      };
      const idCard = foundUrl.replace('https://trello.com/c/','');

      //console.log(idCard, "<--------------------- ",checkString)

      dragItem.idCard = idCard;
      
    };

    function checkActionPo(checkString: string, labels: string[]): any{
      // PO Check will not work on driver routes
      const actionPoCheck = { action: '', po: ''}

      // PO and Note Check
      const checkPoNotes = /\(.*?\)/g
      const matchPoNotes = checkString.match(checkPoNotes)
      let newCheckString = checkString
      if(matchPoNotes) {
        matchPoNotes.forEach(match => {
          if(match.includes('po')){actionPoCheck.po = match.substring(1,match.length-1).replace(('po:'),'')}
          newCheckString = newCheckString.replace(match, '').trim();
        });
      }

      // Logic, labels should be used as a primary mode of decerning the action
      
      // Need to assign the PO here, if its a return or pickup, this is used for the Emailer
      const splitCardName = passedCardName.split('-');
      if(splitCardName && splitCardName.length > 0){
        const cardDeliveryAddress = splitCardName[splitCardName.length-1];
        const regexPo = /(\d+\s+\w+)/;
        const matchPo = cardDeliveryAddress.match(regexPo);
        if(matchPo && matchPo[0] && !actionPoCheck.po){ actionPoCheck.po = matchPo[0] };
      }

      // Return the found pickup
      actionPoCheck.action = newCheckString.trim();

      // Action Check: Drop directions      
      const checkDrop = /(carry|drop|boom)/;
      const indexDropCheck = labels.findIndex(el => el.match(checkDrop));
      if(indexDropCheck >= 0){ 
        actionPoCheck.action = labels[indexDropCheck].trim()};

      // Check for Pickup & Drop (Least Likely)
      const checkPD = /pd/;
      const indexPDCheck = labels.findIndex(el => el.match(checkPD));
      if(indexPDCheck >= 0){ actionPoCheck.action = labels[indexPDCheck].trim()};
      return actionPoCheck;

    };

    function splitClientCompanyLocation(action: string, newDisplayName: string, passedString: string[]){
      dragItem.action = action;
      dragItem.clientName = passedString[0];
      if(passedString.length === 3){ dragItem.companyName = passedString[1]};
      dragItem.location = passedString[passedString.length-1];

      dragItem.name = newDisplayName;
      dragItem.displayName = newDisplayName;
    };

    function checkName(checkString: string){
      const regexName = /\*\*(.*?)\*\*/;
      const matchName = checkString.match(regexName);

      return matchName;
    };

    function checkRoutedAndOrdered(locationString: string): string{
      let displayName = locationString
      // Check for routed, determined by (R)
      if(locationString.includes('(r)')){
        results.routed = true;
        displayName = locationString.replace('(r)', '').trim();
      }

      // Check for routed, determined by (R)
      if(locationString.includes('(o)')){
        results.ordered = true;
        displayName = locationString.replace(/\(o\)/g,'').trim();
      }

      return locationString;
    };

    function checkLocation(passString: string, cardName: string): string{
      
      let location = passString.toLowerCase();

      const locationSearch = /(?<=@|:)(.+)/
      const locationResults = location.match(locationSearch);

      const dropCheck = /(carry|drop|boom)/;
      const dropCheckResults = location.match(dropCheck);

      const checkTitle = location.includes('-');
      const cardCheck = !locationResults && !dropCheckResults && checkTitle;


      if(!passString){ return 'Starting Location'}
      else if(locationResults && !dropCheckResults && !passString.includes('http')){
        let location = locationResults[0].slice(1,locationResults[0].length);
        if(location.includes(':')){location = location.replace(/^(.*?):.*/, '$1')};

        const checkRouted = location.includes('(r)')
        if(checkRouted){ location.replace(/\s*\(.*?\)\s*/, '')};

        location = location.trim();

        // Future ADD: If this fails need a function to check a mapping software that will find the address
      }else if(dropCheckResults || cardCheck){
        const lastIndex = cardName.lastIndexOf('-');
        const address = cardName.substring(lastIndex + 1, cardName.length).trim();
        location = address;


      } 
      // Likely going to catch Checklist items
      else if(passString.includes('@') && passString.includes('https') ){

        const regex = /@ (.*?) https:\/\//;
        const foundLocation = passString.match(regex);
        
        if(foundLocation && foundLocation[1]){ location = foundLocation[1].trim()} else { return ''};
      }
      
      location.replace('**','');

      // Remove (o) && (r) Tag && set ordered and route
      if(location.includes('(r)')){dragItem.routed = true}
      location = location.replace('(r)','').trim();

      if(location.includes('(o)')){dragItem.ordered = true}
      location = location.replace('(o)','').trim();

      // Replace anything that has brakets left over
      location = location.replace(/\(.*?\)/g, '').trim();

      if(location.includes(dragItem.url)){ location = location.replace(dragItem.url,'')}

      return location.trim();
    };

    // Setup Results to map
    let results = { 
      name: '',
      action: '', 
      displayName: '',
      idCard:'', 
      location: '', 
      po: '',
      disableDrag: false, 
      url: '',

      ordered: false,
      routed: false,
    }; 


    const dragItem: DragItem = new DragItem;
    if(passedChecklistName === '**Clean up that van!**' || passedCardName === 'Clean up that van!'){console.log("Display time!")}
    // Mapping active driver and Titg drag
    if(
      from === 'mapActiveDriverDragItem' || 
      from === 'mapTitgDragItem' ){
      results.displayName = passedChecklistName + " " + passedCardName; 

      let displayName = '';
      displayName = passedChecklistName.toLowerCase();

      // This will pass with Checklist names that are not maintenance
      if(displayName){
        // Split the string based on the @ symbol with the action on the left and the location & url on the right
        const splitString = displayName.split('@');
        let actionString = '';
        let locationString = '';
        
        // Custom drag item setup
        let customDrag = false;

        if(splitString && splitString.length >1){ // Standard header with format: Client name - company name - delivery address
          //console.log(splitString, "<-------")
          actionString = splitString[0].replaceAll('**','');
          locationString = splitString[1].replaceAll('**','');
        } else if(splitString && splitString.length === 1){
          if(splitString[0].includes('http')){ // Maintenance Cards 
            checkForCardIdAndUrl(splitString[0]);
       

            const checkName = dragItem.name;
            const removeUrl = displayName.replace('https://trello.com/c/' + dragItem.idCard, '').trim()
            
            //console.log(removeUrl,"-----------0-0-0-000000-0",dragItem, "Mic check I can feel it!", passedChecklistName,  "<------------", checkName )
            if(!removeUrl){
              dragItem.name = 'Van Maintenance - Daily';
              dragItem.displayName = 'Van Maintenance - Daily';
              dragItem.action = 'Daily Maitenance';
              dragItem.location = 'Starting Location';

              if(!dragItem.url){dragItem.url = dragItem.shortUrl}
              
              return dragItem;
            } else {     
           
              dragItem.displayName = displayName;
              dragItem.name = displayName;
              const resultsActionPo = checkActionPo(displayName, [])
              
              if(resultsActionPo.action){ dragItem.action = resultsActionPo.action}
              if(resultsActionPo.po){ dragItem.po = resultsActionPo.po}
              return dragItem;
            }
            


          } else { // Trello Card header or Drop Drag item
          
            const advancedSplit = displayName.split('-');

            if(advancedSplit && advancedSplit.length > 1){ // Drop Drag Item
              const dropCheckRegEx = /(carry|drop|boom|return)/;
              const dropCheck = advancedSplit[1].match(dropCheckRegEx);

              if(dropCheck && dropCheck.length > 0){
                // Drop Case
                const nameClientLocation = passedCardName.split('-')
                splitClientCompanyLocation(displayName, displayName, nameClientLocation)
                return dragItem;
              } else if(advancedSplit.length >= 2 &&advancedSplit.length <= 3){
                splitClientCompanyLocation(displayName, displayName, advancedSplit)
                
                return dragItem;
              }
            }

            dragItem.action = 'Custom route item'
            dragItem.location = '';
            
            if(!displayName.includes('https')){
              dragItem.url = '';
              dragItem.name = displayName;
              dragItem.displayName = displayName;
              dragItem.action = displayName;

              return dragItem;
            }
          }


        } else { 
          console.error('action and location are a miss',displayName,passedCardName,passedChecklistName)
        }

        // Clear ordered and routed
        //displayName = displayName.replace(/\(.*?\)/g, '');

        // Check routed and ordered and clear it from the display string
        displayName = checkRoutedAndOrdered(displayName)

        let labels: string[] = [];

        if(trelloLabels && trelloLabels.length>0){labels = trelloLabels.map(el=> el.name)};

        // Get Check ID from the linked card
        const resultsId = checkForCardIdAndUrl(locationString);

        if(resultsId && resultsId.idCard){
          results.idCard = resultsId.idCard;
          results.url = 'https://trello.com/c/' + resultsId.idCard;
          locationString = locationString.replace(results.url,'');
        };

        results.displayName = displayName.replace(results.url, '');
        results.displayName = displayName.replaceAll('**', '');
        //if(displayName.includes('drop')){console.error(results.displayName, "<--------- maintenance will be empty", splitString )}
        
        if(!customDrag){
            // Map Driver Action        
            const actionPo = checkActionPo(actionString,labels);
            if(actionPo.action){results.action = actionPo.action};
            if(actionPo.po){results.po = actionPo.po}

            // Special Case Maintenance: Disable drag and change name
           // if(results.action.toLowerCase().includes('maintenance')){
             // results.disableDrag = true;
              //console.log("This is the maintenance case",passedCardName,passedChecklistName);

          //  }

            // Map Action Location
            const resultsLocation = checkLocation(locationString, passedCardName);
           
            if(resultsLocation){ results.location = resultsLocation };
           
        }
       
      }

    } else if(from === 'mapTrelloCheckItemToDragItem'){
      const actonPoCheckItem = checkActionPo(passedChecklistName, [])
      console.log(passedChecklistName, passedCardName, trelloLabels, driver, from, " Mapping fromn a checkITem");
    } else if (from === 'mapTrelloCardToDragItem'){
    // This is a roomCard and should be treated as such
      results.action = 'RoomCard';
      results.displayName = passedChecklistName;
      results.name = passedChecklistName;
      
      if(results.displayName.toLowerCase().includes('maintenance')){results.disableDrag = true}
      //console.log(results, " Missing the checklist", passedCardName,passedChecklistName, from);
    }else {console.error("This is the fail case", results, " Missing the checklist", passedCardName,passedChecklistName, from )};

    // RESULTS Assignment
    if(results.action){dragItem.action = results.action};
    if(results.disableDrag){dragItem.disableDrag = results.disableDrag};
    if(results.displayName){ dragItem.displayName = results.displayName; dragItem.name = results.displayName};
    if(!dragItem.displayName){console.log('missing display')}
    if(results.location){ dragItem.location = results.location};
    if(results.po && !dragItem.po){dragItem.po = results.po};
    if(dragItem.shortUrl.includes('ordnybur')){ console.error('something blue and bad', dragItem)}
    if(results.url && !dragItem.url){dragItem.url = results.url};
    
    return dragItem;
  }

  mapRouteChange(what: string, dropData: RouteDragDrop, from: string):RouteChange{
    const defaultRouteChange = DEFAULT_ROUTE_CHANGES;
    let newRouteChange = new RouteChange;

    const routeChange = defaultRouteChange.find(el => el.changeType === what);
    
    if(routeChange){
      routeChange.from = from;
      const routeIndexes: RouteIndexes = {
        indexTitgCardOrDriver: dropData.indexTitgCardOrDriver,
        indexTitgChecklistOrRouteDragItem: dropData.nextIndex,
        indexDragItem: dropData.nextIndex
      }

      routeChange.routeIndexes = routeIndexes;

      return routeChange;
    }

    return newRouteChange;
  }

  mapTrelloCardsToDragItems(driverTrelloCards: any[]){
    const currentRouteInfo = this.currentRouteInfo()
    const currentActiveDrivers = currentRouteInfo.activeDrivers;

    for (let indexDriver = 0; indexDriver < currentActiveDrivers.length; indexDriver++) {
      const driver = currentActiveDrivers[indexDriver];

      driver.dragItems.forEach((dragItem:DragItem) => {
        if(!dragItem.displayName){ dragItem.displayName = dragItem.name};
        
        // Regex to check for drop
        const regexDrop = /(carry|drop|boom)/;
        const checkDrop = dragItem.action.match(regexDrop);

        if(dragItem && dragItem.name && dragItem.name.includes('https') && dragItem.displayName && dragItem.action){
          if(
            driverTrelloCards &&
            driverTrelloCards[indexDriver] &&
            driverTrelloCards[indexDriver][0] &&
            driverTrelloCards[indexDriver][0].name) {   
              // Replace Machine readable title with HUMAN readable
              dragItem.displayName = dragItem.displayName.replace(dragItem.url, ( " - " + driverTrelloCards[indexDriver][0].name.toLowerCase()));            
              const currentDragItem = driverTrelloCards[indexDriver][0];
              // Replace long url with Short url
              if(driverTrelloCards[indexDriver][0].shortUrl){ 
                dragItem.shortUrl = driverTrelloCards[indexDriver][0].shortUrl;
                //console.log(dragItem,driverTrelloCards[indexDriver][0], "On to the next one!!@#!@#!@#!@#!@#!@#!@#");
              };

              // Push new info and del first object
              dragItem.associatedTrelloCards.push(driverTrelloCards[indexDriver][0]);
              driverTrelloCards[indexDriver].shift();
          }
        
        }

        if(checkDrop){}
      });
    }

    this.nextRouteInfo(currentRouteInfo,'mapTrelloCardsToDragItems');
    
  }
  
  mapTrelloCardToDragItem(trelloCard: TrelloCard): DragItem{
    const dragItem: DragItem = this.mapActionLocation(null, trelloCard.name, trelloCard.name, trelloCard.labels, null,  'mapTrelloCardToDragItem');

    dragItem.idCard = trelloCard.id;
 
    dragItem.name = trelloCard.name.trim();
    dragItem.idList = trelloCard.idList;
    dragItem.idBoard = trelloCard.idBoard;
    dragItem.idChecklists = trelloCard.idChecklists;

    dragItem.labels = trelloCard.labels;
    
    dragItem.dueComplete = trelloCard.dueComplete;
    dragItem.idLabels = trelloCard.idLabels;
    dragItem.url = trelloCard.url;
    dragItem.state = trelloCard.state;

    if(typeof trelloCard.pos === 'number' ){dragItem.pos = trelloCard.pos};

    // Map Action, Card ID, and location 
    const resultsActionLocation = 
    //this.newMapActionLocation(trelloCard.name, trelloCard.name, trelloCard.labels,  'mapTrelloCardToDragItem')

    dragItem.url = "https://trello.com/c/" + dragItem.idCard;
    
    return dragItem;
  }

  mapTrelloChecklistToDragItem(trelloChecklist: TrelloChecklist, driver: Driver|null, associatedDragItem: DragItem|TrelloCard, requestName: string,  from: string){
    let newDragItem = this.mapActionLocation(null, trelloChecklist.name, associatedDragItem.name,associatedDragItem.labels,driver,'mapTrelloChecklistToDragItem');
    console.log(trelloChecklist, driver,associatedDragItem)
    if(trelloChecklist.name.toLowerCase().includes('(o)')){ newDragItem.ordered = true};
    if(trelloChecklist.name.toLowerCase().includes('(r)')){newDragItem.routed = true}

    if(associatedDragItem){
      newDragItem.idRouteOrTitgCard = associatedDragItem.idBoard;
      newDragItem.idRouteChecklist = associatedDragItem.idChecklists[0];
      console.log("associated route card!@#!@#") 
    }

    if(driver){
      newDragItem.driver = driver.driverInfo.firstName;
      newDragItem.idDriver = driver.driverInfo.idDriver;
    }

    newDragItem.idBoard = trelloChecklist.idBoard
    newDragItem.idCard = trelloChecklist.idCard
    newDragItem.idChecklist = trelloChecklist.id;
    newDragItem.pos = trelloChecklist.pos;
    newDragItem.state = trelloChecklist.state;
    newDragItem.name = trelloChecklist.name;
    if(!newDragItem.displayName){newDragItem.displayName = trelloChecklist.name}
    newDragItem.checkItems = trelloChecklist.checkItems;

    console.log(trelloChecklist, "hummmmmmmmmm", from, requestName, newDragItem)

    return newDragItem;
  }

  mapTrelloCheckItemToDragItem(trelloCheckItem: TrelloCheckItem, driver: Driver|null, associatedDragItem: DragItem|TrelloCard|null, requestName: string,  from: string): DragItem{
    //let newDragItem: DragItem|null = null;

    let newDragItem = new DragItem;

    if(trelloCheckItem.name.toLowerCase().includes('(o)')){ newDragItem.ordered = true};
    if(trelloCheckItem.name.toLowerCase().includes('(r)')){newDragItem.routed = true}

    if(associatedDragItem){
      newDragItem.idRouteOrTitgCard = associatedDragItem.idBoard;
      newDragItem.idRouteChecklist = associatedDragItem.idChecklists[0];
      console.log("associated route card!@#!@#") 
    }

    if(driver){
      newDragItem.driver = driver.driverInfo.firstName;
      newDragItem.idDriver = driver.driverInfo.idDriver;
    }

    newDragItem.idCheckItem = trelloCheckItem.id;
    newDragItem.idChecklist = trelloCheckItem.idChecklist;
    newDragItem.pos = trelloCheckItem.pos;
    newDragItem.state = trelloCheckItem.state;
    newDragItem.name = trelloCheckItem.name;
    if(!newDragItem.displayName){newDragItem.displayName = trelloCheckItem.name}

    console.log(trelloCheckItem, "hummmmmmmmmm", from, requestName, newDragItem)

    return newDragItem;
  }

  // MARK - Sort
  sortDragItems(dragItems: DragItem[]): DragItem[]{
    return dragItems.sort((a,b) =>a.pos - b.pos);
  }

  // MARK - Implement
  implementTeamCreation(drivers: Driver[], dragItem: DragItem){
    // Driver 0 is the card holder and Driver 1 is the card recepient
    const driverFirst = drivers[0];
    const driverSecond = drivers[1];

    // Get the name of drag item and add 'w/(driver)'
    const nameCheckItem = dragItem.name.trim();
    const insertIndex = this.trelloService.findLinkIndex(nameCheckItem) - 3;

    const nameFirstDriver = nameCheckItem.slice(0,insertIndex) + ' w/' + driverSecond.driverInfo.firstName + nameCheckItem.slice(insertIndex);
    const nameSecondDriver = nameCheckItem.slice(0,insertIndex) + ' w/' + driverFirst.driverInfo.firstName + nameCheckItem.slice(insertIndex);

    console.log(nameFirstDriver, nameSecondDriver, drivers)
      
    // Setup overwrite driver one and create driver two

    // PUT: Ammend first driver checkItem **COMPLETED**
    const idCardFirstDriver = driverFirst.routeCard.id;
    const idCheckItem = dragItem.idCheckItem;

    const dataFirstDriver = {name: nameFirstDriver}

    const putFirstDriver = this.trelloService.putRequest('put:checkItem', idCardFirstDriver, '', idCheckItem, dataFirstDriver);

    
    // POST: Create second driver checkItem **COMPLETED**
    const idChecklistSecondDriver = driverSecond.routeCard.idChecklists[0]
    const dataSecondDriver = {pos: 'bottom', idChecklist: idChecklistSecondDriver, name: nameSecondDriver}

    const postSecondDriver = this.trelloService.postRequest('post:checkItem', '', driverSecond.routeCard.idChecklists[0], '', dataSecondDriver);

    // PUT: Move card to team board **COMPLETED**
    const defaultTrelloBoards = TESTING_DRIVER[0].trelloInfo;

    const idChecklistTeam = defaultTrelloBoards.idChecklistDriverRoute;
    const idBoardTeam = defaultTrelloBoards.idBoardDriver;
    const idCard = dragItem.idCard;

    const dataMoveTeamCard= {idList: idChecklistTeam, idBoard: idBoardTeam};
    const putTeamCard = (this.trelloService.putRequest('put:card',idCard, '', '', dataMoveTeamCard)); 
  
    // Need to change and create new dragItems
    //this.trelloService.createTrelloCheckItem();
    const post = forkJoin([putFirstDriver,postSecondDriver,putTeamCard]);
    this.generalSubscription(post, null,'implementTeamCreation');
  
  }

  // MARK - Creation
  makeTrelloChecklist(card: TrelloCard, from: string): TrelloChecklist{
    const newChecklist: TrelloChecklist = new TrelloChecklist;

    if(from === 'drop'){
      if(card && card.name){
        newChecklist.idBoard = card.idBoard;
        newChecklist.idCard = card.id;

        let dropLabel = this.searchTrelloLabels(card.labels, ['DROP','CARRY'], 'makeTrelloChecklist');

        if(dropLabel){newChecklist.name = dropLabel.name + " " + card.url} 
        else { newChecklist.name = "** MISSING DROP LABEL ** "}
      }     
    }

    //newChecklist.name = this.formatBoldText(newChecklist.name);
    return newChecklist
  }
  
  makeRouteMaintenanceCard(): TrelloCard[]{
    const routeMaintenanceCards:TrelloCard[] = [];

    for(let i = 0; i < TESTING_DRIVER.length; i++) {
      const makeRouteMaintenanceCard: TrelloCard = CARD_DAILY_MAINTENANCE;
      TESTING_DRIVER[i].trelloInfo.idBoardDriver
      routeMaintenanceCards.push(makeRouteMaintenanceCard)
    }

    return routeMaintenanceCards;
  }

  makeRouteCard(maintenanceId: string[]): TrelloCard[]{
    // Get Drivers
    const drivers: Driver[] = TESTING_DRIVER;
    
    const routeCards: TrelloCard[] = []


    const newDate = new Date

    drivers.forEach(driver => {
      const routeCard = new TrelloCard;

      //routeCard.idList = driver.idListDriverHolding;

    });
    return routeCards
  }

  // MARK - Request Handling 
  requestTrelloAction(data: any, routeIndexes: RouteIndexes, idCard: string, idChecklist: string, idCheckItem: string, requestName: string, from: string){
    console.log(data, routeIndexes, idCard, idChecklist, idCheckItem, requestName, from, "HELLO")
    let request: Observable<Object> | null = null; 

    //if(requestName.includes('get')){request = this.trelloService.getRequest(requestName, idCard, idChecklist, idCheckItem)}
    if(requestName.includes('del')){request = this.trelloService.delRequest(requestName, idCard, idChecklist, idCheckItem)};
    if(requestName.includes('put')){request = this.trelloService.putRequest(requestName, idCard, idChecklist, idCheckItem, data)};
    if(requestName.includes('post')){request = this.trelloService.postRequest(requestName, idCard, idChecklist, idCheckItem, data)};
    
    if(request){
      request.subscribe({ 
        next: (data: any) => { 

          //if(requestName.includes('get')){this.getRouteDragItem(data, routeIndexes, requestName, from)};
          if(requestName.includes('del')){this.delRouteDragItem(data, routeIndexes, requestName, from)};
          if(requestName.includes('put')){this.putRouteDragItem(data, routeIndexes, requestName, from)};
          if(requestName.includes('post')){this.postRouteDragItem(data, routeIndexes, requestName, from)};
      
      },
      error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
      })  
    }
  }
 
  putRouteDragItem(data: any, routeIndexes: RouteIndexes, requestName: string, from: string){
    const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver;
    const indexTitgChecklistOrRouteDragItem = routeIndexes.indexTitgChecklistOrRouteDragItem;
    const indexDragItem = routeIndexes.indexDragItem;

    let newRouteInfo = this.currentRouteInfo();

    if(from.includes('titg')){
      const currentTitgCard = newRouteInfo.titgCardCatalog[indexTitgCardOrDriver];
      if(from.includes('checklist')){
        // Map Trello Item to Titg Drag Item
        const newTitgCardChecklist = this.mapTitgDragItem(currentTitgCard, data, 'putRouteDragItem:' + from)
        currentTitgCard.dragItems.splice(indexTitgChecklistOrRouteDragItem, 1, newTitgCardChecklist);
      
        // Close Options View
        this.setView('dismiss', null, null, 'putRouteDragItem:'+ from);
      }

      if(from.includes('checkItem')){
        console.log("From", from, requestName,data, "Stuff to do hereerererer", routeIndexes);
        const currentChecklist = currentTitgCard.dragItems[indexTitgChecklistOrRouteDragItem];
        currentChecklist.checkItems[indexDragItem] = data;
      }
      this.setView('dismiss', null, null, 'putRouteDragItem:'+ from);
    }
    
    if(from.includes('driver')){
      console.log(from,data, routeIndexes);
      const currentDriver = newRouteInfo.activeDrivers[indexTitgCardOrDriver];
      console.log(from)
      if(from.includes('checkItem')){
        console.log(from, requestName)
        // Map Trello item to Driver Drag Item
        const newDriverRouteDragItem = this.mapActiveDriverDragItem(data,currentDriver,data.pos, 'putRouteDragItem:' + from);
        currentDriver.dragItems.splice(indexTitgChecklistOrRouteDragItem, 1, newDriverRouteDragItem);
        
        // Close Options View
        this.setView('dismiss', null, null, 'putRouteDragItem:'+ from);
      }
    }

    this.nextRouteInfo(newRouteInfo,'putRouteDragItem' + ':'+ requestName)
  }

  postRouteDragItem(data: any, routeIndexes: RouteIndexes, requestName: string, from: string){
    const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver;
    const indexTitgChecklistOrRouteDragItem = routeIndexes.indexTitgChecklistOrRouteDragItem;
    const indexDragItem = routeIndexes.indexDragItem;

    
    const newRouteInfo = this.currentRouteInfo();

    if(from.includes('titg')){
      const currentTitg = newRouteInfo.titgCardCatalog;
      const associatedDragItem = currentTitg[indexTitgCardOrDriver];
      //console.log(requestName, "From ski dunki")
      if(requestName.includes('checklist')){
        const newChecklistDragItem = this.mapTitgDragItem(associatedDragItem,data,from);
        
        // Push the checklist onto the Associated Card 
        associatedDragItem.idChecklists.push(newChecklistDragItem.idChecklist);
        const indexSplice = currentTitg[indexTitgCardOrDriver].dragItems.length -1;

        currentTitg[indexTitgCardOrDriver].dragItems.splice(indexSplice,0, newChecklistDragItem);
      }

      if(requestName.includes('checkItem')){
        // Will be used when this is changed to a drag Item const newCheckItem = ;
        currentTitg[indexTitgCardOrDriver].dragItems[indexTitgChecklistOrRouteDragItem].checkItems.push(data);
      }
      
    }

    if(from.includes('driver')){
      const currentDriver = newRouteInfo.activeDrivers[indexTitgCardOrDriver];
      // Route Card Item
      if(requestName.includes('dragItem')){
        let newDriverDragItem: DragItem = this.mapActiveDriverDragItem(data,currentDriver,data.pos, "postRouteDragItem:" + from);
        currentDriver.dragItems.push(newDriverDragItem);
      }

      // Route Card Check Item
      if(requestName.includes('checkItem')){
        console.log("Linked sub item", data, routeIndexes,currentDriver.dragItems[indexTitgChecklistOrRouteDragItem])
        currentDriver.dragItems[indexTitgChecklistOrRouteDragItem].checklists[0].checkItems.push(data);
      }

      //newRouteInfo.activeDrivers[indexTitgChecklistOrRouteDragItem].dragItems
    }

    this.setView('dismiss', true, null, 'postRequest:'+requestName)

    this.nextRouteInfo(newRouteInfo, 'postRouteDragItem')
  }

  delRouteDragItem(data: any, routeIndexes: RouteIndexes, requestName: string, from: string){
    const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver;
    const indexTitgChecklistOrRouteDragItem = routeIndexes.indexTitgChecklistOrRouteDragItem;
    const indexDragItem = routeIndexes.indexDragItem;

    const newRouteInfo = this.currentRouteInfo();
    // Del: TITG
    if(from.includes('titg')){

      // Init current TITG Card
      const currentTitgCard = newRouteInfo.titgCardCatalog[indexTitgCardOrDriver];
      // Delete a checkitem
      if(indexDragItem > -1){ currentTitgCard.dragItems[indexTitgChecklistOrRouteDragItem].checkItems.splice(indexDragItem, 1)};
     
      // Delete a Checklist
      if(requestName.includes('checklist')){
        currentTitgCard.idChecklists.splice(indexTitgChecklistOrRouteDragItem, 1);
        currentTitgCard.checklists.splice(indexTitgChecklistOrRouteDragItem, 1);
        currentTitgCard.dragItems.splice(indexTitgChecklistOrRouteDragItem, 1);
        console.log(currentTitgCard.idChecklists, "deleting a checklost ", indexTitgChecklistOrRouteDragItem)
        //newRouteInfo.trackerChecklist[indexTitgCardOrDriver].splice(indexTitgChecklistOrRouteDragItem, 1);
      }

      if(requestName.includes('checkItem')){
        console.log("Del Checkit?!", indexDragItem)
        let indexDel = routeIndexes.indexDragItem;
        if(indexDel < 0){indexDel = routeIndexes.indexTitgChecklistOrRouteDragItem}
        currentTitgCard.dragItems[indexTitgChecklistOrRouteDragItem].checkItems.splice(indexDragItem, 1)
      };
      
      // Delete Card
      //if(indexTitgCardOrDriver > -1){ newRouteInfo.titgCardCatalog[indexTitgCardOrDriver].dragItems.slice(indexTitgChecklistOrRouteDragItem, 1)}
    }

    // Del: DRIVER
    if(from.includes('driver')){
       // Route Card Item
      if(requestName.includes('checkItem') && from.includes('route')){
         newRouteInfo.activeDrivers[indexTitgCardOrDriver].dragItems.splice(indexTitgChecklistOrRouteDragItem, 1)
      };


      // Route Card Check Item
      if(requestName.includes('checkItem') && !from.includes('route')){
        console.log(routeIndexes, requestName, from, data)
        newRouteInfo.activeDrivers[indexTitgCardOrDriver].dragItems[indexTitgChecklistOrRouteDragItem].checklists[0].checkItems.splice(indexDragItem, 1)

      }
    }

    this.nextRouteInfo(newRouteInfo, 'delRouteDragItem');
  }

  // MARK - Build Request
  getTrelloCards(cardData: Driver[]){
    // This is being used to map a url link to the cards name to witch it links to
    // Need to agregate the links and map the name to the correct dragITem

    const getCardData: Observable<any> []= [] 

    // Take driver and iterate for the active dragItems
    cardData.forEach((driver: Driver) => {
      const dragItems: DragItem[] = driver.dragItems;
      const getCardPath: string[] = [];
      dragItems.forEach((dragItem: DragItem) => {
        const idCard = dragItem.idCard;
        if(idCard){getCardPath.push(this.trelloService.buildGetPath('cards',dragItem.idCard))};
      });
      
      if(getCardPath.length >0){
        const getBatchCards = this.trelloService.getBatchRequest(getCardPath);
        if(getBatchCards)
          getCardData.push(getBatchCards);
      }
    });
    const request = forkJoin(getCardData);
    request.subscribe({
      next: (data: any) => {    
        const driveCards: any[] = []
        data.forEach((driverElement:any) => {
          if(driverElement){
            const trelloCards: TrelloCard[] = driverElement.map((el:any)=> el['200']);
            driveCards.push(trelloCards);
          }
        });

        // This is to map the human readable card name to drag item
        this.mapTrelloCardsToDragItems(driveCards);
      },
      error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
    })
  }

  getRequest(ids: string[] | string[][], from: string, request: string): Observable<any>[]| Observable<any>[][]{
    const requestedObservable:Observable<any>[] = []
   
    const objectCheck = typeof ids[0];

    // Run single query
    if(ids.length === 1 && objectCheck === 'string'){
      const batchUrls: string[] = [];
      ids.forEach(id => {if(typeof id === 'string'){requestedObservable.push(this.trelloService.getRequest(request, id))}});
      return requestedObservable;
    }

    // Run batch query
    if(ids.length <= 10 && objectCheck == 'string'){
      const urls: string[] = [];
      ids.forEach((id) => {
        if(typeof id === 'string'){urls.push(this.trelloService.buildGetPath(request, id))}})
      requestedObservable.push(this.trelloService.getBatchRequest(urls))
      return requestedObservable;
    }

    // Run Batch && Fork
    if(ids.length > 10 && objectCheck === 'string'){
      return requestedObservable;
    }

    // Running Multi Tiered Batch && Fork
    if(ids.length > 0 && objectCheck === 'object'){
      const requestedDeepObservable:Observable<any>[] = []

      ids.forEach(idChecklists => {
        const requests: Observable<string>[] = [];

        if(typeof idChecklists === 'object'){
          const urls: string[] = []
          idChecklists.forEach((id: string) => {urls.push(this.trelloService.buildGetPath(request, id))})
          const batchRequest = this.trelloService.getBatchRequest(urls)
          
          requestedDeepObservable.push(this.trelloService.getBatchRequest(urls));
        }
      
        return requestedDeepObservable;
      });
      return requestedDeepObservable
    }
    return requestedObservable;
  }

  getCard(dragItem: any, routeIndexes: RouteIndexes, from:string){
    let idCards: string[] = [];
    if((from.includes('titg') || from.includes('btn')) && dragItem instanceof DragItem){idCards.push(dragItem.idCard)};
    
    if(from.includes('driver')){
      if(from.includes('checkItem') && dragItem instanceof DragItem){  
        console.log("Getting the checkItem", dragItem)

        dragItem.associatedTrelloCards.forEach(card => {idCards.push(card.id)})};
      if(from.includes('card')){
        console.log("Getting the card", dragItem)
        idCards.push(dragItem.id)}
    };

    let mapRequest = this.getRequest(idCards, from , 'cards');
    if(mapRequest){
      let request: any = null;
      if(mapRequest.length === 1){request = mapRequest[0]};
      if(request){  

        request.subscribe({
          next: (data: TrelloCard) => {

            // Index Assignement
            const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver;
            const indexTitgChecklistOrRouteDragItem = routeIndexes.indexTitgChecklistOrRouteDragItem;
            const indexDragItem = routeIndexes.indexDragItem;

            console.log(from,routeIndexes, idCards)

            if(from.includes('driver')){
              if(from.includes('checkItem')){
                console.log("Driver is doing this question");
                const currentChecklistName = dragItem.name;

              }
            }
            if(from.includes('titg')||from.includes('btn') || from.includes('card')){
              const trelloView = this.trelloService.currentTrelloInfo();
              trelloView.active_trello_card = [];
              trelloView.active_trello_card.push(data);
              this.trelloService.nextTrelloInfo(trelloView, from);
              this.setView('show_info', true, null,'getCard');
            }

          },
          error: (error: any) => { console.error(error)}
        })   
      }
    }
  }

  getChecklists(dragItem: DragItem, routeIndexes: RouteIndexes, from:string){
    let idChecklists: string[] = [];
    
    if(from.includes('driver')){
      if(from.includes('checkItem')){
        const findCard = this.currentRouteInfo().activeCards.filter(card => card.id === dragItem.idCard);

        if(findCard){idChecklists = findCard[0].idChecklists}
        else{ console.log("Go and find that damn card somewhere else", dragItem)}
      }
    }

    console.log(idChecklists, "What are we missing>>!>!>!>!",dragItem,routeIndexes)

    let mapRequest = this.getRequest(idChecklists, from , 'checklists');


    if(mapRequest){
      let request: any = null;
      if(mapRequest.length === 1){request = mapRequest[0]};
      if(request){  

        request.subscribe({
          next: (data: any) => {
            let mapChecklists: TrelloChecklist[] = []
            if(data instanceof Array){ mapChecklists = data.map(checklist => checklist['200'])}
            else{mapChecklists.push(data)};

            // Index Assignement
            const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver;
            const indexTitgChecklistOrRouteDragItem = routeIndexes.indexTitgChecklistOrRouteDragItem;
            const indexDragItem = routeIndexes.indexDragItem;

            const newRouteView = this.currentRouteInfo();

            if(from.includes('driver')){
              console.log("Where are we!@!#!@#!@#!", from)
              if(from.includes('checkItem')){
                
                const regexName = /\*\*(.*?)\*\*/g;
                const nameChecklist = dragItem.name.toLowerCase();
                const findChecklistName = nameChecklist.match(regexName);

                if(findChecklistName && findChecklistName[0]){
                  const foundName = findChecklistName[0];
                  const nameChecklist = foundName.replaceAll('**','').toLowerCase().trim();
                  const findChecklist = mapChecklists.filter(checklist => {
                    if(checklist.name.includes(dragItem.action && dragItem.location)){return checklist}else{return null}
                  });

                  console.log(nameChecklist, findChecklist, "HMMMMMMMMM", mapChecklists)
                  if(findChecklist){
                    if(findChecklist.length > 0){
                      const foundChecklist = findChecklist[0];

                      const currentDriver = newRouteView.activeDrivers[indexTitgCardOrDriver];
                      const currentRouteDragItem = currentDriver.dragItems[indexTitgChecklistOrRouteDragItem];
                      console.log(data, "This is the one!")
                      currentDriver.dragItems[indexTitgChecklistOrRouteDragItem].idCard = data.idCard;
                      currentRouteDragItem.checklists.push(foundChecklist);
                    }
                  }
                }

              }
          
            }
          
            this.nextRouteInfo(newRouteView,"showChecklist");

          },
          error: (error: any) => { console.error(error)}
        })   
      }
    }
  }

  getChecklist(routeIndexes: RouteIndexes, idCheckist: string, from: string){

    const indexTitgCardOrDriver = routeIndexes.indexTitgCardOrDriver;
    const indexTitgChecklistOrRouteDragItem = routeIndexes.indexTitgChecklistOrRouteDragItem;
    const indexDragItem = routeIndexes.indexDragItem;

    const request = this.trelloService.getRequest('checklists', idCheckist);
    request.subscribe({
      next: (data: any) => {
        const newRouteView = this.currentRouteInfo();

        const newCheckItems: TrelloCheckItem[] =[];

        if(from.includes('titg')){
          const currentCard = newRouteView.titgCardCatalog[indexTitgCardOrDriver];
        
            const newCheckItem: TrelloCheckItem[] = data.checkItems;
            if(newCheckItem){
              currentCard.checkItems = newCheckItem;
              currentCard.showChecklist[indexTitgChecklistOrRouteDragItem] = true;
            }
        }

        if(from.includes('driver')){
          console.log('Driver!@#!@#!@#!@#!@#@!', data, from, )
        }
       
        this.nextRouteInfo(newRouteView,"showChecklist");
      },
      error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
    })
  }

  setAllTrelloInformation(cards: TrelloCard[], from: string, request: string){
   
    const mapIds = this.mapIdChecklist(cards, from);
    if (mapIds){
      const mapRequest = this.getRequest(mapIds, 'getChecklist', request);
      if(mapRequest){
        let request: any = null;
        if(mapRequest.length > 1){request = forkJoin(mapRequest)};
        if(mapRequest.length === 1){request = mapRequest[0]};
        if(request){
          request.subscribe({
            next: (data: any[]) => {
              const cardData: any[] = [];
              const newRouteInfo = this.currentRouteInfo();
              const checkDepth = data[0]['200'];
              
              // Single Card Data
              if(checkDepth){cardData.push(data.map((foo: any) => foo['200']))}

              // Multiple Card Data
              else {
                data.forEach(el => {
                  cardData.push(el.map((foo: any) => foo['200']))
                });
              }
            
              if(from === 'activeDrivers'){
                const activeDrivers = newRouteInfo.activeDrivers;

                //const getCard = cardData.map(el => el.)
                // Get card name associated to drag Item
             
                cardData.forEach((el: any) => {      
                  const idBoardCard = el[0].idBoard;
                  if(idBoardCard){
                    const indexFindDriver = activeDrivers.findIndex(driver => driver.trelloInfo.idBoardDriver === idBoardCard);

                    if(indexFindDriver >= 0){   
                      const dragData = this.mapActiveCardtoDrag( el, activeDrivers[indexFindDriver], 'activeDrivers');
                      const currentDriver = activeDrivers[indexFindDriver];
                      currentDriver.dragItems = dragData;
                      currentDriver.driverView.showCompleteRouteItems = this.setupCompletedRouteItems(currentDriver)
                    }
                  }
                });

                this.getTrelloCards(activeDrivers);
              }
    
              if(from === 'titg'){
                if(cards instanceof Array){newRouteInfo.cards = cards}

                newRouteInfo.lists = cardData;
                newRouteInfo.titgCardCatalog = [];
                const mapTitg = this.mapTitgCatalog(newRouteInfo.cards, newRouteInfo.lists);

                const titgtrackerChecklist: boolean[][] = []
                const trackerMini: boolean[] = [];
            
                cards.forEach(card => {
                  const trackerChecklist = new Array<boolean>;
                  trackerChecklist.fill(false, card.idChecklists.length);
                  titgtrackerChecklist.push(trackerChecklist); 
                  
                  trackerMini.push(false); // Changes a lot due to testing
                });

                const sortedTitg = mapTitg.sort((a,b) =>a.due.getUTCDate() - b.due.getUTCDate());

                newRouteInfo.titgCardCatalog = sortedTitg;
                newRouteInfo.trackerChecklist = titgtrackerChecklist;
                if(newRouteInfo.trackerMini.length === 0){newRouteInfo.trackerMini = trackerMini};
              }
              this.nextRouteInfo(newRouteInfo, 'getChecklistItemsRequest: ' + from);
            },
            error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
          })
        }
      }
      
     
    }
  }

  setDriverTrelloInfo(driverData: any[][]){
    // Filters driver active vs not
    
    const routeCheck: any[] = driverData[0].map( el => el['200'][0]);
    const cardCheck: any[] = driverData[1].map(el=>el['200']);

    if(routeCheck){
      let i = 0;
      const currentRouteInfo = this.currentRouteInfo();

      currentRouteInfo.availableDrivers = [];
      currentRouteInfo.activeDrivers = [];

      const prototypeOptionsTracker: boolean[][][] = []

      routeCheck.forEach((el: TrelloCard|null) => {
        if(el){ 
          const name = el.name;
          prototypeOptionsTracker.push([])
          const nameCheck = currentRouteInfo.activeDrivers.findIndex(driver => driver.routeCard.name === name);
          if(nameCheck < 0){      
            const routeCards: DragItem[] = [];

            const activeDriver = CURRENT_DRIVERS[i];
            activeDriver.routeCard = el;

            const unformatedRouteCards: TrelloCard[] = cardCheck[i];
            
            if(unformatedRouteCards){
              unformatedRouteCards.forEach(trelloCard => {
                const newDriverRouteCard =this.mapTrelloCardToDragItem(trelloCard)
                newDriverRouteCard.dragType = 'activeDriverCard';

                routeCards.push(newDriverRouteCard)
              });
            }
            activeDriver.routeCards = routeCards;
            //this.showItemOrCard[1].push(true);
  
            currentRouteInfo.activeDrivers.push(activeDriver);
          } else { currentRouteInfo.availableDrivers.push(CURRENT_DRIVERS[i])}
        } else {currentRouteInfo.availableDrivers.push(CURRENT_DRIVERS[i])}
        i++;
      });

      // Qeue up the Checklist
      const activeDrivers = currentRouteInfo.activeDrivers;

      if(activeDrivers.length > 0){
        // Populate 
        //Close Available and open Active boards
        currentRouteInfo.view.routeViewObjects.minorHeaders[2] = false;
        currentRouteInfo.view.routeViewObjects.minorHeaders[4] = true;

        const driverCards = activeDrivers.map(el=>el.routeCard);

        this.setAllTrelloInformation(driverCards, 'activeDrivers', 'checklists');
      }

      this.nextRouteInfo(currentRouteInfo, 'setDriverTrelloInfo');

    }    
  }  

  setRouteBtnContextView(routeBtnContextView: RouteBtnContextView, from: string){
    const newRouteInfo = this.currentRouteInfo();

    newRouteInfo.view.routeBtnContextView = routeBtnContextView;
  }

  // MARK - Search
  searchTrelloLabels(labels: TrelloLabel[], what: string[], from: string): TrelloLabel| null{
    let foundLabel = new TrelloLabel;

    const labelNameArray = labels.map(el => el.name);
    
    what.forEach((searchString:string) => {
      const labelSearchIndex = labelNameArray.findIndex(el =>  el.includes(searchString));
      if(labelSearchIndex > -1){ foundLabel = labels[labelSearchIndex]};
    });

    return foundLabel;
  }

  // MARK - TOOLS
  setupItemToItemCreation(type: string, driver: Driver, dragItem: DragItem, routeDragDrop: RouteDragDrop, pos: string|number, from: string):  Observable<Object>[]{
 

    let postData: Observable<Object>[] = [];
    let routeChanges:RouteChange[] = []

    let checkItemName = '';
    const idChecklistRoute = driver.routeCard.idChecklists[0];
    const idChecklistCard = dragItem.idChecklist;
    const idCheckItem = dragItem.idCheckItem;

      
    const checklistName = (dragItem.name + "(r)").trim(); 

    const checkItemData = {name: checkItemName, idChecklist: idChecklistRoute, pos: pos };
    const checklistData = {name: checklistName};
    
    // Special cases (Action drop|carry|return)
    if(type === 'moveTitgToDriver' ){ 


      // Special cases (Action drop|carry|return)
      const action = dragItem.action.toLocaleLowerCase();
      const includesDrop = action.includes('drop') || action.includes('carry') || action.includes('boom');

      console.log("aLLL case!@#", includesDrop, dragItem.checkItems)
      if (!includesDrop || dragItem.checkItems.length > 0) {// ALL Case not Dropped
        console.log("aLLL case!@#")
        if(dragItem.name.toLowerCase().includes('pickup')){dragItem.name.replace(/^pickup*/,'PICKUP')}
        checkItemData.name = '**' + this.capitalizeWords(dragItem.name) + '**' + " " +  dragItem.url;    

        // None drop drag items have to have checklist name ammended and item added. Two Route actions 
        console.log(checkItemData, " More updating")
        postData.push (this.trelloService.putRequest('put:checklist','', idChecklistCard, '', checklistData));
        postData.push(this.trelloService.postRequest('post:checkItem','', idChecklistRoute,'', checkItemData));

        // Make and post changes
        routeChanges.push(this.mapRouteChange('put:checklist', routeDragDrop ,type));
        routeChanges.push(this.mapRouteChange('post:checkItem', routeDragDrop ,type));
      }else{ // Drop Case
        checkItemName = "**" + this.capitalizeWords(dragItem.action) + " @ " + dragItem.location.trim() + "** " + dragItem.url;    
        checkItemData.name = checkItemName;

        postData.push(this.trelloService.postRequest('post:checkItem','', idChecklistRoute,'', checkItemData));
      }
    };

    // Move a drag item type driver to driver
    if(type === 'moveDrivertoDriver'){ 
      checkItemData.name  = dragItem.name.trim();  

      postData.push(this.trelloService.postRequest('post:checkItem','', idChecklistRoute,'', checkItemData));
      postData.push(this.trelloService.delRequest('del:checkItem', '', idChecklistCard, idCheckItem));

      // Make and post changes
      //routeChanges.push(this.mapRouteChange('post:checkItem', passDropData, type));
      //routeChanges.push(this.mapRouteChange('del:checkItem', passDropData, type));
    };

    return postData;
  }

  setupDriverHeaders(driver: Driver): Driver{
    const newDriver = driver
    const driverRouteCard = new TrelloCard;


    const today = new Date;
    const fDate: string = (today.getMonth() + 1 + '/' + today.getDate()+ '/' +  today.getFullYear()).toString() ;
    let strHour = '';
    if(today.getHours()>9){ strHour = today.getHours().toString()}else{strHour = '0' + today.getHours().toString()}

    const driverStartTime = parseInt(driver.driverInfo.startTime.charAt(1));

    let routeName = '';

    // If routing begin after start time or before
    if(driverStartTime && driverStartTime > today.getHours()){
      routeName =  driver.driverInfo.startTime + ' ' + fDate + ' ' + driver.driverInfo.initial;
    }else {
      let hour = ''
      if(today.getHours() > 9){ 
        
        hour = strHour + ':' + today.getMinutes().toString()}
      else { hour = strHour + ':00'}
      routeName = hour + ' ' + fDate + ' ' + driver.driverInfo.initial;
    }

     
    driverRouteCard.name = routeName;

    driverRouteCard.idCardSource = ROUTE_CARD.idCardSource;
    driverRouteCard.keepFromSource = 'all';
    driverRouteCard.idList = driver.trelloInfo.idChecklistDriverRoute
    
    //newDriver.dragItems = [];
    newDriver.routeCard = driverRouteCard;
   

    return  newDriver
  }

  setupCompletedRouteItems(driver: Driver): ShowCompeteRouteItems{
    const newShowCompleteRouteItems = new ShowCompeteRouteItems;
    
    const dragItems = driver.dragItems;

    newShowCompleteRouteItems.show = driver.driverView.showCompleteRouteItems.show ? false: true;

    const countState = this.recountShow(dragItems);
    if(countState){newShowCompleteRouteItems.count = countState};

    return newShowCompleteRouteItems;
  }

  recountShow(dragItems: DragItem[]): number{
    const countState = dragItems.map(dragItem => dragItem.state === 'complete').filter(dragItem => dragItem).length;
    return countState;
  }

  // MARK - POST
  putDriversRouteRequest(drivers: Driver[]){
    const currentDrivers = drivers;
    
    const postRoutes: Observable<any>[] = [];
    const postMaintenances: Observable<any>[] = [];

    // CHECK FOR MOVE CARD
    //const postMoveCards = this.routeService.moveCards(drivers)

    currentDrivers.forEach((driver: Driver) => {  
      // Init Maintenance && Driver Card 
      const driverMaintenanceCard = CARD_DAILY_MAINTENANCE;
      const driverRouteCard = this.setupDriverHeaders(driver);

      // Get listId's
      const idChecklistDriverRoute = driver.trelloInfo.idChecklistDriverRoute;
      const idChecklistDriverCards = driver.trelloInfo.idChecklistDriverCards;

      // Setup Cards
      driverMaintenanceCard.idList = idChecklistDriverCards;

      // Build Posts
      const postRoute = this.trelloService.postRequest('post:card','', idChecklistDriverRoute,'', driverRouteCard.routeCard);
      const postMaintenance = this.trelloService.postRequest('post:card','',idChecklistDriverCards,'', driverMaintenanceCard);
      
      postRoutes.push(postRoute);
      postMaintenances.push(postMaintenance);

      //urls.push(createUrl)
      //data.push(routeMainCard);
    });

    const posts = [...postRoutes, ...postMaintenances]

    return forkJoin(posts)
  }

  // MARK - BUILDs
  buildEmail(dragItem: DragItem){      
    const defaultVendor = DEFAULT_VENDORS;

    // Long check will be used when an address is added
    let location = dragItem.location.toLowerCase();

    // Check for long address
    if(location.includes(':')){location = location.replace(/^(.*?):.*/, '$1')};

    // Check for ordered status or routed
    if(location.includes('(o)')|| location.includes('(ordered)')||location.includes('(r)')|| location.includes('(routed)')){
      location = location.replace(/\s*\(.*?\)\s*/, '');
      location = location.trim();
    }else {location = location.trim()}

      // Get vendors and return
    const vendorName =  defaultVendor.map(vendors => vendors.name.toLocaleLowerCase());
    const vendorIndex = vendorName.findIndex( vendor => vendor === location);

    if(vendorIndex >= 0){
      const emailVendor = defaultVendor[vendorIndex];

      const items: TrelloCheckItem[] = dragItem.checkItems;
      const mappedItemNames = items.map(item => item.name);
      
      const newEmail = new EmailOrder;  
      newEmail.email_to = emailVendor.emailAddress.join(';');
      newEmail.email_cc = 'orders@roomerator.com';

      console.log(dragItem)
      // Setting the subject
      if(dragItem.action.includes('return')){newEmail.email_subject = "Returns (Roomerator)"}
      else{newEmail.email_subject = "New order (Roomerator)"};

      newEmail.email_body = '';
      const timeCheck = new Date
      const timeCurrent = timeCheck.getHours();
      let gretting = 'Good morning,'

      if(timeCurrent > 11){ gretting = 'Good afternoon,' }
      else if(timeCurrent > 16){ gretting = 'Good evening,'}

      // Set email intro
      let bodyIntro = gretting + '\n\nLooking to place this order for pickup today:\n\n#1\n\n';
      if(dragItem.action.includes('return')){gretting + '\n\nWe have some returns coming in today:\n\n#1\n\n'};

      const bodyDetails = `Driver: ` + dragItem.driver + `\n\nPO: ` + dragItem.po + `\nItems:\n`;
      const bodyCheckItems = mappedItemNames.join('\n');

      const bodyConclusion = '\n___________ END ___________\n\nThank you,\n';

      const currentUser = this.accountService.currentAccountInfo();


      const firstName = this.capitalizeWords(currentUser.user.user_first_name);
      
      const lastName = this.capitalizeWords(currentUser.user.user_last_name);

      const bodyTag = '\n' + firstName + ' ' + lastName;


      newEmail.email_body = bodyIntro + bodyDetails + bodyCheckItems + bodyConclusion + bodyTag;

      return newEmail;
    }
   
    return null;

  }

  sendEmail(emailOrder: EmailOrder){
    // Test email up in here: Change to email order for the real deal
    const testEmail = DEFAULT_EMAIL_ORDER;
    
    const jsonEmail = JSON.stringify(testEmail[0]);

    const requestArray = {
      action_id: 'order_email',
      admin_data: jsonEmail
    }

    // The real deal
    //const jsonEmail = JSON.stringify(emailOrder);

    const requestEmail = this.accountService.sendRequest(requestArray,'json','tool-route.service:sendEmail')

    if(requestEmail){
      requestEmail.subscribe({
        next: (data: any) => {
          console.log(data, "<---- this is a move! email sent", );
         },
        error: (error: any) => { console.error(error); console.log("Nothing to show for it")}
      })
    }
  }

  refreshAllBoards(){    
    let idListRequest = '';

    const showBoard = this.currentRouteInfo().view.showBoard;
    
    if(showBoard === 0){idListRequest = this.idListTitg };
    if(showBoard === 1){idListRequest = this.idListTeam };
    if(showBoard === 2){idListRequest = this.idListTesting };

    // QUERY TisG
    const titgRequest = this.trelloService.getRequest('list_card', idListRequest );
    const driverBoardRequest = this.getDriverBoards('route_card');
    const driverCardRequest = this.getDriverBoards('cards');

    // Used to find checklist ID's for cross referencing
    const photoCardRequest = this.getDriverBoards('pictures');
    

    const joinRequest:Observable<any>[] = [titgRequest, driverBoardRequest, driverCardRequest,photoCardRequest];
    //const joinRequest:Observable<any>[] = [titgRequest, driverBoardRequest, driverCardRequest];

    // Setup up Batch Request
    const forkRequest = forkJoin(joinRequest);

    // Request Board
    if(forkRequest){
      forkRequest.subscribe({
        next: (data: any) => {
          const currentRouteInfo = this.currentRouteInfo();
          const titgCards: TrelloCard[] = data.shift();
          //const activeCards = titgCards.slice(0,1);
          
          //console.log(titgCards, activeCards, " DATA ", data)
          //mapActiveCards()
          const arrayActiveCards: TrelloCard[] = [...titgCards, ...data[data.length-2], ...data[data.length-1]];
          
          const formattedActiveCards = this.mapActiveCards(arrayActiveCards)
          currentRouteInfo.activeCards = formattedActiveCards;
          console.log(currentRouteInfo.activeCards)

          // Setup the TITG Board && Route Card
          if(titgCards && titgCards.length > 0){
            this.setAllTrelloInformation(titgCards, "titg", 'checklists')}
          else{ // EMPTY route
            currentRouteInfo.titgCardCatalog = [];
            this.nextRouteInfo(currentRouteInfo, 'refreshAllBoards');
          }

          // Setup the Driver Cards
          this.setDriverTrelloInfo(data);   
          this.completLogisticsSetup();

        },
        error: (error: any) => { console.error(error)}
      })
    }

    // QUERY ACTIVE DRIVERS
    // QUERY DRIVER BOARDS
  }

  getDriverBoards(request: string): any{
    const drivers = CURRENT_DRIVERS;
    const urls: string[] = [];
    let url = '';

    switch(request){
      case 'cards':      
        drivers.forEach((driver: Driver) => {
          const idBoardDriver = driver.trelloInfo.idChecklistDriverCards;
          
          const url = `/lists/${idBoardDriver}/cards?`
          urls.push(url)
        });
      return this.trelloService.getBatchRequest(urls);
      
      case 'route_card':
        drivers.forEach((driver: Driver) => {
          const driverRouteID = driver.trelloInfo.idChecklistDriverRoute;
          const url = `/lists/${driverRouteID}/cards?`;
          urls.push(url);
        });
      return this.trelloService.getBatchRequest(urls);

      case 'pictures':
        const idListPhoto = '5f32f726dc3428568b44a7e8';
        url = this.trelloService.buildGetPath('list_card',idListPhoto);
        urls.push(url)
      return this.trelloService.getBatchRequest(urls);

      case 'testing':
        url = this.trelloService.buildGetPath('list_card',this.idListTesting);
        urls.push(url)
      return this.trelloService.getBatchRequest(urls);

      case 'team':
        url = this.trelloService.buildGetPath('list_card',this.idListTeam);
        urls.push(url)
      return this.trelloService.getBatchRequest(urls);

    }

    return urls;
  }

  completLogisticsSetup(){
    // TESTING DRIVER SETUP
    //this.routeInfo.drivers = TESTING_DRIVER;
    const newRouteView = this.currentRouteInfo();
    // CURRENT ACTIVE DRIVERS
    newRouteView.view.isLoading = false;
    newRouteView.view.showRoutePage = true;
    newRouteView.view.trelloView.showBoards = true;
    this.nextRouteInfo(newRouteView, "completLogisticsSetup");
  }
  
  // MARK - Simple helpers
  capitalizeWords(str: string) {
    return str.replace(/\b\w/g, function (match) {
        return match.toUpperCase();
    });
  }

  formatBoldText(inputString: string) {
    let firstOccurrence = inputString.replace(/\*\*/, '<b>');
    let finalString = firstOccurrence.replace(/\*\*/, '</b>');
    return finalString;
  }

  assignRouteChange(data: TrelloCard | DragItem, indexTitgCardOrDriver:number, indexTitgChecklistOrRouteDragItem: number, indexDragItem: number, changeType: string, from: string): RouteChange{
    const newRouteChange = new RouteChange;
    
    newRouteChange.changeType = changeType;

    if(data instanceof TrelloCard){newRouteChange.trelloCard = data};
    if(data instanceof DragItem){newRouteChange.dragItem = data};

    const newRouteIndexes: RouteIndexes ={
      indexTitgCardOrDriver: indexTitgCardOrDriver,
      indexTitgChecklistOrRouteDragItem: indexTitgChecklistOrRouteDragItem,
      indexDragItem: indexDragItem
    } 

    newRouteChange.routeIndexes = newRouteIndexes;
    newRouteChange.from = from;   

    return newRouteChange;
  }

  generateRouteBtnContextViewFromDragItem(dragIem: DragItem|null, idCard: string, from: string): RouteBtnContextView{
    
    const dragItem = new DragItem;
    dragItem.idCard = idCard

    const newRouteBtnContextView = new RouteBtnContextView
    newRouteBtnContextView.btnContextMenuInfo = new RouteChange;
    newRouteBtnContextView.btnContextMenuInfo.dragItem = dragItem;

    return newRouteBtnContextView;
  }

  handleError(error: any) {
    console.error('An error occured', error);
    return Promise.reject(error.message || error);
  }
}