

<!-- Background -->
<div class="input-window-background" (click)="dismiss()">
    <!-- Dismiss Button -->
    <label class="input-window-dismiss-label" (click)="dismiss()">Dismiss</label>
</div>

<!-- Input Window -->
<div class="input-window {{currentType.window_css}}">

    <div class="input-window-container" 
    [ngClass]="modalWindowInfo.isLoading ? 'hide-element': '' ">
        <!-- Loading -->
        <div class="input-window-loading-background"
            [ngClass]="modalWindowInfo.isLoading ? '': 'hide-element' ">
            <app-loading *ngIf="modalWindowInfo.isLoading"></app-loading>
        </div>


                
<!-- Header-->
        <div class="input-window-header">
            <div class="col-10">
                <div class="{{currentType.title_css}}" >{{currentType.title}}</div>
            </div>
            <div class="col-2">
                <button (click)="dismiss()" class="input-btn-dismiss"></button>
            </div>
        </div>
        <div class="{{currentType.message_1_container_css}}">
            <label class="{{currentType.message_1_css}}">{{currentType.message_1}}</label>
        </div>

<!-- Show Iframe: Case 11 -->
        <div *ngIf="showIframe">
            <iframe [src]="iframeUrl" frameborder="1" width="100%" height="100%" ></iframe>
        </div>

<!-- FORM CONTROLS-->    
        <form>

<!-- Show INPUT & BUTTON: Case 1 -->
            <div *ngIf="showInputButton">
                <div *ngFor="let row of arrayInputButton">
                    
                </div>
            </div>
<!-- Show OUTPUT & BUTTON: Case 2 -->
            <div *ngIf="showOutputButton">
                <div *ngFor="let row of arrayOutputButton">
                    
                </div>
            </div>

<!-- Show ROW BUTTON: Case 3 -->
            <div *ngIf="showButtons" class="input-window-btn-container">
                <div 
                    *ngFor="let row of arrayButtons; let i = index" 
                    class="{{row.button_setting.container_class}}" >
                    <button 
                        class="{{row.button_setting.button_css_setting}}"
                        (click)="buttonClick(row)"
                        [disabled]="getDisabled(i, row)">
                        {{row.button_setting.button_message}}
                    </button>
                </div>
            </div>
   
<!-- Show HYPERRLINK: Case 6 -->
            <div *ngIf="showDownload">
            </div>

<!-- Show AUTOCOMPLETE: Case 7 -->
            <div *ngIf="showAutocomplete">
            </div>
                        
<!-- Show INPUT: Case 0 -->
            <div *ngIf="showInput">
                <div *ngFor="let row of arrayInput; let i = index">
                    <div *ngIf="row.input_setting">
                        <app-inputbox
                            (validData)="checkInput($event, i)" 
                            (enterPress)="checkInput($event, i)" 
                            [inputSetting]="row.input_setting">
                        </app-inputbox>
                    </div>
                  
                </div>
            </div>


<!-- Show MESSAGES: Case 8 -->
            <div *ngIf="showMessage">
            </div>

<!-- Show SEARCH: Case 9 -->
            <div *ngIf="showSearch">
            </div>
            
<!-- Show CANCEL BUTTON: Case 5 -->
            <div *ngIf="showCancelButton" 
                class="col-6 col-md-5 float-right">
                <div *ngFor="let row of arrayCancelButton">
                    <button 
                        class="{{row.button_setting.button_css_setting}}"
                        (click)="buttonClick(row)">
                        {{row.button_setting.button_message}}
                    </button>
                </div>
            </div>

        </form>

    </div>


</div>


