import { Component, OnChanges, Input } from '@angular/core';
import { trigger, state, animate, transition, style} from '@angular/animations'


@Component({
  selector : 'animation-right-left',
  styleUrls: ['right-left-animation.css'],
  template: `
    <div [@flyRightLeft]="rightLeft" id="{{id}}">
        <ng-content></ng-content>            
    </div>
  `,
  
  
 animations: [
    trigger('flyRightLeft', [
      state('right', style({transform: `translateX(100%)`})),
      state('left', style({transform: 'translateX(0)'})),
      transition('right => left', [
        style({transform: 'translateX(100%)'}),
        animate(350)
      ]),
      
      transition('right => left', [
        animate(350)
      ])
    ])
  ]
})

export class RightLeftAnimation implements OnChanges {
     @Input() isRightLeft : boolean = true;
     @Input() id: string = '';
     @Input() movePercaentage = '100%'
     rightLeft = 'left';
    
    ngOnChanges() {
        this.rightLeft = this.isRightLeft ? 'left': 'right'; 
    }

}